import { ComponentBase } from './control-base';
import { isMobile } from 'is-mobile';

export class MaskInputComponent extends ComponentBase<string> {
  controlType = 'mask';
  type: string;

  constructor(config: {}) {
    super(config);
    this.type = config['type'] || '';

    if (config['keyboard'] === 'number') {
      this.type = isMobile() ? 'tel' : 'textbox';
    }
  }

}
