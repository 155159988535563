<ng-container *ngIf="show && !isComAdmin">
    <div class="newsletter-banner" appBackgroundDesign [backgroundDetails]="backgroundDetails" [ngClass]="{'news-after-login': isAuthenticated}">
        <h2>כל בילוי מתחיל בפינוק</h2>
        <span class="text-before-login">
            ההטבות הכי שוות אצלך במייל
        </span>

        <div class="button-wrapper">
            <a [attr.href]="isAuthenticated ? regUrl : anonUrl" appGtm [data]="{category: 'New website - anonymous', action: 'Update me on benefits', label: 'Click'}" listen="onclick"
               title="כן, תעדכנו אותי" class="update-me"><span>לקבלת ההטבות</span></a>
        </div>
    </div>
</ng-container>
