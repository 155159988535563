<div class="success">
    <div class="main-title">
        <img src="/assets/images/marketing-agreement-popup/illustrations-best.svg" alt="" class="best-icon" />
        <h1>יש לך את זה!</h1>
        <h2>מבטיחים לשלוח עדכונים ומבצעים שווים</h2>
    </div>
    <div class="button-wrapper">
        <a (click)="closeMarketingAgreementPopup.emit()" title="" id="finish" class="brand-button aqua-button"><span>סיימתי, תודה</span></a>
        <span class="update-time">*העדכון יכנס לתוקף תוך 2 ימי עסקים</span>
    </div>
</div>
