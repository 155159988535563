<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-body">
            <span class="sad-icon"></span>
            <span class="text">
                יכול להיות שהלכת בלי לומר שלום?
            </span>
            <span class="text">
                בעוד {{countdown}} שניות גם אנחנו מתנתקים
            </span>
            <div class="stay-connect" (click)="continue(false)">אני עדיין פה</div>
            <a class="logout" (click)="continue(true)">זזתי, להתראות</a>
        </div>
    </div>
</div>