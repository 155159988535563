import { TypingFormat } from "../../shared/enums/typing-format.enum";

export const METADATA_ELIGIBILITY = [
    {
        "name": "בהצדעה",
        "title": "בהצדעה",
        "key": "eligibility",
        "type": "group",
        "productCode": "31",
        "ClubCode": "",
        "eventAction": "beatzdaa",
        "controls": [
            {
                "key": "MemberTypeCode",
                "label": "האם את/ה חייל/ת מילואים או יש לך חייל/ת מילואים במשפחה",
                "type": "select-button",
                "value": 0,
                "options": [
                    {
                        "label": "חייל.ת מילואים / משוחרר.ת",
                        "value": 0
                    },
                    {
                        "label": "בן.ת משפחה של החייל.ת",
                        "value": 1
                    }
                ],
                "required": true
            },
            {
                "key": "MilitaryNumber",
                "label": "מספר אישי",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "pattern",
                        "value": "[0-9]{2,10}",
                        "errorMessage": "אין מצב שזהו המספר הנכון. יש להכניס רק ספרות ולפחות שתיים, ולא יותר מעשר"
                    }
                ]
            },
            {
                "key": "CertificateNumber",
                "label": "מספר תעודה צבאית או רישיון נהיגה",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "pattern",
                        "value": "[0-9]{6,16}",
                        "errorMessage": "אין מצב שזהו המספר הנכון של התעודה או רישיון הנהיגה. יש להכניס רק ספרות ולפחות שש. "
                    }
                ],
                "hint": {
                    "toggleText": "מה זה?",
                    "text": "כל מספרי התעודות הבאות תופסים: תעודה צבאית שברשותך: קצין מילואים/חוגר מילואים/שחרור בכבוד או מספר רישיון נהיגה או מספר תעודת משרת מילואים פעיל (8 ספרות אחרונות מודגשות בלבד)"
                }
            },
            {
                "key": "MemberIdNumber",
                "label": "תעודת זהות",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "maxLength": 9,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "id",
                        "errorMessage": "אחורה פנה. יש להכניס שוב רק ספרות וספרת ביקורת תקינה. "
                    },
                    {
                        "type": "notEqual",
                        "errorMessage": "תעודת הזהות של חבר מועדון אינה יכולה להיות תעודת הזהות של מבקש הבקשה במסלול בן משפחה",
                        "targetKey": "IdNumber"
                    }
                ]
            },
            {
                "key": "FirstName",
                "label": "שם פרטי של מבקש/ת הכרטיס",
                "type": "textbox",
                "required": true,
                "validators": [
                    {
                        "type": "pattern",
                        "value": "([\u0590-\u05FF-'\" ]+$)",
                        "errorMessage": "עשו טובה ותכניסו רק אותיות בעברית"
                    },
                    {
                        "type": "maxlength",
                        "value": 30,
                        "errorMessage": "יש להכניס שם פרטי באורך של עד 30 תווים"
                    }
                ],
                "hint": {
                    "toggleText": "מה זה?",
                    "text": "אל תחסכו כאן. תכניסו את השם המלא כפי שמופיע בתעודת הזהות כולל שם אמצעי אם יש"
                }
            },
            {
                "key": "LastName",
                "label": "שם המשפחה של מבקש/ת הכרטיס",
                "type": "textbox",
                "required": true,
                "validators": [
                    {
                        "type": "pattern",
                        "value": "([\u0590-\u05FF-'\" ]+$)",
                        "errorMessage": "עשו טובה ותכניסו רק אותיות בעברית"
                    },
                    {
                        "type": "maxlength",
                        "value": 30,
                        "errorMessage": "יש להכניס שם משפחה באורך של עד 30 תווים"
                    }
                ]
            },
            {
                "key": "CellularPhoneNumber",
                "label": "טלפון נייד של מבקש/ת הכרטיס",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "maxLength": 10,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "maxlength",
                        "value": 10,
                        "errorMessage": "יש להכניס מספר בן 10 ספרות, ללא מקף - הוא לא נחשב"
                    },
                    {
                        "type": "minlength",
                        "value": 10,
                        "errorMessage": "יש להכניס מספר בן 10 ספרות, ללא מקף - הוא לא נחשב"
                    },
                    {
                        "type": "cellPhonePrefixInvalid",
                        "value": "05",
                        "errorMessage": "לתשומת ליבכם, אנחנו בישראל. יש להכניס רק מספר טלפון שמתחיל ב - 05"
                    },
                    {
                        "type": "pattern",
                        "value": "^05[0-9]{8}$",
                        "errorMessage": "יש להכניס מספר בן 10 ספרות, ללא מקף - הוא לא נחשב"
                    }
                ],
            },
            {
                "key": "IdNumber",
                "label": "תעודת זהות של מבקש/ת הכרטיס",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "maxLength": 9,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "id",
                        "errorMessage": "אחורה פנה. יש להכניס ספרות בלבד"
                    },
                    {
                        "type": "notEqual",
                        "errorMessage": "תעודת הזהות של חבר מועדון אינה יכולה להיות תעודת הזהות של מבקש הבקשה במסלול בן משפחה",
                        "targetKey": "MemberIdNumber"
                    }
                ]
            }
        ]
    },
    {
        "name": "מקומות עבודה",
        "title": "Executive",
        "key": "zakaut",
        "type": "group",
        "productCode": "4",
        "ClubCode": "999",
        "eventAction": "executive",
        "controls": [
            {
                "key": "ClubCode",
                "label": "שם/קוד מקום העבודה",
                "type": "autocomplete",
                "keyboard": "number",
                "required": true,
                "disablePaste": true,
                "requestTarget": "value",
                "validators": [
                    {
                        "type": "selectItemForceSelection",
                        "errorMessage":"נא לבחור ערך מהרשימה"
                    }
                ]
            },
            {
                "key": "FirstName",
                "label": "שם פרטי",
                "type": "textbox",
                "required": true,
                "validators": [
                    {
                        "type": "pattern",
                        "value": "([\u0590-\u05FF-'\" ]+$)",
                        "errorMessage": "מבינים עברית? יש להכניס רק אותיות בעברית"
                    },
                    {
                        "type": "maxlength",
                        "value": 30,
                        "errorMessage": "יש להכניס שם פרטי באורך של עד 30 תווים"
                    }
                ],
                "hint": {
                    "toggleText": "מה זה?",
                    "text": "אל תחסכו כאן. תכניסו את השם המלא כפי שמופיע בתעודת הזהות כולל שם אמצעי אם יש"
                }
            },
            {
                "key": "LastName",
                "label": "שם המשפחה",
                "type": "textbox",
                "required": true,
                "validators": [
                    {
                        "type": "pattern",
                        "value": "([\u0590-\u05FF-'\" ]+$)",
                        "errorMessage": "מבינים עברית? יש להכניס רק אותיות בעברית"
                    },
                    {
                        "type": "maxlength",
                        "value": 30,
                        "errorMessage": "יש להכניס שם משפחה באורך של עד 30 תווים"
                    }
                ]
            },
            {
                "key": "CellularPhoneNumber",
                "label": "טלפון נייד",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "maxLength": 10,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "maxlength",
                        "value": 10,
                        "errorMessage": "יש להכניס מספר בן 10 ספרות, ללא מקף - הוא לא נחשב"
                    },
                    {
                        "type": "minlength",
                        "value": 10,
                        "errorMessage": "יש להכניס מספר בן 10 ספרות, ללא מקף - הוא לא נחשב"
                    },
                    {
                        "type": "cellPhonePrefixInvalid",
                        "value": "05",
                        "errorMessage": "לתשומת ליבכם, אנחנו בישראל. יש להכניס רק מספר טלפון שמתחיל ב - 05"
                    }
                ],
            },
            {
                "key": "IdNumber",
                "label": "תעודת זהות",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "maxLength": 9,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "id",
                        "errorMessage": "קורה שטועים. יש להכניס שוב רק ספרות וספרת ביקורת תקינה. "
                    }
                ]
            }
        ]
    },
    {
        "name": "תמורה",
        "title": "תמורה",
        "key": "zakaut",
        "type": "group",
        "productCode": "32",
        "ClubCode": "",
        "eventAction": "teachers",
        "controls": [
            {
                "key": "MemberTypeCode",
                "label": "האם את/ה חבר/ה בארגון המורים / בן/בת משפחה",
                "type": "select-button",
                "value": 0,
                "options": [
                    {
                        "label": "חבר/ה בארגון המורים",
                        "value": 0
                    },
                    {
                        "label": "בן/בת משפחה",
                        "value": 1
                    }
                ],
                "required": true,
                "hint": {
                    "toggleText": "מה זה?",
                    "text": "הכרטיס יונפק רק לבני/בנות 18 ומעלה בעלי קירבה מדרגה ראשונה לחבר/ה בארגון המורים. "
                }
            },
            {
                "key": "MemberIdNumber",
                "label": "תעודת הזהות של חבר/ה בארגון המורים",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "maxLength": 9,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "id",
                        "errorMessage": "כמעט טוב. יש להכניס שוב רק ספרות וספרת ביקורת תקינה."
                    },
                    {
                        "type": "notEqual",
                        "errorMessage": "תעודת הזהות של חבר מועדון אינה יכולה להיות תעודת הזהות של מבקש הבקשה במסלול בן משפחה",
                        "targetKey": "IdNumber"
                    }
                ]
            },
            {
                "key": "FirstName",
                "label": "שם פרטי של מבקש/ת הכרטיס",
                "type": "textbox",
                "required": true,
                "validators": [
                    {
                        "type": "pattern",
                        "value": "([\u0590-\u05FF-'\" ]+$)",
                        "errorMessage": "עברית שפה יפה. יש להכניס רק אותיות בעברית"
                    },
                    {
                        "type": "maxlength",
                        "value": 30,
                        "errorMessage": "יש להכניס שם פרטי באורך של עד 30 תווים"
                    }
                ],
                "hint": {
                    "toggleText": "מה זה?",
                    "text": "אל תחסכו כאן. תכניסו את השם המלא כפי שמופיע בתעודת הזהות כולל שם אמצעי אם יש"
                }
            },
            {
                "key": "LastName",
                "label": "שם המשפחה של מבקש/ת הכרטיס",
                "type": "textbox",
                "required": true,
                "validators": [
                    {
                        "type": "pattern",
                        "value": "([\u0590-\u05FF-'\" ]+$)",
                        "errorMessage": "עברית שפה יפה. יש להכניס רק אותיות בעברית"
                    },
                    {
                        "type": "maxlength",
                        "value": 30,
                        "errorMessage": "יש להכניס שם משפחה באורך של עד 30 תווים"
                    }
                ]
            },
            {
                "key": "CellularPhoneNumber",
                "label": "טלפון נייד של מבקש/ת הכרטיס",
                "type": "textbox",
                "required": true,
                "maxLength": 10,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "maxlength",
                        "value": 10,
                        "errorMessage": "יש להכניס מספר בן 10 ספרות, ללא מקף - הוא לא נחשב"
                    },
                    {
                        "type": "minlength",
                        "value": 10,
                        "errorMessage": "יש להכניס מספר בן 10 ספרות, ללא מקף - הוא לא נחשב"
                    },
                    {
                        "type": "cellPhonePrefixInvalid",
                        "value": "05",
                        "errorMessage": "לתשומת ליבכם, אנחנו בישראל. יש להכניס רק מספר טלפון שמתחיל ב - 05"
                    }
                ],
            },
            {
                "key": "IdNumber",
                "label": "תעודת זהות של מבקש/ת הכרטיס",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "maxLength": 9,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "id",
                        "errorMessage": "כמעט טוב. יש להכניס שוב רק ספרות וספרת ביקורת תקינה."
                    },
                    {
                        "type": "notEqual",
                        "errorMessage": "תעודת הזהות של חבר מועדון אינה יכולה להיות תעודת הזהות של מבקש הבקשה במסלול בן משפחה",
                        "targetKey": "MemberIdNumber"
                    }
                ]
            }
        ]
    },
    {
        "name": "ביחד",
        "title": "ביחד",
        "key": "beyahad",
        "type": "group",
        "productCode": "34",
        "ClubCode": "",
        "eventAction": "histadrut",
        "controls": [
            {
                "key": "MemberTypeCode",
                "label": "האם את/ה חבר/ת ההסתדרות / בן/בת משפחה",
                "type": "select-button",
                "value": 0,
                "options": [
                    {
                        "label": "חבר/ת ההסתדרות",
                        "value": 0
                    },
                    {
                        "label": "בן/בת משפחה",
                        "value": 1
                    }
                ],
                "required": true,
                "hint": {
                    "toggleText": "מה זה?",
                    "text": "הכרטיס יונפק רק לבני/בנות 18 ומעלה בעלי קירבה מדרגה ראשונה לחבר/ה הסתדרות"
                }
            },
            {
                "key": "MemberIdNumber",
                "label": "תעודת זהות של חבר/ת ההסתדרות",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "maxLength": 9,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "id",
                        "errorMessage": "יש להכניס שוב רק ספרות וספרת ביקורת תקינה. תודה. "
                    },
                    {
                        "type": "notEqual",
                        "errorMessage": "תעודת הזהות של חבר מועדון אינה יכולה להיות תעודת הזהות של מבקש הבקשה במסלול בן משפחה",
                        "targetKey": "IdNumber"
                    }
                ]
            },
            {
                "key": "FirstName",
                "label": "שם פרטי של מבקש/ת הכרטיס",
                "type": "textbox",
                "required": true,
                "validators": [
                    {
                        "type": "pattern",
                        "value": "([\u0590-\u05FF-'\" ]+$)",
                        "errorMessage": "משהו לא מסתדר. יש להכניס רק אותיות בעברית"
                    },
                    {
                        "type": "maxlength",
                        "value": 30,
                        "errorMessage": "יש להכניס שם פרטי באורך של עד 30 תווים"
                    }
                ],
                "hint": {
                    "toggleText": "מה זה?",
                    "text": "אל תחסכו כאן. תכניסו את השם המלא כפי שמופיע בתעודת הזהות כולל שם אמצעי אם יש, אז בכיף. "
                }
            },
            {
                "key": "LastName",
                "label": "שם המשפחה של מבקש/ת הכרטיס",
                "type": "textbox",
                "required": true,
                "validators": [
                    {
                        "type": "pattern",
                        "value": "([\u0590-\u05FF-'\" ]+$)",
                        "errorMessage": "משהו לא מסתדר. יש להכניס רק אותיות בעברית"
                    },
                    {
                        "type": "maxlength",
                        "value": 30,
                        "errorMessage": "יש להכניס שם משפחה באורך של עד 30 תווים"
                    }
                ]
            },
            {
                "key": "CellularPhoneNumber",
                "label": "טלפון נייד של מבקש/ת הכרטיס",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "maxLength": 10,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "maxlength",
                        "value": 10,
                        "errorMessage": "יש להכניס מספר בן 10 ספרות, ללא מקף - הוא לא נחשב"
                    },
                    {
                        "type": "minlength",
                        "value": 10,
                        "errorMessage": "יש להכניס מספר בן 10 ספרות, ללא מקף - הוא לא נחשב"
                    },
                    {
                        "type": "cellPhonePrefixInvalid",
                        "value": "05",
                        "errorMessage": "לתשומת ליבכם, אנחנו בישראל. יש להכניס רק מספר טלפון שמתחיל ב - 05"
                    }
                ],
            },
            {
                "key": "IdNumber",
                "label": "תעודת זהות של מבקש/ת הכרטיס",
                "type": "textbox",
                "keyboard": "number",
                "required": true,
                "maxLength": 9,
                "typingFormat": TypingFormat.IntegerNumbersOnly,
                "validators": [
                    {
                        "type": "id",
                        "errorMessage": "יש להכניס שוב רק ספרות וספרת ביקורת תקינה. תודה"
                    },
                    {
                        "type": "notEqual",
                        "errorMessage": "תעודת הזהות של חבר מועדון אינה יכולה להיות תעודת הזהות של מבקש הבקשה במסלול בן משפחה",
                        "targetKey": "MemberIdNumber"
                    }
                ]
            }
        ]
    }
];
