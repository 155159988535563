import { ComponentBase } from './control-base';

export class SelectButtonComponent extends ComponentBase<string> {
  controlType = 'select-button';
  options: string[] = [];

  constructor(config: {}) {
    super(config);
    this.options = config['options'] || '';
  }
}
