import { FormControl } from "@angular/forms";

export function isTrueValidator(control: any) {
    if (control.value) {
        return null;
    } else {
        return {
            valid: false
        }
    }
}