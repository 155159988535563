import { ComponentBase } from './control-base';
import { isNullOrUndefined } from 'core-max-lib';

export class AutoCompleteComponent extends ComponentBase<{ label: string, value: string }> {
    controlType = 'autocomplete';
    items: { label: string, value: string }[] = [];
    options: any;
    results: { label: string, value: string }[] = [];
    selected: any;
    dropdown: boolean;
    disabled: boolean = false;
    requestTarget: string;
    maxLength?: number;
    extraLabel?: string;

    selectFn: (e: any) => {};

    constructor(config: {}) {
        super(config);
        this.options = config['options'];
        this.dropdown = config['dropdown'] || false;
        this.selected = config['selected'] || "";
        this.requestTarget = config['requestTarget'] || "label";
        this.maxLength = config['maxLength'] || null;
        this.extraLabel = config['extraLabel'] || null;
    }

    setItems(data) {
        this.items = data;
    }

    clearItems() {
        this.items = [];
        this.results = [];
        this.value = null;
    }

    clearResults() {
        this.results = [];
    }

    setValue(value: any) {
        if (value) {
            this.onSelect(value);
        }
    }

    search(e) {
        if (e.query.length < 2 && e.originalEvent.type === 'input') {
            this.results = [];
            return;
        }
        this.results = this.items.filter(item => {
            return (item.label.toLowerCase().indexOf(e.query.toLowerCase()) !== -1);
        });
    }

    onSelect(e) {
        if (this.selectFn) {
            this.selectFn(e);
        }
    }

}
