import { Component, OnInit } from '@angular/core';
import { TotalMaxBackService } from "../../../services/total-max-back.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ReturnUrlService } from "../../../../../shared/cardholders-core/services/return-url.service";
import { AuthService } from "../../../../../shared/cardholders-core/services/auth.service";
import { IResponse } from "../../../../../shared/cardholders-core/services/http-service.service";
import { ILoginResult, LoginStatus } from "../../../../../shared/cardholders-core/models/login-result";
import { UiHelperService } from "../../../../../shared/cardholders-core/services/ui-helper.service";

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private router: Router,
        private uiHelper: UiHelperService) { }

    ngOnInit(): void {
        if (this.uiHelper.isBrowser) {
            this.authService.totalMaxBackLogin().subscribe((data: IResponse<ILoginResult>) => {

                if (data.Result && data.Result.LoginStatus === LoginStatus.success) {

                    return this.router.navigate(['total-max-back/personal/convert-page']);
                } else {
                    return this.router.navigate(['/login'],
                        { queryParams: { ReturnURL: 'total-max-back/personal/convert-page' } });
                }
            });
        }
    }

}
