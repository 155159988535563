import { Observable } from 'rxjs';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { isNullOrUndefined } from 'core-max-lib';
import { TokenService } from '../services/token.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpServiceTokenInterceptor implements HttpInterceptor {
    private isBrowser: boolean;

    constructor(@Inject(PLATFORM_ID) private platformId: any, private tokenService: TokenService) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            this.isBrowser &&
            window.location.origin.includes('cardholderslc-comadmin') &&
            req.method === 'POST' &&
            !req.url.includes('logs/log')
        ) {
            return this.handleNext(req, next);
        }
        if (
            !this.isBrowser ||
            req.url.includes('logs/log') ||
            req.method !== 'POST' ||
            (!req.url.startsWith('/api') &&
                !req.url.startsWith(window.location.origin) &&
                !req.url.includes('onlinelcapi') &&
                !req.url.includes('cardholderslcapi-comadmin8'))
        ) {
            return next.handle(req);
        }

        return this.handleNext(req, next);
    }

    private handleTokenHeaders(req: HttpRequest<any>): HttpRequest<any> {
        if (!isNullOrUndefined(this.tokenService.token)) {
            return req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + this.tokenService.token
                }
            });
        }

        return req;
    }
    private handleNext(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newReq = this.handleTokenHeaders(req);
        return next.handle(newReq).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    const token = event.headers.get('Token');
                    if (isNullOrUndefined(token)) {
                        this.tokenService.cleanToken();
                    } else {
                        this.tokenService.token = token;
                    }
                }
            })
        );
    }
}
