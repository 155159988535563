import { Injectable, Inject, Optional } from "@angular/core";
import { isNullOrUndefined } from 'core-max-lib';
import { DefaultUrlSerializer, Params, Router } from '@angular/router';
import { AppConfigsService } from "../../../config/app-configs.service";
import { UiHelperService } from "core-max-lib";
import { Ave8Service } from "./ave8.service";
import { HttpParams } from "@angular/common/http";


@Injectable()
export class ReturnUrlService {

    returnUrl: string;
    private defaultUrlSerializer: DefaultUrlSerializer;
    private originUrl;
    fullReturnUrlBusinessSsoLogin: any;
    fullReturnUrlBankSsoLogin: any;

    constructor(@Optional() @Inject('serverUrl') private serverUrl: string,
        private router: Router,
        private appConfigs: AppConfigsService,
        private uiHelper: UiHelperService,
        private ave8Serv: Ave8Service) {
        this.defaultUrlSerializer = new DefaultUrlSerializer;
        this.originUrl = this.uiHelper.isBrowser ? window.location.origin : this.serverUrl;

    }

    navigateToReturnUrl(fromLogin: boolean = false): void {
        if (this.fullReturnUrlBusinessSsoLogin) {
            const returnUrl = this.fullReturnUrlBusinessSsoLogin.returnUrl;
            const queryParams = this.fullReturnUrlBusinessSsoLogin.queryParams;
            this.fullReturnUrlBusinessSsoLogin = undefined;
            this.router.navigate([returnUrl], { queryParams, state: { overrideGuard: true } });
            return;
        }
        if (this.fullReturnUrlBankSsoLogin) {
            const returnUrl = this.fullReturnUrlBankSsoLogin.returnUrl;
            const queryParams = this.fullReturnUrlBankSsoLogin.queryParams;
            this.fullReturnUrlBankSsoLogin = undefined;
            this.router.navigate([returnUrl], { queryParams });
            return;
        }
        if (!isNullOrUndefined(this.returnUrl) &&
            this.returnUrl !== '') {

            if (this.returnUrl.includes('SourceGA=BeyachadSkyMaxLogin')) {             
                if (!this.isFromMax()) {
                    this.router.navigate(['/homepage/personal']);
                    return;
                } else {
                    window.location.href = this.returnUrl;
                }
            } else if (this.returnUrl.includes('aspx')) {
                this.returnUrl = this.returnUrl.includes(this.appConfigs.appConfigs.infoRoot) ?
                    this.returnUrl : this.appConfigs.appConfigs.infoRoot + this.returnUrl;
                if (!this.isFromMax()) {
                    this.router.navigate(['/homepage/personal']);
                    return;
                }
                window.location.href = this.returnUrl;

            } else if (this.returnUrl[0] === '/') {
                this.navigateTo(this.returnUrl.split('?')[0], fromLogin, this.defaultUrlSerializer.parse(this.returnUrl).queryParams)
            } else {
                if (!this.isFromMax()) {
                    this.router.navigate(['/homepage/personal']);
                    return;
                }
                let fullDomain = this.originUrl.split('/')[2];
                let baseUrl = this.returnUrl.split('/')[2];
                let relUrl = this.returnUrl.split(baseUrl)[1];
                if (!isNullOrUndefined(baseUrl) &&
                    !isNullOrUndefined(relUrl) &&
                    relUrl.toLowerCase().indexOf('/he-il/') < 0 &&
                    !isNullOrUndefined(fullDomain) &&
                    baseUrl.toLowerCase().indexOf(fullDomain.toLowerCase()) > -1) {
                    this.returnUrl = '';
                    this.navigateTo(relUrl.split('?')[0], fromLogin, this.defaultUrlSerializer.parse(relUrl).queryParams)
                } else {
                    let url = this.returnUrl;
                    this.returnUrl = '';
                    window.location.href = url;
                }
            }
        } else {
            this.navigateTo('/homepage/personal', fromLogin);
        }
    }

    navigateTo(path: string, fromLogin: boolean, queryParams: Params = null){
        if(((this.ave8Serv.checkCookie8() && !this.ave8Serv.isAve8()) || (!this.ave8Serv.checkCookie8() && this.ave8Serv.isAve8())) && fromLogin){
            if(queryParams && Object.keys(queryParams).length > 0){
                let httpParams = new HttpParams();
                for(const [key, value] of Object.entries(queryParams)){
                    httpParams = httpParams.append(key, value);
                }
                window.location.href = this.appConfigs.appConfigs.baseUiRoot + path + "?" + httpParams.toString();
                return;
            }
            window.location.href = this.appConfigs.appConfigs.baseUiRoot + path;
        }else{
            queryParams ? this.router.navigate([path], { queryParams : queryParams }) : this.router.navigate([path]);
        }
    }

    private isFromMax(): boolean {
        try {
            const url = new URL(this.returnUrl);
            let arr = url.hostname.split('.');
            let domain = arr.slice(1, arr.length).join('.');
            return this.appConfigs.appConfigs.returnUrlDomainList.some(d => domain === d);
        } catch (error) {
            return false;
        }
    }
}