import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from '../services/cookie.service';

@Injectable({
  providedIn: 'root'
})
export class Ave8Service {

  constructor(@Inject(DOCUMENT) private document: Document, private cookieService: CookieService) { }

  isAve8(): boolean {
    var elm = this.document.getElementById("ctl00_PlaceHolderOutputCache_divCache");
    if (elm)
      return elm.innerText.indexOf("@@@Host :08") > 0;
    else return false;
  }

  checkCookie8(){
    return this.cookieService.check('eve');
  }
}
