import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs-compat';
import * as internal from 'stream';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { ImyMaxCard } from '../../../max-account/interfaces/api-calls.interfaces';
import { IDashboardContent } from '../../../my-max/interfaces/dashboard-content.interface';
import { MyMaxService } from '../../../my-max/services/my-max.service';
import { IHomePageRecommendationsResult } from '../../models/homepage-recommendation';
import { HomepageService } from '../../services/homepage.service';

@Component({
    selector: 'app-my-max-info',
    templateUrl: './my-max-info.component.html',
    styleUrls: ['./my-max-info.component.scss']
})
export class MyMaxInfoComponent implements OnInit {

    myMaxSliderConfig = {
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        swipeSpeed: 200,
        autoplay: false,
        mobileFirst: true,
        rtl: true,
        responsive: [
            {
                breakpoint: 767,
                settings: 'unslick'
            }
        ]
    };
    showMyMaxInfo = false;
    content: IDashboardContent;
    recommendationsToShow: IHomePageRecommendationsResult[];
    showRecommendation = true;
    recommendationIsLoaded: BehaviorSubject<boolean>;
    myMaxInfoIsLoaded: BehaviorSubject<boolean>;

    get gtmCategory(): string {
        return 'my max cube';
    }

    constructor(private mmService: MyMaxService,
        private homepageService: HomepageService,
        private cd: ChangeDetectorRef,
        public appConfigsService: AppConfigsService,
        private gtmService: GtmService) {}

    get cardsToShow(): ImyMaxCard[] { return this.mmService.cardDetailsRes.filter(c => this.isCardActiveOrNotActivated(c)); }

    get childrenWithoutMoney(): string {
        const names: string[] = [];
        this.cardsToShow.forEach(
            c => {
                if (+c.otb < this.appConfigsService.appConfigs.MinForPromotionForCharging) {
                    names.push(c.nameHeb);
                }
            });
        return this.ArrayToListOfNamesString(names);
    }

    get childCardsNames(): string {
        return this.ArrayToListOfNamesString(this.cardsToShow.map(c => c.nameHeb));
    }

    isCardActiveOrNotActivated(card: ImyMaxCard): boolean {
        return card.blockCode === ' ' || card.blockCode === 'Z' || card.blockCode === 'Y' || card.blockCode === 'G';
    }

    ArrayToListOfNamesString(names: string[]): string {
        if (names.length > 0) {
            let _names = names[0];
            if (names.length > 1) {
                names.slice(1, -1).forEach(n => _names += ', ' + n);
                _names += ' ו' + names[names.length - 1];
            }
            return _names;
        }
        return '';
    }

    ngOnInit(): void {
        this.myMaxInfoIsLoaded = new BehaviorSubject<boolean>(false);
        this.recommendationIsLoaded = new BehaviorSubject<boolean>(false);
        const detaildSub = this.mmService.getChildCardDetails(false).subscribe((res) => {
            if (res.returnCode === 0 && this.cardsToShow.length > 0) {
                const contentSub = this.mmService.getDashboardContent().subscribe((contentRes) => {
                    if (contentRes.returnCode === 0) {
                        this.content = contentRes.result;
                        this.showMyMaxInfo = true;
                        this.cd.detectChanges();
                        this.getRecommendationData();
                    }
                    this.myMaxInfoIsLoaded.next(true);
                    detaildSub.unsubscribe();
                    contentSub.unsubscribe();
                    this.gtmService.pushGeneral('new website - registered', 'my max cube', 'show');
                    this.gtmService.pushMyMaxCube('num of my max cards', `${this.cardsToShow.length}`);
                    if (this.appConfigsService.appConfigs.FLAG_MarkMyMaxCardOTBIsLowForParent && this.childrenWithoutMoney.length > 1) {
                        this.gtmService.pushMyMaxCube('notification', 'show');
                    }
                    if (this.appConfigsService.appConfigs.FLAG_MarkMyMaxCardOTBIsLowForParent &&  this.childrenWithoutMoney.length === 1) {
                        this.gtmService.pushMyMaxCube('notification', 'show');
                    }
                });
            }
        });
    }

    getRecommendationData(): void {
        this.homepageService.mymaxRecommendationsChanged.subscribe(res => {
            if (res && res.length) {
                this.recommendationsToShow = res;
                this.showRecommendation = true;
            } else {
                this.showRecommendation = false;
            }
            this.recommendationIsLoaded.next(true);
            this.cd.detectChanges();
        });

        if (this.homepageService.savingRecommendation !== undefined) {
            this.homepageService.savingRecommendationsChanged.next(this.homepageService.savingRecommendation);
        }
    }
}
