import { Observable } from 'rxjs';
import { ComponentBase } from './control-base';
import { isNullOrUndefined } from 'core-max-lib';

export class DropdownComponent extends ComponentBase<number> {
  controlType = 'dropdown';
  items: { label: string, value: number }[] = [];
  options: any;
  disabled: boolean = false;

  changeFn: (e: any) => {};

  constructor(config: {}) {
    super(config);
    this.options = config['options'];
  }

  setValue(value: any) {
    console.log('drop down options', this.options);
    console.log('drop down set value', value);
    if (value) {
      this.onChange(value);
    }
  }

  setItems(data) {
    this.items = data;
  }

  getItemByValue(value: number): string {
    const bankItem = this.items.find(x => x.value == value);
    if (bankItem) {
      return bankItem.label;
    }
    return null;
  }

  clearItems() {
    this.items = [];
  }

  clearValue() {
    this.value = null;
  }

  onChange(e) {
    if (this.changeFn) {
      this.changeFn(e);
    }
  }

}
