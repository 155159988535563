import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigsService } from '../../../config/app-configs.service';
import { HttpService, IResponseLower } from '../../../shared/cardholders-core/services/http-service.service';
import { IGeneralSsoResponse } from '../interfaces/IGeneralSsoResponse.interfaces';

@Injectable({
    providedIn: 'root'
})
export class SsoGeneralService {
    baseApi: string;

    constructor(private appConfigsService: AppConfigsService, private httpService: HttpService) {
        this.baseApi = `${this.appConfigsService.appConfigs.apiUrl}/sso`;
    }

    getUrl(guid: string): Observable<IResponseLower<IGeneralSsoResponse>> {
        return this.httpService.postLower<IGeneralSsoResponse>(`${this.baseApi}/getURL`, { guid });
    }

    siteToMobileRegister(): Observable<IResponseLower<string>> {
        return this.httpService.getLower<string>(`${this.baseApi}/siteToMobileRegister`);
    }
}
