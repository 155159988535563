import { Observable } from 'rxjs';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent, HttpParams } from '@angular/common/http';
import { UUID } from 'angular2-uuid';
import { PreviewService } from '../../../modules/preview/preview.service';
import { InitializerService } from 'core-max-lib';


@Injectable()
export class HttpServiceCacheVersionAppenderInterceptor implements HttpInterceptor {
    private isBrowser: boolean;

    constructor(@Inject(PLATFORM_ID) private platformId: any,
        private previewService: PreviewService,
        private initializer: InitializerService) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method != "GET") {
            return next.handle(req);
        }

        let params = req.params;
        const clientContext = this.initializer.getClientContext();

        if (clientContext) {
            params = params.append("v", clientContext.Version);
        }

        if (this.isBrowser) {
            try {
                if (clientContext?.Roles?.includes('ADMIN')) {
                    params = params.append("o", window.location.origin.split('.')[0].split("//")[1].split(':')[0]);
                }
            } catch(e) {
                console.error(e);
            }


            if (this.previewService.isEnabled()) {
                params = params.append('preview', UUID.UUID());
            }
        }
        else {
            if (this.previewService.isEnabledByQs()) {
                params = params.append('preview', UUID.UUID());
            }
        }
        
        const request: HttpRequest<any> = req.clone({
            params: params
        });

        return next.handle(request);
    }
}
