import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigsService } from '../../../config/app-configs.service';
import { IResponse, HttpService } from '../../../shared/cardholders-core/services/http-service.service';
import { Observable } from 'rxjs';
import { UiHelperService } from '../../../shared/cardholders-core/services/ui-helper.service';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { IAsterisk } from '../models/asterisks';

@Injectable()
export class AsteriskService {
    /* =======  start of shared subjects    ========  */
    additionalTextToAsterisk: Subject<IAsterisk> = new Subject<IAsterisk>();
    
    private asterisks: any;

    constructor(
        private http$: HttpClient,
        private appConfigsService: AppConfigsService,
        private uiHelperService: UiHelperService,
        private httpSvc: HttpService
    ) { }

    loadAsterisks = () => {
        return new Promise((resolve, reject) => {
            this.getAstersksFromServer().subscribe(() => {
                switch (this.asterisks.ReturnCode) {
                    case 0:
                        if (this.asterisks.Result) {
                            resolve(this.asterisks.Result);
                        } else {
                            resolve(undefined);
                        }
                        break;
                    case 9:
                        resolve(undefined);
                        break;
                    default:
                        resolve(undefined);
                        break;
                }
            });
        });
    }

    private getAstersksFromServer = () => {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/asterisks`;
        return this.httpSvc.getWithSsrCache(url, 'asterisks').pipe(tap(res => (this.asterisks = res)));
    }
}
