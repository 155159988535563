import { Component } from '@angular/core';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { HomepageService } from '../../../homepage/services/homepage.service';
import { Offer, Offers } from '../../interfaces/homepage-content.interface';

@Component({
    selector: 'app-benefit-carusel',
    templateUrl: './benefit-carusel.component.html',
    styleUrls: ['./benefit-carusel.component.scss']
})
export class BenefitCaruselComponent {
    isMobile: boolean;
    offersList: Offer[];

    private touchStartX: number;
    private touchEndX: number;
    private touchStartY: number;
    private touchEndY: number;

    get content(): Offers {
        return this.hpService.homepageContent?.offers;
    }

    constructor(private hpService: HomepageService, private uiHelper: UiHelperService, private gtmService: GtmService) {
        this.isMobile = this.uiHelper.IsMobile(768);
    }

    onContentload(): void {
        if (this.isMobile) {
            this.offersList = this.content.offersListMobile;
        } else {
            this.offersList = this.content.offersListDesktop;
        }
        this.offersList.forEach((o, i) => (o.isActive = i === 0));
    }

    getClass(offer: Offer): string {
        if (offer.video.endsWith('.mp4')) {
            return 'video-benefit';
        }
        if (offer.video.endsWith('.json')) {
            return 'lottie-benefit';
        }
        return '';
    }

    onOfferClick(event: any, offer: Offer): void {
        event.stopPropagation();
        this.gtmService.pushNewWebsiteAnonymous('New - Offers', 'Click - ' + offer.title);
    }

    onOfferSwitchDesktop(): void {
        this.gtmService.pushNewWebsiteAnonymous('New - Offers', 'Desktop - Click on arrow');
    }

    onOfferSwitchMobile(): void {
        this.gtmService.pushNewWebsiteAnonymous('New - Offers', 'Mobile - Swipe');
    }

    // Touch swipe region:

    handlePanelClick(index: number) {
        this.onOfferSwitchDesktop();

        if (this.offersList[index].isActive) {
            this.offersList[index].isActive = false;
            this.offersList[((index + 1) % 3)].isActive = true;
            return;
        }

        // Reset all panels to inactive
        this.offersList.forEach((panel) => (panel.isActive = false));

        // Set the clicked panel to active
        this.offersList[index].isActive = true;
    }

    resetPanels() {
        const panels = document.querySelectorAll('.panel');
        panels.forEach((panel) => {
            panel.classList.remove('active');
        });
    }

    // onTouchStart(event: TouchEvent) {
    //     this.touchStartX = event.touches[0].clientX;
    // }

    // onTouchMove(event: TouchEvent) {
    //     this.touchEndX = event.touches[0].clientX;
    // }

    // onTouchEnd(event: TouchEvent) {
    //     const swipeDistance = this.touchEndX - this.touchStartX;

    //     if (swipeDistance > 0) {
    //         this.nextPanel();
    //     } else {
    //         this.prevPanel();
    //     }
    // }

    onTouchStart(event: TouchEvent) {
        this.touchStartX = event.touches[0].clientX;
        this.touchStartY = event.touches[0].clientY;
        this.touchEndX = event.touches[0].clientX;
        this.touchEndY = event.touches[0].clientY;
    }

    onTouchMove(event: TouchEvent) {
        this.touchEndX = event.touches[0].clientX;
        this.touchEndY = event.touches[0].clientY;

        const deltaX = Math.abs(this.touchEndX - this.touchStartX);
        const deltaY = Math.abs(this.touchEndY - this.touchStartY);

        // If horizontal swipe, prevent vertical scrolling
        if (deltaX > deltaY) {
            event.preventDefault();
        }
    }

    onTouchEnd(event: TouchEvent) {
        const swipeDistance = this.touchEndX - this.touchStartX;
        const threshold = 50; // Minimum swipe distance required to trigger panel change

        if (Math.abs(swipeDistance) >= threshold) {
            if (swipeDistance > 0) {
                this.activateNextPanel();
            } else {
                this.activatePreviousPanel();
            }
            this.onOfferSwitchMobile();
        }
    }

    activateNextPanel() {
        const activeIndex = this.offersList.findIndex((panel) => panel.isActive);
        if (activeIndex !== -1 && activeIndex < this.offersList.length - 1) {
            this.offersList[activeIndex].isActive = false;
            this.offersList[activeIndex + 1].isActive = true;
        }
    }

    activatePreviousPanel() {
        const activeIndex = this.offersList.findIndex((panel) => panel.isActive);
        if (activeIndex !== -1 && activeIndex > 0) {
            this.offersList[activeIndex].isActive = false;
            this.offersList[activeIndex - 1].isActive = true;
        }
    }
}
