<div class="useful-links">
    <ul>
        <li *ngFor="let commonAction of actions.CommonActions | slice:0:showActionsNum; let i=index">
            <a class="action-link" appLinkRoles [noRolesReturnUrl]="getActionLink(commonAction)" [roles]="getActionLinkRoles(commonAction)" appLink [anchorLink]="getActionLink(commonAction)" [isOpenLinkInNewTab]="commonAction.IsOpenLinkInNewTab" [attr.target]="commonAction.IsOpenLinkInNewTab ? '_blank' : ''" title="" appGtm [data]="{category: 'New website', action: 'Activities bar click', label: commonAction.Name}" [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}" listen="onclick">{{commonAction.Name}}</a>
        </li>
        <li class="all-actions">
            <a appGtm [active]="showAllActionsModal" [data]="{category: 'New website', action: 'All activities', label: 'open'}" [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}" listen="onclick" data-toggle="modal" data-target="#myModal" data-backdrop="static" data-keyboard="false" (click)="onAllActionsClick()">{{ allActions.Title }}</a>
            <!-- Modal -->
            <div class="all-actions-popup show-all-actions" id="all-actions" *ngIf="showAllActionsModal" [@fadeInOut]>
                <div class="modal-header d-md-none">
                    <span class="title">{{ allActions.Title }}</span>
                    <button type="button" class="close" (click)="toggleAllActions()"><span></span></button>
                </div>
                <div class="pop-up-content">
                    <ul *ngFor="let category of allActions.Categories">
                        <li><span class="link-title">{{ category.Title }}</span></li>
                        <li *ngFor="let action of category.Actions">
                            <a *ngIf="!action.DisableCommboxAccess || !authService.isUserInRoleName('COMMBOX')" appLinkRoles [noRolesReturnUrl]="getActionLink(action)" [roles]="getActionLinkRoles(action)" appLink [anchorLink]="getActionLink(action)" [isOpenLinkInNewTab]="action.IsOpenLinkInNewTab" [attr.target]="action.IsOpenActionLinkInNewTab ? '_blank' : ''" appGtm [data]="{category: 'New website', action: 'All activities', label: action.Name + ' - Click'}" [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}" listen="onclick">{{ action.Name }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </ul>
</div>