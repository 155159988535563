import { Component, OnInit } from '@angular/core';
import { UiHelperService } from '../../shared/cardholders-core/services/ui-helper.service';
import { PreviewService } from './preview.service';

@Component({
    selector: 'app-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
    isPreviewEnabled = false;

    constructor(private uiHelper: UiHelperService, private previewService: PreviewService) { }
    ngOnInit(): void {
        if (this.uiHelper.isBrowser) {
            this.isPreviewEnabled = this.previewService.isEnabled();
        }
    }

}
