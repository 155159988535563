<section class="benefits-pinukim">
    <div class="container">
        <h2>{{ title }}</h2>
        <a href="{{ titleLinkUrl }}" title="" class="default-link target-link-bold" appGtm [data]="{category: 'New website', action: 'Show me all benefits', label: 'Click'}" [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}" listen="onclick">{{ titleLink }}</a>
        <ng-container *ngIf="isLoggedIn && showBenefitStatusBanner">
            <div class="collect-benfits-alert row" *appLoader="isLoadedEligibility">
                <h3>
                    {{ benefitsStatusTitle }}
                </h3>
                <a href="{{ benefitsStatusLinkUrl }}" title="" appGtm [data]="{category: 'New website - registered', action: 'Click on pinukim calculator', label: 'Click'}" listen="onclick">{{ benefitsStatusLinkText }}</a>
            </div>
        </ng-container>
    </div>
    <div class="slider-benfits-wrapper">
        <ng-template #benefitSlickTemplateEl>
            <div class="slider benfits-slider benefits-slider-template col-sm-12 col-md-12 col-lg-12 col-xl-12 app-loader-blink" [ngClass]="{'benfits-slider-not-login' : !isLoggedIn}"></div>
        </ng-template>
        <!--remove class benfits-slider-not-login after log in-->
        <div class="slider benfits-slider col-sm-12 col-md-12 col-lg-12 col-xl-12" [ngClass]="{'benfits-slider-not-login' : !isLoggedIn}" *appLoader="isLoadedBenefits; template: benefitSlickTemplateEl">
            <!-- add class benfits-slider-not-login-->

            <ngx-slick-carousel class="multiple-items slider" #slickModal="slick-carousel" (init)="registrationToGtm($event)" [config]="slideConfig" (mouseenter)="showArrows()" (mouseleave)="hideArrows()" (beforeChange)="uiHelper.IsMobile(768) ? swipeToGtm(): false">
                <div ngxSlickItem *ngFor="let benefit of benefits.Benefits" appGtm [data]="{category: 'New website', action: 'Click on benefit', label: benefit.BenefitTitle}" [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}" listen="onclick">
                    <div class="card-details">
                        <a href="{{ contentRoot + benefit.BenefitLink }}" title="">
                            <div class="img-wrapper">
                                <img [attr.data-src]="getBenefitImage(benefit.BenefitImage)" class="lazyload pic" alt="{{benefit.BenefitTitle}} | max" />
                                <span *ngIf="benefit.DaysLeftForBenefit > 2 && benefit.DaysLeftForBenefit < 11" class="day-left"> נשארו עוד {{benefit.DaysLeftForBenefit}} ימים להטבה</span>
                                <span *ngIf="benefit.DaysLeftForBenefit == 2" class="day-left">  נשארו עוד יומיים להטבה</span>
                                <span *ngIf="benefit.DaysLeftForBenefit == 1" class="day-left">  נשאר עוד יום אחד להטבה</span>
                                <span *ngIf="benefit.DaysLeftForBenefit == 0" class="day-left">  נשאר עוד יום אחרון להטבה</span>
                            </div>
                            <div class="benefit-info">
                                <h3>{{ benefit.BenefitTitle }}</h3>
                                <p>
                                    {{ benefit.BenefitSubTitle }}
                                    <!-- <span class="hover-benfits">{{ BenefitSubTitle }}</span> -->
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</section>