import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UiHelperService } from "../../../cardholders-core/services/ui-helper.service";
import { ModalService } from "../../../modules/bs-modal/services/modal.service";
import { uiIsMobile } from "../../../services/isMobile";

@Component({
  selector: 'app-dynamic-popup',
  templateUrl: './dynamic-popup.component.html',
  styleUrls: ['./dynamic-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicPopupComponent implements OnInit {

  @Input("data") data: any;
  public isMobile: boolean;
  isBrowser: boolean;

  constructor(private uiHelper: UiHelperService,
    private modalSvc: ModalService) { }

  ngOnInit() {
    this.isMobile = uiIsMobile();
    this.isBrowser = this.uiHelper.isBrowser;
  }

  close() {
    this.modalSvc.closeModal();
  }

    action(val) {
    if (val === 1 && this.data.url) {
        window.open(this.data.url, "_blank");
    }
    if (this.data.closeOnAction) {
      this.modalSvc.closeModalAndAlertSubscribers(val);
      return;
    }
    this.modalSvc.alertModalSubscribers(val);
  }
}
