export function a11yFix() {
    if (!window) {
        return null;
    }
    return (window as any).u1?.fix;
}

export function setA11yLang(lang: string) {
    if (window) {
        const u1 = (window as any).u1;
        if (u1) u1.lang = lang;
    }
}