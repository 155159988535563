import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'maxCurrency'
})
export class MaxCurrencyPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) { }
    transform(value: any, id?: any, spaces?: string): any {
        return `${id ? this.getSymbol(id)  + (value < 0 ? '-' : '')  : ''}${this.currencyPipe.transform((value < 0 ? value * -1 : value), 'symbol-narrow', '')}`;
    }   

    getSymbol(id: any): string {
        if (id === 'ILS' || Number(id) === 0 || Number(id) === 376) {
            return '₪';
        }
        if (id === 'USD' || Number(id) === 840) {
            return '$';
        }
        if (id === 'EUR' || Number(id) === 978) {
            return '€';
        }
        if (id === 'GBP' || Number(id) === 826) { return '£'; }
        if (id === 'CAD' || Number(id) === 124) { return '$C'; }
        return ` ${id} `;
    }
}