import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Subscription } from 'rxjs/Rx';
import { AppConfigsService } from '../../../../../config/app-configs.service';
import { ModalService } from '../../../../../shared/modules/bs-modal/services/modal.service';
import { RecaptchaHelperService } from '../../../../../shared/services/recaptcha-helper.service';
import { InsuranceService } from '../../../services/insurance.service';

@Component({
    selector: 'app-side-popup',
    templateUrl: './side-popup.component.html',
    styleUrls: ['./side-popup.component.scss']
})
export class SidePopupComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() type: 'side' | 'policy' | 'error' | 'insurance' | 'aig' = 'side';
    @Input() content: any;
    @Input() ssoPassingRecapcha: number;
    @Input() policyNumber: number;
    @ViewChild('captchaRef') recaptcha: RecaptchaComponent;

    recaptchaSiteKey: string;
    enableRecaptcha: boolean;
    loader: boolean;
    ssoUrl: string;
    private subs: Subscription[] = [];
    private recaptchaEvent: string;

    constructor(
        private modalService: ModalService,
        private appConfigsService: AppConfigsService,
        private recaptchaHelper: RecaptchaHelperService,
        private insuranceHPSrv: InsuranceService
    ) {}
    ngOnDestroy(): void {
        this.subs.forEach(x => x.unsubscribe());
    }
    ngOnInit(): void {
        this.recaptchaSiteKey = this.appConfigsService.appConfigs.recaptchaSiteKey;
        this.enableRecaptcha =
            this.type === 'policy' &&
            this.ssoPassingRecapcha >= 10 &&
            this.recaptchaHelper.getIsenableRecaptcha();
    }

    ngAfterViewInit(): void {
        if (this.enableRecaptcha) {
            this.recaptcha.execute();
        } else {
            this.getUrlForPolicy();
        }
    }

    saveRecaptchaEvent(event?: string): void {
        this.recaptchaEvent = event;
        this.getUrlForPolicy();
    }

    getUrlForPolicy() {
        if (this.type === 'policy') {
            this.loader = true;
            this.subs.push(
                this.insuranceHPSrv.sso({ policyNum: this.policyNumber, captchaRes: this.recaptchaEvent }).subscribe(x => {
                    this.insuranceHPSrv.ssoPassingRecapcha += 1;
                    this.loader = false;
                    if (x.returnCode === 0) {
                        this.ssoUrl = x.result;
                    } else {
                        this.type = 'error';
                    }
                })
            );
        }
    }

    next(type: string = 'button', event?: string) {
        if (this.type === 'policy' && type === 'button') {
            window.open(this.ssoUrl, '_blank');
        }
        this.modalService.closeModalAndAlertSubscribers({ type, event: this.recaptchaEvent });
    }

    get picture(): string {
        if (this.type === 'error') {
            return 'icecream.svg';
        }
        return 'sidepopup.png';
    }
}
