import { Output, EventEmitter, ElementRef, Directive } from '@angular/core';
import { LoginScreenMessages, IErrorScreenParams, LoginErrorScreenMessages } from '../login-messages.consts';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import {
    ILoginResult,
    IUserTransferResult,
    LoginStatus,
    LoginType,
    PopupType
} from '../../../../shared/cardholders-core/models/login-result';
import { LoginService } from '../../login.service';
import { isNullOrUndefined } from 'core-max-lib';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { LoginWithMaxService } from '../../../login-with-max/login-with-max.service';
import { OpenBankingService } from '../../../open-banking/open-banking.service';
import { BiometricModal } from '../../biometric/biometric.constant';
import { BiometricPopupService } from 'ClientApp/app/shared/cardholders-core/services/biometricPopup.service';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class BaseLoginComponent {
    @Output()
    loginFailed: EventEmitter<IErrorScreenParams> = new EventEmitter<IErrorScreenParams>();
    @Output()
    needIdChanged: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    loginFailedWithTechnicalError: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    switchLoginTab: EventEmitter<number> = new EventEmitter<number>();
    @Output()
    updateLoaderStatusEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    get needId(): boolean {
        return this.loginService.needId.value;
    }
    set needId(value: boolean) {
        this.loginService.needId.next(value);
    }
    showError = false;
    errorMsg: string;
    isLoginWithMax: boolean;
    isOpenBankingLogin: boolean;
    isDigitalTransferCardLogin: boolean;

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected modalSvc: ModalService,
        protected appConfigsService: AppConfigsService,
        protected gtmService: GtmService,
        protected loginService: LoginService,
        protected uiHelper: UiHelperService,
        protected loginWithMaxSvc: LoginWithMaxService,
        protected openBankingService: OpenBankingService,
        protected bioSrv: BiometricPopupService
    ) {}

    checkLoginStatus(loginResult: ILoginResult, loaderStatusEmitter: EventEmitter<boolean>): void {
        this.loginService.userTransferResult = loginResult.UserTransferResult;
        if (
            (this.isLoginWithMax || this.isDigitalTransferCardLogin) &&
            loginResult.LoginStatus !== LoginStatus.falied
        ) {
            return;
        }
        if (this.isOpenBankingLogin) {
            if (loginResult.LoginStatus === LoginStatus.falied && loginResult.LoginCode === 19) {
                this.openBankingService.onOpenBankingLoginSuccess.next(true);
            }
            if (loginResult.LoginStatus === LoginStatus.falied) {
                this.doOnLoginFail(loginResult);
                loaderStatusEmitter.emit(true);
            } else {
                this.openBankingService.onOpenBankingLoginSuccess.next(true);
            }
            return;
        }
        if (loginResult.LoginStatus !== LoginStatus.falied && this.authService.isContactAuthorization()) {
            this.navigateToReturnUrl();
            return;
        }
        const returnUrlQueryString: string =
            !isNullOrUndefined(this.loginService.returnUrl) && this.loginService.returnUrl !== ''
                ? this.loginService.returnUrl[0] === '/'
                    ? `?returnUrl=${this.loginService.originUrl}${this.loginService.returnUrl}`
                    : `?returnUrl=${this.loginService.returnUrl}`
                : '';

        switch (loginResult.LoginStatus) {
            case LoginStatus.falied:
                this.doOnLoginFail(loginResult);
                loaderStatusEmitter.emit(true);
                break;
            case LoginStatus.success:
                // this.modalSvc.closeModal();
                // loaderStatusEmitter.emit(true);
                break;
            // case LoginStatus.cardRemoved:
            //      if (loginResult.PopupToShow === PopupType.cardsRemovedFromAccount) {
            //          if (loginResult.UserTransferResult && loginResult.UserTransferResult.IsTransferMessageNeeded) {
            //              this.loginService.navigateUserTransferPage(
            //                  `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/Login/CardRemoved.aspx${returnUrlQueryString}`,
            //                  false,
            //                  loaderStatusEmitter
            //              );
            //          } else {
            //              window.location.href = `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/Login/CardRemoved.aspx${returnUrlQueryString}`;
            //          }
            //      } else {
            //          this.navigateToReturnUrl();
            //      }
            //      break;
            // case LoginStatus.needToSignAggreement:
            //     if (loginResult.PopupToShow === PopupType.signAggreement) {
            //         if (loginResult.UserTransferResult && loginResult.UserTransferResult.IsTransferMessageNeeded) {
            //             this.loginService.navigateUserTransferPage(
            //                 `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/Login/LoginAggreement.aspx${returnUrlQueryString}`,
            //                 false,
            //                 loaderStatusEmitter
            //             );
            //         } else {
            //             window.location.href = `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/Login/LoginAggreement.aspx${returnUrlQueryString}`;
            //         }
            //     } else {
            //         this.navigateToReturnUrl();
            //     }
            //     break;
            case LoginStatus.passwordExpired:
                if (loginResult.PopupToShow === PopupType.passwordExpired) {
                    if (loginResult.UserTransferResult && loginResult.UserTransferResult.IsTransferMessageNeeded) {
                        this.loginService.navigateUserTransferPage(
                            this.appConfigsService.appConfigs.FLAG_ISEnableNewRenewPasswordScreen ?
                            `${this.appConfigsService.appConfigs.baseUiRoot}/renew-password` :
                            `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/Login/PasswordExpired.aspx${returnUrlQueryString}` ,
                            false,
                            loaderStatusEmitter
                        );
                    } else {
                        this.appConfigsService.appConfigs.FLAG_ISEnableNewRenewPasswordScreen ?
                        this.router.navigate([`/renew-password`]
                        )
                        : window.location.href = `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/Login/PasswordExpired.aspx${returnUrlQueryString}`;
                    }
                    this.modalSvc.closeModal();
                    this.updateLoaderStatusEvent.emit(true);
                } else {
                    this.navigateToReturnUrl();
                }
                break;
            case LoginStatus.CreditLawAgreementAvailable:
                if (loginResult.PopupToShow === PopupType.fairCredit) {
                    if (loginResult.UserTransferResult && loginResult.UserTransferResult.IsTransferMessageNeeded) {
                        this.loginService.navigateUserTransferPage(
                            `${this.appConfigsService.appConfigs.infoRoot}/Registred/CreditProducts/CreditLawSigning.aspx${returnUrlQueryString}`,
                            false,
                            loaderStatusEmitter
                        );
                    } else {
                        window.location.href = `${this.appConfigsService.appConfigs.infoRoot}/Registred/CreditProducts/CreditLawSigning.aspx${returnUrlQueryString}`;
                    }
                } else {
                    this.navigateToReturnUrl();
                }
                break;
            case LoginStatus.NeedToShowInsurance:
                if (loginResult.PopupToShow === PopupType.insurance) {
                    this.needToShowInsurance(returnUrlQueryString, loginResult.UserTransferResult, loaderStatusEmitter);
                } else {
                    this.navigateToReturnUrl();
                }
                break;
            default:
                break;
        }
        if (loginResult.LoginStatus !== LoginStatus.falied) {
            this.saveLoginType(loginResult.LoginType);
        }
    }
    needToShowInsurance(
        returnUrlQueryString: string,
        userTransferResult: IUserTransferResult,
        loaderStatusEmitter: EventEmitter<boolean>
    ): void {
        if (returnUrlQueryString.includes('RecoverCardPinCodeReg.aspx')) {
            if (userTransferResult && userTransferResult.IsTransferMessageNeeded) {
                this.loginService.navigateUserTransferPage(
                    `${this.appConfigsService.appConfigs.infoRoot}/Registred/RecoverCardPinCodeReg.aspx`,
                    false,
                    loaderStatusEmitter
                );
            } else {
                window.location.href = `${this.appConfigsService.appConfigs.infoRoot}/Registred/RecoverCardPinCodeReg.aspx`;
            }
        }

        if (returnUrlQueryString.includes('BlockCard.aspx')) {
            if (userTransferResult && userTransferResult.IsTransferMessageNeeded) {
                this.loginService.navigateUserTransferPage(
                    `${this.appConfigsService.appConfigs.infoRoot}/Registred/Card/BlockCard.aspx`,
                    false,
                    loaderStatusEmitter
                );
            } else {
                window.location.href = `${this.appConfigsService.appConfigs.infoRoot}/Registred/Card/BlockCard.aspx`;
            }
        }
    }

    showErrorMsg(msg: string): void {
        this.showError = true;
        this.errorMsg = msg;
    }

    hideErrorMsg(): void {
        this.showError = false;
        this.errorMsg = null;
    }

    navigateToReturnUrl(): void {
        this.loginService.navigateReturnUrlAfterLoginSuccess();
        this.modalSvc.closeModal();
        this.updateLoaderStatusEvent.emit(true);
    }

    protected pushDirectiveGtm(categoryText: string, actionText: string, labelText: string): void {
        this.gtmService.pushDirective({
            category: this.loginService.loginPageMode ? categoryText + ' page' : categoryText,
            action: actionText,
            label: labelText
        });
    }

    protected pushDirectiveGtmWorkflow(
        categoryText: string,
        actionText: string,
        labelText: string,
        subjectId: string,
        workflowDescription: string,
        workflowStep: string,
        workflowTotalStep: string
    ): void {
        this.gtmService.pushWorkflowEvent({
            category: this.loginService.loginPageMode ? categoryText + ' page' : categoryText,
            action: actionText,
            label: labelText,
            subjectId: subjectId,
            workflowDescription: this.loginService.loginPageMode ? workflowDescription + ' page' : workflowDescription,
            workflowStep: workflowStep,
            workflowTotalStep: workflowTotalStep
        });
    }

    protected setFocusOnElement(element: ElementRef): void {
        setTimeout(() => {
            if (this.uiHelper.isBrowser) {
                element?.nativeElement.focus();
            }
        });
    }

    private doOnLoginFail(result: ILoginResult): void {
        switch (result.LoginCode) {
            case 9:
            case 99:
                this.loginFailedWithTechnicalError.emit();
                break;
            case 11:
                if (this.needId) {
                    this.showErrorMsg(LoginScreenMessages.wrongDetails11Id);
                } else {
                    this.showErrorMsg(LoginScreenMessages.wrongDetails11);
                }
                break;
            case 12:
            case 31:
            case 33:
                if (result.LoginType === LoginType.Id) {
                    this.loginFailed.emit(LoginErrorScreenMessages.lockedCanReleaseOtp12);
                    this.pushDirectiveGtm('New website - log in', 'OTP errors', 'Too many attempts error');
                } else {
                    this.loginFailed.emit(LoginErrorScreenMessages.lockedCanReleasePassword12);
                    this.pushDirectiveGtm('New website - log in', 'Password login errors', 'Too many attempts error');
                }
                break;
            case 13:
            case 32:
            case 34:
                this.loginFailed.emit(LoginErrorScreenMessages.lockedCantRelease13);
                if (result.LoginType === LoginType.Id) {
                    this.pushDirectiveGtm('New website - log in', 'OTP errors', 'Blocked account');
                } else {
                    this.pushDirectiveGtm('New website - log in', 'Password login errors', 'Blocked account');
                }
                break;
            case 16:
                this.loginFailed.emit(LoginErrorScreenMessages.lockedNoLogin6Months16);
                this.pushDirectiveGtm('New website - log in', 'Password login errors', 'Dormant account');
                break;
            case 17:
                this.needId = true;
                this.needIdChanged.emit();
                this.pushDirectiveGtm('New website - log in', 'Password login', 'ID request');
                break;
            case 18:
                this.loginFailed.emit(LoginErrorScreenMessages.tempPasswordNoLoginForMonth18);
                this.pushDirectiveGtm('New website - log in', 'Password login errors', 'Password expired');
                break;
            case 19:
                this.loginFailed.emit(LoginErrorScreenMessages.noCardsLeft19);
                this.pushDirectiveGtm('New website - log in', 'Password login errors', 'No Cards');
                break;
            case 21:
                this.loginFailed.emit(LoginErrorScreenMessages.crmUserNoLogin7Days21);
                this.pushDirectiveGtm('New website - log in', 'Password login errors', 'Password expired');
                break;
            case 22:
                this.loginFailed.emit(LoginErrorScreenMessages.kadamUserNoLogin7Days22);
                this.pushDirectiveGtm('New website - log in', 'Password login errors', 'Password expired');
                break;
            case 24:
                this.showErrorMsg(LoginScreenMessages.generalError);
                break;
            case 25:
                this.loginFailed.emit(LoginErrorScreenMessages.userTransferToOtherUsername25);
                this.pushDirectiveGtm('New website - log in', 'Password login errors', 'User Transferred');
                break;
            case 30:
            case 37:
                this.showErrorMsg(LoginScreenMessages.otpWrongDetails30_37);
                break;
            case 35:
                this.showErrorMsg(LoginScreenMessages.otpCardNotAllowed35);
                break;
            case 39:
                this.loginFailed.emit(LoginErrorScreenMessages.moreThanOneCountsActiveTo8Digit);
                this.pushDirectiveGtm('New website - log in', 'Password login errors', 'More than one Counts');
                break;
            default:
                break;
        }
    }
    private saveLoginType(loginType: LoginType): void {
        localStorage.setItem('lastLoginTypeSuccess', LoginType[loginType]);
    }
}
