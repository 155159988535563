export interface IHomePageUserData {
    UserCards: UserCards;
}

interface UserCards {
    Summary: ISummaryNew[];
    Cards: ICardData[];
    IsMultUsers: boolean;
    IsMultAccounts: boolean;
}

export enum ForeignCurrency {
    NoForeignCurrency = 0,
    Nis = 376,
    Dollar = 840,
    Euro = 978
}

export enum CreditLimitType {
    Hard = 0,
    Flexible = 1,
    Card = 3,
    Account = 4
}

export interface ISummaryNew {
    Currency: ForeignCurrency;
    ActualDebitSum: number;
    TotalDebitSum: number;
    CurrencySymbol: string;
}

export interface ICardData {
    CatalogId: string;
    Last4Digits: string;
    ExpirationDate: string;
    OwnerFullName: string;
    CardName: string;
    CardImage: string;
    CreditLimit: number;
    OpenToBuy: number;
    CycleSummary: ICycleSummaryNew[];
    CycleSummaryInfo: string;
    FixedDebit: number;
    ReturnCode: number;
    CardLogo: number;
    Index: number;
    IsActiveDigitalCard: boolean;
    IsOwnerDigitalCard: boolean;
    IsViewCardDetailsOK: boolean;
    CreditLimitType: CreditLimitType;
    ShowMonthlyBillingLayout: boolean;
    IsControlsBiZCardSubscribe: boolean;
    ClearingAmtForOtb: number;
    IsMyMAX: boolean;
    MyMaxHebrewName: string;
}

export interface ICycleSummaryNew {
    Date: Date;
    Currency: ForeignCurrency;
    CurrencySymbol: string;
    ActualDebitSum: number;
    TotalDebitSum: number;
    IsFinnal: boolean;
}
