<div class="modal-header">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    </button>
</div>

<div *ngIf="!showLoader && contents" class="marketing-email">
    <img *ngIf="isMobile" class="main-banner" [src]="bgImgUrl"/>
    <div class="content">
        <img *ngIf="!isMobile" class="back-desktop" [src]="bgImgUrl"/>
        <div class="info-wrapper">
            <div class="info-content" *ngIf="showMailForm">
                <h1>שלחו לי הטבות והצעות לפני כולם!</h1>
                <p class="confirm-details" [innerHTML]="(isMobile ? contents && contents.mobileText : contents && contents.desktopText) | sanitizeHtml">
                </p>
                <div *ngIf="data" class="fill-details">
                    <form [formGroup]="marketingAgreementForm">
                        <div *ngIf="data.showEmailField" class="input-wrapper">
                            <input *ngIf="data.emails.length === 1" type="text" required id="mail" formControlName="email" (input)="emailNotValidPattern=false;" (blur)="isEmailNotValid()" maxlength="55" />
                            <p-autoComplete *ngIf="data.emails.length > 1" #autocomplete (onSelect)="onEmailSelect($event)" [suggestions]="filteredEmails" (completeMethod)="search($event)" (onFocus)="onFocus(); autocomplete.handleDropdownClick()" (onBlur)="onBlur($event); isEmailNotValid()" field="label" [minLength]="0" [maxlength]="55" (input)="onInput($event)"></p-autoComplete>
                            <label class="float-label email-label"> כתובת המייל שלך</label>
                            <div class="error-wrapper"><span class="err-msg" *ngIf="emailNotValidPattern">{{emailNotValidPatternMessage}}</span></div>
                        </div>
                    <div *ngIf="data.showPhoneNumberField" class="input-wrapper">
                        <input type="text" required id="phone" class="phone-number" formControlName="phoneNumber" disabled="disabled" />
                        <label class="float-label"> הטלפון הנייד שלך</label>
                        <!--<div class="error-wrapper"><span class="err-msg">הודעת שגיאה</span></div>-->
                        <a href="{{infoRoot}}/Registred/UserSettings/UserSettings.aspx?SourceGA=MarketingPopup" title="" class="link arrow-link" target="_blank">לעדכון</a>
                    </div>
                    </form>
                </div>
                <div class="button-wrapper">
                    <a ComadminDisabled title="" id="confirm" class="brand-button aqua-button" (click)="agree()"><span>אישור</span></a>
                    <a class="link" title="" (click)="clickNo()">לא, תודה</a>

                </div>
                <div class="terms">
                    <p [innerHTML]="contents && contents.asterisks | sanitizeHtml">
                    </p>
                </div>
            </div>
            <app-success *ngIf="!showMailForm && isSuccess" (closeMarketingAgreementPopup)="close()"></app-success>
            <app-fail *ngIf="isFail" (closeMarketingAgreementPopup)="close()"></app-fail>
        </div>
    </div>

</div>
<max-loader *ngIf="showLoader"></max-loader>