import { Component, OnInit, Inject, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppConfigsService } from '../../config/app-configs.service';
import { UiHelperService } from '../../shared/cardholders-core/services/ui-helper.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
    selector: 'auth-login-redirect',
    templateUrl: './auth-login-redirect.component.html',
    styleUrls: ['./auth-login-redirect.component.scss']
})
export class AuthLoginRedirectComponent implements OnInit {
    constructor(
        @Inject(DOCUMENT) private document: any,
        @Optional() @Inject('serverUrl') private serverUrl: string,
        private appConfigService: AppConfigsService,
        private uiHelperSvc: UiHelperService,
        private activateRoute: ActivatedRoute,
        private router: Router
    ) {
        this.originUrl = this.uiHelperSvc.isBrowser ? window.location.origin : this.serverUrl;
    }

    private originUrl;

    ngOnInit() {
        if (this.uiHelperSvc.isBrowser) {
            this.activateRoute.queryParams.subscribe(params => {
                let returnUrl = params['returnUrl'] || '';
                let sourceGA = params['sourceGA'] || '';
                let SourceGA = params['SourceGA'] || '';
                if (this.appConfigService.appConfigs.FLAG_ISRedirectToNewLogin) {
                    let queryParams = {
                        ReturnURL: `${this.originUrl}${returnUrl}`
                    };
                    // NOTE: Do not add every query param, because they might affect the process we redirect to.
                    // e.g. if there was queryparam :guid in the original page, this is probably not needed in the redirect page.
                    if (!returnUrl.includes('ourceGA') && (sourceGA || SourceGA)) {
                        if (sourceGA) {
                            queryParams['sourceGA'] = sourceGA;
                        } else if (SourceGA) {
                            queryParams['SourceGA'] = SourceGA;
                        }
                    }
                    this.router.navigate(['login'], {
                        queryParams: queryParams
                    });
                } else {
                    let url = `${this.appConfigService.appConfigs.infoRoot}/Anonymous/Login/CardholdersLogin.aspx?ReturnUrl=${this.originUrl}${returnUrl}`;
                    this.document.location.href = url;
                }
            });
        }
    }
}
