<div class="biometric">
    <button aria-label="Close" class="close ng-star-inserted" data-dismiss="modal" type="button" (click)="dontWant()">
        <span aria-hidden="true">×</span>
    </button>
    <div class="start" *ngIf="appleSignInStates?.show">
        <img class="smile" src="/assets/images/login/biometric.svg" alt="" />
        <h1>יש לנו דרך חכמה ובטוחה להזדהות לאתר</h1>
        <p>
            השתמשו בטביעת האצבע או זיהוי הפנים שלכם להתחברות קלה, מהירה והכי חשוב בטוחה!
        </p>
        <div class="btn-wrapper">
            <div *ngIf="appleSignInStates?.show" class="apple-sign-in-wrapper">
                <button (click)="registerAppleSignIn()" class="general-button send-me-code apple-sign-in">
                    <span class="button-text"
                        >רישום לכניסה חכמה ובטוחה<img class="apple" src="/assets/images/login/apple.svg" alt=""
                    /></span>
                </button>
            </div>
            <div *ngIf="fingerPrintStates?.show" class="fingerprint-sign-in-wrapper">
                <button (click)="registerAppleSignIn()" class="general-button send-me-code fingerprint-sign-in">
                    <span class="button-text">רישום לכניסה חכמה ובטוחה</span>
                </button>
            </div>
            <a class="link" (click)="dontWant()">לא תודה, אמשיך להשתמש בדרך הישנה</a>
            <label class="label-terms" for="confirmTerms">
                ‏בלחיצה על הכפתור אני מאשר/ת את
                <a [attr.href]="appleIDAggrement" title="" class="link" target="_blank">התקנון</a></label
            >
        </div>
    </div>

    <div class="end" *ngIf="appleSignInStates?.showError">
        <img class="smile" src="/assets/images/login/biometricFailed.svg" alt="" />
        <h1 [innerHTML]="appleSignInStates?.message"></h1>
        <div class="btn-wrapper">
            <div class="apple-sign-in-wrapper">
                <button (click)="next('apple', 'button')" class="brand-button blue-button">
                    <span class="button-text">{{ appleButton }}</span>
                </button>
            </div>
            <a class="link" (click)="next('apple', 'link')">{{ appleLink }}</a>
        </div>
    </div>
    <div class="end" *ngIf="appleSignInStates?.sucess">
        <img class="smile" src="/assets/images/login/biometric.svg" alt="" />
        <h1>{{ appleSignInStates?.message }}</h1>
        <div class="btn-wrapper">
            <div class="apple-sign-in-wrapper">
                <button (click)="close()" class="brand-button blue-button">
                    <span>אוקי, הבנתי </span>
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="appleSignInStates?.loader" class="loader-wrapper">
    <div class="loader">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
