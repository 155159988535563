import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigsService } from '../../config/app-configs.service';
import { IResponse, HttpService } from '../cardholders-core/services/http-service.service';
import { Observable } from 'rxjs';
import { UiHelperService } from '../cardholders-core/services/ui-helper.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ScriptStoreService {
    noscript: boolean = false;
    private scripts: any = undefined;

    constructor(
        private http$: HttpClient,
        private appConfigsService: AppConfigsService,
        private httpSvc: HttpService,
        private uiHelperService: UiHelperService
    ) {}

    load(...scriptsNames: string[]) {
        var promises: any[] = [];
        return new Promise((resolve, reject) => {
            this.getScripts().subscribe(() => {
                if (this.uiHelperService.isBrowser) {
                    scriptsNames.forEach(scriptName => {
                        if (this.scripts[scriptName]) {
                            promises.push(this.loadScript(scriptName));
                        }
                    });
                }
                resolve(Promise.all(promises));
            });
        });
    }
    private loadScript(name: string) {
        return new Promise((resolve, reject) => {
            //resolve if already loaded
            if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            } else {
                // load script
                if (this.noscript) {
                    return;
                }

                //load script
                let script: any = document.createElement('script');
                script.async = this.scripts[name].async;
                script.charset = this.scripts[name].charset;
                script.defer = this.scripts[name].defer;
                script.src = this.scripts[name].src;
                script.type = this.scripts[name].type;

                this.scripts[name].data.forEach(dataAttr => {
                    script.setAttribute(`data-${dataAttr.Attr}`, `${dataAttr.Value}`);
                });

                if (script.readyState) {
                    //IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {
                    //Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }
    private getScripts(): Observable<any> {
        return new Observable(observer => {
            if (this.scripts) {
                observer.next();
                return observer.complete();
            }
            this.scripts = {};
            const url = `${this.appConfigsService.appConfigs.apiUrl}/scripts`;
            this.httpSvc
                .getWithSsrCache<any>(url, 'scripts')
                .pipe(
                    map(res => {
                        res.Result.Scripts.forEach(script => {
                            this.scripts[script.Name] = {
                                async: script.Async,
                                charSet: script.CharSet,
                                data: script.Data,
                                defer: script.Defer,
                                src: script.Src,
                                type: script.Type
                            };
                        });
                    })
                )
                .subscribe(() => {
                    observer.next();
                    observer.complete();
                });
        });
    }
}
