export interface IInsuranceDetails {
    insuranceDetailsList: IPolicyData[];
    idNumber: string;
    idType: string;
    systemId: string;
    isOkShareInfo: number;
    isHavePolicy: number;
}

export interface IPolicyData {
    carNumber: string;
    carModel: string;
    endDate: Date;
    homePropertyAddress: string;
    insuranceCompanyName: string;
    lineCode: LineCode;
    lineDesc: string;
    mortgageBank: string;
    mortgageBeneficiaryName1: string;
    mortgageBeneficiaryName2: string;
    policyStatus: number;
    primeTotal: number;
    profileCellularPhone: string;
    recId: number;
    startDate: Date;
    travelDestination: string;
    travelNumOfInsured: number;
    externalURL: string;
    renewalURL: string;
    fields?: { name: string; value: any; isEllipsis?: boolean }[];
    title?: string;
    showRenewInsurance?: boolean;
    indRenewedPolicy?: IndRenewedPolicy;
    indPolicyAuthoRenew?: number;
}

export enum LineCode {
    carMekifHova = -121,
    carMekif = 1,
    abroad = 16,
    carHova = 21,
    apartment = 31,
    mortgage = 70,
    saving = 12
}

export interface IAigSsoResult {
    url: string;
}

export enum IndRenewedPolicy {
    notRenewed = 0, // לא חודשה
    renewed = 1 // חודשה
}

export enum IndPolicyAuthoRenew {
    notAuthoRenew = 0,
    authoRenew = 1
}
