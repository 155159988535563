import { Injectable, Predicate } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';

@Injectable()
export class HelperService {
    public isTouchedAndNotValid(form: FormGroup, controlName: string): boolean {
        return form.get(controlName).touched && !form.get(controlName).valid;
    }

    public scrollToControl(controlName: string) {
        var element;
        if (controlName) {
            element = document.querySelector('[formcontrolname="' + controlName + '"]');
        }

        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    public updateFormAndControls(formGroup: FormGroup): void {
        (<any>Object).values(formGroup.controls).forEach((control) => {
            if (control.controls) {
                // control is a FormGroup
                this.updateFormAndControls(control);
            } else {
                // control is a FormControl
                control.markAsTouched();
                control.markAsDirty();
                control.updateValueAndValidity();
            }
        });
    }

    /**
     * IMPORTANT: when using this function, you MUST subscribe to the valueChanges of the @predicate,
     *  and use FormControl.updateValueAndValidity
     */
    conditionalValidator(predicate: Predicate<void>, validator: ValidatorFn): ValidatorFn {
        return (formControl) => {
            if (!formControl.parent) {
                return null;
            }
            if (predicate()) {
                return validator(formControl);
            }
            return null;
        };
    }
}
