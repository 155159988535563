import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UUID } from 'angular2-uuid';


@Injectable()
export class SessionIdService {

    private isBrowser: boolean;

    constructor(@Inject(PLATFORM_ID) private platformId: any) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (!this.sessionId) {
            this.sessionId = UUID.UUID();
        }
    }

    public set sessionId(guid: string) {
        if (this.isBrowser) {
            sessionStorage.setItem('SessionId', guid);
        }
    }
    public get sessionId(): string {
        if (this.isBrowser) {
            return sessionStorage.getItem('SessionId');
        }
        return null;
    }

    refreshSessionId() {
        this.sessionId = UUID.UUID();
    }
}
