import { Injectable, Inject, PLATFORM_ID, NgZone } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { HttpService, IHttpRequestOptions, IResponse } from './http-service.service';
import { AppConfigsService } from "../../../config/app-configs.service";
import { ILog } from "../../models/log"
import { LogType } from "../../enums/logType";
import { LoggerHandlerService } from "./logger.handler.service";
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { IApiErrorLog } from '../models/api-error-log';

@Injectable()
export class LoggerService {
    private isBrowser: boolean;
    private httpClient: HttpClient;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private loggerHandler: LoggerHandlerService,
        private appConfigsService: AppConfigsService,
        private httpSvc: HttpService,
        private handler: HttpBackend
    ) {
        this.httpClient = new HttpClient(handler);
        this.isBrowser = isPlatformBrowser(platformId);

        this.setLoggerHandlingServiceSubscriptions();
    }

    private setLoggerHandlingServiceSubscriptions(): void {
        this.loggerHandler.log.subscribe((message: string) => {
            this.sendLogSync(message, LogType.Info).subscribe(() => {
                this.loggerHandler.logCompleted.next(message);
            }, () => {
                this.loggerHandler.logCompleted.next(message);
            });
        });

        this.loggerHandler.warning.subscribe((message: string) => {
            this.sendLogSync(message, LogType.Warning).subscribe(() => {
                this.loggerHandler.warningCompleted.next(message);
            }, () => {
                this.loggerHandler.warningCompleted.next(message);
            });
        });

        this.loggerHandler.error.subscribe((message: string) => {
            this.sendLogSync(message, LogType.Error).subscribe(() => {
                this.loggerHandler.errorCompleted.next(message);
            }, () => {
                this.loggerHandler.errorCompleted.next(message);
            });
        });

        this.loggerHandler.fatal.subscribe((message: string) => {
            this.sendLogSync(message, LogType.Fatal).subscribe(() => {
                this.loggerHandler.fatalCompleted.next(message);
            }, () => {
                this.loggerHandler.fatalCompleted.next(message);
            });
        });

        this.loggerHandler.apiError.subscribe((apiError: IApiErrorLog) => {
            this.sendLogSync(apiError.message, LogType.Error, apiError.referer).subscribe(() => {
                this.loggerHandler.errorCompleted.next(apiError.message);
            }, () => {
                this.loggerHandler.errorCompleted.next(apiError.message);
            });
        });
    }




    public log(message: string): void {
        this.sendLog(message, LogType.Info);
    }

    public warning(message: string): void {
        this.sendLog(message, LogType.Warning);
    }

    public error(message: string): void {
        this.sendLog(message, LogType.Error);
    }

    public fatal(message: string): void {
        this.sendLog(message, LogType.Fatal);
    }

    public sync = {
        log: (message: string) => {
            return this.sendLogSync(message, LogType.Info);
        },
        warning: (message: string) => {
            return this.sendLogSync(message, LogType.Warning);
        },
        error: (message: string) => {
            return this.sendLogSync(message, LogType.Error);
        },

        fatal: (message: string) => {
            return this.sendLogSync(message, LogType.Fatal);
        }
    }

    private sendLog(message: string, logType: LogType, referer: string = null): void {
        if (this.isBrowser) {
            let options: IHttpRequestOptions;
            if (referer) {
                options = {
                    defaultApiFailureBehavior: false,
                    headers: new HttpHeaders({'URF': referer})
                }
            }
            // This http client instance bypasses interceptors. 
            // We use this because the app makes a "user entered page" log when loading up.
            // This log happens very early and is a POST request.
            // When the transfer state interceptor sees this POST request it thinks we are making a state mutating action
            // and it ignores the transfered state from the server renderer, making the app needlessly call all content APIs again.
            this.httpClient.post(
                `${this.appConfigsService.appConfigs.apiUrl}/logs/log`,
                this.buildMessage(message, logType),
                { withCredentials: true }).subscribe((res) => {});
            //this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/logs/log`, this.buildMessage(message, logType), options).subscribe((res) => {});
        }
    }

    private sendLogSync(message: string, logType: LogType, referer: string = null): Observable<IResponse<null>> {
        if (this.isBrowser) {
            let options: IHttpRequestOptions;
            if (referer) {
                options = {
                    defaultApiFailureBehavior: false,
                    headers: new HttpHeaders({'URF': referer})
                }
            }
            return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/logs/log`, this.buildMessage(message, logType), options);
        } else {
            return new Observable<IResponse<null>>(observer => {
                observer.next();
            });
        }
    }

    private buildMessage(message: string, logType: LogType): ILog {
        let log: ILog = {
            Message: message,
            Type: logType
        };
        return log;
    }
}
