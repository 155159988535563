<div class="personal-message" *ngIf="notificationArr.length > 0">
    <div class="container" [ngClass]="{'more-then-one': notificationArr.length > 1}">
        <span class="title">יש לך הודעה</span>
        <div *ngIf="uiHelper.IsMobile(768)">
            <ngx-slick-carousel class="autoplay slider personal-msg-slider message-wrapper" #slickModal="slick-carousel" [config]="sildeConfig" *appIfStable>
                <div ngxSlickItem class="message" *ngFor="let notification of notificationArr; let i = index">
                    <span class="mobile-notice-icon"></span>
                    <div class="text-wrapper">
                        <span class="main-text">{{notification.NoticeTitle}}</span>
                        <span class="alert-text">{{notification.NoticeSubTitle}}</span>
                    </div>
                    <div class="link-wrapper">
                        <div class="ignor-link">
                            <a class="default-link" (click)="removeNotification(notification.NoticeId)"
                               appGtm [data]="{category: 'New website - registered', action: 'Notices - Thank you click', label: '' + notification.NoticeName + ' - ' + notificationArr.length + ' - ' + (i + 1)}" listen="onclick">ראיתי, תודה</a>
                        </div>
                        <a class="default-link" appLink [anchorLink]="notification.NoticeLink" [isOpenLinkInNewTab]="false"
                           appGtm [data]="{category: 'New website - registered', action: 'Notices - action click', label: '' + notification.NoticeName + ' - ' + notificationArr.length + ' - ' + (i + 1) }" listen="onclick">{{notification.NoticeLinkTitle}}</a>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
        <div *ngIf="!uiHelper.IsMobile(768)" class="slider personal-msg-slider message-wrapper">
            <div class="message" *ngFor="let notification of notificationArr; let i = index">
                <span class="mobile-notice-icon"></span>
                <div class="text-wrapper">
                    <span class="main-text">{{notification.NoticeTitle}}</span>
                    <span class="alert-text">{{notification.NoticeSubTitle}}</span>
                </div>
                <div class="link-wrapper">
                    <div class="ignor-link" *ngIf="notificationArr.length > 1">
                        <a class="default-link" (click)="removeNotification(notification.NoticeId)"
                           appGtm [data]="{category: 'New website - registered', action: 'Notices - Thank you click', label: '' + notification.NoticeName + ' - ' + notificationArr.length + ' - ' + (i + 1)}" listen="onclick">ראיתי, תודה</a>
                    </div>
                    <a class="default-link" appLink [anchorLink]="notification.NoticeLink" [isOpenLinkInNewTab]="false"
                       appGtm [data]="{category: 'New website - registered', action: 'Notices - action click', label: '' + notification.NoticeName + ' - ' + notificationArr.length + ' - ' + (i + 1)}" listen="onclick">{{notification.NoticeLinkTitle}}</a>
                    <div class="ignor-link" *ngIf="notificationArr.length == 1">
                        <a class="default-link" (click)="removeNotification(notification.NoticeId)"
                           appGtm [data]="{category: 'New website - registered', action: 'Notices - Thank you click', label: '' + notification.NoticeName + ' - ' + notificationArr.length + ' - ' + (i + 1)}" listen="onclick">ראיתי, תודה</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>