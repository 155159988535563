import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from "@angular/core";
@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    public  newUrl: string;

    parse(url: string): UrlTree {
        // Optional Step: Do some stuff with the url if needed.
        // If you lower it in the optional step 
        // you don't need to use "toLowerCase" 
        // when you pass it down to the next function
        const urlParams = url.split('?');
        if (urlParams[1] != undefined) {
            this.newUrl = urlParams[0].toLowerCase() + '?' + urlParams[1];
        } else {
            this.newUrl = url.toLowerCase();
        }
        return super.parse(this.newUrl); 
    }
}