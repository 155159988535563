import { GroupComponent } from './../dynamic-form-control/controls/control-group';
import { ComponentBase } from './../dynamic-form-control/controls/control-base';
import { Injectable } from '@angular/core';
import { TextboxComponent } from '../dynamic-form-control/controls/control-textbox';
import { DropdownComponent } from '../dynamic-form-control/controls/control-dropdown';
import { SwitchComponent } from '../dynamic-form-control/controls/control-switch';
import { AutoCompleteComponent } from '../dynamic-form-control/controls/control-autocomplete';
import { ButtonComponent } from '../dynamic-form-control/controls/control-button';
import { DateComponent } from '../dynamic-form-control/controls/control-date';
import { SelectButtonComponent } from '../dynamic-form-control/controls/control-select-button';
import { CheckBoxComponent } from '../dynamic-form-control/controls/control-checkbox';
import { LabelComponent } from '../dynamic-form-control/controls/control-label';
import { UploadComponent } from '../dynamic-form-control/controls/control-upload';
import { RecordComponent } from '../dynamic-form-control/controls/control-record';
import { RadioGroupComponent } from '../dynamic-form-control/controls/control-radio-group';
import { RadioGroupExtendedComponent } from '../dynamic-form-control/controls/control-radio-group-extended';
import { SummaryBoxComponent } from '../dynamic-form-control/controls/control-summary-box';
import { UlListComponent } from "../dynamic-form-control/controls/control-ul-list";
import { BDateComponent } from "../dynamic-form-control/controls/control-bdate";
import { ITextComponent } from "../dynamic-form-control/controls/control-iText";
import { MaskInputComponent } from '../dynamic-form-control/controls/control-mask-input';
import { DreamCardChildComponent } from "../dynamic-form-control/controls/control-dream-card-child";


@Injectable()
export class FormBuilderService {

    public createViewComponents(metaData): ComponentBase<any>[] {
        const components: ComponentBase<any>[] = [];

        metaData.controls.forEach(item => {
            switch (item.type) {
                case "group":
                    components.push(
                        new GroupComponent({
                            key: item.key,
                            id: item.id,
                            label: item.label,
                            layout: item.layout,
                            visible: item.visible,
                            order: item.order,
                            controls: this.createViewComponents(item)
                        })
                    );
                    break;
                case "textbox":
                    components.push(
                        new TextboxComponent({
                            key: item.key,
                            label: item.label,
                            link: item.link,
                            validators: item.validators,
                            parentKey: item.parentKey,
                            placeholder: item.placeholder,
                            visible: item.visible,
                            order: item.order,
                            hint: item.hint,
                            keyboard: item.keyboard,
                            value: item.value,
                            required: item.required,
                            readonly: item.readonly,
                            maxLength: item.maxLength,
                            typingFormat: item.typingFormat,
                            disablePaste: item.disablePaste,
                            warningText: item.warningText,
                            autoCompleteHtml: item.autoCompleteHtml,
                            extraLabel: item.extraLabel
                        }));
                    break;
                case "autocomplete":
                    components.push(
                        new AutoCompleteComponent({
                            key: item.key,
                            label: item.label,
                            validators: item.validators,
                            hint: item.hint,
                            dropdown: item.dropdown,
                            placeholder: item.placeholder,
                            order: item.order,
                            required: item.required,
                            visible: item.visible,
                            keyboard: item.keyboard,
                            selected: item.selected,
                            requestTarget: item.requestTarget,
                            maxLength: item.maxLength,
                            disablePaste: item.disablePaste,
                            extraLabel: item.extraLabel
                        }));
                    break;
                case "dropdown":
                    components.push(
                        new DropdownComponent({
                            key: item.key,
                            label: item.label,
                            link: item.link,
                            validators: item.validators,
                            parentKey: item.parentKey,
                            order: item.order,
                            placeholder: item.placeholder,
                            required: item.required,
                            hint: item.hint,
                            value: item.value
                        })
                    );
                    break;
                case "switch":
                    components.push(
                        new SwitchComponent({
                            key: item.key,
                            label: item.label,
                            validators: item.validators,
                            options: item.options,
                            order: item.order,
                            value: item.value,
                            visible: item.visible,
                            hint: item.hint,
                        })
                    );
                    break;
                case "button":
                    components.push(
                        new ButtonComponent({
                            key: item.key,
                            text: item.text,
                            order: item.order,
                        })
                    );
                    break;
                case "date":
                    components.push(
                        new DateComponent({
                            key: item.key,
                            label: item.label,
                            validators: item.validators,
                            value: item.value,
                            order: item.order,
                            fromYear: item.fromYear
                        })
                    );
                    break;
                case "mask":
                    components.push(
                        new MaskInputComponent({
                            key: item.key,
                            label: item.label,
                            validators: item.validators,
                            placeholder: item.placeholder,
                            value: item.value,
                            order: item.order,
                            required: item.required,
                            keyboard: item.keyboard,
                            disablePaste: item.disablePaste
                        })
                    );
                    break;
                case "select-button":
                    components.push(
                        new SelectButtonComponent({
                            key: item.key,
                            label: item.label,
                            order: item.order,
                            options: item.options,
                            value: item.value
                        })
                    );
                    break;
                case "uploadPhoto":
                    components.push(
                        new UploadComponent({
                            key: item.key,
                            title: item.title,
                            text: item.text,
                            order: item.order,
                            photoType: item.photoType,
                            validators: item.validators,
                            required: item.required
                        })
                    );
                    break;
                case "checkbox":
                    components.push(
                        new CheckBoxComponent({
                            key: item.key,
                            label: item.label,
                            customClass: item.customClass,
                            text: item.text,
                            value: item.value,
                            order: item.order,
                            validators: item.validators,
                            customTextTitle: item.customTextTitle,
                            customText: item.customText,
                            visible: item.visible,
                            required: item.required,
                            optionalText: item.optionalText,
                            customRequiredErrorMessage: item.customRequiredErrorMessage
                        })
                    );
                    break;
                case "label":
                    components.push(
                        new LabelComponent({
                            key: item.key,
                            order: item.order,
                            text: item.text,
                            icon: item.icon,
                            iconName: item.iconName,
                            optionalText: item.optionalText,
                            visible: item.visible,
                            hint: item.hint
                        })
                    );
                    break;
                case "itext":
                    components.push(
                        new ITextComponent({
                            key: item.key,
                            order: item.order,
                            text: item.text,
                            textWithHtml: item.textWithHtml,
                            icon: item.icon,
                            hint: item.hint
                        })
                    );
                    break;
                case "record":
                    components.push(
                        new RecordComponent({
                            key: item.key,
                            order: item.order,
                        })
                    );
                    break;
                case "radioGroup":
                    components.push(
                        new RadioGroupComponent({
                            key: item.key,
                            label: item.label,
                            order: item.order,
                            options: item.options,
                            hint: item.hint,
                            required: item.required,
                            icons: item.icons,
                            value: item.value,
                            attentionInfo: item.attentionInfo
                        })
                    );
                    break;
                case "radioGroupExtended":
                    components.push(
                        new RadioGroupExtendedComponent({
                            key: item.key,
                            label: item.label,
                            order: item.order,
                            options: item.options,
                            hint: item.hint,
                            required: item.required,
                            value: item.value,
                            attentionInfo: item.attentionInfo
                        })
                    );
                    break;
                case "summaryBox":
                    components.push(
                        new SummaryBoxComponent({
                            key: item.key,
                            label: item.label,
                            order: item.order,
                            options: item.options
                        })
                    );
                    break;
                case "ulList":
                    components.push(
                        new UlListComponent({
                            key: item.key,
                            label: item.label,
                            text: item.text,
                            order: item.order,
                            options: item.options
                        })
                    );
                    break;
                case "dreamCardChildren":
                    components.push(
                        new DreamCardChildComponent({
                            key: item.key,
                            order: item.order
                        })
                    );
                    break;
            }
        });

        // return components;
        return components.sort((a, b) => a.order - b.order);
    }
}
