import { IAppleAuthorization } from '../models/apple-sign-in.interface';
import { AppConfigsService } from '../../../config/app-configs.service';
import { ILoginResult } from '../models/login-result';
import { HttpService, IHttpRequestOptions } from './http-service.service';
import { IResponseLower } from 'core-max-lib';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { IResponse } from './http-service.service';
import { LoginStatus } from '../models/login-result';
import { OpenBankingService } from '../../../modules/open-banking/open-banking.service';
import { ScriptHelperService } from '../../services/script-load-helper.service';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AppleSignInService {
    constructor(
        private http: HttpService,
        private httpC: HttpClient,
        private configService: AppConfigsService,
        private authService: AuthService,
        private scriptHelper: ScriptHelperService,

        private openBankingService: OpenBankingService
    ) {}

    getChallenge(signInId?: string, options?: IHttpRequestOptions): Observable<IResponseLower<string>> {
        return this.http.postLower(`${this.configService.appConfigs.apiUrl}/appleSignIn/generateChallenge`, {
            signInId
        }) as any;
    }
    getChallengeNewUser(signInId?: string, options?: IHttpRequestOptions): Observable<IResponseLower<string>> {
        return this.http.postLower(`${this.configService.appConfigs.apiUrl}/register-website/generateChallenge`, {
            signInId
        }) as any;
    }
    getChallengeH(signInId?: string, options?: IHttpRequestOptions): Observable<HttpResponse<IResponseLower<string>>> {
        return this.httpC.post<IResponseLower<string>>(
            `${this.configService.appConfigs.apiUrl}/appleSignIn/generateChallenge`,
            {
                signInId
            },
            { observe: 'response' }
        );
    }
    register(auth: IAppleAuthorization): Observable<IResponseLower<string>> {
        return this.http.postLower(
            `${this.configService.appConfigs.apiUrl}/appleSignIn/registerVerifyAuth`,
            auth
        ) as any;
    }

    login(auth: IAppleAuthorization): Promise<IResponse<ILoginResult>> {
        const method: string = this.openBankingService.isOpenBankingLoginMode() ? 'openBanking' : '';
        return new Promise((resolve, reject) => {
            // tslint:disable:max-line-length
            this.http
                .post<ILoginResult>(
                    `${this.configService.appConfigs.apiUrl}/appleSignIn/${method}loginVerifyAuth`,
                    auth
                )
                .subscribe((res: IResponse<ILoginResult>) => {
                    console.log(res);
                    if (
                        res.ReturnCode === 0 &&
                        res.Result.LoginStatus !== LoginStatus.passwordExpired &&
                        res.Result.LoginStatus !== LoginStatus.falied
                    ) {
                        this.authService.markUserHasAuthenticated(res.Result.Roles);
                    }
                    resolve(res);
                });
        });
    }

    removeAppleSignIn(signInId: string): Observable<IResponseLower<any>> {
        return this.http.getLower(
            `${this.configService.appConfigs.apiUrl}/appleSignIn/removeSignIn?signInId=${signInId}`
        ) as any;
    }

    loadScript(src: string): Promise<any> {
        return this.scriptHelper.singleScriptLoader(src, 'url', null, { defer: true, async: true });
    }
}
