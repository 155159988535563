import { Injectable } from '@angular/core';
import { UiHelperService } from './ui-helper.service';
import { Observable } from 'rxjs';
import { HttpService, IResponse } from './http-service.service';
import { AppConfigsService } from '../../../config/app-configs.service';

@Injectable()
export class VersionService {

  constructor(private httpSvc: HttpService,
    private appConfigsService: AppConfigsService) { }

   compare(): Observable<IResponse<boolean>> {
     return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/version/compareWithServer`, null);
   }
}
