import { SpinnerService } from '../services/spinner.service';
import { Observable } from 'rxjs';
import { timeout, tap } from 'rxjs/operators';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
import { LoggerHandlerService } from '../services/logger.handler.service';
import { ErrorsHandlerService } from '../services/errors.handler.service';
import { isNullOrUndefined } from 'core-max-lib';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from '@angular/router';
import { NotRedirectToLogin } from '../utils/auth-to-no-login';
import { IApiErrorLog } from '../models/api-error-log';

@Injectable()
export class HttpServiceInterceptor implements HttpInterceptor {
    private _reqs;
    private isBrowser: boolean;
    private currRouteUseSpinner: any;

    constructor(
        private spinnerSrv: SpinnerService,
        @Inject(PLATFORM_ID) private platformId: any,
        private loggerHandler: LoggerHandlerService,
        private errorHandler: ErrorsHandlerService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this._reqs = 0;
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const TIME_OUT = 1000 * 60 * 2; // 120 Sec

        const newReq = req.clone({
            withCredentials: true,
            params: req.params.delete('defaultApiFailureBehavior')
        });

        if (
            !this.isBrowser ||
            newReq.url.includes('logs/log') ||
            newReq.url.includes('session/keepAlive') ||
            newReq.url.includes('common/SendElqCustomer')
        ) {
            return next.handle(newReq);
        }

        return next.handle(newReq).pipe(
            timeout(TIME_OUT),
            tap(
                event => {
                    if (
                        !event.type &&
                        !newReq.url.includes('/api/scripts') &&
                        this.checkDisableDefaultSpinnerBehavior(req)
                    ) {
                        // exclude this api from showing the spinner because its a background task
                        this._reqs++;
                    }
                    if (event instanceof HttpResponse) {
                        if (!newReq.url.includes('/api/scripts') && this.checkDisableDefaultSpinnerBehavior(req)) {
                            this._reqs--;
                        }
                        // imperva status code
                        if (event.status === 202) {
                            this.loggerHandler.log.next(
                                `URL[${event.url}] is blocked by imperva. Method [${
                                    req.method
                                }] Payload [${JSON.stringify(event.body)}]`
                            );
                        }
                    }
                    if (event instanceof HttpErrorResponse) {
                        // error handler;
                        this._reqs = 0;
                        if (this.isBrowser) {
                            this.loggerHandler.error.next(this.BuildErrorMessage(event));
                        }
                    }
                    if (
                        !(
                            this.activatedRoute.snapshot.firstChild !== null &&
                            this.activatedRoute.snapshot.firstChild.firstChild.data['useSpinner'] === false
                        ) &&
                        !newReq.url.includes('/api/scripts')
                    ) {
                        this.spinnerSrv.spinner.next(this._reqs);
                    }
                    /*this.currRouteUseSpinner = this.router.config.filter(conf => {
                        return conf.data !== undefined && conf.data['useSpinner'] !== undefined;
                    });*/

                    return event;
                },
                error => {
                    if (this.checkDisableDefaultSpinnerBehavior(req)) {
                        this._reqs--;
                    }
                    if (this.isBrowser) {
                        const errorMessage: string = this.BuildErrorMessage(error);

                        if (error.status === 401 || error.status === 403) {
                            if (NotRedirectToLogin.active(error.url)) {
                                return;
                            }
                            this.loggerHandler.warning.next(errorMessage);
                            this.errorHandler.redirectToLoginPage.next(error.status);
                        } else if (error instanceof HttpErrorResponse && error.error && error.error.type === 'abort') {
                            // if the request is abortted we log warrning.
                            this.loggerHandler.warning.next(errorMessage);
                        } else {
                            const apiError: IApiErrorLog = {
                                message: errorMessage,
                                referer: window.location.origin + newReq.url
                            };
                            this.loggerHandler.apiError.next(apiError);
                            if (this.checkDefaultApiFailureBehavior(req)) {
                                this.errorHandler.redirectToErrorPageSubject.next();
                            }
                        }
                    }
                }
            )
        );
    }

    private BuildErrorMessage(error: any): string {
        let message = `HTTPERROR. Message [${error.message}]`;

        if (error instanceof HttpErrorResponse && error.error && error.error.type) {
            message += ` Type [${error.error.type}]`;
        }

        if (!isNullOrUndefined(error.name)) {
            message += ` Name [${error.name}]`;
        }
        if (!isNullOrUndefined(error.status)) {
            message += ` Status [${error.status}]`;
        }
        if (!isNullOrUndefined(error.statusText)) {
            message += ` StatusText [${error.statusText}]`;
        }
        if (!isNullOrUndefined(error.url)) {
            message += ` Url [${error.url}]`;
        }

        return message;
    }

    private checkDisableDefaultSpinnerBehavior(req: HttpRequest<any>): boolean {
        if (req.params.get('disableDefaultSpinnerBehavior') !== null) {
            return /^false$/i.test(req.params.get('disableDefaultSpinnerBehavior'));
        }
        return true;
    }

    private checkDefaultApiFailureBehavior(req: HttpRequest<any>): boolean {
        if (req.params.get('defaultApiFailureBehavior') !== null) {
            return !/^false$/i.test(req.params.get('defaultApiFailureBehavior'));
        }
        return true;
    }
}
