export interface LinkRelDef {
    source: string;
    type: RelSource;
    exstraType?: string;
    crossorigin?: boolean;
}

export enum RelSource {
    audio = 'audio',
    video = 'video',
    track = 'track',
    script = 'script',
    style = 'style',
    font = 'font',
    image = 'image',
    fetchcrossorigin = 'fetch crossorigin',
    worker = 'worker',
    embed = 'embed',
    object = 'object',
    iframe = 'document',
    preconnect = 'preconnect'
}
