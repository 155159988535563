<div class="product immediate-loan" [ngStyle]="{
            'background-image' : isMobile ?
            'url(' + recommendation.RecommendationBackgroundPicMobile.Url + ')' :
            'url(' + recommendation.RecommendationBackgroundPicDesktop.Url + ')'
         }">
    <h3 [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}">{{recommendation.RecommendationTitle}}</h3>
    <div class="loan-sum">
        <div class="pick-price">
            <h5 [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}">כמה תרצה?</h5>
            <div class="sum">
                <input type="tel" maxlength="8" appNumber [(ngModel)]="loan.amount" (click)="removePlaceHolder('amount')" (blur)="addPlaceHolder('amount')" [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}" />
            </div>
        </div>
        <div class="pick-price">
            <h5 [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}">בכמה תשלומים?</h5>
            <div class="sum">
                <input type="tel" maxlength="2" [(ngModel)]="loan.payments" id="payment2" (click)="removePlaceHolder('payments')" (paste)="onPaste($event, 2)" (blur)="addPlaceHolder('payments')" [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}" />
            </div>
        </div>
    </div>
    <p class="loan-warning" [ngStyle]="{'color' : ((recommendation.FontColor !== null) ? (recommendation.FontColor) : '')}">{{recommendation.GeneralText}}</p>
    <a class="general-button prod-link" (click)="onRecommendationButtonClick(recommendation, indexOfRecommendation)" [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : ''), 'border-color' : ((recommendation.FontColor !== null) ? recommendation.FontColor : '')}">
        <span [ngStyle]="{'color' : ((recommendation.FontColor !== '') ? (recommendation.FontColor) : '')}">{{recommendation.RecommendationLinkTitle}}</span>
    </a>
</div>