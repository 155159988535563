import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { HttpService, IResponse } from "./http-service.service";
import { IBanks } from "../../../modules/join-form/models/banks/banks";
import { ICities } from "../../../modules/join-form/models/cities/cities";
import { IStreets } from "../../../modules/join-form/models/streets/streets";
import { AppConfigsService } from "../../../config/app-configs.service";
import { IBranch } from '../../models/branch';
import { tap } from 'rxjs/operators';

@Injectable()
export class ContentsService {

    cities: IResponse<ICities>;

    constructor(private httpSvc: HttpService, private appConfigsService: AppConfigsService) {

    }
    getBanks(): Observable<IResponse<IBanks>> {
        return this.httpSvc.getWithSsrCache(`${this.appConfigsService.appConfigs.apiUrl}/contents/getBanks`, "banksData" );
    }

    getBranches(bankId: number): Observable<IResponse<IBranch[]>> {
        const httpParams = new HttpParams().set('bankId', bankId.toString());
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/contents/getBranches`, httpParams);
    }

    getCities(): Observable<IResponse<ICities>> {
        if(this.cities){
            return of(this.cities);
        }
        return this.httpSvc.get<ICities>(`${this.appConfigsService.appConfigs.apiUrl}/contents/getCities`).pipe(
            tap(res => {
                this.cities = res;
            })
        );
    }

    getStreet(cityCode): Observable<IResponse<IStreets>> {
        const httpParams = new HttpParams().set('cityCode', cityCode.toString());
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/contents/getStreets`, httpParams);
    }


}
