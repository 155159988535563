import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { UserBoxComponent } from './header/user-box/user-box.component';
import { NavComponent } from './header/nav/nav.component';
import { SearchComponent } from './header/search/search.component';
import { ActionsComponent } from './header/actions/actions.component';
import { MainLayoutComponent } from './main-layout.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MainLayoutService } from './services/main-layout.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CardholdersCoreModule } from '../../shared/cardholders-core/cardholders-core.module';
import { SessionTimeoutModalComponent } from './modals/session-timeout-modal/session-timeout-modal.component';
import { MainLayoutSessionService } from './services/main-layout-session.service';
import { TechnicalErrorModalComponent } from './modals/technical-error-modal/technical-error-modal.component';
import { CookiesNotificationComponent } from './header/cookies-notification/cookies-notification.component';
import { Ev8NotificationComponent } from './header/ev8-notification/ev8-notification.component';
import { LoginModule } from '../login/login.module';
import { AsteriksComponent } from './asteriks/asteriks.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MinSearchComponent } from './header/min-search/min-search.component';
import { AsteriskService } from './services/asterisk.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
    imports: [
        CommonModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        AutoCompleteModule,
        FormsModule,
        RouterModule,
        CarouselModule.forRoot(),
        CardholdersCoreModule,
        LoginModule,
        AccordionModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule,
        SlickCarouselModule
    ],
    declarations: [
        HeaderComponent,
        UserBoxComponent,
        NavComponent,
        SearchComponent,
        MinSearchComponent,
        ActionsComponent,
        MainLayoutComponent,
        AsteriksComponent,
        FooterComponent,
        CookiesNotificationComponent,
        Ev8NotificationComponent,
        SessionTimeoutModalComponent,
        TechnicalErrorModalComponent
    ],
    entryComponents: [SessionTimeoutModalComponent, TechnicalErrorModalComponent],
    providers: [MainLayoutService, MainLayoutSessionService, AsteriskService]
})
export class MainLayoutModule {}
