import { IModalConfig } from '../interfaces/modal-config.interface';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// tslint:disable-next-line:import-blacklist
import { Subject, Subscription, Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'core-max-lib';

@Injectable()
export class ModalService {
    afterClosed = new EventEmitter();
    private _modalSubject: Subject<any> = new Subject();
    private currentActiveModal: string;
    private subs: Subscription[] = [];
    private modalRef: BsModalRef;
    private modalRefsArray: BsModalRef[] = [];

    constructor(private bsModalSvc: BsModalService) {
        this.bsModalSvc.onHidden.subscribe(() => {
            if (this.modalRef) {
                this.modalRefsArray.push(this.modalRef);
            }
            this.modalRef = null;
            this.afterClosed.emit();
        });
    }

    // return observable to alert subscriber when modal closed
    openModal(modalConfig: IModalConfig): Observable<any> {
        if (isNullOrUndefined(this.modalRef)) {
            this.doOpenModal(modalConfig);
        } else {
            if (this.currentActiveModal !== modalConfig.type) {
                this.doCloseModal();
                this.doOpenModal(modalConfig);
            }
        }

        return new Observable((obs) => {
            this.subs.push(this._modalSubject.subscribe((data) => {
                obs.next(data);
            }));
        });
    }

    closeModal(): void {
        if (!isNullOrUndefined(this.modalRef)) {
            this.doCloseModal();
        }
    }
    // alert subscribers that modal closed with data
    closeModalAndAlertSubscribers(data?: any): void {
        if (!isNullOrUndefined(this.modalRef)) {
            this.afterClosed.emit(data);
            this._modalSubject.next(data);
            this.doCloseModal();
        } else {
            this.closeAllModals();
        }
    }

    // alert subscribers an action occurred. without closing the modal.
    alertModalSubscribers(data?: any) {
        if(this.modalRef){
            this._modalSubject.next(data);
        }
    }

    private doOpenModal(modalConfig: IModalConfig): void {
        this.modalRef = this.bsModalSvc.show(modalConfig.content, modalConfig.options);
        this.currentActiveModal = modalConfig.type;
    }
    // clear current modal and clear subscribers
    private clearModal(): void {
        this.clearSubscribers();
        this.modalRef = null;
        this.currentActiveModal = null;
    }

    private clearSubscribers(): void {
        // tbd complete subscribers, below mothod wont fire complete event on subscribers
        // this._modalSubject.observers.forEach((obs) => {
        //    obs.complete();
        // });
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
        this.subs = [];
    }

    private doCloseModal(): void {
        if (!isNullOrUndefined(this.modalRef)) {
            this.modalRef.hide();
            this.clearModal();
        }
    }

    private closeAllModals(): void {
        for (const ref of this.modalRefsArray) {
            try {
                ref.hide();
            } catch (error) {

            }
        }
        this.clearModal();
    }
}
