export enum ReplaceCardReason {
    PealedOrBroken = 0,
    NotWorking = 1,
    ContactLess = 2
}

export enum VerifyDetails {
    Correct = 0,
    Incorrect = 1
}

export enum DeliveryType {
    DeliveryPerson = 1,
    Post = 2,
    Bank = 3
}

export enum ErrorType {
    WorldElite = 1,
    Technical = 2,
    TechnicalWhenProcess = 3,
    NoCards = 4
}
