import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AppConfigsService } from '../../../config/app-configs.service';
import { IResponse } from './http-service.service';

@Injectable({
  providedIn: 'root'
})
export class SeoContentService {

  seoContent: string[];

  constructor(private router: Router,
  private httpSvc: HttpClient,
  private route: ActivatedRoute,
  private appConfigsService: AppConfigsService,
  private meta: Meta) { }

  start(): void {
    this.httpSvc.get<IResponse<string[]>>(`${this.appConfigsService.appConfigs.apiUrl}/seocontent/getSeoContentDisallow`)
            .subscribe(seo => {
              this.seoContent = seo.Result;
              this.routerEvent();
            });
  }

  routerEvent(){
    this.router.events.pipe(filter(e => e instanceof NavigationEnd),
    map((e) => ({e: e, route: this.route.snapshot})),
    map((obj) => {
        while (obj.route.firstChild) {
            obj.route = obj.route.firstChild;
        }
        return ({e: obj.e, route: obj.route});
    }),
    filter((obj) => !obj.route.data || !obj.route.data['skipSeoPageData']),
    map((obj) => (obj.e as NavigationEnd).urlAfterRedirects.split('?')[0])).subscribe(path => {
        this.handleSeoResponse(path, this.seoContent);
    });
  }

  handleSeoResponse(path: string, seo: string[]){
    if(seo.includes(path)){
      this.meta.addTag({name: "robots", content: "noindex, nofollow"});
    }else{
      this.meta.removeTag('name=robots');
    }
  }
}
