<div class="my-insurance" *ngIf="showMyInsurance">
    <div class="container">
        <h2 class="title">הביטוחים שלי</h2>

        <div class="card-box-wrapper insurance-acceptance-cube"
             *ngIf="showInsuranceAcceptance"
             (click)="acceptInsurance()">
            <div class="insurance-acceptance-text">
                כאן נוכל להציג את המידע הביטוחי שלך ב- m_a_x ביטוח
            </div>
            <div class="default-link target-link-bold">אני רוצה לקבל את המידע</div>
        </div>

        <div class="card-details" *ngIf="!showInsuranceAcceptance">
            <div class="card-box-wrapper" *appLoader="myInsuranceIsLoaded; template: insuranceLoaderTemplateEl">
                <div class="only-card-wrapper">
                    <ngx-slick-carousel class="autoplay clearfix slider"
                                        #slickModal="slick-carousel"
                                        [config]="myInsuranceSildeConfig"
                                        *appIfStable
                                        [ngClass]="{
                                        'not-one-card-insurance-autoplay' : insuranceCards.length>
                        1 || !uiHelper.IsMobile(770)
                        }"
                        >
                        <div (click)="insuranceClick(insurance)"
                             ngxSlickItem
                             class="card card-box"
                             [ngClass]="{
                             'desktop-hide' : j>
                            3 && !isShowAllInsurance,
                            'one-card-insurance': insuranceCards.length === 1
                            }"
                            *ngFor="let insurance of insuranceCards; index as j"
                            >
                            <div class="card-content">
                                <div class="card-detail-wrapper">
                                    <h4>חברה מבטחת: {{ insurance.insuranceCompanyName }}</h4>
                                    <h2>{{ insurance.title }}</h2>
                                </div>
                                <div class="policy-details">
                                    <div class="fields">
                                        <div *ngFor="let field of insurance.fields"
                                             [ngClass]="{ ellipsis: field.isEllipsis }">
                                            <span *ngIf="field.name">{{ field.name }} - </span>
                                            <span *ngIf="field.value">{{ field.value }}</span>
                                        </div>
                                    </div>
                                    <div class="renew-policy" *ngIf="insurance.showRenewInsurance">
                                        <a (click)="renewInsurance(insurance); $event.stopPropagation()"
                                           class="brand-button aqua-button"
                                           title=""><span>לחידוש הפוליסה</span></a>
                                    </div>
                                    <div class="default-link target-link-bold">לפרטי הפוליסה ופעולות</div>
                                </div>
                            </div>
                        </div>
                        <div ngxSlickItem
                             *ngIf="!uiHelper.IsMobile(770)"
                             [ngClass]="{ 'card card-box empty-card': true }"
                             [hidden]="
                             insuranceCards?.length <= 1 ||
                                insuranceCards?.length % 2 === 0 ||
                                (insuranceCards?.length > 4 && !isShowAllInsurance)
                            "
                        ></div>
                    </ngx-slick-carousel>
                </div>
                <div>
                    <div [ngClass]="{ 'show-all-cards': true, 'show-less-card': isShowAllInsurance }"
                         (click)="toggleInsurance()"
                         *ngIf="isShowInsuranceText">
                        <span *ngIf="isShowAllInsurance">תראו לי פחות</span>
                        <span *ngIf="!isShowAllInsurance">תראו לי הכל</span>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="showRecommendation">
                <app-recommendation-box *appLoader="recommendationIsLoaded; template: recommendationLoaderTemplateEl"
                                        class="recommendation-box"
                                        [recommendation]="recommendationsToShow[0]"
                                        [backgroundColor]="'#e5e5e5'"
                                        [wideTitle]="true"
                                        [isInsurance]="true"></app-recommendation-box>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #insuranceLoaderTemplateEl>
    <div class="card-box-wrapper">
        <div class="only-card-wrapper">
            <div class="autoplay clearfix slider mobile-hide">
                <div class="card card-box app-loader-blink"></div>
                <div class="card card-box app-loader-blink"></div>
                <div class="card card-box app-loader-blink"></div>
                <div class="card card-box app-loader-blink"></div>
            </div>

            <ngx-slick-carousel class="autoplay clearfix slider desktop-hide"
                                #slickModal="slick-carousel"
                                [config]="myInsuranceSildeConfig"
                                *appIfStable>
                <div ngxSlickItem class="card card-box app-loader-blink"></div>
                <div ngxSlickItem class="card card-box app-loader-blink"></div>
            </ngx-slick-carousel>
        </div>
    </div>
</ng-template>

<ng-template #recommendationLoaderTemplateEl>
    <app-recommendation-box class="recommendation-box recommendation-box-template app-loader-blink"></app-recommendation-box>
</ng-template>

<max-loader *ngIf="loader"></max-loader>
