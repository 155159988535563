<div class="modal-body">
    <span class="title">יש לנו בעיה טכנית קטנה</span>
    <span class="text">ממליצים לנסות שוב בעוד מספר דקות</span>
    <input type="button" (click)="refresh()" class="general-button load-page" value="טעינת העמוד מחדש">
    <span class="close-tech" (click)="close()" aria-label="Close"></span>
</div>






