import { Injectable, Inject } from '@angular/core';
import { AppConfigsService } from '../../config/app-configs.service';
import { DOCUMENT } from '@angular/common';
import { CookieService } from '../../shared/cardholders-core/services/cookie.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class Server8Service {
    server8Mode$ = new BehaviorSubject<boolean>(false);
    server10Mode$ = new BehaviorSubject<boolean>(false);
    constructor(private appConfigs: AppConfigsService, private cookieService: CookieService) {}

    isCookieEnabled(name, value = ''): boolean {
        if (value === '') {
            return this.cookieService.check(name);
        }
        return this.cookieService.check(name) && this.cookieService.get(name) === value;
    }

    setCookie(name, value): void {
        this.cookieService.set(name, value, 8, null, window.location.origin.split('.')[1] + '.co.il', null, null);
        if (name === 'eve') {
            switch (this.cookieService.get(name)) {
                case '8':
                    this.server8Mode$.next(true);
                    break;
                case '10':
                    this.server10Mode$.next(true);
                    break;
                default:
                    break;
            }
        }
    }

    deleteCookie(name): void {
        this.cookieService.delete(name, null, window.location.origin.split('.')[1] + '.co.il');
        if (name === 'eve') {
            switch (this.cookieService.get(name)) {
                case '8':
                    this.server8Mode$.next(false);
                    break;
                case '10':
                    this.server10Mode$.next(false);
                    break;
                default:
                    break;
            }
        }
    }
}
