import { Component, OnInit } from '@angular/core';
import { AuthService, Roles } from '../../../../shared/cardholders-core/services/auth.service';
import { HomepageService } from '../../services/homepage.service';
import { IResponse } from '../../../../shared/cardholders-core/services/http-service.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { IBackgroundDetails } from "../../models/homepage-background";
import { IHomepageNewsletter } from "../../models/homepage-newsletter";

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

    public isAuthenticated: boolean;
    public show: boolean = false;
    public regUrl: string;
    public anonUrl: string;
    public backgroundDetails: IBackgroundDetails;

    isComAdmin!: boolean;

    constructor(private authSvc: AuthService,
        private homepageSvc: HomepageService,
        private uiHelper: UiHelperService,
        private appConfigSvc: AppConfigsService,
        private authService: AuthService
    ) {
        this.regUrl = this.appConfigSvc.appConfigs.infoRoot + "/Registred/Services/Newsletter.aspx?SourceGA=Homepage";
        this.anonUrl = this.appConfigSvc.appConfigs.infoRoot + "/Anonymous/Newsletter.aspx?sourceGA=Homepage";
    }

    ngOnInit() {
        this.isComAdmin = this.authService.isUserInRole(Roles.ADMIN);
        this.isAuthenticated = this.authSvc.isUserAuthenticated();
        if (this.isAuthenticated && !this.uiHelper.isBrowser) {
            this.show = false;
        }
        else if (this.isAuthenticated && this.uiHelper.isBrowser) {
            this.homepageSvc.isDisplayNewsletterReg().subscribe((res: IResponse<IHomepageNewsletter>) => {
                if (res.ReturnCode === 0) {
                    this.show = res.Result.IsDisplayNL;
                    this.backgroundDetails = res.Result.Background;
                }
            });
        } else {
            this.homepageSvc.getNewsletterData().subscribe((res: IResponse<IHomepageNewsletter>) => {
                if (res.ReturnCode === 0) {
                    this.show = true;
                    this.backgroundDetails = res.Result.Background;
                }
            });

        }
    }

}
