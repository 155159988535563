export class NotRedirectToLogin {

    static data: string[] = [
       '/api/blockCard/getCards'
    ];

    constructor() { }
    static active(url: string): boolean {
        return this.data.filter(x => url.includes(x)).length > 0;
    }
}
