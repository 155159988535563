import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigsService } from '../../../config/app-configs.service';
import {
    IResponse,
    HttpService,
    IHttpRequestOptions,
    IResponseLower
} from '../../../shared/cardholders-core/services/http-service.service';
import { Observable } from 'rxjs';
import { UiHelperService } from '../../../shared/cardholders-core/services/ui-helper.service';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ISearchAutoCompete } from '../models/searchAutoComplete';

@Injectable({
    providedIn: 'root'
})
export class MainLayoutService {
    toggleSearchOpen: boolean;

    searchAutocompleteStrings: string[];
    /* =======  start of shared subjects    ========  */
    unReadMessage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private isNavOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // tslint:disable-next-line:member-ordering
    isNavOpen: Observable<boolean> = this.isNavOpenSubject.asObservable();

    private isUserBoxOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // tslint:disable-next-line:member-ordering
    isUserBoxOpen: Observable<boolean> = this.isUserBoxOpenSubject.asObservable();

    private isAllActionsOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // tslint:disable-next-line:member-ordering
    isAllActionsOpen: Observable<boolean> = this.isAllActionsOpenSubject.asObservable();

    /*  ======   end of shared subjects   =========   */

    private menu: any;
    userbox: any;
    private actions: any;
    private footer: any;
    isRemoveLayout = new EventEmitter<boolean>();
    toggleSearchEvent = new EventEmitter();
    headerTransparent = new BehaviorSubject<boolean>(false);

    constructor(
        private http$: HttpClient,
        private appConfigsService: AppConfigsService,
        private uiHelperService: UiHelperService,
        private httpSvc: HttpService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    raiseHeaderTransparent(value: boolean) {
        this.headerTransparent.next(value);
    }

    toggleSearch() {
        this.toggleSearchEvent.emit();
    }

    removeLayout() {
        this.isRemoveLayout.emit(true);
    }

    hideLayout(just: 'hide-layout' | 'hide-header' | 'hide-footer' = 'hide-layout') {
        this.document.body.classList.add(just);
    }

    showLayout() {
        this.document.body.classList.remove('hide-layout');
        this.document.body.classList.remove('hide-header');
        this.document.body.classList.remove('hide-footer');
    }

    changeNavMode(newMode: boolean) {
        this.isNavOpenSubject.next(newMode);
    }

    changeUserBoxMode(newMode: boolean) {
        this.isUserBoxOpenSubject.next(newMode);
    }

    changeAllActionsMode(newMode: boolean) {
        this.isAllActionsOpenSubject.next(newMode);
    }

    getMenuWithActions() {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/menuwithactions`;
        return this.httpSvc.getWithSsrCache(url, 'mainMenuWithActions');
    }

    loadMenu = () => {
        return new Promise((resolve, reject) => {
            this.getMenuFromServer().subscribe(() => {
                switch (this.menu.ReturnCode) {
                    case 0:
                        if (this.menu.Result) {
                            resolve(this.menu.Result);
                        } else {
                            resolve(undefined);
                        }
                        break;
                    case 9:
                        resolve(undefined);
                        break;
                    default:
                        resolve(undefined);
                        break;
                }
            });
        });
    };

    loadUserBox = () => {
        return new Promise((resolve, reject) => {
            this.getUserBoxFromServer().subscribe(() => {
                switch (this.userbox.ReturnCode) {
                    case 0:
                        if (this.userbox.Result) {
                            resolve(this.userbox.Result);
                        } else {
                            resolve(undefined);
                        }
                        break;
                    case 9:
                        resolve(undefined);
                        break;
                    default:
                        resolve(undefined);
                        break;
                }
            });
        });
    };

    loadActions = () => {
        return new Promise((resolve, reject) => {
            this.getActionsFromServer().subscribe(() => {
                switch (this.actions.ReturnCode) {
                    case 0:
                        if (this.actions.Result) {
                            resolve(this.actions.Result);
                        } else {
                            resolve(undefined);
                        }
                        break;
                    case 9:
                        resolve(undefined);
                        break;
                    default:
                        resolve(undefined);
                        break;
                }
            });
        });
    };

    loadFooter = () => {
        return new Promise((resolve, reject) => {
            this.getFooterFromServer().subscribe(() => {
                switch (this.footer.ReturnCode) {
                    case 0:
                        if (this.footer.Result) {
                            resolve(this.footer.Result);
                        } else {
                            resolve(undefined);
                        }
                        break;
                    case 9:
                        resolve(undefined);
                        break;
                    default:
                        resolve(undefined);
                        break;
                }
            });
        });
    };

    getSearchAutocompleteStrings() {
        if (!this.searchAutocompleteStrings) {
            if (this.appConfigsService.appConfigs.FLAG_ISUseUmbracoSearch) {
                return this.http$
                    .get<IResponseLower<ISearchAutoCompete>>(
                        `${this.appConfigsService.appConfigs.apiUrl}/search/searchAutocomplete`
                    )
                    .pipe(
                        map(res => {
                            this.searchAutocompleteStrings = res?.result?.names;
                            if (!this.uiHelperService.isBrowser) {
                                global['searchAutocompleteStringsData'] = this.searchAutocompleteStrings;
                            }
                            return this.searchAutocompleteStrings;
                        })
                    );
            } else {
                return this.http$
                    .get<IResponse<string[]>>(`${this.appConfigsService.appConfigs.apiUrl}/searchAutocomplete`)
                    .pipe(
                        map(res => {
                            res.Result = res.Result.map(x => x.replace('\\', ''));
                            const data = res;
                            this.searchAutocompleteStrings = data.Result;
                            if (!this.uiHelperService.isBrowser) {
                                global['searchAutocompleteStringsData'] = this.searchAutocompleteStrings;
                            }
                            return this.searchAutocompleteStrings;
                        })
                    );
            }
        } else {
            return new Observable(ob => {
                ob.next(this.searchAutocompleteStrings);
                ob.complete();
            });
        }
    }

    private getMenuFromServer = () => {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/menu`;
        return this.httpSvc.getWithSsrCache(url, 'mainMenu').pipe(tap(res => (this.menu = res)));
    };

    private getActionsFromServer = () => {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/actions`;
        return this.httpSvc.getWithSsrCache(url, 'mainActions').pipe(tap(res => (this.actions = res)));
    };

    private getFooterFromServer = () => {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/mainfooter/footer`;
        return this.httpSvc.getWithSsrCache(url, 'mainFooter').pipe(tap(res => (this.footer = res)));
    };

    private getUserBoxFromServer = () => {
        const url = `${this.appConfigsService.appConfigs.apiUrl}/registered/userbox`;
        const options: IHttpRequestOptions = {
            defaultApiFailureBehavior: false
        };
        return this.httpSvc.getWithSsrCache(url, 'userbox', null, options).pipe(tap(res => (this.userbox = res)));
    };
}
