<div class="my-bog" *ngIf="showMyBogWallet && !reload">
    <div class="container" [ngClass]="{'one-card-container':  myWallets.length == 1 && isMobile}">
        <h2> <span *ngIf="!isOneWallet && totalSum">סה״כ <span class="ltr">{{totalSum | numberCurrency: 'ILS'}}</span> </span> בארנק שלך ב-Bits of Gold</h2>
        <div class="card-details" *appLoader="myWalletsIsLoaded; template: bitsOfGoldLoaderTemplateEl">
            <div class="card-box-wrapper">
                <div class="only-card-wrapper">
                    <ngx-slick-carousel class="autoplay clearfix slider"
                                        #slickModal="slick-carousel"
                                        [config]="myBogSliderConfig"
                                        *appIfStable
                                        [ngClass]="{'not-one-card-wallet-autoplay' :
                                        myWallets>
                        1 || !isMobile}" >
                        <div ngxSlickItem
                             class="card card-box"
                             [ngClass]="{ 'one-card-wallet': myWallets.length === 1 }"
                             *ngFor="let wallet of myWallets; index as j">
                            <div class="card-content" *ngIf="totalSum && totalSum > 0">
                                <div class="one-card-content">
                                    <div class="sum">
                                        <div class="fields accumulate">
                                            <h4>{{ wallet.CryptoType == 1 ? 'הביטקוין שלך' : 'האתריום שלך' }} </h4>
                                            <span>
                                                {{ getCryptoBalance(wallet.CyptoBalance)}}
                                                <span class="currency-icon" [ngClass]="{' btc': wallet.CryptoType === 1, 'eth': wallet.CryptoType === 2}"></span>
                                            </span>
                                        </div>
                                        <div class="fields withdraw">
                                            <h4> שווי נוכחי</h4>
                                            <span class="ltr">{{ wallet.IlsBalance | numberCurrency: 'ILS' }}</span>
                                        </div>
                                    </div>
                                    <div class="rate">
                                        <h4>{{ wallet.CryptoType == 1 ? '1 BTC =' : '1 ETH =' }} {{wallet.IlsRate | numberCurrency: 'ILS'}} </h4>
                                        <h4 *ngIf="isPercentDown(wallet.PercentChange)" class="percentChangeDown">{{wallet.PercentChange}}<span class="percent-icon"></span> </h4>
                                        <h4 *ngIf="!isPercentDown(wallet.PercentChange)" class="percentChangeUp">{{wallet.PercentChange}}<span class="percent-icon"></span> </h4>
                                    </div>
                                </div>
                                <div class="wallet-details">
                                    <div class="default-link target-link-bold" (click)="sso()">
                                        לקנייה ומידע באתר Bits of Gold
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>

                    <div *ngIf="(myWallets.length === 0 || totalSum === 0) && !isErrorCase" class="card one-card-wallet empty-card">
                        <div class="card-content">
                            <div class="fields accumulate">
                                <h5 [ngClass]="{' bold': !isSSOLink}">כאן יוצג סכום הקריפטו שלך לאחר ביצוע עסקאות או קניית קריפטו</h5>
                                <h3 *ngIf="isCardActive && isSSOLink">
                                    אפשר לרכוש כבר עכשיו
                                </h3>
                            </div>

                            <div class="wallet-details" *ngIf="isSSOLink">
                                <div class="default-link target-link-bold" (click)="sso()">
                                    לקנייה ומידע באתר Bits of Gold
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isErrorCase" class="card one-card-wallet empty-card">
                        <div class="card-content">
                            <div class="fields accumulate">
                                <h5>כאן בדרך כלל מוצג הקריפטו שלך ב Bits of Gold. <br />אבל יש לנו תקלה קטנה כרגע.</h5>
                                <div class="mrg"></div>
                                <h3>
                                    בינתיים אפשר לעבור לאתר Bits of Gold
                                </h3>
                            </div>
                            <div class="wallet-details">
                                <div class="default-link target-link-bold" (click)="sso()">
                                    לקנייה ומידע באתר Bits of Gold
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #bitsOfGoldLoaderTemplateEl>
    <div class="card-box-wrapper">
        <div class="only-card-wrapper">
            <div class="autoplay clearfix slider mobile-hide">
                <div class="card card-box app-loader-blink"></div>
                <div class="card card-box app-loader-blink"></div>
            </div>

            <ngx-slick-carousel class="autoplay clearfix slider desktop-hide"
                                #slickModal="slick-carousel"
                                [config]="myBogSliderConfig"
                                *appIfStable>
                <div ngxSlickItem class="card card-box app-loader-blink"></div>
                <div ngxSlickItem class="card card-box app-loader-blink"></div>
            </ngx-slick-carousel>
        </div>
    </div>
</ng-template>

<max-loader *ngIf="loader"></max-loader>
