import {Injectable} from "@angular/core";
//import {Http, URLSearchParams, Headers} from "@angular/http";
import {appConsts} from "../../../app.consts";
import { HttpClient } from "@angular/common/http";

const BASE_URL = appConsts.api;

@Injectable()
export class HTTP{
  private http: any;

  constructor(http: HttpClient){
    this.http = http;
  }

  get(method: string, payload: any = {}):any{
    let params = new URLSearchParams();
    for(let key in payload){
      params.set(key, payload[key]);
    }
    return this.http.get(`${BASE_URL}/${method}`, {
      search: params
    });
  }

  post(method: string, payload: any = {}):any{
    return this.http.post(`${BASE_URL}/${method}`, payload);
  }

  delete(method: string, payload: any = {}):any{
    return this.http.delete(`${BASE_URL}/${method}`, payload);
  }

  put(method: string, payload: any = {}):any{
    return this.http.put(`${BASE_URL}/${method}`, payload);
  }

  multipart(method: string, payload: any = {}, file: any):any{
    let params = new FormData();
    params.append("file", file);
    for(let key in payload){
      params.append(key, payload[key]);
    }
    let headers = new Headers();

    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${BASE_URL}/${method}`, params, headers);
  }

}