export enum DepositType {
    OneTime = 1,
    MultiTime = 2
}

export enum DepositStatus {
    Active = 1,
    NotActive = 2
}

export enum ExternalSavingInd {
    NotEligible = 0,
    Eligible = 1,
    EligibleWithPolicy = 2
}

export enum MaxSavingInd {
    NotEligible = 0,
    Eligible = 1,
    EligibleWithPolicy = 2,
    NotEligibleWith5Polices = 3,
    NotEligibleMaxDeposit = 4
}

export enum AgreeInd {
    NotExist = 0,
    Exist = 1,
    NotRequired = 2
}

export enum IndRenewedPolicy {
    NotRenewed = 0,
    Renewed = 1
}

export enum LineCode {
    CarMekif = 1,
    CarHova = 21,
    Saving = 12,
    CarMekifHova = -121,
    Apartment = 31,
    Abroad = 16,
    Mortgage = 70
}
