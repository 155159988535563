import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner.component';
import { LottieModule } from '../lottie/lottie.module';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { CardholdersCoreModule } from '../../cardholders-core/cardholders-core.module';

@NgModule({
    declarations: [
        SpinnerComponent,
        SpinnerOverlayComponent
    ],
    imports: [
        CommonModule,
        LottieModule,
        CardholdersCoreModule
    ],
    exports: [
        SpinnerComponent,
        LottieModule
    ]
})
export class SpinnerModule { }