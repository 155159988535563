import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiHelperService } from 'core-max-lib';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppConfigsService } from '../../config/app-configs.service';
import { HttpService, IResponseLower } from '../../shared/cardholders-core/services/http-service.service';
import { SpinnerService } from '../../shared/cardholders-core/services/spinner.service';
import { IGetAllConsentsRes, IGetConsentReq, IGetConsentRes, IOpenBankingDetails, PsuIdType, GetConsentsRoutingRes,
    IGetConsentRoutingReq
} from './models';

@Injectable({
    providedIn: 'root'
})
export class OpenBankingService {
    onOpenBankingLoginSuccess: Subject<boolean> = new Subject<boolean>();
    sentToNumber: string;
    loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    getConsentRes: IGetConsentRes;
    getConsentReq: IGetConsentReq;
    getConsentRoutingReq: IGetConsentReq;
    details: IOpenBankingDetails;
    isDeepLinkAndMobileSsoFailed: boolean;
    originalUrl: any;
    fullUrl: string;
    state: string;
    consentGuid: string;
    constructor(
        private spinnerService: SpinnerService, private route: ActivatedRoute,
        private appConfigs: AppConfigsService,
        private uiHelper: UiHelperService,
        private httpSvc: HttpService,
        private router: Router,
        @Optional() @Inject('serverUrl') private serverUrl: string) { }


    getParams() {
        const params = this.route.snapshot.queryParams;

        this.isDeepLinkAndMobileSsoFailed = params['saveExtraData'] === 'true'; // no application, back to web

        if (this.isDeepLinkAndMobileSsoFailed) {
            this.originalUrl = encodeURIComponent(decodeURIComponent(params['originExtraData']));
            let unShtrudeled = decodeURIComponent(this.originalUrl).replace(/@/g, '&');


            console.log("isDeepLinkAndMobileSsoFailed::unShtrudeled: " + unShtrudeled);
            console.log("isDeepLinkAndMobileSsoFailed::originalUrl: " + this.originalUrl);

            let resultObj = { obScope: undefined, obClientId: undefined, guid: undefined};

            unShtrudeled.split('&').forEach((pair) => {
                if (pair !== '') {
                    let splitpair = pair.split('=');
                    let key = splitpair[0].charAt(0).toLowerCase() + splitpair[0].slice(1).split(' ').join('');
                    resultObj[key] = splitpair[1];
                }
            });

            console.log("@@@@@@@@@ resultObj @@@@@@@@@@@@@@: ");
            console.dir(resultObj);

            this.getConsentReq = {
                consentId: resultObj?.obScope?.replace('AIS:', '')?.replace('ais:', ''),
                dataConsumerCode: resultObj?.obClientId
            }
            
            this.consentGuid = resultObj?.guid;

            console.log("@@@@@@@@@ getConsentReq @@@@@@@@@@@@@@: ");
            console.dir(this.getConsentReq);
        }
        else {
            const queryStringFromExtraData = params['queryStringFromExtraData'];
            if (!queryStringFromExtraData) { // before deeplink
                this.fullUrl = params['original-url'];
                //this.originalUrl = decodeURIComponent(this.router.url.split('original-url=')[1]); // &original-url or params['original-url']
                //console.log("NO deep link::fullUrl: " + this.fullUrl);
                //console.log("NO deep link::originalUrl: " + this.originalUrl);
            } 
            const qsUrl = new URL(queryStringFromExtraData ? queryStringFromExtraData : this.fullUrl);
            if (queryStringFromExtraData) {
                this.consentGuid = qsUrl.searchParams.get('guid');

            }
          
            this.getConsentReq = {
                consentId: qsUrl.searchParams.get('obScope')?.replace('AIS:', '')?.replace('ais:', '') || qsUrl.searchParams.get('obscope').replace('AIS:', '').replace('ais:', ''),//params['obScope'].replace('AIS:', ''),
                dataConsumerCode: qsUrl.searchParams.get('obClientId') || qsUrl.searchParams.get('obclientid')//params['obClientid']
            };

            console.log("params['obScope']: " + qsUrl.searchParams.get('obScope')/*params['obScope']*/);
            console.log("getConsentReq::consentId: " + this.getConsentReq.consentId);
            console.log("getConsentReq::dataConsumerCode: " + this.getConsentReq.dataConsumerCode);

            const original = qsUrl.href;//decodeURIComponent(params['original-url']);
            this.originalUrl = original;

            try {
                this.state = new URL(original).searchParams.get('digital_oauthtest');
            } catch(e){console.debug(e)};


            console.log("@@@@@@@@@ getConsentReq @@@@@@@@@@@@@@: ");
            console.dir(this.getConsentReq);
        }
    }

    navigateBusiness() {
        const params = this.route.snapshot.queryParams;
        location.href = `${this.appConfigs.appConfigs.businessRoot}/personal/open-banking/main?${this.route.snapshot.queryParams}`;
    }

    getAllConsents(): Observable<IResponseLower<IGetAllConsentsRes>> {
        return this.httpSvc.postLower<IGetAllConsentsRes>(
            `${this.appConfigs.appConfigs.apiUrl}/registered/openBanking/getAllConsents`, null);
    }

    getConsent(req: IGetConsentReq): Observable<IResponseLower<IGetConsentRes>> {
        return this.httpSvc.postLower<IGetConsentRes>(
            `${this.appConfigs.appConfigs.apiUrl}/registered/openBanking/getConsent`, req);
    }

    getConsentForRouting(req: IGetConsentRoutingReq): Observable<IResponseLower<GetConsentsRoutingRes>> {
        return this.httpSvc.postLower<GetConsentsRoutingRes>(
            `${this.appConfigs.appConfigs.apiUrl}/registered/openBanking/getConsentForRouting`, req);
    }

    updateConsentStatus(isApprove: boolean, selected: number[]): Observable<IResponseLower<string>> {
        return this.httpSvc.postLower<string>(
            `${this.appConfigs.appConfigs.apiUrl}/registered/openBanking/updateConsentStatus`, { selected: selected, isApprove: isApprove });
    }

    deleteConsent(consentId: string): Observable<IResponseLower<any>> {
        return this.httpSvc.postLower<IGetConsentRes>(
            `${this.appConfigs.appConfigs.apiUrl}/registered/openBanking/deleteConsent`, { consentId: consentId });
    }

    checkOtp(code: string): Observable<IResponseLower<any>> {
        return this.httpSvc.postLower(
            `${this.appConfigs.appConfigs.apiUrl}/registered/openBanking/checkOtp`, { code: code });
    }

    goToMessage(message: string) {
        this.router.navigate(['open-banking/message'], { queryParamsHandling: 'merge', state: { message: message } });
    }

    isOpenBankingLoginMode(): boolean {
        //find the last component in the route - where the data is
        let route = this.route.snapshot;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route.data && route.data.openBankingLoginMode === true;
    }

    navigateBackToOApi(guid: string) {
        window.location.href = `${this.originalUrl}&username=${this.getConsentReq.consentId}&confirmation=${guid}`;
    }

    getAndNavigateBackToOApi() {
        this.httpSvc.postLower<string>(
            `${this.appConfigs.appConfigs.apiUrl}/registered/openBanking/goBack`, { consentId: this.getConsentReq.consentId }).subscribe(res => {
                if (res.returnCode == 0) {
                    this.navigateBackToOApi(res.result);
                }
            });
    }
}
