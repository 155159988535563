import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Subject, Subscription } from 'rxjs';
import { UserIdleConfig } from 'angular-user-idle';
import { PrebootService } from 'core-max-lib';


@Injectable()
export class UserIdleTimeoutService {
    private timeoutInSec: number;
    private onTimerStartSubscription: Subscription;
    private onPingSubscription: Subscription;
    public isTimerStart = false;
    public isActivated: boolean = false;
    public onTimerStartSubject: Subject<any> = new Subject();
    public onTimeoutSubject: Subject<any> = new Subject();
    public onTimeoutCountdownSubject: Subject<number> = new Subject();
    public onPingSubject: Subject<any> = new Subject();

    constructor(private userIdleService: UserIdleService, private prebootSvc: PrebootService) {
        this.userIdleService.onTimeout().subscribe(() => {
            console.log("on timeout");
            this.clearSubscriptions();
            this.isTimerStart = false;
            this.onTimeoutSubject.next();
        });
    }

    start(): void {
        this.prebootSvc.transitionComplete.subscribe(complete => {
            if (complete) {
                this.timeoutInSec = this.userIdleService.getConfigValue().timeout;
                //console.log("start");
                this.onTimerStartSubscription = this.userIdleService.onTimerStart().subscribe((countdown: number) => {
                    if (countdown) {
                        this.isTimerStart = true;
                        const countdownToTimeout = this.timeoutInSec - countdown;
                        if (countdown === 1) {
                            //console.log("on idle end");
                            this.onTimerStartSubject.next(this.userIdleService.getConfigValue().timeout);
                        }
                        //console.log("count down to timeout : " + countdownToTimeout);
                        this.onTimeoutCountdownSubject.next(countdownToTimeout);
                    }
                });
                this.userIdleService.startWatching();
                this.onPingSubscription = this.userIdleService.ping$.subscribe((data) => {
                    this.onPingSubject.next(data);
                })
                this.isActivated = true;
            }
        });
    }

    stop(): void {
        //console.log("stop");
        this.clearSubscriptions();
        this.userIdleService.stopWatching();
        this.isActivated = false;
        this.isTimerStart = false;
    }

    reset(): void {
        //console.log("reset");
        this.userIdleService.resetTimer();
        this.isTimerStart = false;
    }


    getConfigValue(): UserIdleConfig {
        return this.userIdleService.getConfigValue();
    }

    setConfigVale(config: UserIdleConfig) {
        this.userIdleService.setConfigValues(config);
    }

    private clearSubscriptions() {
        if (this.onTimerStartSubscription)
            this.onTimerStartSubscription.unsubscribe();
        if (this.onPingSubscription)
            this.onPingSubscription.unsubscribe();
    }

}


