import { Component, OnInit } from '@angular/core';
import { HomepageService } from '../../../homepage/services/homepage.service';
import { IHomepageContent } from '../../interfaces/homepage-content.interface';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    constructor(private homepageService: HomepageService) {}

    ngOnInit(): void {
        // if (!this.homepageService.canLoadNewHomepage()) {
        //     return;
        // }
        this.homepageService
            .loadHomepageContent()
            .then((res) => (this.homepageService.homepageContent = res as IHomepageContent));
    }
}
