import { ILcImage } from "../Image";

export interface IUpdateRequest {
    GeneralError?: IGeneralError[];
    FieldError?: IErrorItem[];
    RequestId?: number;
    AsBsIndCode?: number;
    LoyaltyCode?: number;
    CreditLimit?: number;
    Interest?: number;
    CardTypeHebrew: string;
    FoxNeedToPayMembershipInd?: number;
    TzAppendixNeedDocInd?: number;
    LeadID?: number;
    SttDigitalLtrRegInd?: number;
    FastIssuePossInd?: number;
    IdentMethodRequiredCode ?: number;
    FastIssueWaitEndTime?: string;
    CreditLimitInd?: number;
    CreditLimitTemp?: number;
    FairCreditLawDetailsResult: IFairCreditLawDetailsResult;
    KranotPopCode?: number;
    ProposalLogoCodeDesc: string;
    CreditDataIdenCustomerInd: number;
    ActionCode?: ActionCode;
    ApplicationWayCode: number;
    IframeUrlResult: IIframeUrlResult;
    MinimumFix?: number;
    FixPayment?: number;
    RealInterest?: number;
    EffectiveInterest?: number;
    CreateDate: string;
    InternetCardInfo: string;
    CreditLimitSplitDetails: ICreditLimitSplitDetails;
    IsIframeNeedToBeSigned: boolean;
    IsExistCustomer: boolean;
    IsOtp: boolean;
    IsMaxAccountOtp: boolean;
    IsOpenSkyMaxPopUp: boolean;
    TransportationTypeCode: string;
    //UniInstituteCode: number;
}

export interface ICreditLimitSplitDetails {
    ShortCardNumber: string;
    CreditLimitOnExistingCard: number;
    MinCreditLimitForRequest: number;
    MaxCreditLimitForRequest: number;
    CardTitle: string;
    CardImageUrl: string;
}

interface IGeneralError {
    Title: string;
    Message: string;
    A2C_actionCode?: number;
    Event?: ICategoryActionLabelEvent;
}

interface ICategoryActionLabelEvent {
    Category: string;
    Action: string;
    Label: string;
}

interface IErrorItem {
    Key: string;
    ErrorText: string;
}

export interface IIframeUrlResult{
    IframeId : string;
    FormRequestId : string;
}

export interface IFairCreditLawDetailsResult {
    CreditCardFairCreditDetails: ICreditCardFairCreditDetails;
    FixPaymentFairCreditDetails: IFixPaymentFairCreditDetails;
}

interface ICreditCardFairCreditDetails {
    LenderName: string;
    LenderAddress: string;
    CustomerNameAndId: string;
    CustomerMailAddress: string;
    CreditLimitAndCurrency: string;
    CreditLimitAndCurrencyLender: string;
    CreditValidity: string;
    CreditDate: string;
    Interest: string;
    LinkageTypeAndRate: string;
    LinkageBasisAndDate: string;
    LinkageComponentsApplies: string;
    EarlyPayment: string;
    SanctionRight: string;
    Supervisor: string;
    RealInterest: string;
    MaxInterest: string;
    DelayInterest: string;
    MaxDelayInterest: string;
}


interface IFixPaymentFairCreditDetails {
    FairCreditProduct: string;
    LenderName: string;
    LenderAddress: string;
    CustomerNameAndId: string;
    CustomerMailAddress: string;
    CreditLimitAndCurrency: string;
    CreditDate: string;
    CreditLimitAndCurrencyLender: string;
    Interest: string;
    EffectiveInterest: string;
    LinkageTypeAndRate: string;
    LinkageBasisAndDate: string;
    LinkageComponentsApplies: string;
    EarlyPayment: string;
    SanctionRight: string;
    Supervisor: string;
    RealInterest: string;
    MaxInterest: string;
    DelayInterest: string;
    MaxDelayInterest: string;
}

export enum ActionCode {
    LeumiCardExtraCheck = 0,
    NotEligible = 1,
    CheckEligibilityNotEligible = 2,
    InCustomerService = 3,
    OtherRequestExist = 4,
    NotEligableHarel = 5,
    NotEligableKranot = 6,
    NotEligableHistadrutFamily = 7,
    NotEligableKranotFamily = 8,
    NotEligableKranotChildAge28 = 9,
    OnlyOneCardAtTime = 10,
    NotEligableUniversityJerusalem = 11,
    NotEligableTeacherFamily = 12,
    InCustomerService4031 = 15,
}

