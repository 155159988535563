import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ErrorsHandlerService {
    public redirectToErrorPageSubject = new Subject<number>();
    public redirectToSessionExpiredPageSubject = new Subject();
    public redirectToHomePageSubject = new Subject();
    public redirectToLoginPage = new Subject();
}   
