import { Component, OnInit, Input } from '@angular/core';
import { IHomePageRecommendationsResult } from "../../../models/homepage-recommendation";
import { UiHelperService } from "../../../../../shared/cardholders-core/services/ui-helper.service";
import { ILoanParams } from "../../../../../shared/cardholders-core/models/loan-params";
import { GtmService } from "../../../../../shared/cardholders-core/services/gtm.service";

@Component({
    selector: 'app-reg-product-box',
    templateUrl: './reg-product-box.component.html',
    styleUrls: ['./reg-product-box.component.scss']
})
export class RegProductBoxComponent implements OnInit {

    @Input() recommendation: IHomePageRecommendationsResult;
    @Input() loanParams: ILoanParams;
    @Input() indexOfRecommendation: number;
    public isMobile;
    bankLoanParams: ILoanParams;

    constructor(private uiHelper: UiHelperService, private gtmService: GtmService) { }

    ngOnInit() {
        this.isMobile = this.uiHelper.IsMobile();
        this.bankLoanParams = {
            amount: 100000,
            payments: 60
    }
    }

    onRecommendationButtonClick(recommendationClicked: IHomePageRecommendationsResult, indexOfRecommendation: number) {
        this.gtmService.pushDirective({ category: 'New website - registered', action: 'Click on more interests', label: `RecommendationName: ${recommendationClicked.RecommendationName}` });
        this.gtmService.pushDirective({
            category: 'New website - registered', action: 'Click on more interests - placement', label: `index: ${indexOfRecommendation+1}` });
    }

}
