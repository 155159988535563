import { Component, OnInit, Input, HostBinding, Inject, HostListener, EventEmitter, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { RecommendationCategory, IHomePageRecommendationsResult } from '../../models/homepage-recommendation';
import { ILoanParams } from '../../../../shared/cardholders-core/models/loan-params';
import { AppConfigsService } from '../../../../config/app-configs.service';

@Component({
    selector: 'app-recommendation-box',
    templateUrl: './recommendation-box.component.html',
    styleUrls: ['./recommendation-box.component.scss']
})
export class RecommendationBoxComponent implements OnInit {
    isLoanRecommendation = false;
    @Input()
    recommendation: IHomePageRecommendationsResult;
    @Input()
    wideTitle = false;
    @Input()
    loanParams: ILoanParams = { amount: 0, payments: 0 };
    @HostBinding('style.background-color')
    private _backgroundColor: string;
    @HostBinding('style.background-image')
    private _backgroundImageUrl: string;
    @Input() isInsurance: boolean;
    flagIsUseRecommendationLottie: boolean;
    lottieConfig: Object;
    @Output() clickEvent: EventEmitter<any> = new EventEmitter();

    constructor(
        @Inject(DOCUMENT) private document: any,
        public domSanitizer: DomSanitizer,
        private gtmService: GtmService,
        private appConfig: AppConfigsService
    ) {
        this.flagIsUseRecommendationLottie = this.appConfig.appConfigs.FLAG_ISUseRecommendationNewDesignAndLottie;
    }

    ngOnInit() {
        if (this.recommendation) {
            this.isLoanRecommendation = this.recommendation.RecommendationId === '18';
            this.gtmService.pushDirective({
                category: 'New website - registered',
                action: this.isInsurance ? '8' : 'Proposition type',
                label: this.isInsurance ? this.recommendation.RecommendationId : `${this.recommendation.RecommendationName} - ${
                    RecommendationCategory[this.recommendation.RecommendationCategory]
                    }`,
                value: this.isInsurance ? '1' : null
            });

            if (this.flagIsUseRecommendationLottie && this.recommendation.RecommendationLottie) {
                this.initLottie();
            }

        }
    }

    initLottie(): void {
        this.lottieConfig = {
            autoplay: true,
            loop: 4,
            path: this.recommendation.RecommendationLottie //'./staticassets/jsonAnimation/loader-transparent.json'
        };
    }

    @HostListener('click')
    onClick() {
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: this.isInsurance ? '8' : 'Click on proposition',
            label: this.isInsurance ? this.recommendation.RecommendationId : `${this.recommendation.RecommendationName}`,
            value: this.isInsurance ? '2' : null
        });
        this.clickEvent.emit();
        if (this.recommendation.RecommendationLinkOpenNewTab) {
            let newtablink = document.createElement('a');
            newtablink.target = '_blank';
            newtablink.href = this.recommendation.RecommendationLink;
            newtablink.click();
        }
        else {
            this.document.location.href = `${this.recommendation.RecommendationLink}`;
        }
    }

    @Input('backgroundColor')
    set backgroundColor(color) {
        if (this.flagIsUseRecommendationLottie) {
            this._backgroundColor = '#ffffff';
        } else {
            this._backgroundColor = color;
        }
    }

    @Input('backgroundImageUrl')
    set backgroundImageUrl(url) {
        this._backgroundImageUrl = <string>this.domSanitizer.bypassSecurityTrustStyle(`url('${url}')`);
    }
}
