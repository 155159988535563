import { Directive, ElementRef, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UiHelperService } from "../services/ui-helper.service";
import { IBackgroundDetails } from "../../../modules/homepage/models/homepage-background";

@Directive({
    selector: '[appBackgroundDesign]'
})

export class BackgroundDirective implements OnChanges {
    ngOnChanges(changes: SimpleChanges): void {
        this.setElementStyle();
    }

    @Input() backgroundDetails: IBackgroundDetails;

    constructor(private elementRef: ElementRef, private render: Renderer2, private uiHelper: UiHelperService) { }

    setElementStyle() {
        if (this.backgroundDetails != undefined) {
            let element = this.elementRef.nativeElement;
            if (this.backgroundDetails.BackgroundType === 'Color') {
                this.render.setStyle(element, 'background-color', this.uiHelper.hexColorToRgb
                    (this.backgroundDetails.BackgroundColor, this.backgroundDetails.BackgroundColorOpacity));
                this.render.setStyle(element, 'background-image', 'none');
            }
            else if (this.backgroundDetails.BackgroundType === 'Image') {
                this.render.setStyle(element, 'background', 'url('+this.uiHelper.getBackgroundImageByType(this.backgroundDetails)+') no-repeat center');
                this.render.setStyle(element, 'background-size', 'cover');
            }
        }
    }
}