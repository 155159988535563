<div class="my-saving" *ngIf="showMySaving">
    <div class="container">
        <h2 class="title">החסכונות שלי</h2>

        <div class="card-details">
            <div class="card-box-wrapper">
                <div class="only-card-wrapper">
                    <ngx-slick-carousel
                        class="autoplay clearfix slider"
                        #slickModal="slick-carousel"
                        [config]="mySavingSildeConfig"
                        *appIfStable
                        [ngClass]="{
                            'not-one-card-insurance-autoplay':
                                insuranceDetailsList.length + homePageSavingsData.length > 1 || !uiHelper.IsMobile(770)
                        }"
                    >
                        <div
                            class="card one-card-insurance one-row-cards-insurance agreement"
                            *ngIf="showInsuranceAcceptance"
                            ngxSlickItem
                        >
                            <div class=" card-box-wrapper insurance-acceptance-cube" (click)="acceptInsurance()">
                                <div class="insurance-acceptance-text">
                                    כסף פלוס
                                </div>
                                <div class="insurance-acceptance-text">
                                    כאן נוכל להציג את פרטי חסכון שלך ב- m_a_x ביטוח
                                </div>
                                <div class="default-link target-link-bold">אני רוצה לקבל את המידע</div>
                            </div>
                        </div>
                        <ng-container *ngIf="homePageSavingsData && homePageSavingsData.length">
                            <div
                                (click)="savingClick(saving)"
                                ngxSlickItem
                                class="card card-box"
                                [ngClass]="{
                                    'desktop-hide': j > 3 && !isShowAllInsurance,
                                    'one-card-insurance':
                                        insuranceDetailsList.length + homePageSavingsData.length === 1,
                                    'one-row-cards-insurance':
                                        insuranceDetailsList.length + homePageSavingsData.length === 1
                                }"
                                *ngFor="let saving of homePageSavingsData; index as j"
                            >
                                <div class="card-content">
                                    <div class="card-detail-wrapper">
                                        <h4>כסף בצד | {{ saving.savingName }}</h4>
                                        <p class="policy-type" *ngIf="savingSideActive(saving)">
                                            הפקדה קבועה -
                                            <span>
                                                {{ savingSideActive(saving).depositAmount | numberCurrency: 'ILS' }}
                                            </span>
                                            כל חודש
                                        </p>
                                    </div>
                                    <div class="sum">
                                        <div class="fields accumulate">
                                            <h4>היתרה שלי</h4>
                                            <span>{{ (saving.otb ? saving.otb : 0) | numberCurrency: 'ILS' }}</span>
                                        </div>
                                    </div>
                                    <div class="policy-details">
                                        <div class="default-link target-link-bold">
                                            פעולות שאפשר לעשות
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!showInsuranceAcceptance">
                            <div
                                ngxSlickItem
                                class="card card-box"
                                [ngClass]="{
                                    'desktop-hide': homePageSavingsData.length + j > 3 && !isShowAllInsurance,
                                    'one-card-insurance': insuranceDetailsList.length + homePageSavingsData.length === 1
                                }"
                                *ngFor="let saving of insuranceDetailsList; index as j"
                            >
                                <div class="card-content">
                                    <div class="card-detail-wrapper">
                                        <h4>כסף פלוס | {{ saving.policyName }}</h4>
                                        <p class="policy-type">
                                            {{ saving.indDirectDebit == 1 ? 'הפקדה קבועה' : 'הפקדה חד פעמית' }}
                                            <span *ngIf="saving.lastDirectDebitSum > 0"> {{ ' - ' + (saving.lastDirectDebitSum | numberCurrency: 'ILS') }}</span>
                                            {{ (saving.indDirectDebit == 1 && saving.lastDirectDebitSum > 0) ? 'כל חודש' : '' }}
                                        </p>
                                    </div>
                                <div class="sum">
                                        <div class="fields accumulate">
                                            <h4>הסכום שצברת</h4>
                                            <span>{{ saving.insuranceAmount | numberCurrency: 'ILS' }}</span>
                                        </div>
                                        <!--<div class="fields withdraw">
                                            <h4 [id]="'t' + j">
                                                <span
                                                    appTooltip
                                                    class="question-tooltip"
                                                    tooltipText="הסכום שצברת והסכום זמין למשיכה נכונים לאתמול. הסכום המלא יהיה זמין למשיכה 5 ימי עסקים אחרי יום מועד חיוב הכרטיס"
                                                    [tooltipHtmlParent]="'t' + j"
                                                ></span>
                                                זמין למשיכה
                                            </h4>
                                            <span>{{ saving.sumToWithdraw | numberCurrency: 'ILS' }}</span>
                                        </div>-->
                                    </div>
                                    <div class="policy-details">
                                        <div class="default-link target-link-bold" (click)="sso(saving.policyNum)">
                                            פעולות שאפשר לעשות
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ngx-slick-carousel>
                </div>
                <div>
                    <div
                        [ngClass]="{ 'show-all-cards': true, 'show-less-card': isShowAllInsurance }"
                        (click)="toggleInsurance()"
                        *ngIf="insuranceDetailsList.length + homePageSavingsData.length > 4"
                    >
                        <span *ngIf="isShowAllInsurance">תראו לי פחות</span>
                        <span *ngIf="!isShowAllInsurance">תראו לי הכל</span>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="showRecommendation">
                <app-recommendation-box
                    *appLoader="recommendationIsLoaded; template: recommendationLoaderTemplateEl"
                    class="recommendation-box"
                    [recommendation]="recommendationsToShow[0]"
                    [backgroundColor]="'#e5e5e5'"
                    [wideTitle]="true"
                    [isInsurance]="true"
                ></app-recommendation-box>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #recommendationLoaderTemplateEl>
    <app-recommendation-box
        class="recommendation-box recommendation-box-template app-loader-blink"
    ></app-recommendation-box>
</ng-template>
<max-loader *ngIf="loader"></max-loader>
