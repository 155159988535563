import { isNullOrUndefined } from 'core-max-lib';
import { AbstractControl } from "@angular/forms";

export function validateFutureDate(control: AbstractControl) {

  if (isNullOrUndefined(control.value))
    return null;

  const valArr = control.value ? control.value.split('/') : '';
  const day = parseInt(valArr[0]);
  const month = parseInt(valArr[1]);
  const year = parseInt(valArr[2]);
  const dateTime = new Date(year, month - 1, day).getTime();
  const now = new Date().getTime();

  if (dateTime > now) {
    return { isFutureDate: true };
  }

  return null;

}

