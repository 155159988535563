import { ICustomerIdentificationResult } from '../models/customer-identification-result.interface';
export const customerIdentificationRes: ICustomerIdentificationResult = {
    mainHeaderTitle: 'זיהוי קצר וממשיכים',
    startDetails: {
        title: 'הכרטיס החדש שלך תכף יוצא לדרך אבל למה לחכות לשליח?',
        text: 'אפשר להשתמש בכרטיס כבר עכשיו ולשלם בנייד ובעסקאות אונליין',
        firstButtonText: 'המשך',
        secondButtonText: 'אוותר כרגע, לתיאום שליח'
    },
    finishDetails: {
        title: 'תהליך הזיהוי בוצע בהצלחה',
        text: 'הבקשה שלך מועברת להמשך טיפול',
        buttonText: 'יופי תודה',
        promotion: '',
        comment: ''
    },
    successDetails: {
        title: 'עוד כמה דקות הכרטיס הדיגיטל שלך אצלך בנייד ומוכן לשימוש',
        text: 'את הכרטיס הפיזי אנחנו מתחילים להכין, נשאר לך רק לבחור מתי יגיע השליח הנחמד',
        buttonText: 'לתיאום שליח ללא עלות',
        promotion: 'אין לך עדין את <a href="https://www.IL/InTheMedia/Pages/WalletPlus.aspx" title="" class="link">אפליקצית max</a> ? זה הזמן להוריד אותה וליהנות מתשלום בנייד כבר עכשיו',
        comment: '*ללקוחות אנדרואיד בלבד.'
    },
    stepsInfoDetails: {
        title: 'הכרטיס החדש שלך תכף יוצא לדרך אבל למה לחכות לשליח?',
        text: 'כרטיס שניתן לעשות בו שימוש מיידי באתרי אינטרנט, עסקאות טלפוניות ותשלום בנייד ולהתחיל להנות מיתרונות הכרטיס כבר מעכשיו לאחר השלמת התהליך נשלח לך הודעת SMS עם פרטי הכרטיס החדש',
        firstButtonText: 'בוא נתקדם',
        secondButtonText: 'לא, תודה',
        showGiveUpLink: true
    },
    linkSentDetails: {
        firstButtonText: 'בסדר, נתראה בנייד',
        secondButtonText: 'אוותר כרגע, לתיאום שליח',
        showGiveUpLink: true
    },
    ocrDetails: {
        showGiveUpLink: true
    },
    livenessDetails: {
        title: 'זיהוי פנים',
        text: 'בלחיצה על הכפתור תופעל מצלמת הטלפון שלך לצורך סריקת הפנים',
        bulletsTitle: 'מה צריך לעשות?',
        bulletsList: ['הוראות יופיעו על גבי המסך', 'לוודא שהינך במקום מואר', 'לשמור על הטלפון יציב בזמן הצילום'],
        button: 'צלמו אותי',
        showGiveUpLink: true
    },
    otpDetails: {
        showGiveUpLink: true
    },
    fourDigitsDetails: {
        showGiveUpLink: true
    },
    signingDetails: {
        showGiveUpLink: true
    },
    maxUserDetails: {
        title: 'האם פרטי ההתחברות שלך לאתר ולאפליקציית max זכורים לך?',
        text: 'לתשומת לבך, כדי שיהיה אפשר לשלם בנייד עם אפליקציית max, ולצפות בפרטי כרטיס האשראי החדש באתר, יהיה צריך להזדהות עם <span class="title">שם משתמש וסיסמה.</span>',
        firstButtonText: 'כן, הסיסמה זכורה לי!',
        secondButtonText: 'לא, אני רוצה סיסמה חדשה'
    },
    changePasswordDetails: {
        showGiveUpLink: true
    },
    createNewUserDetails: {
        showGiveUpLink: true
    }
};
