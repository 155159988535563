import { Injectable } from '@angular/core';

@Injectable()
export class JoinHelperService {
    isMaxAccountRequest = false;
    firstName: string;
    lastName: string;
    idNumber: string;
    phone: string;
    birthDate: string;
    gender: string;
    email: string;

    public cleanService() {
        this.isMaxAccountRequest = false;
        this.firstName = null;
        this.lastName = null;
        this.idNumber = null;
        this.phone = null;
        this.birthDate = null;
        this.email = null;
        this.gender = null;
    }
}
