import { ComponentBase } from './control-base';
import { isMobile } from 'is-mobile';

export class TextboxComponent extends ComponentBase<string> {
    controlType = 'textbox';
    type: string;
    parent: ComponentBase<any>;
    readonly: boolean;
    action: { name: string, run: any };
    short = true;
    autoCompleteHtml: string;
    maxLength?: number;
    onBlur?: () => void;
    onInput?: () => void;
    extraLabel?: string;

    constructor(config: {}) {
        super(config);
        this.type = config['type'] || '';
        this.autoCompleteHtml = config['autoCompleteHtml'] || '';
        this.action = config['action'] || undefined;
        this.readonly = config['readonly'] || false;
        this.maxLength = config['maxLength'] || null;
        this.extraLabel = config['extraLabel'] || null;

        if (config['keyboard'] === 'number') {
            this.type = isMobile() ? 'tel' : 'text';
        }
    }
}
