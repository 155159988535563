export interface ILoginResult {
    LoginStatus: LoginStatus;
    LoginCode: number;
    LoginType: LoginType;
    Roles: string[];
    PopupToShow?: PopupType;
    UserTransferResult: IUserTransferResult;
}

export interface IUserTransferResult {
    IsTransferMessageNeeded: boolean;
    TransferReason: UserTransferReason;
    FirstName: string;
    UserName: string;
    RedirectUrlFromMobileSSO: string;
    TransferUserNames: string[];
}

export enum LoginStatus {
    success,
    passwordExpired,
    needToSignAggreement,
    cardRemoved,
    falied,
    CreditLawAgreementAvailable,
    NeedToShowInsurance
}

export enum LoginType {
    Id,
    Password
}

export enum PopupType {
    passwordExpired = 1,
    cardsRemovedFromAccount = 2,
    signAggreement = 3,
    userTransferMessage = 4,
    nabat = 5,
    biometricRegister = 6,
    marketingMailing = 7, // divurShivuki
    dapap = 8,
    recoverPinCode = 9,
    insurance = 10,
    fairCredit = 11
}

export enum UserTransferReason {
    none = 0,
    notActive = 1,
    registeredForApp = 2,
    webAuthentication = 3,
    chooseUserInAuthentication = 4,
    chooseUserInRestore = 5,
    oneUserAutoTransfer = 6,
    registerProcess = 7,
    instantIssuingProcessOrMultiUsers = 8,
    recoveryProcess = 9
}
