import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { UiHelperService } from "../services/ui-helper.service";

@Directive({
    selector: '[appNumber]'
})
export class AppNumberDirective {

    @Input() decimal = false;

    constructor(private input: ElementRef, private uiHelper: UiHelperService) {
        if (this.uiHelper.isBrowser) {
            input.nativeElement.value = this.setNumber(input.nativeElement.value);
        }
    }

    @HostListener('input') keyup() {
        this.input.nativeElement.value = this.setNumber(this.input.nativeElement.value);
    }
    setNumber(value: string): string {
        let maxLength = this.input.nativeElement.getAttribute('maxlength');
        let numberOfCharsNotNumbers = 0;
        if (!value.includes(',')) {
            numberOfCharsNotNumbers = 2;
        }
        if (maxLength && (maxLength - numberOfCharsNotNumbers < value.length)) {
            return this.decimal ? this.uiHelper.setNumberDecimal(value) : 
            this.uiHelper.setNumber(value.slice(0, maxLength - numberOfCharsNotNumbers));
        }
        return this.decimal ? this.uiHelper.setNumberDecimal(value): this.uiHelper.setNumber(value);
    }
}
