import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AppConfigsService } from '../../../config/app-configs.service';
import { isNullOrUndefined } from 'core-max-lib';
import { InitializerService } from 'core-max-lib';

@Injectable()
export class UiHelperService {
    isBrowser: boolean;
    prebootComplete = false;
    isCookieAppear = true;
    isMobileRequest;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private appConfigSvc: AppConfigsService,
        private initializer: InitializerService
    ) {
        this.isMobileRequest = initializer.getClientContext().IsMobileRequest;
        this.isBrowser = isPlatformBrowser(platformId);
    }

    IsMobile(width = 1279) {
        if (!this.isBrowser) {
            return this.isMobileRequest;
        }
        return window.innerWidth < width;
    }
    IsMobileByUserAgent(): boolean {
        const ua = navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(ua)) {
            return true;
        } else {
            return false;
        }
    }

    IsMobileByScreen(width = 1279) {
        if (!this.isBrowser) {
            return this.isMobileRequest;
        }
        return window.screen.width < width;
    }

    detectIE() {
        if (!this.isBrowser) {
            return false;
        }
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            const rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        const edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
        // other browser
        return false;
    }

    getCompanyLogoImage(oldLogo: string, logoWithLc: boolean) {
        if (this.appConfigSvc.appConfigs.companyName.toLowerCase() === 'max') {
            return logoWithLc ? '/assets/images/lc-max-logo.svg' : '/assets/images/homepage/max-logo.svg';
        } else {
            return oldLogo;
        }
    }

    getCompanyWhiteLogoImage(oldLogo: string, logoWithLc: boolean) {
        if (this.appConfigSvc.appConfigs.companyName.toLowerCase() === 'max') {
            return logoWithLc ? '/assets/images/lc-max-white-logo.svg' : '/assets/images/homepage/max-white-logo.svg';
        } else {
            return oldLogo;
        }
    }

    hexColorToRgb(hex, alpha) {
        const r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);
        if (alpha) {
            return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
        } else {
            return 'rgb(' + r + ', ' + g + ', ' + b + ')';
        }
    }

    getBackgroundImageByType(backgroundDetails) {
        if (this.IsMobile()) {
            return isNullOrUndefined(backgroundDetails.MobileBackgroundImage)
                ? ''
                : backgroundDetails.MobileBackgroundImage.Url;
        } else {
            return isNullOrUndefined(backgroundDetails.DesktopBackgroundImage)
                ? ''
                : backgroundDetails.DesktopBackgroundImage.Url;
        }
    }

    setNumber(value: string): string {
        let result = '';
        for (let i = 0; i < value.length; i++) {
            if (/^\d+$/.test(value[i])) {
                result += value[i];
            }
        }
        const arr = [];
        while (result.length > 3) {
            arr.push(this.cut(result));
            result = result.slice(0, result.length - 3);
        }
        arr.push(this.cut(result));
        let finel = '';
        for (let i = arr.length - 1; i >= 0; i--) {
            finel += arr[i];
            finel += ',';
        }
        finel = finel.slice(0, finel.length - 1);
        finel = '₪' + finel;
        return finel === '₪' ? '' : finel;
    }

    setNumberDecimal(value: string): string {
        let result = '';
        let dotOcc = 0;
        for (let i = 0; i < value.length; i++) {
            if (/[0-9.]+/.test(value[i])) {
                if(value[i] == '.' && dotOcc > 0) continue;
                result += value[i];
                if(value[i] == '.'){
                    dotOcc++;
                }
            }
        }
        let beforeDec = result;
        let afterDec = '';
        if(result.indexOf('.') != -1){
            beforeDec = result.substring(0, result.indexOf('.'));
            afterDec = result.substring(result.indexOf('.'), result.length);
        }
        result = beforeDec;
        const arr = [];
        while (result.length > 3) {
            arr.push(this.cut(result));
            result = result.slice(0, result.length - 3);
        }
        arr.push(this.cut(result));
        let finel = '';
        for (let i = arr.length - 1; i >= 0; i--) {
            finel += arr[i];
            finel += ',';
        }
        finel = finel.slice(0, finel.length - 1);
        finel = '₪' + finel + afterDec;
        return finel === '₪' ? '' : finel;
    }

    setNumberWithoutSymbol(value: string): string {
        let result = '';
        for (let i = 0; i < value.length; i++) {
            if (/^\d+$/.test(value[i])) {
                result += value[i];
            }
        }
        const arr = [];
        while (result.length > 3) {
            arr.push(this.cut(result));
            result = result.slice(0, result.length - 3);
        }
        arr.push(this.cut(result));
        let finel = '';
        for (let i = arr.length - 1; i >= 0; i--) {
            finel += arr[i];
        }
        return finel;
    }

    getRootLink() {
        if (this.appConfigSvc.appConfigs.enableNewHomepage) {
            return '/';
        } else {
            return this.appConfigSvc.appConfigs.contentRoot;
        }
    }

    SetCookieAppear(isAppear: boolean): void {
        this.isCookieAppear = isAppear;
        if (this.isBrowser) {
            if (isAppear) {
                document.body.classList.add('cookie-appear-style');
            } else {
                document.body.classList.remove('cookie-appear-style');
            }
        }
    }

    groupByArray(xs, key) {
        return xs.reduce(function (rv, x) {
            let v = key instanceof Function ? key(x) : x[key];
            let el = rv.find((r) => r && r.key === v);
            if (el) {
                el.values.push(x);
            } else {
                rv.push({ key: v, values: [x] });
            }
            return rv;
        }, []);
    }

    private cut(str: string): string {
        return str.slice(str.length - 3 < 0 ? 0 : str.length - 3, str.length);
    }
}
