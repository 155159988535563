import { Observable } from 'rxjs';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent, HttpParams } from '@angular/common/http';
import { isNullOrUndefined } from 'core-max-lib';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpServiceDataGuidInterceptor implements HttpInterceptor {
    private isBrowser: boolean;

    constructor(@Inject(PLATFORM_ID) private platformId: any) {
        this.isBrowser = isPlatformBrowser(this.platformId);        
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isBrowser) {
            return next.handle(req);
        }

        const newReq: HttpRequest<any> = req.clone();

        return next.handle(newReq).pipe(tap(event => {
            if (event instanceof HttpResponse) {
                try {
                    const cid = event.headers.get("X-AS-CORRELATION");
                    const url = req.url.substring(req.url.lastIndexOf('/'));
                    if (!isNullOrUndefined(cid)) {
                        const div = document.createElement('div');
                        div.setAttribute('data-guid', cid);
                        div.setAttribute('data-guid-url', url);
                        div.style.display = 'none';
                        document.body.append(div);
                    }
                }
                catch { }
            }
        }));
    }
}
