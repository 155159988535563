import {ComponentBase} from './control-base';

export class SwitchComponent extends ComponentBase<any> {
  controlType = 'switch';
  type: string;
  options: { text: string, value: any };
  onLabel: string;
  offLabel: string;
  onValue: number;
    offValue: number;
    disabled: boolean;

  changeFn: (value: number) => {};

  constructor(config: {}) {
    super(config);
    this.type = config['type'] || '';
    this.options = config['options'];

    this.onLabel = this.options[0].text;
    this.offLabel = this.options[1].text;
    this.onValue = this.options[0].value;
      this.offValue = this.options[1].value;
      this.disabled = config['disabled'] || false;
  }

  onChange(e) {
    if (this.changeFn) {
      if (e.checked) {
        this.changeFn(this.onValue);
      } else {
        this.changeFn(this.offValue);
      }
    }
  }
}


