import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UiHelperService } from '../../../shared/cardholders-core/services/ui-helper.service';
import { Router, NavigationEnd } from '@angular/router';
import { isNullOrUndefined } from 'core-max-lib';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IAsterisksResult } from '../models/asterisks';
import { AsteriskService } from '../services/asterisk.service';

@Component({
    selector: 'app-asteriks',
    templateUrl: './asteriks.component.html',
    styleUrls: ['./asteriks.component.scss']
})
export class AsteriksComponent implements OnInit, OnDestroy {
    show = false;
    asterixText: SafeHtml;
    subscription: Subscription;
    transactionDetailsPage = false;
    asteriskSubscription: Subscription;
    constructor(
        private uiHelper: UiHelperService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private asteriskService: AsteriskService
    ) {
        this.subscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((event: any) => {
            const asteriskId = event.urlAfterRedirects.split('?')[0];
            this.asteriskService.loadAsterisks().then(
                (res: IAsterisksResult) => {
                    const filterAsterisk = !isNullOrUndefined(res)
                        ? res.Asterisks.find(_ => _.Id === asteriskId)
                        : null;
                    if (!isNullOrUndefined(filterAsterisk) && filterAsterisk.Text.length > 0) {
                        if (filterAsterisk.Id === '/transaction-details/personal') {
                            this.transactionDetailsPage = true;
                            this.asteriskSubscription = asteriskService.additionalTextToAsterisk.subscribe(a => {
                                if (a.Id === filterAsterisk.Id && !isNullOrUndefined(a.Text)) {
                                    this.asterixText = this.sanitizer.bypassSecurityTrustHtml(filterAsterisk.Text.replace('{#validityDate}', a.Text));
                                } else {
                                    this.asterixText = this.sanitizer.bypassSecurityTrustHtml(filterAsterisk.Text.replace('{#validityDate}', ''));
                                }
                            });
                        }
                        if (this.transactionDetailsPage && isNullOrUndefined(this.asterixText)) {
                            this.asterixText = this.sanitizer.bypassSecurityTrustHtml(filterAsterisk.Text.replace('{#validityDate}', ''));
                        } else if (!this.transactionDetailsPage) {
                            this.asterixText = this.sanitizer.bypassSecurityTrustHtml(filterAsterisk.Text);
                        }
                        this.show = true;
                    } else {
                        this.show = false;
                    }
                }
            );
        });
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
        if (this.transactionDetailsPage) {
            this.asteriskSubscription.unsubscribe();
        }
    }
}
