import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { FormHelperService } from '../../../../shared/cardholders-core/services/form-helper.service';
import { IResponseLower } from '../../../../shared/cardholders-core/services/http-service.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { validators } from '../../../../shared/consts/validators';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';
import { IMarketingAgreementContent } from '../../interfaces/marketing-agreement-content.interface';
import { IMarketingAgreementData } from '../../interfaces/marketing-agreement-data.interface';
import { MarketingAgreementService } from '../../services/marketing-agreement.service';
import { IUpdateMarketingAgreementRequest } from '../../interfaces/update-marketing-agreement-request.interface';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { Router } from '@angular/router';

interface IAutoCompleteList {
    label: string;
    value: number;
}

@Component({
  selector: 'app-marketing-popup',
  templateUrl: './marketing-popup.component.html',
  styleUrls: ['./marketing-popup.component.scss']
})
export class MarketingPopupComponent implements OnInit, OnDestroy {
    showMailForm: boolean;
    isSuccess: boolean;
    isFail: boolean;
    showLoader = false;
    private subs: Subscription[] = [];
    contents: IMarketingAgreementContent;
    data: IMarketingAgreementData;
    isMobile: boolean;
    infoRoot: string;
    marketingAgreementForm: FormGroup = new FormGroup({});
    emailNotValidPattern = false;
    emailNotValidPatternMessage = 'זה לא נראה כמו מייל תקין. אפשר לנסות שוב.';
    allEmails: IAutoCompleteList[]= [];
    filteredEmails: IAutoCompleteList[];
    popupSource: string;
    popupMailIndication: string;

    constructor(
        private modalSvc: ModalService,
        private marketingAgreementService: MarketingAgreementService,
        private uiHelper: UiHelperService,
        private appConfig: AppConfigsService,
        private formHelperService: FormHelperService,
        private gtmService: GtmService,
        public router: Router
    ) {
        this.infoRoot = this.appConfig.appConfigs.infoRoot;
        this.isMobile = this.uiHelper.IsMobile(768);
    }

    ngOnInit(): void {
        this.popupSource = this.router.url.includes('transaction-details/personal') ? 'trans_details' : 'homepage';
        document.querySelector('modal-container').classList.add('full-screen');
        this.showMailForm = true;
        this.isSuccess = false;
        this.isFail = false;

        this.subs.push(
            this.marketingAgreementService.showLoader.subscribe((isShow: boolean) => {
                this.showLoader = isShow;
            })
        );
        this.marketingAgreementService.getContents().subscribe((res: IResponseLower<IMarketingAgreementContent>) => {
            if (res.returnCode === 0) {
                this.contents = this.marketingAgreementService.contents;
            } else {
                this.isFail = true;
                this.showMailForm = false;
            }
        });

        this.marketingAgreementService.getMarketingAgreementData().subscribe((res: IResponseLower<IMarketingAgreementData>) => {
            if (res.returnCode === 0) {
                this.data = this.marketingAgreementService.data;
                if (this.data.showEmailField) {
                    this.marketingAgreementForm.addControl('email',
                        new FormControl(null,
                            [Validators.required, Validators.maxLength(55), Validators.pattern(validators.email)]));
                    if (this.data.emails.length === 1) {
                        this.marketingAgreementForm.controls["email"].setValue(this.data.emails[0]);
                    } else if (this.data.emails.length > 1) {
                        this.data.emails.map((item, index) => {
                            this.allEmails.push({
                                label: item,
                                value: index
                            });
                        });
                        this.filteredEmails = [...this.allEmails];
                    }
                    this.popupMailIndication = 'with mail';
                } else {
                    this.popupMailIndication = 'no mail';
                }

                this.gtmService.pushPopUpDivur(
                    `${this.popupMailIndication} ${this.popupSource}`,
                    'LOAD' + this.marketingAgreementService.showingCounter);

                if (this.data.showPhoneNumberField) {
                    this.marketingAgreementForm.addControl('phoneNumber', new FormControl(null, [Validators.required]));
                    this.marketingAgreementForm.controls["phoneNumber"].setValue(this.data.maskedPhoneNumber);
                }
            } else {
                this.isFail = true;
                this.showMailForm = false;
            }
        });
        this.gtmService.pushPopUpDivur('43', '334', '1');
    }

    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    agree(): void {
        this.formHelperService.validateAllFormFields(this.marketingAgreementForm);
        if (!this.marketingAgreementForm.valid) {
            this.isEmailNotValid();
            return;
        }
        this.gtmService.pushPopUpDivur(
            `${this.popupMailIndication} ${this.popupSource}`,
            'Approve');
        this.gtmService.pushPopUpDivur('43', '334', '2');
        const request: IUpdateMarketingAgreementRequest  = {
        };
        if (this.data.showEmailField) {
            request.emailIndication = true;
            request.marketingEmail = this.marketingAgreementForm.get('email').value;
        }
        if (this.data.showPhoneNumberField) {
            request.smsIndication = true;
        }
        request.directMarketingIndication = true;
        this.marketingAgreementService.updateMarketingSignUp(request).subscribe((res) => {
            switch (res.returnCode) {
            case 0:
                this.isSuccess = true;
                this.showMailForm = false;
                this.gtmService.pushPopUpDivur(
                    `${this.popupMailIndication} ${this.popupSource}`,
                    'SUCCESS');
                break;
            case 4:
                this.emailNotValidPattern = true;
                break;
            case 9:
            default:
                this.isFail = true;
                this.showMailForm = false;
                this.gtmService.pushPopUpDivur(
                    `${this.popupMailIndication} ${this.popupSource}`,
                    'Technical Error');
                break;
            }
        });
    }

    clickNo() {
        this.gtmService.pushPopUpDivur(
            `${this.popupMailIndication} ${this.popupSource}`,
            'Not now');
        this.close();
    }

    close() {
        this.modalSvc.closeModalAndAlertSubscribers();
        // this.marketingAgreementService.updateCookie();
    }

    get bgImgUrl(): string {
        return this.contents ? `${this.uiHelper.IsMobile() ? this.contents.mobileImage.url : this.contents.desktopImage.url
            }` : null;
    }

    isEmailNotValid() {
        this.emailNotValidPattern = this.formHelperService.isFormControlEmptyAfterTouched(this.marketingAgreementForm.get('email')) ||
            !this.formHelperService.isFormControlValidByPattern(this.marketingAgreementForm.get('email'));
    }

    onFocus() {
        document.querySelector('.float-label.email-label').classList.add('focus');
        this.marketingAgreementForm.get('email').markAsTouched();
    }

    onBlur(e) {
        if (e.target.value === '') {
            document.querySelector('.float-label.email-label').classList.remove('focus');
        }
        this.isEmailNotValid();
    }

    onInput(e) {
        this.emailNotValidPattern = false;
        this.marketingAgreementForm.controls["email"].setValue(e.target.value);
    }

    onEmailSelect(e) {
        this.marketingAgreementForm.controls["email"].setValue(e.label);
        this.isEmailNotValid();
    }

    search(e) {
        if (e.query.length < 1) {
            this.filteredEmails = [...this.allEmails];
            return;
        }

        this.filteredEmails = this.allEmails.filter(item => {
            return item.label.toLowerCase().indexOf(e.query.toLowerCase()) !== -1;
        });
    }
}