import { Inject, Injectable } from '@angular/core';
import { CookieService } from './cookie.service';


@Injectable({ providedIn: 'root' })
export class VConsoleBootService {
    constructor(private cookieService: CookieService) {}

    init() {
        if (this.cookieService.check('vconsole')) {
            import('vconsole').then(instance => {
                const vConsole = new instance.default();
            });
        }
    }
}
