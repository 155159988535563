import { Component, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { HomepageService } from "../../services/homepage.service";
import { IForeignCurrencyPockets, IPocket, IPocketsSummary, ActionLink } from "../../models/homepage-forigen-currency";
import { IResponse } from 'ClientApp/app/shared/cardholders-core/services/http-service.service';
import { UiHelperService } from "../../../../shared/cardholders-core/services/ui-helper.service";
import { AuthService } from "../../../../shared/cardholders-core/services/auth.service";
import { Router } from '@angular/router';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';


@Component({
    selector: 'app-my-foreign-currency',
    templateUrl: './my-foreign-currency.component.html',
    styleUrls: ['./my-foreign-currency.component.scss']
})
export class MyForeignCurrencyComponent implements OnInit {
    private subs: Subscription[] = [];
    myWalletsIsLoaded: BehaviorSubject<boolean>;
    isMobile: boolean;
    reload: boolean;
    pocketsList: IPocket[];
    summary: IPocketsSummary;
    isShowForeignCurrencies: boolean = false;
    myCardsShowLoaderBlink: boolean = false;
    canOpenNewPocket: boolean;
    onePocket : boolean = false;
    showNoPocketsCampaign : boolean;
    campaignCurrencies : number [] = [840, 978, 826];
    title : string = "";

    constructor(private homepageService: HomepageService,
        private uiHelper: UiHelperService,
        private authSvc: AuthService,
        private router: Router,
        private appConfigs: AppConfigsService,
        private gtmService: GtmService
    ) { }


    ngOnInit(): void {
        this.isMobile = this.uiHelper.IsMobile(768);
        this.myWalletsIsLoaded = new BehaviorSubject<boolean>(false);
        if (this.uiHelper.isBrowser && this.authSvc.isUserAuthenticated()) {
            this.getForeignCurrencyPockets();
        }
        this.reload = true;
        setTimeout(() => {
            this.reload = false;
        }, 50);

    }

    slideConfig = {
        infinite: false,
        variableWidth: true,
        slidesToScroll: 1,
        speed: 200,
        swipeSpeed: 200,
        arrows: false,
        mobileFirst: true,
        rtl: true,
        responsive: [
            {
                breakpoint: 768,
                settings: 'unslick'
            },
            {
                breakpoint: 320,
                settings: {
                    variableWidth: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]


    }

    getForeignCurrencyPockets() {
        this.subs.push(
            this.homepageService.getForeignCurrencyPockets().subscribe((res: IResponse<IForeignCurrencyPockets>) => {
                if (res.ReturnCode === 0) {
                    if (res.Result) {
                        this.title = res.Result.HasFcpCampaign && res.Result.PocketsList.length === 0 ? 'חשבון המט"ח שלך' :  'ארנקי המט"ח שלך' ;
                        this.showNoPocketsCampaign = res.Result.HasFcpCampaign;
                        if(this.showNoPocketsCampaign){
                            this.gtmService.pushFCP("fake matach product web", '55', '165', '1');
                        }
                        this.canOpenNewPocket = res.Result.CanOpenNewPocket;
                        if (!this.pocketsList)
                            this.onePocket = true;
                        if (res.Result.PocketsList?.length > 0){
                            this.pocketsList = res.Result.PocketsList;
                            if (this.pocketsList.length === 1)
                                this.onePocket = true;
                        }
                        else if (res.Result.Summary != null) {
                            this.summary = res.Result.Summary;
                        }
                    }
                }
            })
        );
    }

    getLinkText(action: ActionLink) {
        switch (action) {
            case ActionLink.ActiveAccount:
                return "להפעלת החשבון";
            case ActionLink.Charge:
                return "להטענה";
            case ActionLink.Info:
            default:
                return "לפרטים ופעולות";
        };
    }

    toDashboard(){
        this.router.navigate(['foreign-currency-pocket/personal/dashboard']);
    }

    toBuyCoin(){
        this.gtmService.pushFCP("fake matach product web", '55', '165', '2');
        this.router.navigate(['foreign-currency-pocket/personal/buy-coin']);
    }

    navigateAction(pocket: IPocket) {
        const currencyId = pocket.Currencies[0].MpCurrency;
        if (pocket.Currencies.length === 1) {
            this.router.navigate([this.appConfigs.appConfigs.FLAG_ISFcpTransactionsNew? 'foreign-currency-pocket/personal/transactions-new' :'foreign-currency-pocket/personal/transactions'],
                {
                    queryParams: { cardIndex: pocket.CardInfo.CardIndex, currency: currencyId }
                });
        } else {
            this.router.navigate(['foreign-currency-pocket/personal/dashboard'],
                {
                    queryParams: { cardIndex: pocket.CardInfo.CardIndex }
                });
        }
    }

    isPocketActive(pocket: IPocket) {
        var isPocketActive = pocket.Currencies.find(c => c.IsActive);
        return isPocketActive == null;
    }

    getCurrencies() {
        var currenciesList = [];
        this.summary.Currencies.forEach(c => currenciesList.push(this.getCurrencyName(c)));
        return currenciesList?.join(', ');
    }

    getCurrencyName(c: number) {
        switch (c) {
            case 840:
                return "USD";
            case 978:
                return "EUR";
            case 826:
                return "GBP";
            case 124:
                return "CAD";
            default:
                return "";
        }
    }
}

