import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fail',
  templateUrl: './fail.component.html',
  styleUrls: ['./fail.component.scss']
})
export class FailComponent implements OnInit {
    @Output()
    closeMarketingAgreementPopup = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
