import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'max-loader',
    templateUrl: './max-loader.component.html',
    styleUrls: ['./max-loader.component.scss']
})
export class MaxLoaderComponent implements OnInit {
    @Input() justLoaderIcon = false;

    constructor() {}

    ngOnInit() {}
}
