import { Injectable } from '@angular/core';
import { InitializerService } from 'core-max-lib';
import { AppConfigsService } from '../../config/app-configs.service';
import { LoggerService } from '../cardholders-core/services/logger.service';

@Injectable({
    providedIn: 'root'
})
export class RecaptchaHelperService {
    private isApprovedIpForRecaptcha: boolean;
    private isInLcLan: boolean;
    private enableRecaptcha: boolean;

    constructor(
        private initializer: InitializerService,
        private appConfigsService: AppConfigsService,
        public logger: LoggerService
    ) {
        this.isApprovedIpForRecaptcha = this.initializer.getClientContext().IsApprovedIpForRecaptcha;
        this.isInLcLan = initializer.getClientContext().IsInMaxLan;
        this.enableRecaptcha = this.appConfigsService.appConfigs.enableRecaptcha;
    }

    getIsenableRecaptcha(): boolean {
        if (this.isInLcLan) {
            this.logger.warning(`In UI - recaptcha is disabled when user in max lan.`);
        }
        if (!this.enableRecaptcha) {
            this.logger.warning(`In UI - recaptcha is disabled when enableRecaptcha in appConfigs is false. flag value in configuration : ${this.enableRecaptcha}.`);
        }
        if (this.isApprovedIpForRecaptcha) {
            this.logger.warning(`In UI - recaptcha is disabled when ip approved.`);
        }

        return !this.isInLcLan && this.enableRecaptcha && !this.isApprovedIpForRecaptcha;
    }
}
/////////////////////////////////////////////////////////////////////////////////
//                             FOR USE :                                       //
//  constructor( private recaptchaHelper: RecaptchaHelperService) {            //
//      this.enableRecaptcha = this.recaptchaHelper.getIsenableRecaptcha();    //
//  }                                                                          //
/////////////////////////////////////////////////////////////////////////////////
