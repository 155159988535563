import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketingAgreementPopupComponent } from './marketing-agreement-popup.component';
import { MarketingAgreementPopupRoutingModule } from './marketing-agreement-popup-routing.module';
import { MarketingPopupComponent } from './components/marketing-popup/marketing-popup.component';
import { SuccessComponent } from './components/success/success.component';
import { FailComponent } from './components/fail/fail.component';
import { MarketingAgreementService } from './services/marketing-agreement.service';
import { CardholdersCoreModule } from '../../shared/cardholders-core/cardholders-core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  imports: [
        CommonModule,
        CardholdersCoreModule,
        //MarketingAgreementPopupRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        AutoCompleteModule
  ],
    declarations: [MarketingAgreementPopupComponent, MarketingPopupComponent, SuccessComponent, FailComponent],
    providers: [MarketingAgreementService]
})
export class MarketingAgreementPopupModule { }
