import { ComponentBase } from './../dynamic-form-control/controls/control-base';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilderService } from './form-builder.service';
import { FormControlService } from './form-control.service';
import { isNullOrUndefined } from 'core-max-lib';

@Injectable()
export class FormService {

    public mainFrm: FormGroup = new FormGroup({});
    public flatComponentTree: any = {};
    public flatFormControls: any = {};

    constructor(private frmBuilderSvc: FormBuilderService, private frmControlSvc: FormControlService) {
    }

    public createView(groupKey?, metaData?): { view: ComponentBase<any>[], formGroup: FormGroup } {
        let viewComponents = this.frmBuilderSvc.createViewComponents(metaData);
        this.setFlatComponentTree(viewComponents);

        if (this.mainFrm.controls[groupKey]) {
            const group = (this.mainFrm.controls[groupKey] as FormGroup);
            Object.keys(group.controls).forEach(controlKey => {
                if (this.flatComponentTree.hasOwnProperty(controlKey)) {
                    this.flatComponentTree[controlKey].value = group.controls[controlKey].value;
                }
            });
        }

        let formControls = this.frmControlSvc.createFormControls(viewComponents);
        this.setFlatFormControls(formControls);

        this.mainFrm.controls[groupKey] = formControls;

        return { view: viewComponents, formGroup: formControls };
    }

    private setFlatComponentTree(components: ComponentBase<any>[]) {
        components.forEach(component => {
            if (component.controlType === 'group') {
                this.flatComponentTree[component.key] = component;
                this.setFlatComponentTree(component['controls']);
            } else {
                this.flatComponentTree[component.key] = component;
            }
        });
    }

    private setFlatFormControls(group?) {
        if (isNullOrUndefined(group)) {
            group = this.mainFrm;
        }

        const keys = Object.keys(group.controls);
        keys.forEach(key => {
            const control = group.controls[key];
            if (control['controls']) {
                this.flatFormControls[key] = control;
                this.setFlatFormControls(control);
            } else {
                this.flatFormControls[key] = control;
            }
        });
    }

    get componentTree() {
        return this.flatComponentTree;
    }

    public getComponentValue(componentName: string) {
        if (this.componentTree[componentName]) {
            return this.componentTree[componentName].value;
        } else {
            return '';
        }
    }

    public setComponentValue(componentName: string, value: any) {
        this.componentTree[componentName].value = value;
    }

    get formControlsTree() {
        return this.flatFormControls;
    }

    public getComponent(key: string) {
        return this.flatComponentTree[key];
    }

    public showControl(controlKey: string, show: boolean) {
        this.flatComponentTree[controlKey].show(show);
        if (show) {
            this.flatFormControls[controlKey].enable();
        } else {
            this.flatFormControls[controlKey].disable();
        }
    }

    public hideControls(controls: string[]) {
        controls.forEach(controlName => {
            this.showControl(controlName, false);
        });
    }

    public showControls(controls: string[]) {
        controls.forEach(controlName => {
            this.showControl(controlName, true);
        });
    }

    public setValue(controlKey: string, value: any) {
        this.flatFormControls[controlKey].setValue(value);
    }

    public cleanForm() {
        Object.keys(this.flatComponentTree).forEach(key => {
            if (this.flatComponentTree[key]) {
                this.flatComponentTree[key].setValue('');
            }
            if (this.flatFormControls[key]) {
                this.flatFormControls[key].setValue('');
            }
        });
    }

    public destroyForm() {
        this.flatComponentTree = {};
        this.flatFormControls = {};
        this.mainFrm = new FormGroup({});
    }

    public setRequiredFieldsErrors(form: FormGroup) {
        for (const controlsKey in form.controls) {
            if (form.controls[controlsKey].hasError('required')) {
                this.flatFormControls[controlsKey].setErrors({ required: true });
                this.flatFormControls[controlsKey].markAsDirty();
                this.flatFormControls[controlsKey].markAsTouched();
            }
        }
    }

    public setRequiredAndSomeOtherFieldsErrors(form: FormGroup) {
        for (const controlsKey in form.controls) {
            if (form.controls[controlsKey].hasError('required')) {
                this.flatFormControls[controlsKey].setErrors({ required: true });
                this.flatFormControls[controlsKey].markAsDirty();
                this.flatFormControls[controlsKey].markAsTouched();
            }
            else if (controlsKey !== 'captcha') {
                this.flatFormControls[controlsKey].markAsDirty();
                this.flatFormControls[controlsKey].markAsTouched();
                this.flatFormControls[controlsKey].updateValueAndValidity();
            }
        }
    }

    public setFormAndComponentValue(key: string, value: any) {
        this.setComponentValue(key, value);
        this.setValue(key, value);
    }

}
