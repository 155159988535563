import { Injectable, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigsService } from '../../../config/app-configs.service';
import { InitializerService, UiHelperService } from 'core-max-lib';
import { DOCUMENT } from '@angular/common';
import { MainLayoutService } from '../../../modules/main-layout/services/main-layout.service';
import { LoggerService } from './logger.service';
import { SessionIdService } from './sessionId.service';
import { OpenBankingService } from '../../../modules/open-banking/open-banking.service';
import { IScriptAttribute } from '../../models/script-store/IOptions.interface';
import { ScriptHelperService } from '../../services/script-load-helper.service';

@Injectable()
export class TrusteerSnippetsService {
    originUrl: string;
    CSID = ''; // session ID
    UID = ''; // User hash identification

    csIdCallbackStgScript = 'function caller() {var result = {"c": "{this.CSID}"};return result;}';
    uIdCallbackStgScript = 'function caller() {var result = {"u" : {"p": ["{this.UID}"]}};return result;}';
    csIdCallbackProductionScript: string = undefined;
    uIdCallbackProductionScript: string = undefined;

    isFirstPageAfterLogin = false;
    private trusteerSnippetStgScript: string =
        '(function () {var f = document,e = window,i = e.location.protocol,b = [["src", [i == "https:" ? "https:/" : "http:/", "stem.max-stg.co.il/507740/resetes.js?dt=login&r=" + Math.random()].join("/")],["type", "text/javascript"],["async", true]],c = f.createElement("script"),h = f.getElementsByTagName("head")[0];setTimeout(function () {for (var d = 0, l = b.length; d < l; d++) {c.setAttribute(b[d][0], b[d][1])}h.appendChild(c)}, 0)})();' +
        '(function () {var c = window,d = document,g = c.location.protocol,f = d.getElementsByTagName("head")[0],b = d.createElement("script");b.src = (g == "https:" ? "https://" : "http://") + "stem.max-stg.co.il/507740/catox.js?r=" + Math.random();b.async = true;setTimeout(function () {b.type = "text/javascript";f.appendChild(b)}, 0)})();';
    private trusteerSnippetProductionScript: string = undefined;
    private isInLcLan = false;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        @Optional() @Inject('serverUrl') private serverUrl: string,
        private router: Router,
        private appConfigs: AppConfigsService,
        private scriptHelper: ScriptHelperService,
        private uiHelper: UiHelperService,
        private mainLayoutService: MainLayoutService,
        private logger: LoggerService,
        private sessionIdService: SessionIdService,
        private initializer: InitializerService,
        private openBankingService: OpenBankingService
    ) {
        // window.location.href.includes('max-stg.co.il')
        this.originUrl = this.uiHelper.isBrowser ? window.location.origin : this.serverUrl;
        this.isInLcLan = this.initializer.getClientContext().IsInMaxLan;
    }

    loadScript(): void {
        if (
            !this.appConfigs.appConfigs.FLAG_ISMainHeaderScriptFirstLoad ||
            this.document.querySelector(`script[id="mainHeaderScriptFirstLoad"]`)
        ) {
            // FLAG_ISLoadTrusteerSnippetScript
            return;
        }
        const script = this.originUrl.includes('max-stg.co.il')
            ? this.trusteerSnippetStgScript
            : this.originUrl.includes('max.co.il')
            ? this.trusteerSnippetProductionScript
            : undefined;

        if (script) {
            const attrs: IScriptAttribute[] = [
                {
                    qualifiedName: 'id',
                    value: 'mainHeaderScriptFirstLoad'
                },
                {
                    qualifiedName: 'type',
                    value: 'text/javascript'
                },
                {
                    qualifiedName: 'charset',
                    value: 'utf-8'
                }
            ];
            this.scriptHelper.singleScriptLoader(
                script,
                'text',
                attrs,
                null,
                this.document.getElementsByTagName('head')[0]
            );
        }
    }

    loadCallbackScripts(isUserAuthenticated: boolean): void {
        if (!this.uiHelper.isBrowser || !this.appConfigs.appConfigs.FLAG_ISMainHeaderScriptFirstLoad) {
            return;
        }
        if (isUserAuthenticated && !this.openBankingService.isOpenBankingLoginMode()) {
            this.removeCallbackScript('anonymousUserMainBodyScript');
            if (!this.document.querySelector(`script[id="authenticatedUserMainBodyScript"]`)) {
                this.mainLayoutService.loadUserBox().then((res) => {
                    if (res) {
                        const result: any = res;
                        this.UID = result.PersonalCacheHash;
                        const uIdScript = this.originUrl.includes('max-stg.co.il')
                            ? this.uIdCallbackStgScript.replace('{this.UID}', this.UID)
                            : this.originUrl.includes('max.co.il')
                            ? this.uIdCallbackProductionScript
                            : undefined;
                        this.loadCallbackScript(uIdScript, 'authenticatedUserMainBodyScript');
                    }
                });
            }
        } else {
            this.removeCallbackScript('authenticatedUserMainBodyScript');
            if (!this.document.querySelector(`script[id="anonymousUserMainBodyScript"]`)) {
                this.CSID = this.sessionIdService.sessionId;
                const csIdScript = this.originUrl.includes('max-stg.co.il')
                    ? this.csIdCallbackStgScript.replace('{this.CSID}', this.CSID)
                    : this.originUrl.includes('max.co.il')
                    ? this.csIdCallbackProductionScript
                    : undefined;
                this.loadCallbackScript(csIdScript, 'anonymousUserMainBodyScript');
            }
        }
    }

    executeTagmastersEvent(event: string): void {
        if (!this.uiHelper.isBrowser || !this.appConfigs.appConfigs.FLAG_ISMainHeaderScriptFirstLoad) {
            return;
        }
        try {
            if (this.originUrl.includes('max-stg.co.il')) {
                if (event === 'main_account') {
                    this.isFirstPageAfterLogin = true;
                } else if (event === 'other') {
                    if (this.isFirstPageAfterLogin) {
                        this.isFirstPageAfterLogin = false;
                        return;
                    }
                }
                if (this.isInLcLan) {
                    console.log('tagmasters - ' + event);
                }
                window['tagmasters'](event);
            }
        } catch (error) {
            this.logger.warning(`tagmasters fail for ${event} , error: ${JSON.stringify(error)}`);
        }
    }

    private loadCallbackScript(script: string, scriptId: string): void {
        if (script) {
            const attrs: IScriptAttribute[] = [
                {
                    qualifiedName: 'id',
                    value: scriptId
                },
                {
                    qualifiedName: 'type',
                    value: 'text/javascript'
                },
                {
                    qualifiedName: 'charset',
                    value: 'utf-8'
                }
            ];
            this.scriptHelper.singleScriptLoader(
                script,
                'text',
                attrs,
                { defer: true },
                this.document.getElementsByTagName('body')[0]
            );
        }
    }

    private removeCallbackScript(scriptId: string): void {
        const script = this.document.getElementById(scriptId);
        if (script) {
            script.parentNode.removeChild(script);
        }
    }
}
