import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
    ChangeDetectorRef
} from '@angular/core';
import { UiHelperService } from '../services/ui-helper.service';
import { BehaviorSubject } from 'rxjs';

@Directive({
    selector: '[appLoader]'
})
export class AppLoaderDirective implements OnInit {
    @Input()
    set appLoader(isLoaded: BehaviorSubject<boolean>) {
        this._appLoader = isLoaded;
    }

    @Input()
    set appLoaderTemplate(value: TemplateRef<HTMLElement>) {
        this._template = value;
    }

    @Input()
    set appLoaderClass(value: string) {
        this._class = value;
    }

    private _appLoader: BehaviorSubject<boolean>;
    private _template: TemplateRef<HTMLElement>;
    private _class: string;
    private noTemplateLoadingContent: ElementRef<any>;
    private classPrefix: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private uiHelper: UiHelperService,
        private _templateRef: TemplateRef<HTMLElement>,
        private _viewContainer: ViewContainerRef,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.classPrefix = this._class ? this._class : 'app';
        this._appLoader.subscribe(val => {
            if (val === true) {
                this.finishedLoading();
            } else {
                this.loading();
            }
        });
    }

    private loading(): void {
        if (this._template) {
            this._viewContainer.clear();
            this._viewContainer.createEmbeddedView(this._template);
            this.cdRef.detectChanges();
        } else {
            if (this.noTemplateLoadingContent === undefined) {
                this.noTemplateLoadingContent = this._viewContainer.createEmbeddedView(this._templateRef)
                    .rootNodes[0] as ElementRef<any>;
            }
            this.cdRef.detectChanges();
            this.renderer.removeClass(this.noTemplateLoadingContent, `${this.classPrefix}-loaded`);
            this.renderer.addClass(this.noTemplateLoadingContent, `${this.classPrefix}-loader`);
            this.renderer.addClass(this.noTemplateLoadingContent, `${this.classPrefix}-loading`);
        }
    }

    private finishedLoading(): void {
        if (this._template) {
            this._viewContainer.clear();
            this._viewContainer.createEmbeddedView(this._templateRef);
            this.cdRef.detectChanges();
        } else {
            if (this.noTemplateLoadingContent === undefined) {
                this.noTemplateLoadingContent = this._viewContainer.createEmbeddedView(this._templateRef)
                    .rootNodes[0] as ElementRef<any>;
            }
            this.renderer.removeClass(this.noTemplateLoadingContent, `${this.classPrefix}-loading`);
            this.renderer.addClass(this.noTemplateLoadingContent, `${this.classPrefix}-loaded`);
        }
    }
}
