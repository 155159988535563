import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConfigsService } from '../../../config/app-configs.service';
import { IResponse, HttpService} from '../../../shared/cardholders-core/services/http-service.service';
import { UiHelperService } from '../../../shared/cardholders-core/services/ui-helper.service';
import { IGeneralText } from "../models/general-text";
import { isNullOrUndefined } from 'core-max-lib';

declare var _detector: any;


@Injectable()
export class DetectorService {
    constructor()
    {}

    addEventForGlassBox(eventName: string, eventValue: string, domElement = undefined) {
        if (typeof (_detector) !== 'undefined' && !isNullOrUndefined(_detector)) {
            _detector.triggerCustomEvent(eventName, eventValue, domElement);
        }
    }

    addIdEventForGlassBox(idNumber: number) {
        this.addEventForGlassBox("MAX_EVENT", "iDnumber: " + idNumber.toString());
    }
}