import { Directive, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isNullOrUndefined } from 'core-max-lib';
import { IStyleLink } from '../models/style-link';
import { UiHelperService } from '../services/ui-helper.service';
import { InitializerService } from 'core-max-lib';
import { environment } from '../../../../environments/environment';

@Directive({
    selector: 'app-load-style'
})
export class AppLoadStyleDirective implements OnInit, OnDestroy {
    @Input()
    styleList: IStyleLink[];
    private version;

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private uiHelper: UiHelperService,
        private initializer: InitializerService
    ) {
        this.version = initializer.getClientContext().Version;
    }

    ngOnInit(): void {
        if (!isNullOrUndefined(this.styleList) && this.styleList.length > 0) {
            this.styleList.forEach(style => {
                if (this.document.head.querySelector(`link[id="${style.id}"]`)) {
                    return;
                }

                const linkElement = <HTMLLinkElement>(
                    this.document.head.appendChild(this.document.createElement('link'))
                );

                const href = environment.production? style.href : style.href.replace(".min", "");

                linkElement.setAttribute('href', href + '?version=' + this.version);
                linkElement.setAttribute('rel', 'stylesheet');
                linkElement.setAttribute('id', style.id);
            });
        }
    }

    ngOnDestroy(): void {
        if (this.uiHelper.isBrowser) {
            this.styleList?.forEach(style => {
                this.document.head.querySelector(`#${style.id}`).remove();
            });
        }
    }
}
