import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'maxCurrencyNegativePriceSec'
})
export class MaxCurrencyNegativePriceSecPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) { }

    transform(value: any, id?: any, spaces?: string): any {
        switch (id) {
            case 'ILS':
            case 0:
            case 376:
                return `${this.getSymbol(id) +
                    (value < 0 ? '-' : '') +
                    (spaces ? spaces : '')}${this.currencyPipe.transform(
                        value < 0 ? value * -1 : value,
                        'symbol-narrow',
                        ''
                    )} `;
            case 'USD':
            case 'EUR':
            case 'CAD':
            case 'GBP':
            case 978:
            case 840:
            case 124:
            case 826:
                return `${(value < 0 ? '-' : '') + (spaces ? spaces : '')}${this.currencyPipe.transform(
                    value < 0 ? value * -1 : value,
                    'symbol-narrow',
                    ''
                )}${this.getSymbol(id)}`;
            default:
                return this.currencyPipe.transform(value < 0 ? value * -1 : value, 'symbol-narrow', '');
        }
    }

    getSymbol(id: any): string {
        if (id === 'ILS' || Number(id) === 0 || Number(id) === 376) {
            return '₪';
        }
        if (id === 'USD' || Number(id) === 840) {
            return '$';
        }
        if (id === 'EUR' || Number(id) === 978) {
            return '€';
        }
        if (id === 'GBP' || Number(id) === 826) {
            return '£';
        }
        if (id === 'CAD' || Number(id) === 124) {
            return 'C$';
        }
        return ` ${id} `;
    }
}