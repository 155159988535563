import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from '../services/cookie.service';

@Injectable({
    providedIn: 'root'
})
export class Ave10Service {

    constructor(@Inject(DOCUMENT) private document: Document, private cookieService: CookieService) {}

    isAve10(): boolean {
        var elm = this.document.getElementById('ctl00_PlaceHolderOutputCache_divCache');
        if (elm) return elm.innerText.indexOf('@@@Host :10') > 0;
        else return false;
    }

    checkCookie10() {
        return this.cookieService.check('eve');
    }
}