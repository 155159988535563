import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { PopupsService } from "../../../../shared/cardholders-core/services/popups.service";
import { SessionTimeoutService } from "../../../../shared/cardholders-core/services/session-timeout.service";
import { Subscription } from 'rxjs';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';

@Component({
    selector: 'app-session-timeout-modal',
    templateUrl: './session-timeout-modal.component.html',
    styleUrls: ['./session-timeout-modal.component.scss']
})
export class SessionTimeoutModalComponent implements OnInit, OnDestroy {
    public companyName : string='';
    public countdown: number;
    private subs: Subscription[] = [];

    constructor(private popupsSvc: PopupsService, private appConfigSvc: AppConfigsService, private sessionTimeoutService: SessionTimeoutService,private modalSvc : ModalService) { }

    ngOnInit() {
        this.companyName = this.appConfigSvc.appConfigs.companyName;
        this.subs.push(this.sessionTimeoutService.onTimeoutCountdownSubject.subscribe((countdown) => {
            this.countdown = countdown;
        }));
    }

    continue(val: boolean) {
        this.popupsSvc.sessionTimeoutPopupNavigateAwaySelection.next(val);
        this.modalSvc.closeModal();
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => { sub.unsubscribe() });
    }

}
