<div class="what-your-max-today">
    <h2>{{ content?.title }}</h2>
    <div class="actions-wrapper">
        <div *ngFor="let product of content?.productsList let i = index" class="action" id="cards"
             (click)="onProductClick(product)" (mouseenter)="onMouseEnter(i)" (mouseleave)="onMouseLeave(i)">
            <lottie-animation-view [options]="{
                autoplay: false,
                loop: false,
                path: product.lottie
            }" [width]="90" [height]="90" (animCreated)="onAnimCreated($event, i)"> </lottie-animation-view>
            <a appLink [anchorLink]="product.url" [isPreventDefaultForRouterLink]="true" [href]="product.url"
            (click)="onProductClick(product)" ><h3>{{ product.title }}</h3></a>
            <span class="sub-title" [innerHTML]="product.text | sanitizeHtml"></span>
        </div>
    </div>
</div>