import { HomepageService } from "../../services/homepage.service";
import { Component } from '@angular/core';
import { AuthService } from "../../../../shared/cardholders-core/services/auth.service";
import { UiHelperService } from "../../../../shared/cardholders-core/services/ui-helper.service";
import { GtmService } from "../../../../shared/cardholders-core/services/gtm.service";

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
    notificationArr = [];
    public sildeConfig = {
        infinite: false, arrows: false, slidesToShow: 1, slidesToScroll: 1,
        speed: 200, swipeSpeed: 200, autoplay: false, mobileFirst: true, rtl: true
    };

    constructor(private homePageService: HomepageService, private authSvc: AuthService, private uiHelper: UiHelperService, private gtmService: GtmService) { }

    ngOnInit() {
        if (this.uiHelper.isBrowser && this.authSvc.isUserAuthenticated()) {
            this.homePageService.getNotifications().subscribe(
                res => {
                    this.notificationArr = res.Result;
                    this.updateAnalytics();
                },
                err => console.log(err)
            );
        }
    }


    removeNotification(id: number) {
        this.notificationArr.splice(this.notificationArr.findIndex(n => n.NoticeId === id), 1);
        this.homePageService.removeNotification(id).subscribe();
    }

    updateAnalytics() {
        this.gtmService.pushDirective({ category: 'New website - registered', action: 'Notices - number', label: this.notificationArr.length.toString() });
        let result = '';
        this.notificationArr.map((notice, i) => {
            if (i === 0) {
                result += `${notice.NoticeName}`;
            } else {
                result += ` | ${notice.NoticeName}`;
            }
        });
        this.gtmService.pushDirective({ category: 'New website - registered', action: 'Notices - number', label: result});
        
    }
}
