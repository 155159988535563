import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepComponent } from './components/step/step.component';
import { FormComponent } from './components/step/form/form.component';
import { StepsFormComponent } from './components/steps-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TitleRadiosComponent } from './components/step/form/title-radios/title-radios.component';
import { StepsFormService } from './steps-form.service';
import { CardholdersCoreModule } from '../../cardholders-core/cardholders-core.module';
import { RecaptchaModule } from 'ng-recaptcha';


@NgModule({
    declarations: [
        StepComponent,
        FormComponent,
        StepsFormComponent,
        TitleRadiosComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CardholdersCoreModule,
        RecaptchaModule
    ],
    exports: [
        StepComponent,
        FormComponent,
        StepsFormComponent
    ],
    providers: [StepsFormService]
})
export class StepsFormModule { }
