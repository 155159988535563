import {
  Component,
  Input,
  Inject,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  PLATFORM_ID,
  OnChanges,
  ChangeDetectionStrategy
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { isNullOrUndefined } from 'core-max-lib';

declare let require: any;

@Component({
  selector: 'lottie-animation-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container>
      <div #lavContainer
           [ngStyle]="{'width': viewWidth, 'height': viewHeight, 'overflow':'hidden', 'margin': viewMargin}">
      </div>
    </ng-container>`
})

export class LottieAnimationViewComponent implements OnInit, OnChanges {
  @Input() options: any;
  @Input() width: any;
  @Input() height: any;
  @Input() margin: any;
  @Output() animCreated: any = new EventEmitter();
  @Output() onLoopComplete: EventEmitter<{animation: any, event: any}> = new EventEmitter<{animation: any, event: any}>();
  @ViewChild('lavContainer', { static: true }) lavContainer: ElementRef;

  viewWidth: string;
  viewHeight: string;
  viewMargin: string;

  animation: any;

  private _options: any;
  private lottie: any;
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
        this.lottie = require('lottie-web/build/player/lottie.js');
    }
  }

  ngOnChanges(): void {
    if (!this.isBrowser) {
      return;
    }
    const element = this.lavContainer.nativeElement.querySelectorAll('svg');
    if (element.length > 0) {
      for (let i = 0; i < element.length; i++) {
        element[i].remove();
      }
    }
    this.initAnimation();
    if (isNullOrUndefined(this.viewWidth)) {
      this.viewWidth = this.width + 'px';
      this.viewHeight = this.height === 0 ? 'auto' : this.height + 'px';
      }
    if (!this.viewMargin) {
        this.viewMargin = this.margin || '0 auto';
    }
  }

  ngOnInit(): void {
    // this.initAnimation();
  }

  initAnimation(): void {
    this._options = {
      container: this.lavContainer.nativeElement,
      renderer: this.options.renderer || 'svg',
      loop: this.options.loop === true ? true : this.options.loop === false ? false : this.options.loop,
      autoplay: this.options.autoplay !== false,
      autoloadSegments: this.options.autoloadSegments !== false,
      animationData: this.options.animationData,
      path: this.options.path || '',
      rendererSettings: this.options.rendererSettings || {}
    };

    if (this.isBrowser) {
        const anim: any = this.lottie.loadAnimation(this._options);
        this.animation = anim;
        anim.onLoopComplete = ((event) => {this.onLoopComplete.emit({animation: this.animation, event: event}); });
      this.animCreated.emit(anim);
      if (this.viewHeight === 'auto') {
        const element = this.lavContainer.nativeElement.querySelectorAll('svg');
        element[0].style.height = null;
        element[0].style.width = null;
      }
    }
  }
}
