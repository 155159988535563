import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { AutoComplete } from 'primeng/autocomplete';
import { MainLayoutService } from '../../services/main-layout.service';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {
    hidePlaceHolder = false;
    removePlaceHolder = false;
    contentRoot: string;
    results: string[];
    text = ' ';
    companyName: string;
    isUserAuthenticated: boolean;
    searchRedirectLoader: BehaviorSubject<boolean>;
    inputTouched: boolean;
    isMobile: boolean;

    @ViewChild('searchInput')
    searchInput: AutoComplete;

    constructor(
        private appConfigsService: AppConfigsService,
        public layoutService: MainLayoutService,
        private authService: AuthService,
        private uiHelper: UiHelperService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {
        this.isMobile = uiHelper.IsMobile(768);
        this.contentRoot = this.appConfigsService.appConfigs.contentRoot;
        this.companyName = 'max'; /*this.appConfigsService.appConfigs.companyName.toLowerCase();*/
        this.isUserAuthenticated = this.authService.isUserAuthenticated();
        this.inputTouched = false;
    }

    ngOnInit() {
        this.searchRedirectLoader = new BehaviorSubject<boolean>(true);
        this.authService.onAuthenticationChangeSubject
            .subscribe(isAuth => {
                this.isUserAuthenticated = isAuth;
            });
    }

    ngAfterViewInit(): void {
        this.activatedRoute.queryParams.subscribe((query) => {
            let searchText = query['search'];
            this.setSearchText(searchText);
        });
    }

    setSearchText(text) {
        if (text) {
            this.hidePlaceHolder = true;
            setTimeout(_ => {
                if (this.hidePlaceHolder) {
                    this.removePlaceHolder = true;
                }
                this.searchInput.inputEL.nativeElement.value = text;
                this.searchInput.filled = true;
            }, 0);
        }
    }

    onBlur() {
        this.hidePlaceHolder = this.searchInput.inputEL.nativeElement.value.length > 0;
        this.removePlaceHolder = this.searchInput.inputEL.nativeElement.value.length > 0;
    }

    onFocus() {
        if (!this.inputTouched) {
            this.inputTouched = true;
            this.searchInput.inputEL.nativeElement.addEventListener('input', () => {
                if (this.searchInput.inputEL.nativeElement.value === '') {
                    this.text = '';
                }
            });
        }
        this.hidePlaceHolder = true;
        setTimeout(_ => {
            if (this.hidePlaceHolder) {
                this.removePlaceHolder = true;
            }
        }, 1000);
    }

    onSelect(e) {
        this.searchRedirectLoader.next(false);
        setTimeout(_ => {
            if (this.appConfigsService.appConfigs.FLAG_ISUseUmbracoSearch) {
                this.router.navigate(['/search/main'], {
                    queryParams: {search: e}
                }).then(_ => {this.searchRedirectLoader.next(true); this.setSearchText(e);});
            }
            else {
                window.location.href = `${this.contentRoot}/he-il/pages/searchpage.aspx?k=${e}`;
            }
        }, 200);
    }

    search(e) {
        if (e.query.length < 2) {
            this.results = [];
            return;
        }

        this.layoutService.getSearchAutocompleteStrings().subscribe(
            res =>
                (this.results = (res as string[])
                    .filter(item => {
                        return item.indexOf(e.query.toLowerCase()) !== -1;
                    })
                    .slice(0, 5))
        );
    }

    freeSearch() {
        const freeSearchText = (this.searchInput.inputEL.nativeElement as HTMLInputElement).value;
        if (freeSearchText.trim() !== '') {
            this.onSelect(freeSearchText);
        }
    }

    onKeyUp(event: any) {
        if (event.key === 'Enter') {
            this.freeSearch();
        }
    }
}
