import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, IResponse } from './http-service.service';
import { AppConfigsService } from '../../../config/app-configs.service';

@Injectable()
export class TesterService {

  constructor(private httpSvc: HttpService,
    private appConfigsService: AppConfigsService) { }

   getTestPageData(): Observable<IResponse<string>> {
     return this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/registered/homepagedemo/getTestPageData`, null);
   }
}
