import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
    @Output()
    closeMarketingAgreementPopup = new EventEmitter();
  constructor(private gtmService: GtmService) { }

  ngOnInit(): void {
    this.gtmService.pushPopUpDivur('43', '334', '3');
  }

}
