import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentBase } from '../dynamic-form-control/controls/control-base';
import { isNullOrUndefined } from 'core-max-lib';
import { validateId } from './validators/idValidator';
import { isTrueValidator } from './validators/isTrue.validator';
import { BirthDateValidator } from './validators/birth_date.validator';
import { distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';
import { validateFutureDate } from "./validators/futureDate.validator";
import { validateFutureDatePlusXDays } from './validators/futureDatePlusXDays.validator';
import { AppConfigsService } from '../../../../config/app-configs.service';


export const ValidationTypes = {
  REQUIRED: "required",
  MAX: "max",
  MIN: "min",
  MIN_LENGTH: "minlength",
  MAX_LENGTH: "maxlength",
  EMAIL: "email",
  PATTERN: "pattern",
  MIN_AGE: "minAge",
  ID: "id",
  CREDIT_CARD: "cCard",
  IS_TRUE: "isTrue",
  REQUIRED_TRUE: "requiredTrue",
  UNDER_18: "under18",
  OVER_110: "over110",
  INVALID_DATE: "invalidDate",
  CELLPHONE_PREFIX: "cellPhonePrefixInvalid",
  SELECT_ITEM_PATTERN: "selectItemPattern",
  IS_FUTURE_DATE: "isFutureDate",
  IS_FUTURE_DATE_PLUS_X_DAYS: "isFutureDatePlusXDays",
  NOT_EQUAL: "notEqual",
  EQUAL: "equal",
  EQUAL_PHONE: "equalPhone",
  SELECT_ITEM_PATTERN_FREE: "selectItemPatternFree",
  SELECT_ITEM_FORCE_SELECTION: "selectItemForceSelection",
  MAX_NUMERIC_VALUE: "maxNumericValue"
};

@Injectable()
export class FormControlService {

    constructor(private appConfigsService: AppConfigsService) {
    }

    createFormControls(components: ComponentBase<any>[]): FormGroup {
        const controls: any = {};

        components.forEach(component => {
            const validators = [];
            if (component.controlType === 'group') {
                const formGroup = this.createFormControls(component['controls']);
                if (component.visible === false) {
                    formGroup.disable();
                }
                controls[component.key] = formGroup;
            } else {
                if (component.required) {
                    validators.push(Validators.required);
                }
                if (!isNullOrUndefined(component.validators) && component.validators.length) {
                    component.validators.forEach(item => {
                        switch (item.type) {
                            case ValidationTypes.REQUIRED:
                                validators.push(Validators.required);
                                break;
                            case ValidationTypes.MIN_LENGTH:
                                validators.push(Validators.minLength(item.value));
                                break;
                            case ValidationTypes.MAX_LENGTH:
                                validators.push(Validators.maxLength(item.value));
                                break;
                            case ValidationTypes.EMAIL:
                                validators.push(Validators.email);
                                break;
                            case ValidationTypes.PATTERN:
                                validators.push(Validators.pattern(item.value));
                                break;
                            case ValidationTypes.REQUIRED_TRUE:
                                validators.push(Validators.requiredTrue);
                                break;
                            case ValidationTypes.MAX:
                                validators.push(Validators.max(item.value));
                                break;
                            case ValidationTypes.MIN:
                                validators.push(Validators.min(item.value));
                                break;
                            case ValidationTypes.MIN_AGE:
                                validators.push(this.ValidateMinAge(item.value));
                                break;
                            case ValidationTypes.ID:
                                validators.push(validateId);
                                break;
                            case ValidationTypes.CREDIT_CARD:
                                validators.push(this.ValidateCreditCard(item.value));
                                break;
                            case ValidationTypes.IS_TRUE:
                                validators.push(isTrueValidator);
                                break;
                            case ValidationTypes.UNDER_18:
                                validators.push(this.ValidateUnder18());
                                break;
                            case ValidationTypes.OVER_110:
                                validators.push(this.ValidateOver110());
                                break;
                            case ValidationTypes.INVALID_DATE:
                                validators.push(BirthDateValidator);
                                break;
                            case ValidationTypes.CELLPHONE_PREFIX:
                                validators.push(this.ValidateCellPhonePrefix(item.value));
                                break;
                            case ValidationTypes.SELECT_ITEM_PATTERN:
                                validators.push(this.ValidateSelectItemPattern(item.value));
                                break;
                            case ValidationTypes.IS_FUTURE_DATE:
                                validators.push(validateFutureDate);
                                break;
                            case ValidationTypes.IS_FUTURE_DATE_PLUS_X_DAYS:
                                validators.push(this.validateFutureDatePlusXDays(this.appConfigsService.appConfigs.daysUntilIdWillBeExpired));
                                break;
                            case ValidationTypes.NOT_EQUAL:
                                validators.push(this.ValidateNotEqual(controls, item.targetKey));
                                break;
                            case ValidationTypes.EQUAL:
                                validators.push(this.ValidateEqual(controls, item.targetKey));
                                break;
                            case ValidationTypes.EQUAL_PHONE:
                                validators.push(this.ValidateEqualPhone(controls, item.targetKey));
                                break;
                            case ValidationTypes.SELECT_ITEM_PATTERN_FREE:
                                validators.push(this.ValidateSelectItemPatternFree(item.value));
                                break;
                            case ValidationTypes.SELECT_ITEM_FORCE_SELECTION:
                                validators.push(this.ValidateForceSelection());
                                break;
                            case ValidationTypes.MAX_NUMERIC_VALUE:
                                validators.push(this.ValidateMaxNumericValue(item.value));
                                break;
                        }
                    });
                }

                validators.push(this.ServerValidation());

                controls[component.key] = new FormControl(isNullOrUndefined(component.value) ? '' : component.value, validators);
                controls[component.key].valueChanges.subscribe(() => {
                    let errors = controls[component.key].errors;
                    if (!isNullOrUndefined(errors) && errors["serverError"]) {
                        delete errors["serverError"];
                        controls[component.key].setErrors(errors);
                        controls[component.key].updateValueAndValidity();
                    }
                });
            }

        });

        Object.keys(controls).forEach((key) => {
            let component = _.find(components, (component) => { return component.key === key && !isNullOrUndefined(component.validators) });
            if (!isNullOrUndefined(component)) {
                let targetKeys = component.validators.filter((validator) => { return (validator.type === ValidationTypes.NOT_EQUAL || validator.type === ValidationTypes.EQUAL_PHONE) }).map((validatorNotEqual) => {
                    return validatorNotEqual.targetKey;
                });
                if (targetKeys.length > 0)
                    controls[key].valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
                        targetKeys.forEach((targetKey) => { controls[targetKey].updateValueAndValidity() })
                    });
            }

        });

        return new FormGroup(controls);

    }
    
    ValidateMinAge(minAge: number) {
        return (control: AbstractControl) => {
            if (isNullOrUndefined(control.value)) {
                return null;
            }
            const from = control.value.split("/"); // parse date
            const birthday = new Date(from[2], from[1] - 1, from[0]); // convert to type date

            const ageDifMs = Date.now() - birthday.getTime();
            const ageDate = new Date(ageDifMs); // miliseconds from epoch
            const age = Math.abs(ageDate.getUTCFullYear() - 1970);

            return age > minAge ? null : { underMinAge: true };
        };
    }

    ValidateCreditCard(cardNum: number) {
        return (control: AbstractControl) => {
            if (isNullOrUndefined(control.value)) {
                return null;
            }
            if (cardNum === 16) {
                return { cardGood: true };
            } else {
                return null;
            }
        };
    }

    ValidateCellPhonePrefix(phoneNumPrefix: string) {
        return (control: AbstractControl) => {
            if (isNullOrUndefined(control.value)) {
                return null;
            }
            if (!control.value.startsWith(phoneNumPrefix)) {
                return { cellPhonePrefixInvalid: true };
            } else {
                return null;
            }
        };
    }

    ValidateSelectItemPattern(pattern: string) {

        return (control: AbstractControl) => {
            if (isNullOrUndefined(control.value)) {
                return null;
            }

            const patt = new RegExp(pattern);

            if (isNullOrUndefined(control.value.label) || !patt.test(control.value.label)) {
                return { selectItemPattern: true };
            } else {
                return null;
            }
        };
    }

    ValidateUnder18() {
        return (control: AbstractControl) => {
            if (isNullOrUndefined(control.value)) {
                return null;
            }

            const valArr = control.value ? control.value.split('/') : '';
            const day = parseInt(valArr[0]);
            const month = parseInt(valArr[1]);
            const year = parseInt(valArr[2]);
            const dateTime = new Date(year, month - 1, day).getTime();
            const now = new Date().getTime();

            // 568024668000 == 18 years in milliseconds
            if (dateTime < now && now - dateTime < 568024668000) {
                return {
                    under18: true
                };
            }

            return null;

        };
    }

    ValidateOver110() {
        return (control: AbstractControl) => {
            if (isNullOrUndefined(control.value)) {
                return null;
            }

            const valArr = control.value ? control.value.split('/') : '';
            const day = parseInt(valArr[0]);
            const month = parseInt(valArr[1]);
            const year = parseInt(valArr[2]);
            const dateTime = new Date(year, month - 1, day).getTime();
            const now = new Date().getTime();

            // ‭3471261860000‬ == 110 years in milliseconds
            if (dateTime < now && now - dateTime > 3471261860000) {
                return {
                    over110: true
                };
            }

            return null;
        };
    }

    ValidateNotEqual(controls: any, targetKey: string) {
        return (control: FormControl) => {
            const target: FormControl = controls[targetKey];
            if (
                isNullOrUndefined(target) ||
                target.value === '' ||
                target.value === undefined ||
                target.value === null ||
                target.disabled
            ) {
                return null;
            }
            return control.value != target.value ? null : { notEqual: true };
        }
    }

    ValidateEqual(controls: any, targetKey: string) {
        return (control: FormControl) => {
            const target: FormControl = controls[targetKey];
            if (isNullOrUndefined(target) || target.value === "" || target.disabled) {
                return null;
            }
            return control.value == target.value ? null : { equal: true };
        }
    }

    ValidateEqualPhone(controls: any, targetKey: string) {
        return (control: FormControl) => {

            const target: FormControl = controls[targetKey];
            if (isNullOrUndefined(target) || target.value === "" || target.disabled || String(control.value).length != 10 || String(target.value).length != 10) {
                return null;
            }

            return control.value == target.value ? null : { equalPhone: true };
        }
    }

    ValidateSelectItemPatternFree(pattern: string) {

        return (control: AbstractControl) => {
            if (isNullOrUndefined(control.value)) {
                return null;
            }

            const patt = new RegExp(pattern);

            if (!patt.test(control.value.label) && !patt.test(control.value)) {
                return { selectItemPatternFree: true };
            } else {
                return null;
            }
        };
    }

    ValidateForceSelection() {
        return (control: AbstractControl) => {
            const selection: any = control.value;
            if (typeof selection === 'string') {
                return { selectItemForceSelection: true };
            }
            return null;
        };
    }

    ValidateMaxNumericValue(maxValue: number) {

        return (control: AbstractControl) => {
            if (isNullOrUndefined(control.value)) {
                return null;
            }

            let numericValue: number = parseInt(control.value);

            if (numericValue > maxValue) {
                return { maxNumericValue: true };
            } else {
                return null;
            }
        };
    }

    ServerValidation() {
        return (control: AbstractControl) => {
            if (control.errors && control.errors['serverError']) {
                return { serverError: true }
            };

            return null;
        };

    }

    addDays(date, days) {
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy;
    }

    validateFutureDatePlusXDays(daysToAdd: number) {
        return (control: AbstractControl) => {
            if (isNullOrUndefined(control.value))
                return null;

            const valArr = control.value ? control.value.split('/') : '';
            const day = parseInt(valArr[0]);
            const month = parseInt(valArr[1]);
            const year = parseInt(valArr[2]);
            const inputDateTime = new Date(year, month - 1, day);           
            const nowPlusXdays = this.addDays(new Date(), daysToAdd);

            if (inputDateTime.getTime() <= nowPlusXdays.getTime()) {
                return { isFutureDatePlusXDays: true };
            }
            return null;
        }

    }
}
