import { Injectable } from '@angular/core';

@Injectable()
export class ModuleTransferService {
  private _data: any = {};

  public setData(key: string, data: any) {
    this._data[key] = data;
  }

  public getData(key: string) {
    return this._data[key];
  }
}
