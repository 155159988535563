import { UiHelperService } from "../../../../shared/cardholders-core/services/ui-helper.service";
import { AppConfigsService } from "../../../../config/app-configs.service";
import { Component, Inject, OnInit, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent } from 'rxjs';

@Component({
    selector: 'app-cookies-notification',
    templateUrl: './cookies-notification.component.html',
    styleUrls: ['./cookies-notification.component.scss']
})
export class CookiesNotificationComponent implements OnInit {
    show: boolean;
    link: string;
    isScroll = false;
    scrollEvent;
    @Input() element: any;

    constructor(@Inject(DOCUMENT) private document: Document, private appConfigSvc: AppConfigsService, private uiHelper: UiHelperService) { }

    ngOnInit() {
        if (this.uiHelper.isBrowser) {
            this.show = this.getCookie();
            this.uiHelper.SetCookieAppear(this.show);
            if (this.show) {
                this.link = this.appConfigSvc.appConfigs.contentRoot;
                this.element.classList.add('fix-jump');
                this.scrollEvent = fromEvent(window, 'scroll').subscribe(() => {
                    if (window.scrollY > 50) {
                        this.isScroll = true;
                    } else {
                        this.isScroll = false;
                    }
                });
            }
        }
    }

    close() {
        this.document.cookie = `LegalMessage=true;expires=${new Date('3333-01-01').toUTCString()};domain=${window.location.hostname.replace(/([a-z]+.)/, "")};path=/`;
        this.show = false;
        this.element.classList.remove('fix-jump');
        this.scrollEvent.unsubscribe();
        this.uiHelper.SetCookieAppear(false);
    }

    getCookie(): boolean {
        const cookies = this.document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let item = cookies[i];
            while (item.charAt(0) === ' ') {
                item = item.substring(1);
            }
            if (item.indexOf('LegalMessage') === 0) return false;
        }
        return true;
    }
}