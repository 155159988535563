import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { IStep, IStepsFormData } from "./models";

export class StepsFormService {
    
    data: IStepsFormData;
    currentStep: IStep;
    step: number = 0;
    currentForm: FormGroup;
    onSubmit: EventEmitter<any> = new EventEmitter<any>();
    onStepChange: Subject<any> = new Subject<any>();
    loading: Subject<boolean> = new Subject<boolean>();
    setFormError: Subject<string> = new Subject<string>();



    constructor() { }

    init(data: IStepsFormData) {
        this.data = data;
        this.currentStep = data.Steps[0];
    }

    setStep(step: number) {
        this.currentStep = this.data.Steps[step];
        this.step = step;
        this.onStepChange.next();
    }

    setFormServerError(control: string) {
        this.setFormError.next(control);
    }
}
