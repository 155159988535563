import { Observable } from 'rxjs';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { isNullOrUndefined } from 'core-max-lib';

@Injectable()
export class HttpServiceAntiForgeryInterceptor implements HttpInterceptor {
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isBrowser) {
      return next.handle(req);
    }
    const cookieValue = this.getCookie('OnlineLC-XSRF-TOKEN');

    if (isNullOrUndefined(cookieValue)) {
      return next.handle(req);
    }

    let newReq = req.clone({
      setHeaders: {
        'X-OnlineLC-XSRF-TOKEN': cookieValue
      }
    });

    return next.handle(newReq);
  }

  getCookie(name: string) {
    let value = '; ' + document.cookie;
    let parts = value.split('; ' + name + '=');
    if (parts.length === 2)
      return parts
        .pop()
        .split(';')
        .shift();
    return undefined;
  }
}
