<div class="transaction-deny-gif-loader">
    <div class="loader-container">
        <img src="/assets/images/customer-loan/max-loader.gif" />
        <div class="loader-text" *ngIf="title">
            <span class="main-title" [innerHtml]="title"></span>
            <div class="hide-text"></div>
            <div class="row-text" *ngFor="let message of data">
                <span class="sub-title" *ngIf="message?.isShow" [ngClass]="{'sub-title-out' : message?.isShow}">{{message?.msg}}</span>
            </div>
        </div>
    </div>

</div>
