import { Injectable, Inject, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'core-max-lib';
import { Observable } from 'rxjs';
import { IResponse, HttpService } from '../../shared/cardholders-core/services/http-service.service';
import { IScopesModel, ILoginWithMaxResult } from './models';
import { AppConfigsService } from '../../config/app-configs.service';
import { UiHelperService } from 'core-max-lib';

@Injectable({
    providedIn: 'root'
})
export class LoginWithMaxService {

    scopes: string;
    applicationName: string;
    originalUrl: string;
    originUrl;

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private appConfigs: AppConfigsService,
        private uiHelper: UiHelperService,
        @Optional() @Inject('serverUrl') private serverUrl: string,) {
            this.originUrl = this.uiHelper.isBrowser ? window.location.origin : this.serverUrl;
    }

    getApplicationAndScopes(): Observable<IResponse<IScopesModel>> {
        const params = this.route.snapshot.queryParams;

        const appName = params['app-name'];
        if (!isNullOrUndefined(appName)) {
            this.applicationName = appName;
        }
        const origUri = params['original-url'];
        if (!isNullOrUndefined(origUri)) {
            this.originalUrl = origUri;
        }
        const scopesString: string = this.getScope(origUri);
        if (!isNullOrUndefined(scopesString)) {
            this.scopes = scopesString;
        }
        return this.http.get(`${this.appConfigs.appConfigs.apiUrl}/registered/loginwithmax/applicationandscopes?applicationname=${this.applicationName}&scopes=${this.scopes}`);
    }

    loginWithMax(): Observable<IResponse<ILoginWithMaxResult>>  {
        return this.http.post(`${this.appConfigs.appConfigs.apiUrl}/registered/loginwithmax/createtoken`);
    }

    isLoginWithMaxMode(): boolean {
        //find the last component in the route - where the data is
        let route = this.route.snapshot;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route.data && route.data.loginWithMaxMode === true;
    }

    getReturnToScopesScreenUrl(): string {
        const params = this.route.snapshot.queryParams;
        const encodedOriginalUrl = encodeURIComponent(params['original-url']);
        return `${this.originUrl}/loginwithmax/scopes?original-url=${encodedOriginalUrl}&app-name=${params['app-name']}`;
    }

    private getScope(originalUrl: string): string {
        const query = new URLSearchParams('?' + originalUrl.split('?')[1]);
        return query.get('scope');
    }
}
