import { ComponentBase } from './control-base';
import { FormGroup } from '@angular/forms';

export class GroupComponent extends ComponentBase<string> {
  controlType = 'group';
  controls: ComponentBase<any>[];
  layout: string;

  constructor(options: {}) {
    super(options);
    this.controls = options['controls'] || [];
    this.layout = options['layout'];
  }

  // afterViewInit(form: FormGroup) {
  //   if (this.link) {

  //     if (!form.controls[this.link.to].value) {
  //       form.controls[this.key].enable();
  //       this.visible = true;
  //     }

  //     form.controls[this.link.to].valueChanges.subscribe(value => {

  //       if (!value) {
  //         form.controls[this.key].enable();
  //       } else {
  //         form.controls[this.key].disable();
  //       }

  //       this.visible = !value;
  //     });
  //   }
  // }
}
