import { Injectable } from '@angular/core';
import { FormControl, AbstractControl, FormGroup } from '@angular/forms';

@Injectable()
export class FormHelperService {
    constructor() {}

    public isFormControlEmptyAfterTouched(control: AbstractControl) {
        return control.hasError('required') && control.touched;
    }

    public isFormControlValidByPattern(control: AbstractControl) {
        return !control.hasError('pattern');
    }

    public isFormControlValidByLength(control: AbstractControl) {
        return !control.hasError('minlength') && !control.hasError('maxlength');
    }

    public isFormControlNotValid(control: AbstractControl) {
        return !control.valid && control.touched;
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
}
