import { AppConfigsService } from '../../../config/app-configs.service';
import { ILoginResult } from '../models/login-result';
import { HttpService } from './http-service.service';
import { IResponseLower } from 'core-max-lib';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { IGetPublicKeyResult } from '../models/finger-print.model';
import { IResponse } from './http-service.service';
import { LoginStatus } from '../models/login-result';
import { tap } from 'rxjs/operators';
import { OpenBankingService } from '../../../modules/open-banking/open-banking.service';

@Injectable({ providedIn: 'root' })
export class FingerPrintService {

    publicKeyResult: IGetPublicKeyResult;
    fpId: string;

    constructor(private http: HttpService, private configService: AppConfigsService, private authService: AuthService,
        private openBankingService: OpenBankingService) { }

    getChallenge(): Observable<IResponseLower<string>> {
        return this.http.postLower(`${this.configService.appConfigs.apiUrl}/fingerPrint/generateChallenge`, {}) as any;
    }

    verifyAttestation(attestation: any): Observable<IResponseLower<any>> {
        return this.http.postLower(`${this.configService.appConfigs.apiUrl}/fingerPrint/verifyAttestation`, attestation) as any;
    }

    getPublicKey(fpId: string): Observable<IResponseLower<IGetPublicKeyResult>> {
        return (this.http.postLower(`${this.configService.appConfigs.apiUrl}/fingerPrint/getPublicKey?fpId=${fpId}`, {})
            .pipe(
                (tap((res: IResponseLower<IGetPublicKeyResult>) => {
                    if (res.returnCode === 0) {
                        this.publicKeyResult = res.result;
                        this.fpId = fpId;
                    }
                })) as any
            )) as any;
    }

    verifyAssertion(assertion: any, fpId: string): Promise<IResponse<ILoginResult>> {
        let method = this.openBankingService.isOpenBankingLoginMode() ? 'openBanking' : '';
        return new Promise((resolve, reject) => {
            // tslint:disable:max-line-length
            this.http.post<ILoginResult>(`${this.configService.appConfigs.apiUrl}/fingerPrint/${method}verifyAssertion?fpId=${fpId}`, assertion).subscribe((res: IResponse<ILoginResult>) => {
                console.log(res);
                if (res.ReturnCode === 0 && res.Result.LoginStatus !== LoginStatus.passwordExpired && res.Result.LoginStatus !== LoginStatus.falied) {
                    this.authService.markUserHasAuthenticated(res.Result.Roles);
                }
                resolve(res);
            });
        });
    }

    deleteFingerPrint(fpId: string): Observable<IResponseLower<any>> {
        return this.http.getLower(`${this.configService.appConfigs.apiUrl}/fingerPrint/deleteCredential?fpId=${fpId}`) as any;
    }
}
