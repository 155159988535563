<div class="max-account-dashboard">
    <h2>יתרה בm_a_x עצמאיים</h2>
    <div class="info-wrapper">
        <div class="bussiness-balance">
            <h3>יתרה בעסק שלך</h3>
            <span class="sum"> <span class="shekel">₪</span>{{ maxAccountRes?.result?.otb | number: '1.2-2' }} <span id="home-page-max-balance-tooltip" class="tool-tip-wrapper">
                <span class="question-tip"
                      appTooltip
                      tooltipText="סכום זה זמין כמסגרת נוספת לשימוש בכרטיס האשראי
                      או למשיכה מידיית. לא כולל עסקאות תשלומים ומטבע חוץ (מט''ח)"
                      tooltipHtmlParent="home-page-max-balance-tooltip"></span>
            </span></span>
            
            <span class="sum-alert">סכום זה כולל הכנסות נכון ליום העסקים הקודם</span>
            <a [routerLink]="['/max-account/personal/dashboard']" title="" class="link arrow-link more-details">לפרטים נוספים </a>
            <div class="open-deals-status" [ngSwitch]="maxAccountRes?.result?.numOfWaitingRequests">
                <span class="open-deals-alert"
                      *ngSwitchCase="
                      !maxAccountRes?.result?.numOfWaitingRequests || maxAccountRes?.result?.numOfWaitingRequests < 1
                    "
                    >אין לך עסקאות פתוחות</span
                >
                <span class="open-deals-alert" *ngSwitchCase="1">יש לך עסקה אחת פתוחה</span>
                <span class="open-deals-alert" *ngSwitchDefault>יש לך {{ maxAccountRes?.result?.numOfWaitingRequests }} עסקאות פתוחות</span>
                <div class="open-deals-wrapper"
                     *ngIf="
                     !!maxAccountRes?.result?.paymentRequests && maxAccountRes?.result?.paymentRequests?.length>
                    0
                    "
                    >
                    <div class="title-row">
                        <div>ת.עסקה</div>
                        <div>ממי התשלום?</div>
                        <div>סכום</div>
                    </div>

                    <div class="deal-row" *ngFor="let paymentRequest of waitingPaymentRequests | slice: 0:2">
                        <div class="deal-info">
                            <span class="name">{{ paymentRequest.customerName }}</span>
                            <span class="date">
                                <span class="date-title">ת.עסקה</span>
                                {{ paymentRequest.transactionDate | date: 'dd.MM.yyyy' }}
                            </span>
                        </div>
                        <div class="sum-wrapper">
                            <span class="deal-sum">₪{{ paymentRequest.transactionAmount | number: '1.2-2' }}</span>
                            <a (click)="
                                    gtmService.pushMaxAccount(
                                        'Card account - Did not pay me',
                                        'Send payment remember',
                                        'Click'
                                    );
                                    sendReminder(paymentRequest)
                                "
                               class="link arrow-link"
                               title="">לשליחת תזכורת</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="send-new-request">
            <img src="/assets/images/max-account/bill-icon.svg" class="bill-icon" alt="" />
            <div>
                <h4>שליחת בקשת תשלום חדשה</h4>
                <a (click)="getChoosePayDeepLink()" class="link arrow-link" title=""
                >מילוי ושליחה בקליק</a
                >
            </div>
        </div>
    </div>
</div>
