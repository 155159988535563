<div *ngIf="content" class="markting-banners">
    <div class="max-application banner-encapsulation">
        <h2 [innerHtml]="content.appTitle | sanitizeHtml"></h2>
        <h3 [innerHtml]="(unTypedContent.appSubTitle || '') | sanitizeHtml"></h3>
        <div class="inside-contents">
            <div class="score-counter">
                <div class="score" (ngInit)="scoreNgInit()">
                    <lottie-animation-view [options]="lottieConfig" width="87" height="180"
                                           (animCreated)="animCreated($event)">
                    </lottie-animation-view>
                </div>
                <img [src]="content.appImage" alt="" class="mobile-pssword-desktop" />
            </div>
            <a href="https://maxapp.onelink.me/2187048928/ihmlynbq" class="home-button" title="להורדת האפליקציה" id="app-download" (click)="onAppClick()">להורדת האפליקציה</a>
            <div class="best-app">
                <h3 class="you-score">{{ content.appText }}</h3>
                <span class="avarge-store">{{ content.appAsterisks }}</span>
            </div>
        </div>
        <img [src]="content.appImage" alt="" class="mobile-pssword" />
    </div>

    <div class="here-for-you banner-encapsulation">
        <img [src]="content.contactUsImage" alt="" class="max-speaker" />
        <h2 [innerHtml]="content.contactUsTitle | sanitizeHtml"></h2>
        <h3 [innerHtml]="content.contactUsText | sanitizeHtml"></h3>
        <a class="home-button" title="" id="contact-us" (click)="onContactUsClick()">{{ content.contactUsButton.text }}</a>
    </div>
</div>