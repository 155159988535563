import { ComponentBase } from './control-base';

export class ButtonComponent extends ComponentBase<string> {
  controlType = 'button';
  type: string;
  text: string;

  constructor(options: {}) {
    super(options);
    this.type = options['type'] || '';
    this.text = options['text'] || '';
  }
}
