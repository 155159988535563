import { Component, OnInit, HostListener, OnDestroy, Inject, Input } from '@angular/core';
import { MainLayoutService } from '../../services/main-layout.service';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { isNullOrUndefined } from 'core-max-lib';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { IHomePageHeaderResult } from '../../../homepage/models/homepage-header';
import { Subscription, SubscriptionLike } from 'rxjs';
import { LoginService } from '../../../../modules/login/login.service';
import { Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'app-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                // :enter is alias to 'void => *'
                style({ opacity: 0 }),
                animate(200, style({ opacity: 1 }))
            ]),
            transition(':leave', [
                // :leave is alias to '* => void'
                animate(200, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ActionsComponent implements OnInit, OnDestroy {
    @Input()
    isMobileLandscape: boolean;
    actions: any;
    showActionsNum: number;
    allActions: any;
    showAllActionsModal = false;
    isNavOpen: boolean;
    private subs: Subscription[] = [];
    locationSubscription: SubscriptionLike;

    constructor(
        private mainLayoutService: MainLayoutService,
        public authService: AuthService,
        private uiHelper: UiHelperService,
        private loginSvc: LoginService,
        private router: Router,
        @Inject(DOCUMENT) private document: any,
        private appConfigService: AppConfigsService,
        private location: Location
    ) {
        this.actions = {};
        this.allActions = {};
    }

    ngOnInit() {
        this.subs.push(
            this.mainLayoutService.isAllActionsOpen.subscribe(isAllActionsOpen =>
                this.insideToggleAllActions(isAllActionsOpen)
            )
        );
        this.subs.push(
            this.mainLayoutService.isNavOpen.subscribe(isNavOpen => {
                this.isNavOpen = isNavOpen;
                if (isNavOpen && this.showAllActionsModal) {
                    this.toggleAllActions();
                }
            })
        );
        this.mainLayoutService.loadActions().then((res: IHomePageHeaderResult) => {
            this.actions = res;
            this.allActions = this.actions.AllActions;
            this.showActionsNum = this.uiHelper.IsMobile(768) || this.isMobileLandscape ? 3 : this.actions.CommonActions.length;
        });
    }

    ngOnDestroy() {
        this.subs.forEach(sub => sub.unsubscribe());
        this.showAllActionsModal = false;
        if (this.uiHelper.isBrowser) {
            if (this.uiHelper.IsMobile(768) || this.isMobileLandscape) {
                document.querySelector('body').classList.remove('overflow');
                document.querySelector('html').classList.remove('overflow');
                if (!isNullOrUndefined(this.locationSubscription)) {
                    this.locationSubscription.unsubscribe();
                }
            } else {
                this.mainLayoutService.changeAllActionsMode(false);
                document.querySelector('.homepage .pop-up-back').classList.remove('show-back-all-actions');
            }
        }
    }

    getActionLink(action: any) {
        if (!this.authService.isUserAuthenticated() && !isNullOrUndefined(action.Link) && action.Link !== '') {
            return action.Link;
        }
        return action.RegLink;
    }

    getActionLinkRoles(action: any) {
        if (!this.authService.isUserAuthenticated() && !isNullOrUndefined(action.Link) && action.Link !== '') {
            return action.LinkRoles;
        }
        return action.RegLinkRoles;
    }

    onAllActionsClick() {
        this.mainLayoutService.changeAllActionsMode(!this.showAllActionsModal);
    }

    onBackButtonClick() {
        //history.forward();
        this.mainLayoutService.changeAllActionsMode(false);
        return false;
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.showAllActionsModal && !document.querySelector('li.all-actions').contains(event.target)) {
            this.mainLayoutService.changeAllActionsMode(false);
        }
    }

    toggleAllActions() {
        this.mainLayoutService.changeAllActionsMode(!this.showAllActionsModal);
    }

    private insideToggleAllActions(isAllActionsOpen: boolean) {
        if (this.showAllActionsModal !== isAllActionsOpen) {
            this.showAllActionsModal = isAllActionsOpen;
            if (this.uiHelper.isBrowser) {
                if (this.uiHelper.IsMobile(768) || this.isMobileLandscape) {
                    document.querySelector('body').classList.toggle('overflow');
                    document.querySelector('html').classList.toggle('overflow');
                    window.scroll(0, 0);
                    if (this.showAllActionsModal) {
                        history.pushState(null, null, location.href);
                        this.locationSubscription = this.location.subscribe(() => {
                            this.onBackButtonClick();
                        });
                    } else {
                        this.locationSubscription.unsubscribe();
                    }
                } else {
                    document.querySelector('.homepage .pop-up-back').classList.toggle('show-back-all-actions');
                }
            }
        }
    }
}
