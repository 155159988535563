import {
    Component,
    OnInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
    ViewEncapsulation
} from '@angular/core';
import { UiHelperService } from '../../../shared/cardholders-core/services/ui-helper.service';
import { Observable, interval, Subscription } from 'rxjs';
import { IResponse } from '../../../shared/cardholders-core/services/http-service.service';
import { HttpClient } from '@angular/common/http';
import { AppConfigsService } from '../../../config/app-configs.service';
import { MainLayoutService } from '../services/main-layout.service';
import { isNullOrUndefined } from 'core-max-lib';
import { AuthService } from '../../../shared/cardholders-core/services/auth.service';
import { Router } from '@angular/router';
import { IHomePageFooterResult } from "../../homepage/models/homepage-footer";
import { IBackgroundDetails } from "../../homepage/models/homepage-background";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit, OnDestroy {
    footerColumns: any;
    legalMessages: any;
    backgroundType: string = 'Default';
    backgroundDetails: IBackgroundDetails;

    showImportant: boolean = false;
    currLegalMessage: number = 0;
    companyLogoImage: string;
    socialNetworks = [
        { href: 'https://www.youtube.com/c/maxFinanceIL', class: 'youtube' },
        { href: 'https://www.linkedin.com/company/maxfinanceil', class: 'linkedin' },
        // { href: 'https://twitter.com/leumicard_il', class: 'twitter' },
        { href: 'https://www.facebook.com/max.finance.il', class: 'facebook' },
        { href: 'https://www.instagram.com/max.co.il/', class: 'instagram' }
    ];
    isMobile: boolean;


    constructor(
        private http$: HttpClient,
        public mainLayoutService: MainLayoutService,
        private appConfigsService: AppConfigsService,
        private uiHelperService: UiHelperService,
        private authService: AuthService,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {
        this.footerColumns = [];
        this.legalMessages = [];
        this.companyLogoImage = '/assets/images/homepage/logo-max-footer.svg';
        this.isMobile = this.uiHelperService.IsMobile(768);
    }

    ngOnInit() {
        this.mainLayoutService.loadFooter().then((res: IHomePageFooterResult) => {
            this.footerColumns = res.FooterColumns;
            this.backgroundType = res.Background.BackgroundType;
            this.backgroundDetails = res.Background;
            if (this.router.url.includes('/homepage') || this.router.url === '/') {
                this.legalMessages = res.LegalMessages;
            }
            this.detectChanges();
        });
        if (this.uiHelperService.isBrowser && this.appConfigsService.appConfigs.companyName.toLowerCase() === 'max') {
            document.querySelector('.footer-wrapper .logo-footer').classList.add('max-logo');
        }
    }

    detectChanges() {
        this.cd.detectChanges();
    }
    ngOnDestroy() {
        this.cd.detach();
    }

    getFooterItemLink(footerItem: any) {
        if (this.authService.isUserAuthenticated() && !isNullOrUndefined(footerItem.RegLink) && footerItem.RegLink !== '') {
            return footerItem.RegLink;
        }
        return footerItem.Link;
    }
}
