import { ComponentBase } from './control-base';

export class ITextComponent extends ComponentBase<string> {
  controlType = 'itext';
  text: string;
  textWithHtml: string;
  icon: string;

  constructor(options: {}) {
    super(options);
    this.text = options['text'] || '';
    this.textWithHtml = options['textWithHtml'] || '';
    this.icon = options['icon'] || '';
  }
}
