import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoggerHandlerService } from './logger.handler.service';

export enum PopupTypes {
    stopJoinFormPopup,
    sessionTimeoutPopup,
    stopJoinFormSuccessPopup,
    exitTrackerPopup,
    stopUploadFilesPopup,
    fairCreditLawPopup,
    digitalSignedPopup,
    fairCreditProductsPopup,
    customerloanPopup,
    customerLoanCallMeBackPopup,
    monthlyBillingPopup,
    newDeliveryPopup,
    spreadTransactionCreditDataPopup,
    creditDataContactUs,
    creditDataGenericPopup
}

@Injectable()
export class PopupsService {

    popupSubject: Subject<{ popupType: PopupTypes, data: any, show: boolean }> = new Subject();

    stopJoinFormPopupNavigateAwaySelection: Subject<boolean> = new Subject<boolean>();
    sessionTimeoutPopupNavigateAwaySelection: Subject<boolean> = new Subject<boolean>();
    exitTrackerPopupNavigateAwaySelection: Subject<boolean> = new Subject<boolean>();
    stopUploadFilesPopupNavigateAwaySelection: Subject<boolean> = new Subject<boolean>();
    fairCreditLawPopupNavigateAwaySelection: Subject<boolean> = new Subject<boolean>();
    digitalSignedPopupNavigateAwaySelection: Subject<boolean> = new Subject<boolean>();
    fairCreditProductsPopupNavigateAwaySelection: Subject<boolean> = new Subject<boolean>();
    customerloanPopupPopupNavigateAwaySelection: Subject<number> = new Subject<number>();
    customerLoanCallMeBackPopupNavigateAwaySelection: Subject<string> = new Subject<string>();
    monthlyBillingPopupPopupNavigateAwaySelection: Subject<number> = new Subject<number>();
    spreadTransactionCreditDataPopupNavigateAwaySelection: Subject<number> = new Subject<number>();
    newDeliveryPopupNavigateAwaySelection: Subject<boolean> = new Subject<boolean>();
    creditDataGenericPopupNavigateAwaySelection: Subject<number> = new Subject<number>();

    constructor(private loggerHandler: LoggerHandlerService) {

    }

    openPopup(popupType: PopupTypes, data?: any) {
        this.loggerHandler.log.next(`Dialog [${PopupTypes[popupType].toString()}] was shown`);
        this.popupSubject.next({ popupType, data, show: true });
    }

    closePopup(popupType: PopupTypes, data?: any) {
        this.loggerHandler.log.next(`Dialog [${PopupTypes[popupType].toString()}] has closed`);
        this.popupSubject.next({ popupType, data, show: false });
    }
}
