import { Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RoutingHistoryHandlerService } from './routing-history.handler.service';

@Injectable()
export class RoutingHistoryService {
    private archive: string[] = [];
    private _isFirstPage: boolean = true;

    constructor(
        private routingHistoryHandler: RoutingHistoryHandlerService
    ) {
        this.setSubscribtions();
    }

    private setSubscribtions(): void {
        this.routingHistoryHandler.store.subscribe((url: string) => {
            this.archive.push(url);
        });
    }

    public get history(): string[] {
        return this.archive ? this.archive : null;
    }

    public get previousUrl(): string {
        return this.archive && this.archive[this.archive.length - 1] ? this.archive[this.archive.length - 1] : null;
    }

    public get isFirstPage(): boolean {
        if (this._isFirstPage && this.archive && this.archive.length >= 1) {
            this._isFirstPage = false;
        }
        return this._isFirstPage;
    }
}
