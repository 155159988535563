import { Component, OnInit } from '@angular/core';
import { HomepageService } from "../../services/homepage.service";
import { UiHelperService } from "../../../../shared/cardholders-core/services/ui-helper.service";
import { AuthService } from "../../../../shared/cardholders-core/services/auth.service";
import { IResponse } from "../../../../shared/cardholders-core/services/http-service.service";
import { ILoginInfo } from "../../models/login-info";
import { isNullOrUndefined } from 'core-max-lib';

@Component({
    selector: 'app-hello',
    templateUrl: './hello.component.html',
    styleUrls: ['./hello.component.scss']
})
export class HelloComponent implements OnInit {

    public show: boolean;
    public name: string;
    public lastLoginDate: string;

    constructor(private homepageSvc: HomepageService, private uiHelper: UiHelperService, private authSvc: AuthService) { }

    ngOnInit() {
        if (!this.uiHelper.isBrowser || !this.authSvc.isUserAuthenticated()) {
            this.show = false;
        } else {
            this.homepageSvc.getLoginInfo().subscribe((res: IResponse<ILoginInfo>) => {
                if (res.ReturnCode === 0) {
                    this.show = !isNullOrUndefined(res.Result.LastLoginDate);
                    this.name = res.Result.Name;
                    this.lastLoginDate = res.Result.LastLoginDate;
                }
            });
        }

    }

}
