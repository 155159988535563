import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import {ICard} from "../../shared/models/cards/card";


@Injectable()
export class CompareService {
   // private isBrowser: boolean;
    //constructor( @Inject(PLATFORM_ID) private platformId: any) {

    //    this.isBrowser = isPlatformBrowser(platformId);
    //}
    public cards: ICard[] = [];
    getCards() {
        return this.cards;
    }
    setCards(newCards) {
        this.cards = newCards;
    }
}
