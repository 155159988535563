import { IModalConfig } from '../../../../shared/modules/bs-modal/interfaces/modal-config.interface';
import { MarketingPopupComponent } from './marketing-popup.component';

export const MarketingModal: IModalConfig = {
    content: MarketingPopupComponent,
    options: {
        class: 'marketing-modal',
        initialState: {
            transactions: [],
            successCount: 0,
            total: 0,
        } as Object,
        backdrop: 'static'
    },
    type: 'MarketingModal'
};
