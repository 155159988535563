import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AuthHandlerService } from '../services/auth-handler.service';


@Directive({
    selector: '[appLinkRoles]'
})
export class AppLinkRolesDirective {
    @Input()
    roles: string[];
    @Input()
    noRolesReturnUrl: string;

    constructor(private el: ElementRef, private authSvc: AuthService, private authHandlerService: AuthHandlerService) {}

    @HostListener('click', ['$event'])
    click(event: Event): void {
        if (this.roles && !this.authSvc.isUserMatchRolesNames(this.roles)) {
            event.stopPropagation();
            event.preventDefault();
            this.authHandlerService.notAuthorizedSubject.next(this.noRolesReturnUrl);
        }
    }
}
