import { Component, ElementRef, OnInit } from '@angular/core';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { HomepageService } from '../../../homepage/services/homepage.service';
import { ServicesMobile } from '../../interfaces/homepage-content.interface';

@Component({
    selector: 'app-here-for-you-mobile',
    templateUrl: './here-for-you-mobile.component.html',
    styleUrls: ['./here-for-you-mobile.component.scss']
})
export class HereForYouMobileComponent implements OnInit {
    get content(): ServicesMobile {
        return this.hpService.homepageContent?.servicesMobile;
    }

    constructor(private hpService: HomepageService, private elRef: ElementRef, private gtmService: GtmService) {}

    ngOnInit(): void {}

    onElementInit(): void {
        const observer: IntersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('movement');
                } else {
                    entry.target.classList.remove('movement');
                }
            });
        });

        const actions = this.elRef.nativeElement.querySelectorAll('.banner');
        actions.forEach((banner) => {
            observer.observe(banner);
        });
    }

    onClick(): void {
        this.hpService.navigate(this.content.contactUsButton.url);
        this.gtmService.pushNewWebsiteAnonymous('New - Contact us', 'Click');
    }
}
