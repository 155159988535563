import { Directive, ElementRef, Input, OnInit, Inject, HostListener, OnChanges, SimpleChanges, Renderer2, Optional } from '@angular/core';
import { isNullOrUndefined } from 'core-max-lib';
import { UiHelperService } from '../services/ui-helper.service';
import { Router, UrlTree, DefaultUrlSerializer, Params } from '@angular/router';
import { LoggerHandlerService } from '../services/logger.handler.service';

@Directive({
    selector: '[appLink]'
})
export class AppLinkDirective implements OnInit, OnChanges {
    @Input() anchorLink: string;
    @Input() isOpenLinkInNewTab: boolean;
    @Input() isPreventDefaultForRouterLink: boolean;

    private defaultUrlSerializer: DefaultUrlSerializer;
    private linkForRouterLink: string = undefined;
    private params: Params = {};
    private originUrl;

    constructor(@Optional() @Inject('serverUrl') private serverUrl: string,
        private el: ElementRef,
        private renderer: Renderer2,
        private uiHelper: UiHelperService,
        private router: Router,
        private loggerHandler: LoggerHandlerService
    ) {
        this.defaultUrlSerializer = new DefaultUrlSerializer;
        this.originUrl = this.uiHelper.isBrowser ? window.location.origin : this.serverUrl;
    }

    ngOnInit() {
        if (this.uiHelper.isBrowser) {
            this.setLink();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.uiHelper.isBrowser) {
            this.setLink();
        }
    }

    public setLink() {
        if (isNullOrUndefined(this.anchorLink)) {
            return;
        }
        try {
            if (this.isOpenLinkInNewTab) {
                this.renderer.setAttribute(this.el.nativeElement, 'href', this.anchorLink);
            } else if (this.anchorLink[0] === '/') {
                this.linkForRouterLink = this.anchorLink.split('?')[0];
                this.params = this.defaultUrlSerializer.parse(this.anchorLink).queryParams;
            } else {
                let fullDomain = this.originUrl.split('/')[2];
                let baseUrl = this.anchorLink.split('/')[2];
                let relUrl = this.anchorLink.split(baseUrl)[1];
                if (!isNullOrUndefined(baseUrl) &&
                    !isNullOrUndefined(relUrl) &&
                    relUrl.toLowerCase().indexOf('/he-il/') < 0 &&
                    !isNullOrUndefined(fullDomain) &&
                    baseUrl.toLowerCase().indexOf(fullDomain.toLowerCase()) > -1) {
                    this.linkForRouterLink = relUrl.split('?')[0];
                    this.params = this.defaultUrlSerializer.parse(relUrl).queryParams;
                } else {
                    this.linkForRouterLink = undefined;
                    this.params = {};
                    this.renderer.setAttribute(this.el.nativeElement, 'href', this.anchorLink);
                }
            }
        } catch (e) {
            this.loggerHandler.error.next(
                `link from umbraco: ${this.anchorLink} fails to parse due to ${e.toString()}`);
        }
    }

    @HostListener('click', ['$event']) click(event) {
        if (this.uiHelper.isBrowser && this.linkForRouterLink !== undefined) {
            this.router.navigate([this.linkForRouterLink], { queryParams: this.params });
            if (this.isPreventDefaultForRouterLink) {
                event.preventDefault();
            }
        }
    }
}
