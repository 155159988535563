import { ComponentBase } from './control-base';

export class UploadComponent extends ComponentBase<string> {
  controlType = 'uploadPhoto';
  text: string;
  title: string;
  photoType: string;

  constructor(config: {}) {
    super(config);
    this.text = config['text'] || '';
    this.title = config['title'] || '';
    this.photoType = config['photoType'] || '';
  }
}
