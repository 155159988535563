import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { ISavingEligibilityResponse } from '../../../saving/interfaces/ISavingEligibilityResponse.interface';
import { InsuranceService } from '../../services/insurance.service';

@Component({
    selector: 'app-policy',
    templateUrl: './policy.component.html',
    styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit, OnDestroy {
    newFlow = false;
    subs: Subscription[] = [];
    savingEligibilityResponse: ISavingEligibilityResponse;
    mySavingSildeConfig = {
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        swipeSpeed: 200,
        autoplay: false,
        mobileFirst: true,
        rtl: true,
        responsive: [
            {
                breakpoint: 767,
                settings: 'unslick'
            }
        ]
    };
    constructor(private appConfig: AppConfigsService, private insuranceHPSrv: InsuranceService) {
        this.newFlow = this.appConfig.appConfigs.FLAG_ISDisplayDashboardInsurance;
    }
    ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe());
    }

    ngOnInit() {
        if (this.newFlow) {
            this.subs.push(
                this.insuranceHPSrv.checkEligibility().subscribe(x => {
                    switch (x.returnCode) {
                        case 0:
                        case 10:
                        case 30:
                            this.savingEligibilityResponse = x.result;

                            break;

                        default:
                            break;
                    }
                })
            );
        }
    }
}
