import { InjectionToken } from '@angular/core';

export const appConsts = {
  api: '/api'
};

export let isInLcLan = new InjectionToken<boolean>('isInLcLan');
export let isApprovedIpForRecaptcha = new InjectionToken<boolean>('isApprovedIpForRecaptcha');
export let version = new InjectionToken<string>('version');
export let isUserAuthenticated = new InjectionToken<boolean>(
  'isUserAuthenticated'
);
export let roles = new InjectionToken<string[]>('roles');
