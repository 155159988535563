import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardholdersCoreModule } from '../../shared/cardholders-core/cardholders-core.module';
import { PreviewComponent } from './preview.component';
import { PreviewService } from './preview.service';

@NgModule({
    imports: [CommonModule, CardholdersCoreModule],
    declarations: [PreviewComponent],
    exports: [PreviewComponent],
    providers: [PreviewService]

})
export class PreviewModule { }
