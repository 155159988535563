import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { AppConfigsService } from '../../../config/app-configs.service';
import { CookieService } from '../../../shared/cardholders-core/services/cookie.service';
import { HttpService, IResponseLower } from '../../../shared/cardholders-core/services/http-service.service';
import { IMarketingAgreementContent } from '../interfaces/marketing-agreement-content.interface';
import { IMarketingAgreementData } from '../interfaces/marketing-agreement-data.interface';
import { IUpdateMarketingAgreementRequest } from '../interfaces/update-marketing-agreement-request.interface';

@Injectable()
export class MarketingAgreementService {
    baseApi = '';
    showLoader: EventEmitter<boolean> = new EventEmitter<boolean>();
    mode: string;
    contents: IMarketingAgreementContent;
    data: IMarketingAgreementData;
    cookie: string;
    showingCounter: number;

    constructor(private httpService: HttpService, private appConfigsService: AppConfigsService, private cookieService: CookieService) {
        this.baseApi = `${this.appConfigsService.appConfigs.apiUrl}/registered/marketingAgreement`;
    }

    needToShowMarketingAgreementPopup(): Observable<IResponseLower<boolean>> {
        this.showLoader.emit(true);
        return this.httpService.postLower<boolean>(`${this.baseApi}/needToShowMarketingAgreementPopup`, null).pipe(
            tap((x) => {
                this.showLoader.emit(false);

            })
        );
    }

    getContents(): Observable<IResponseLower<IMarketingAgreementContent>> {
        this.showLoader.emit(true);
        return this.httpService.getLower<IMarketingAgreementContent>(`${this.baseApi}/getContent`).pipe(
            tap((x) => {
                this.showLoader.emit(false);
                if (x.result) {
                    this.contents = x.result;
                }
            })
        );

    }

    getMarketingAgreementData(): Observable<IResponseLower<IMarketingAgreementData>> {
        return this.httpService.postLower<IMarketingAgreementData>(`${this.baseApi}/getMarketingAgreementData`, null).pipe(
            tap((x) => {
                if (x.result) {
                    this.data = x.result;
                }
            })
        );
    }

    updateMarketingSignUp(request: IUpdateMarketingAgreementRequest): Observable<IResponseLower<any>> {
        this.showLoader.emit(true);
        return this.httpService.postLower<any>(`${this.baseApi}/updateMarketingSignUp`, request).pipe(
            tap((x) => {
                this.showLoader.emit(false);
            })
        );
    }

    needToShowMarketingAgreementPopupByCookie() {
        this.cookie = this.cookieService.get('MarketingAgreementPopupCookie_V2');
        if (!this.cookie) { // cookie is not exist
            this.createNewCookie();
            return true;
        } else {
            return false;
        }
    }
    createNewCookie() {
        const expirationDate = new Date();
        const hoursToCookieExpire = 48;
        expirationDate.setTime(expirationDate.getTime() + (hoursToCookieExpire * 60 * 60 * 1000));
        this.cookieService.set('MarketingAgreementPopupCookie_V2',
            null,
            expirationDate,
            "/",
            window.location.origin.split('.')[1] + '.co.il',
            null,
            null);
    }
}
