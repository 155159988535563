import { Injectable, Inject, Optional } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IResponse, HttpService, IEmptyResult } from './http-service.service';
import { ILoginResult, LoginStatus } from '../models/login-result';
import { AppConfigsService } from '../../../config/app-configs.service';
import { IBankAccessResult } from '../models/bank-access-result.interface';
import { ICommboxLoginResult } from '../models/commbox-access-result.interface';
import { InitializerService, UiHelperService } from 'core-max-lib';
import { TrusteerSnippetsService } from './trusteer-snippets.service';
import { SessionIdService } from './sessionId.service';
import { OpenBankingService } from '../../../modules/open-banking/open-banking.service';
import { CookieService } from './cookie.service';

export enum Roles {
    ALL,
    GALI,
    REG,
    STT,
    IVR,
    SENSITIVEIVR,
    WS,
    ADMIN,
    NONE,
    SL,
    BUSINESS,
    INSTANT_ISSUE_BANK,
    OPEN_BANKING_CONSENT,
    CONTACT_AUTHORIZATION,
    COMMBOX,
    MAXBACKCLIENT
}

/// singleton class per all app
@Injectable()
export class AuthService {
    onAuthenticationChangeSubject: Subject<boolean> = new Subject();
    isBrowser: boolean;
    private isDirty = false;
    private isUserLoggedIn = false;
    private rolesLoggedIn: string[];
    private isUserAuthenticatedInject;
    private rolesToken;
    originUrl;
    isBankSso: boolean;
    isMobileSso: boolean;

    constructor(
        private httpSvc: HttpService,
        private appConfigsService: AppConfigsService,
        private initializer: InitializerService,
        private uiHelper: UiHelperService,
        @Optional() @Inject('serverUrl') private serverUrl: string,
        private trusteerSnippetsService: TrusteerSnippetsService,
        private sessionIdService: SessionIdService,
        private openBankingService: OpenBankingService,
        private cookieService: CookieService
    ) {
        this.isUserAuthenticatedInject = initializer.getClientContext().IsAuthenticated;
        this.rolesToken = initializer.getClientContext().Roles;
        this.originUrl = this.uiHelper.isBrowser ? window.location.origin : this.serverUrl;
        this.isBrowser = this.uiHelper.isBrowser;
    }

    hasEverLoggedIn(): boolean {
        if (this.isBrowser) {
            if (localStorage.getItem('hasEverLoggedIn')) {
                return true;
            }
        }
        return false;
    }

    isUserAuthenticated(): boolean {
        return this.isDirty ? this.isUserLoggedIn : this.isUserAuthenticatedInject;
    }

    // if you made any changes to this function do the same for isUserInRoleName function.
    isUserInRole(role: Roles): boolean {
        return this.isDirty && this.rolesLoggedIn !== undefined
            ? this.rolesLoggedIn.includes(Roles[role])
            : this.rolesToken
            ? this.rolesToken.includes(Roles[role])
            : false;
    }

    isUserMatchRoles(userRoles: Roles[]): boolean {
        for (const userRole of userRoles) {
            if (this.isUserInRole(userRole)) {
                return true;
            }
        }
        return false;
    }

    // if you made any changes to this function do the same for isUserInRole function.
    isUserInRoleName(role: string): boolean {
        return this.isDirty && this.rolesLoggedIn !== undefined
            ? this.rolesLoggedIn.includes(role)
            : this.rolesToken
            ? this.rolesToken.includes(role)
            : false;
    }

    isUserMatchRolesNames(rolesNames: string[]): boolean {
        for (const role of rolesNames) {
            if (this.isUserInRoleName(role)) {
                return true;
            }
        }
        return false;
    }

    isAdmin(): boolean {
        return this.isUserInRole(Roles.ADMIN);
    }

    isIvr(): boolean {
        return this.isUserInRole(Roles.IVR);
    }

    isSensitiveIvr(): boolean {
        return this.isUserInRole(Roles.SENSITIVEIVR);
    }

    isInstantIssueBank(): boolean {
        return this.isUserInRole(Roles.INSTANT_ISSUE_BANK);
    }

    isGali(): boolean {
        return this.isUserInRole(Roles.GALI);
    }

    isContactAuthorization(): boolean {
        return this.isUserInRole(Roles.CONTACT_AUTHORIZATION);
    }

    markUserHasAuthenticated(rolesArray: string[]): void {
        this.isDirty = true;
        this.isUserLoggedIn = true;
        this.rolesLoggedIn = rolesArray;
        this.onAuthenticationChangeSubject.next(true);
        localStorage.setItem('hasEverLoggedIn', 'true');
        //  this.trusteerSnippetsService.loadCallbackScripts(true);
    }

    markUserHasAnonymous(): void {
        this.isDirty = true;
        this.isUserLoggedIn = false;
        this.rolesLoggedIn = undefined;
        this.rolesToken = undefined;
        this.isBankSso = undefined;
        this.onAuthenticationChangeSubject.next(false);
        // this.trusteerSnippetsService.loadCallbackScripts(false);
    }

    login(username: string, password: string, idNumber: string): Observable<IResponse<ILoginResult>> {
        let method = this.openBankingService.isOpenBankingLoginMode() ? 'openBanking' : '';
        return new Observable(obs => {
            this.httpSvc
                .post(`${this.appConfigsService.appConfigs.apiUrl}/login/${method}login`, {
                    username: username,
                    password: password,
                    id: idNumber
                })
                .subscribe((res: IResponse<ILoginResult>) => {
                    this.handleLogin(res.Result);
                    obs.next(res);
                });
        });
    }

    loginWebview(): Observable<IResponse<ILoginResult>> {
        return new Observable(obs => {
            this.httpSvc
                .post(`${this.appConfigsService.appConfigs.apiUrl}/login/webviewpoc`, {})
                .subscribe((res: IResponse<ILoginResult>) => {
                    this.handleLogin(res.Result);
                    obs.next(res);
                });
        });
    }

    loginOtpVerify(
        id: string,
        card8Digits: string,
        verifyCode: string,
        accountNumber: string,
        isTypeIsCard8Digits: boolean
    ): Observable<IResponse<ILoginResult>> {
        let method = this.openBankingService.isOpenBankingLoginMode() ? 'openBanking' : '';
        return new Observable(obs => {
            this.httpSvc
                .post(`${this.appConfigsService.appConfigs.apiUrl}/login/${method}otpLoginVerify`, {
                    // tslint:disable-next-line:radix
                    id: Number.parseInt(id),
                    card8Digits: card8Digits,
                    verifyCode: verifyCode,
                    accountNumber: accountNumber,
                    isTypeIsCard8Digits: isTypeIsCard8Digits
                })
                .subscribe((res: IResponse<ILoginResult>) => {
                    this.handleLogin(res.Result);
                    obs.next(res);
                });
        });
    }

    biometricLoginVerify(): Observable<IResponse<ILoginResult>> {
        return new Observable(obs => {
            this.httpSvc
                .post(`${this.appConfigsService.appConfigs.apiUrl}/login/biometricLoginVerify`)
                .subscribe((res: IResponse<ILoginResult>) => {
                    this.handleLogin(res.Result);
                    obs.next(res);
                });
        });
    }

    bankAccessLogin(guid: string): Observable<IResponse<IBankAccessResult>> {
        return new Observable(obs => {
            this.httpSvc
                .post(`${this.appConfigsService.appConfigs.apiUrl}/login/bankAccessLogin`, { guid: guid })
                .subscribe((res: IResponse<IBankAccessResult>) => {
                    this.handleLogin(res.Result.LoginResult);
                    obs.next(res);
                });
        });
    }

    commboxLogin(guid: string): Observable<IResponse<ICommboxLoginResult>> {
        return new Observable(obs => {
            this.httpSvc
                .post(`${this.appConfigsService.appConfigs.apiUrl}/login/commboxSsoLogin`, { guid: guid })
                .subscribe((res: IResponse<ICommboxLoginResult>) => {
                    this.handleLogin(res.Result.LoginResult);
                    obs.next(res);
                });
        });
    }

    logoff(): Observable<any> {
        return new Observable(obs => {
            if (this.isUserAuthenticated()) {
                this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/login/logoff`, {}).subscribe(() => {
                    this.sessionIdService.refreshSessionId();
                    this.trusteerSnippetsService.executeTagmastersEvent('login');
                    this.handleLogoff();
                    if (this.cookieService.check('fromTraklinDan')) {
                        this.cookieService.delete(
                            'fromTraklinDan',
                            null,
                            window.location.origin.split('.')[1] + '.co.il'
                        );
                    }
                    obs.next();
                });
            } else {
                obs.next();
            }
        });
    }

    private handleLogin(loginResult: ILoginResult): void {
        if (
            loginResult.LoginStatus === LoginStatus.success ||
            loginResult.LoginStatus == LoginStatus.NeedToShowInsurance ||
            loginResult.LoginStatus == LoginStatus.CreditLawAgreementAvailable
        ) {
            this.markUserHasAuthenticated(loginResult.Roles);
        } else if (this.openBankingService.isOpenBankingLoginMode() && loginResult.LoginStatus == LoginStatus.falied) {
            this.markUserHasAuthenticated(loginResult.Roles);
        }
    }

    private handleLogoff(): void {
        this.markUserHasAnonymous();
    }

    totalMaxBackLogin(): Observable<IResponse<ILoginResult>> {
        return new Observable(obs => {
            this.httpSvc
                .post(`${this.appConfigsService.appConfigs.apiUrl}/login/convertMaxBackToTotalLogin`)
                .subscribe((res: IResponse<ILoginResult>) => {
                    this.handleLogin(res.Result);
                    obs.next(res);
                });
        });
    }
}
