import { ValidatorFn, FormControl, AbstractControl } from "@angular/forms";
import { isNullOrUndefined } from 'core-max-lib';

export function BirthDateValidator(control: AbstractControl) {
    if (control.value === "" || isNullOrUndefined(control.value))
        return null;

    const valArr = control.value ? control.value.split('/') : '';
    const day = parseInt(valArr[0]);
    const month = parseInt(valArr[1]);
    const year = parseInt(valArr[2]);
    const date = new Date();
    date.setFullYear(year, month - 1, day);


    let isValid = !isNaN(date.getTime()) && year >= 1900 && ((date.getFullYear() == year) && (date.getMonth() == month - 1) && (date.getDate() == day));

    if (!isValid)
        return { invalidDate: true };

    return null;
}
