import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IErrorScreenParams, IErrorButtonLink, ErrorLinkType } from '../login-messages.consts';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { isNullOrUndefined } from 'core-max-lib';
import { Router } from '@angular/router';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';

@Component({
  selector: 'app-error-screen',
  templateUrl: './error-screen.component.html',
  styleUrls: ['./error-screen.component.scss']
})
export class ErrorScreenComponent implements OnInit {

  @Input() params: IErrorScreenParams = {
    text: '',
    title: '',
    button: {}
  };

  isMobile: boolean;
  @Output()
  onButtonForTryAgainClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(private uiHelper: UiHelperService,
      private appConfigSvc: AppConfigsService,
      private router: Router,
      private modalSvc: ModalService) {
      this.isMobile = uiHelper.IsMobile();
  }

    ngOnInit() {
        if (this.appConfigSvc.appConfigs.FLAG_ISUsingNewAuthRecoverPageOnlineLC &&
            !isNullOrUndefined(this.params.button.desktopLink) &&
            this.params.button.desktopLink.text === 'לשחרור מנעילה') {
            this.params.button.desktopLink.url =
                this.params.button.desktopLink.url.replace('/Anonymous/RecoverPassword.aspx', '/auth/recover');
            this.params.button.desktopLink.type = ErrorLinkType.Route;
        }
  }

  getButtonText() {
    if (!this.isMobile) {
      return this.params.button.desktopLink.text;
    }
    return (this.params.button.mobileLink || this.params.button.desktopLink).text;
  }

  getButtonHref() {
    if (!this.isMobile) {
      return this.getUrlFromParam(this.params.button.desktopLink);
    }
    return this.getUrlFromParam(this.params.button.mobileLink || this.params.button.desktopLink);
  }

  getUrlFromParam(linkParams: IErrorButtonLink) {
    switch (linkParams.type) {
        case ErrorLinkType.Full:
        case ErrorLinkType.Route:
        return linkParams.url;
      case ErrorLinkType.Info:
        return this.appConfigSvc.appConfigs.infoRoot + linkParams.url;
    }
    }

    isButtonLinkRouteType() {
        if (!this.isMobile) {
            return this.params.button.desktopLink.type === ErrorLinkType.Route;
        }
        return (this.params.button.mobileLink || this.params.button.desktopLink).type === ErrorLinkType.Route;
  }

  isButtonVisible() {
    if (!this.isMobile) {
      return !isNullOrUndefined(this.params.button.desktopLink);
    }
    return !isNullOrUndefined(this.params.button.desktopLink) || !isNullOrUndefined(this.params.button.mobileLink);
    }

  navigateUrl() {
      this.router.navigateByUrl(this.getButtonHref());
      this.modalSvc.closeModal();
    }

  buttonForTryAgainClick() {
      this.onButtonForTryAgainClick.emit();
  }
}
