import { ComponentBase } from './control-base';

export class LabelComponent extends ComponentBase<string> {
  controlType = 'label';
  text: string;
  optionalText: string;
  icon: boolean;
  iconName: string;

  constructor(options: {}) {
    super(options);
    this.text = options['text'] || '';
    this.optionalText = options['optionalText'] || '';
    this.icon = options['icon'] || '';
    this.iconName = options['iconName'] || '';
  }
}
