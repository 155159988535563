import { Pipe, PipeTransform } from '@angular/core';
import { ICycleSummaryNew, ISummaryNew } from '../models/homepage-user-data';

@Pipe({
  name: 'cycleSummaryFilter'
})
export class CycleSummaryFilter implements PipeTransform {
  transform(items: ICycleSummaryNew[]): any[] {
    return items.filter(item => item.TotalDebitSum != 0)
  }
}

@Pipe({
  name: 'summaryFilter'
})
export class SummaryFilter implements PipeTransform {
  transform(items: ISummaryNew[]): any[] {
    return items.filter(item => item.TotalDebitSum != 0)
  }
}
