<div class="dynamic-popup">
  <div class="modal-header" *ngIf="data?.showCloseButton">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
        aria-hidden="true" (click)="close()">&times;</span></button>
  </div>
  <div class="modal-body">
    <img *ngIf="data?.img" [src]="data?.img" class="img" alt="" />

    <div class="title-wrapper">
      <span *ngIf="data?.title" class="title" [innerHTML]="data.title"> </span>
      <span *ngIf="data?.subTitle" class="sub-title" [innerHTML]="data.subTitle"></span>
    </div>

    <p *ngIf="data?.text" class="text" [innerHTML]="data.text"></p>

    <div class="button-wrapper">
      <a class="brand-button aqua-button" title="" (click)="action(1)"><span>{{data?.btnText}}</span></a>
      <a *ngIf="data?.linkText" class="link" title="" (click)="action(2)">{{data?.linkText}}</a>
    </div>
  </div>
</div>