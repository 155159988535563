import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardholdersCoreModule } from '../../shared/cardholders-core/cardholders-core.module';
import { BsModalModule } from '../../shared/modules/bs-modal/bs-modal.module';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserLoginFormComponent } from './login-popup/user-login-form/user-login-form.component';
import { OtpLoginFormComponent } from './login-popup/otp-login-form/otp-login-form.component';
import { ErrorScreenComponent } from './login-popup/error-screen/error-screen.component';
import { LoginPopupComponent } from './login-popup/login-popup.component';
import { ProtectedAnchorComponent } from './protected-anchor/protected-anchor.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SignInWithAppleComponent } from './login-popup/sign-in-with-apple/sign-in-with-apple.component';
import { GoogleFingerPrintComponent } from './login-popup/google-finger-print/google-finger-print.component';
import { LoginService } from './login.service';
import { BiometricComponent } from './biometric/biometric.component';
import { FingerprintResultComponent } from './login-popup/user-login-form/fingerprint-result/fingerprint-result.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Fido2Component } from './login-popup/fido2/fido2.component';

@NgModule({
    declarations: [
        UserLoginFormComponent,
        OtpLoginFormComponent,
        ErrorScreenComponent,
        LoginPopupComponent,
        ProtectedAnchorComponent,
        SignInWithAppleComponent,
        GoogleFingerPrintComponent,
        BiometricComponent,
        FingerprintResultComponent,
        Fido2Component
    ],
    imports: [
        CommonModule,
        CardholdersCoreModule,
        FormsModule,
        ReactiveFormsModule,
        BsModalModule.forRoot(),
        TooltipModule,
        TabsModule.forRoot(),
        NgxTrimDirectiveModule
    ],
    entryComponents: [LoginPopupComponent],
    exports: [
        UserLoginFormComponent,
        OtpLoginFormComponent,
        ErrorScreenComponent,
        ProtectedAnchorComponent,
        SignInWithAppleComponent,
        GoogleFingerPrintComponent
    ]
    // providers: [LoginService]
})
export class LoginModule {}
