import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { ActivatedRoute } from '@angular/router';
import { IResponseLower, isNullOrUndefined } from 'core-max-lib';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';
import { MarketingAgreementService } from '../../../marketing-agreement-popup/services/marketing-agreement.service';
import { MarketingModal } from '../../../marketing-agreement-popup/components/marketing-popup/constant.modal';
import { BiometricPopupService } from 'ClientApp/app/shared/cardholders-core/services/biometricPopup.service';
import { isAppleDevice } from 'ClientApp/app/shared/services/is-apple-device';
import { MaxAccountService } from '../../../max-account/services/max-account.service';
import { MaxAccountDetailsResponse } from '../../../max-account/interfaces/api-calls.interfaces';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { HomepageService } from '../../services/homepage.service';

@Component({
    selector: 'app-personal-home',
    templateUrl: './personal-home.component.html',
    styleUrls: ['./personal-home.component.scss']
})
export class PersonalHomeComponent implements OnInit, AfterViewInit, OnDestroy {
    maxAccountRes: IResponseLower<MaxAccountDetailsResponse>;
    private isBrowser: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private gtmService: GtmService,
        private activeRoute: ActivatedRoute,
        private homepageService: HomepageService,
        private modalSvc: ModalService,
        private marketingAgreementService: MarketingAgreementService,
        private bioService: BiometricPopupService,
        private _maxAccountService: MaxAccountService,
        public appConfigService: AppConfigsService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        this.gtmService.pushDirective({ category: 'New website - registered', action: 'Load', label: 'Load' });
        //this.gtmService.pushMain('virtual_page', 'NewHomePage/registered', 'event', 'virtual_page');
        if (this.isBrowser) {
            this.activeRoute.queryParams.subscribe((params) => {
                if (!isNullOrUndefined(params.myPoints) && params.myPoints === 'true') {
                    setTimeout((_) => {
                        const elm = document.getElementsByClassName('my-points');
                        elm[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }, 2000);
                }
            });

            if (this.appConfigService.appConfigs.FLAG_ISShowMaxAccountInDashboard) {
                this._maxAccountService.getMaxAccountDetails().subscribe({
                    next: (res) => {
                        this.maxAccountRes = res;
                        this.homepageService.isMaxAccountExists = this.appConfigService.appConfigs.FLAG_ISShowMaxAccountInDashboard &&
                                                                  res.returnCode == 0 &&
                                                                  res.result && res.result.isMerchantExists;
                    },
                    error: (err) => {
                        console.log('getMaxAccountDetails Error: ', err);
                    }
                });
            }
        }
    }

    ngAfterViewInit(): void {
        if (this.isBrowser) {
            this.marketingAgreementService.needToShowMarketingAgreementPopup().subscribe((res) => {
                if (res.result && this.marketingAgreementService.needToShowMarketingAgreementPopupByCookie()) {
                    this.modalSvc.openModal(MarketingModal).subscribe((x) => {});
                }
            });
            if (isAppleDevice()) {
                this.bioService.checkPopup().subscribe((res) => {
                    if (res.result) {
                        this.bioService.check();
                    }
                });
            }
        }
    }

    ngOnDestroy(): void {
        this.homepageService.insuranceRecommendation = undefined;
        this.homepageService.savingRecommendation = undefined;
        this.homepageService.mymaxRecommendationsChanged.next(null);
    }
}
