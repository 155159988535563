import { Injectable } from '@angular/core';
import { ServerClientContextLoader } from 'core-max-lib';
import { CardHoldersIClientContext } from '../shared/cardholders-core/models/cardholders-client-context';

@Injectable()

export class CardholdersClientContextLoader implements ServerClientContextLoader {
    load(request: any): CardHoldersIClientContext {
        return request.ssrbag.clientctx;
    }
}