import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Directive({
  selector: '[ngInit]'
})
export class NgInitDirective implements OnInit {
    @Input() isLast = true;
    /**
     * @directive
     * NgInitDirective
     * @description
     * This directive adds an event that triggers when the HTML element has initialized
     * @usage
     * ```html
     * <div *ngFor="let quetionnaireData of questionnairelist ; let $last = last" [isLast]='$last'
       (ngInit)="doSomething('Hello')"></div>
     * ```
     * @input
     * isLast - in case you want to trigger the event only when the last element of an ngFor is initialized
     */
  @Output('ngInit') initEvent: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {
    if (this.isLast) {
      setTimeout(() => this.initEvent.emit(), 10);
    }
  }
}
