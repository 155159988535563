import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IResponseLower } from 'core-max-lib';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { MaxAccountDetailsResponse, PaymentRequest } from '../../../max-account/interfaces/api-calls.interfaces';
import { MaxAccountService } from '../../../max-account/services/max-account.service';
import { SsoGeneralService } from '../../../sso-general/services/sso-general.service';

@Component({
    selector: 'app-max-account',
    templateUrl: './max-account.component.html',
    styleUrls: ['./max-account.component.scss']
})
export class MaxAccountComponent implements OnInit {
    @Input() maxAccountRes: IResponseLower<MaxAccountDetailsResponse>;
    waitingPaymentRequests: PaymentRequest[];
    constructor(private _maxAccountService: MaxAccountService, private router: Router, public gtmService: GtmService, public appConfigsService: AppConfigsService, public ssoService: SsoGeneralService,
        private authSerivce: AuthService, private uiHelper: UiHelperService) {}

    ngOnInit(): void {
        if (!!this.maxAccountRes?.result?.paymentRequests && this.maxAccountRes?.result?.paymentRequests?.length > 0) {
            this.waitingPaymentRequests = this.maxAccountRes?.result?.paymentRequests?.filter((request) => {
                return request?.status === 'waiting';
            });
        }
    }

    sendReminder(item: PaymentRequest): void {
        this._maxAccountService.linkData = {
            amount: item.transactionAmount,
            description: '',
            reminderUrl: item.reminderUrl,
            name: item.customerName,
            mail: item.customerEmail,
            phoneNumber: item.customerPhone,
            comment: item.comment,
            isRestore: true
        };

        this.router.navigate(['max-account/personal/payment/link'], {
            queryParams: { linkData: encodeURIComponent(JSON.stringify(this._maxAccountService.linkData)) }
        });
    }

    getChoosePayDeepLink() {
        if (this.authSerivce.isMobileSso && this.uiHelper.IsMobile()) {
            this.ssoService.siteToMobileRegister().subscribe((res) => {
                switch (res.returnCode) {
                case 0:
                        let urlWithToken = `leumicardch://?screen=315&token=${res.result}`;
                    console.log(urlWithToken);
                    window.location.href = urlWithToken;
                    break;
                default:
                    let url = 'leumicardch://?screen=315';
                    console.log(url);
                    window.location.href = url;
                }
            });
        } else {
            let url = this.appConfigsService.appConfigs.baseUiRoot +
                '/max-account/personal/payment/choose';
            console.log(url);
            window.location.href = url;

        }
    }
}
