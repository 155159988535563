import { Component, OnInit, ViewEncapsulation, OnDestroy, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { setTheme } from 'ngx-bootstrap/utils';
import { MainLayoutSessionService } from './services/main-layout-session.service';
import { IStyleLink } from '../../shared/cardholders-core/models/style-link';
import { AuthHandlerService } from '../../shared/cardholders-core/services/auth-handler.service';
import { Subscription } from 'rxjs';
import { LoginService } from '../login/login.service';
import { UiHelperService } from '../../shared/cardholders-core/services/ui-helper.service';
import { MainLayoutService } from './services/main-layout.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class MainLayoutComponent implements OnInit, OnDestroy {
    minimalHeader: boolean;
    removeLayout: boolean;
    isHeaderTransparent = false;
    isNavOpen = false;

    styleLinks: IStyleLink[] = [
        {
            href: '/styles/main-layout-styles.bundle.min.css',
            id: 'mainLayoutbundleStyles'
        }
    ];
    private subs: Subscription[] = [];

    constructor(
        private mlSessionSvc: MainLayoutSessionService,
        private authHandlerSvc: AuthHandlerService,
        private loginSvc: LoginService,
        private router: Router,
        private route: ActivatedRoute,
        private uiHelper: UiHelperService,
        private mainLayoutService: MainLayoutService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.subs.push(this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.route.snapshot),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                })
            )
            .subscribe((route: ActivatedRouteSnapshot) => {
                const isChanged = route.data['isHeaderTransparent'] !== this.isHeaderTransparent;
                this.isHeaderTransparent = route.data['isHeaderTransparent'] === true;
                if (isChanged) {
                    this.mainLayoutService.raiseHeaderTransparent(this.isHeaderTransparent);
                }
            }));

        setTheme('bs4');
        this.subs.push(
            this.authHandlerSvc.notAuthorizedSubject.subscribe(returnUrl => {
                this.loginSvc.showPopup(returnUrl);
            })
        );
        this.subs.push(
            this.mainLayoutService.isRemoveLayout.subscribe(remove => {
                this.removeLayout = remove;
            })
        );
        this.subs.push(
            this.mainLayoutService.isNavOpen.subscribe(value => {
                this.isNavOpen = value;
            })
        );
        if (this.uiHelper.isBrowser && !!this.route.snapshot.queryParams['noHF']) {
            this.mainLayoutService.hideLayout();
        }
    }
    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20) {
            document.getElementById('layout-header').classList.add('sticky-style');
        } else
        {
            document.documentElement.scrollTop < 20,
            document.getElementById('layout-header').classList.remove('sticky-style');
        }
    }


    ngOnInit() {
        this.mlSessionSvc.initSessionTimeout();
    }

    ngOnDestroy() {
        this.mlSessionSvc.clearSessionTimeout();
        this.subs.forEach(sub => sub.unsubscribe());
    }

    onHeaderSizeChanged(minimal: boolean) {
        this.minimalHeader = minimal;
    }

    getStyleForSection() {
        if (!this.removeLayout) {
            let margin;
            if (this.minimalHeader) {
                if (this.uiHelper.isCookieAppear) {
                    margin = this.uiHelper.IsMobileByScreen() ? '11rem' : '12rem';
                    return { 'margin-top': margin };
                } else {
                    margin = this.uiHelper.IsMobileByScreen() ? '68px' : '7rem';
                    return { 'margin-top': margin };
                }
            }
        }
    }


}



