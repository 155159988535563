import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SessionTimeoutService } from './session-timeout.service';
import { HttpService } from './http-service.service';



@Injectable()
export class SessionTimeoutHandlerService {
    public onNewApiCallResponseSubject: Subject<any> = new Subject();
}
