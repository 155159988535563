import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, ActivationEnd, ActivationStart, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AppConfigsService } from '../../config/app-configs.service';
import { AuthService } from '../cardholders-core/services/auth.service';
import { HttpService } from '../cardholders-core/services/http-service.service';
import { RoutingHistoryService } from '../cardholders-core/services/routing-history.service';
import { IChatManagement } from '../models/chat-management';
import { IScriptAttribute } from '../models/script-store/IOptions.interface';
import { ScriptHelperService } from './script-load-helper.service';
declare var BY: any;

@Injectable()
export class ChatManagementService {

    chatManagementData: IChatManagement[];
    isBrowser: boolean;
    ta: string;
    isChatActive = false;
    isScriptLoaded = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private httpService: HttpService,
        private appConfigsService: AppConfigsService,
        private scriptHelper: ScriptHelperService,
        private routingHistoryService: RoutingHistoryService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);

        this.init();
    }

    init(): void {
        this.setUpNavigationSubscriptions();

        this.authService.onAuthenticationChangeSubject.subscribe(isLoggedIn => {
            if (!isLoggedIn) {
                this.ta = null;
            }
            this.removeChat();
        });
    }

    getChatManagementData() {
        return this.httpService.get<IChatManagement[]>(`${this.appConfigsService.appConfigs.apiUrl}/registered/ChatManagement/getActiveChatManagementData`);
    }

    getTa() {
        if (this.ta) {
            return of(this.ta);
        }

        return this.httpService.get<string>(`${this.appConfigsService.appConfigs.apiUrl}/registered/ChatManagement/GetTa`).pipe(
            take(1),
            map(res => {
                if (res.ReturnCode === 0) {
                    this.ta = res.Result;
                    return res.Result;
                } else {
                    return null;
                }
            })
        );
    }

    loadChatScript() {
        const src = this.appConfigsService.appConfigs.BumpYardChatScript;
        const attrs: IScriptAttribute[] = [
            {
                qualifiedName: 'id',
                value: 'connectScript'
            }
        ];
        return this.scriptHelper.singleScriptLoader(src, 'url', attrs, { defer: true });
    }

    getChatSettings(ta?: string): any {
        const chatBaseDomain = this.appConfigsService.appConfigs.BumpYardChatBaseDomain;
        const chatStreamId = this.appConfigsService.appConfigs.BumpYardChatStreamId;

        const chatSettings = {
            baseDomain: chatBaseDomain,
            streamId: chatStreamId,
            subStreamId: 0,
            ta: ta ?? '',
            mode: 'slider', /* can be 'popup' or 'slider' */
            height: '100%',  /* when using mode popup you can use "650", if slider you can use "100%" */
            width: '450px',   /* when using mode popup you can use "350px", if slider you can use "70%" */
            // initialState: "opened", // opened chat
            initialState: 'preButton', // add pre button to open chat.
            // initialState: "hidden",  // use the function BY.show() when you want to show iframe. also remove the parameter "preButton"
            dock: 'left',
            direction: 'ltr',
            showTimeout: 1000, // the pre button and iframe slide speed
            animatePosition: 'bottom',
            animateDistance: 1, // the space from right/top/bottom/left- (used for preButton and frame)
            preButton: {
                imageSize: 87, // if the image slide from top/bottom = > insert Image height, if the image slide from left/right => insert Image width
                shake: true, /* true to enable shake, false to disable */
                shakeInterval: 4000, /* shake every x miliseconds(1000 miliseconds = 1 sec) */
                // vAlign: "middle", //used when "animatePosition" is from left or right. can be "top", "middle" or "bottom"
                bubbleMessages: ['צריכים עזרה?', 'לצ\'אט עם נציג'],
                bubbleMessagesInterval: 6000
            }
        };

        return chatSettings;
    }

    setUpChat(): void {
        this.isChatActive = true;

        if (this.authService.isUserAuthenticated()) {
            this.getTa().subscribe(ta => {
                if (ta) {
                    const chatSettings = this.getChatSettings(ta);
                    BY.chat(chatSettings);
                }
            });
        } else {
            const chatSettings = this.getChatSettings();
            BY.chat(chatSettings);
        }
    }

    pageLoadChatSetup() {
        if (!this.isBrowser) { return; }
        const currentSlug = window.location.pathname?.split('/')[1];
        const isChatSlug = !!this.chatManagementData?.find((cm: IChatManagement) => cm.Url.split('/')[1] === currentSlug);

        if (isChatSlug) {
            if (!this.isScriptLoaded) {
                this.loadChatScript().then((res) => {
                    if (res.loaded && typeof BY !== 'undefined' && BY) {
                        this.isScriptLoaded = true;
                        this.setUpChat();
                    }
                });
            } else {
                this.setUpChat();
            }
        }
    }

    routeChangedChatSetup(e) {

        const prevSlug = this.routingHistoryService.previousUrl?.split('/')[1];
        const currentSlug = e.url?.split('/')[1];
        const isChatSlug = !!this.chatManagementData?.find((cm: IChatManagement) => cm.Url.split('/')[1] === currentSlug);

        if (!isChatSlug) {
            this.removeChat();
            return;
        }

        if (prevSlug !== currentSlug && !this.isChatActive) {
            if (!this.isScriptLoaded) {
                this.loadChatScript().then((res) => {
                    if (res.loaded && typeof BY !== 'undefined' && BY) {
                        this.isScriptLoaded = true;
                        this.setUpChat();
                    }
                });
            } else {
                this.setUpChat();
            }
        }
    }

    setUpNavigationSubscriptions() {
        this.activatedRoute.url.subscribe(e => {

            if (!this.chatManagementData) {
                this.getChatManagementData().pipe(take(1)).subscribe(response => {

                    if (response.ReturnCode === 0) {
                        this.chatManagementData = response.Result;
                        this.pageLoadChatSetup();
                    }
                });
            } else {
                this.pageLoadChatSetup();
            }
        });

        this.router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                this.routeChangedChatSetup(e);
            }
        });
    }

    removeChat(): void {
        this.isChatActive = false;

        if (typeof BY !== 'undefined' && BY && BY.instances && Object.keys(BY.instances).length > 0) {
            BY._removeInstance(Object.keys(BY.instances)[0]);
            BY.instances = {};
        }
    }

}
