import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Log } from '../cardholders-core/decorator/log.decorator';
import { LoggerHandlerService } from '../cardholders-core/services/logger.handler.service';


export enum DialogTypes {
    none,
    registeredCustomer,
    openRequest,
    smsCode,
    smsCodeRegistered,
    fedex,
    fedexCrypto,
    sendToFedex,
    notEligible,
    generalError,
    success,
    failure,
    dreamCard,
    smsFailed,
    smsFailedRegisteredUser,
    phoneNotfound,
    sessionTimeout,
    continueProcessSmsSent,
    agentRequest,
    ProfileLoanUserExists,
    ProfileLoanOpenRequest,
    profileSendOtp,
    profileSmsFailed,
    populationRegistryFailed,
    partialSuccessExternalResourcesNotAvailableDialog,
    tauUniqSuccess,
    callCustomerService,
    addressError,
    didntGetCode,
    maxAccount,
    simplePaySessionLost,
    simplePaySessionTimeout,
}
@Injectable()
export class DialogsService {
    dialogSubject = new Subject();
    dialogClosed = new Subject<DialogTypes>();
    sendOtpSubject = new Subject();
    verifyOtpSubject = new Subject();
    sendOtpRegisteredUserSubject = new Subject();
    getValuesToSyncRegisteredUserWithOtpSubject = new Subject();
    verifyOtpRegisteredUserSubject = new Subject();
    showFormSubject = new Subject();
    restartProcessSubject = new Subject();
    restartRegisteredCustomerProcessSubject= new Subject();
    sendToFedexSubject = new Subject();
    userClickedNoCodeRecievedSubject = new Subject();
    callCustomerServicePhoneClickedSubject = new Subject();
    callCustomerServiceSubmitSubject = new Subject();
    addressErrorSubject = new Subject();

    constructor(private loggerHandler: LoggerHandlerService) { }

    openDialog(dialogType: DialogTypes, data?: any): void {
        this.loggerHandler.log.next(`Dialog [${DialogTypes[dialogType].toString()}] was shown`);

        switch (dialogType) {
            case DialogTypes.registeredCustomer:
                this.dialogSubject.next({ dialogType: DialogTypes.registeredCustomer, data: data });
                break;
            case DialogTypes.openRequest:
                this.dialogSubject.next({ dialogType: DialogTypes.openRequest });
                break;
            case DialogTypes.smsCode:
                this.dialogSubject.next({ dialogType: DialogTypes.smsCode, data: data });
                break;
            case DialogTypes.smsCodeRegistered:
                this.dialogSubject.next({ dialogType: DialogTypes.smsCodeRegistered, data: data });
                break;
            case DialogTypes.phoneNotfound:
                this.dialogSubject.next({ dialogType: DialogTypes.phoneNotfound, data: data });
                break;
            case DialogTypes.didntGetCode:
                this.dialogSubject.next({ dialogType: DialogTypes.didntGetCode });
                break;
            case DialogTypes.notEligible:
                if (data) {
                    this.dialogSubject.next({ dialogType: DialogTypes.notEligible, data: data });
                } else {
                    this.dialogSubject.next({ dialogType: DialogTypes.notEligible });
                }
                break;
            case DialogTypes.fedex:
                this.dialogSubject.next({ dialogType: DialogTypes.fedex });
                break;
            case DialogTypes.fedexCrypto:
                this.dialogSubject.next({ dialogType: DialogTypes.fedexCrypto });
                break;
            case DialogTypes.sendToFedex:
                this.dialogSubject.next({ dialogType: DialogTypes.sendToFedex, data: data });
                break;
            case DialogTypes.generalError:
                this.dialogSubject.next({ dialogType: DialogTypes.generalError, data: data });
                break;
            case DialogTypes.success:
                this.dialogSubject.next({ dialogType: DialogTypes.success });
                break;
            case DialogTypes.failure:
                this.dialogSubject.next({ dialogType: DialogTypes.failure });
                break;
            case DialogTypes.simplePaySessionLost:
                this.dialogSubject.next({ dialogType: DialogTypes.simplePaySessionLost });
                break;
            case DialogTypes.simplePaySessionTimeout:
                this.dialogSubject.next({ dialogType: DialogTypes.simplePaySessionTimeout });
                break;
            case DialogTypes.sessionTimeout:
                this.dialogSubject.next({ dialogType: DialogTypes.sessionTimeout });
                break;
            case DialogTypes.smsFailed:
                this.dialogSubject.next({ dialogType: DialogTypes.smsFailed });
                break;
            case DialogTypes.smsFailedRegisteredUser:
                this.dialogSubject.next({ dialogType: DialogTypes.smsFailedRegisteredUser });
                break;
            case DialogTypes.phoneNotfound:
                this.dialogSubject.next({ dialogType: DialogTypes.phoneNotfound });
                break;
            case DialogTypes.dreamCard:
                this.dialogSubject.next({ dialogType: DialogTypes.dreamCard });
                break;
            case DialogTypes.continueProcessSmsSent:
                this.dialogSubject.next({ dialogType: DialogTypes.continueProcessSmsSent });
                break;
            case DialogTypes.agentRequest:
                this.dialogSubject.next({ dialogType: DialogTypes.agentRequest });
                break;

            case DialogTypes.ProfileLoanUserExists:
                this.dialogSubject.next({ dialogType: DialogTypes.ProfileLoanUserExists });
                break;
            case DialogTypes.ProfileLoanOpenRequest:
                this.dialogSubject.next({ dialogType: DialogTypes.ProfileLoanOpenRequest });
                break;
            case DialogTypes.profileSendOtp:
                this.dialogSubject.next({ dialogType: DialogTypes.profileSendOtp });
                break;
            case DialogTypes.profileSmsFailed:
                this.dialogSubject.next({ dialogType: DialogTypes.profileSmsFailed });
                break;
            case DialogTypes.populationRegistryFailed:
                this.dialogSubject.next({ dialogType: DialogTypes.populationRegistryFailed });
                break;
            case DialogTypes.partialSuccessExternalResourcesNotAvailableDialog:
                this.dialogSubject.next({ dialogType: DialogTypes.partialSuccessExternalResourcesNotAvailableDialog });
                break;
            case DialogTypes.callCustomerService:
                this.dialogSubject.next({ dialogType: DialogTypes.callCustomerService, data: data });
                break;
            case DialogTypes.addressError:
                this.dialogSubject.next({ dialogType: DialogTypes.addressError, data: data });
                break;
            case DialogTypes.maxAccount:
                this.loggerHandler.log.next(`dialog data of max account: [${data?.state}], [${data?.links?.noladRequestUrl}], [${data?.links?.deliveryLink}]`);
                console.log('state: ', data);
                this.dialogSubject.next({ dialogType: DialogTypes.maxAccount, data: data });
                break;
            default:
                this.dialogSubject.next({ dialogType: DialogTypes.generalError });
                break;
        }
    }

    closeDialog(dialogType: DialogTypes) {
        switch (dialogType) {
            case DialogTypes.registeredCustomer:
                this.dialogClosed.next(DialogTypes.registeredCustomer);
                break;
            case DialogTypes.openRequest:
                this.dialogClosed.next(DialogTypes.openRequest);
                break;
            case DialogTypes.smsCode:
                this.dialogClosed.next(DialogTypes.smsCode);
                break;
            case DialogTypes.smsCodeRegistered:
                this.dialogClosed.next(DialogTypes.smsCodeRegistered);
                break;
            case DialogTypes.notEligible:
                this.dialogClosed.next(DialogTypes.notEligible);
                break;
            case DialogTypes.fedex:
                this.dialogClosed.next(DialogTypes.fedex);
                break;
            case DialogTypes.fedexCrypto:
                this.dialogClosed.next(DialogTypes.fedexCrypto);
                break;
            case DialogTypes.sendToFedex:
                this.dialogClosed.next(DialogTypes.sendToFedex);
                break;
            case DialogTypes.generalError:
                this.dialogClosed.next(DialogTypes.generalError);
                break;
            case DialogTypes.success:
                this.dialogClosed.next(DialogTypes.success);
                break;
            case DialogTypes.failure:
                this.dialogClosed.next(DialogTypes.failure);
                break;
            case DialogTypes.sessionTimeout:
                this.dialogClosed.next(DialogTypes.sessionTimeout);
                break;
            case DialogTypes.smsFailed:
                this.dialogClosed.next(DialogTypes.smsFailed);
                break;
            case DialogTypes.phoneNotfound:
                this.dialogClosed.next(DialogTypes.phoneNotfound);
                break;
            case DialogTypes.dreamCard:
                this.dialogClosed.next(DialogTypes.dreamCard);
                break;
            case DialogTypes.continueProcessSmsSent:
                this.dialogClosed.next(DialogTypes.continueProcessSmsSent);
                break;
            case DialogTypes.agentRequest:
                this.dialogClosed.next(DialogTypes.agentRequest);
                break;
            case DialogTypes.ProfileLoanUserExists:
                this.dialogClosed.next(DialogTypes.ProfileLoanUserExists);
                break;
            case DialogTypes.ProfileLoanOpenRequest:
                this.dialogClosed.next(DialogTypes.ProfileLoanOpenRequest);
                break;
            case DialogTypes.profileSendOtp:
                this.dialogClosed.next(DialogTypes.profileSendOtp);
                break;
            case DialogTypes.profileSmsFailed:
                this.dialogClosed.next(DialogTypes.profileSmsFailed);
                break;
            case DialogTypes.populationRegistryFailed:
                this.dialogClosed.next(DialogTypes.populationRegistryFailed);
                break;
            case DialogTypes.partialSuccessExternalResourcesNotAvailableDialog:
                this.dialogClosed.next(DialogTypes.partialSuccessExternalResourcesNotAvailableDialog);
                break;
            default:
                this.dialogClosed.next(DialogTypes.generalError);
                break;
        }
    }
}
