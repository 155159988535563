import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConfigsService } from '../../../config/app-configs.service';
import { HttpService, IResponse, IResponseLower } from '../../../shared/cardholders-core/services/http-service.service';
import { IDigitalTransferDetails } from '../models/digital-transfer-details.interface';
import { ITakanon } from '../models/takanon.interface';
import { IUserNameClient } from '../models/user-name-client.interface';

export interface LoaderContent {
    title: string;
    messages: string[];
}

@Injectable({
    providedIn: 'root'
})
export class DigitalTransferService {
    content: IDigitalTransferDetails;
    password: string;
    userNameResult: IUserNameClient;
    onDigitalTransferCardLoginSuccess: Subject<boolean> = new Subject<boolean>();
    birthDate: Date;
    showOcrLayout = new Subject();
    hasUserRememberedPassword = false;
    navExtra: NavigationExtras = undefined;

    constructor(
        public appConfigsService: AppConfigsService,
        private httpSvc: HttpService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    updateApprovalRegistered(cardIndex: number): Observable<IResponseLower<any>> {
        return this.httpSvc.postLower<any>(
            `${this.appConfigsService.appConfigs.apiUrl}/registered/instantIssuing/updateApproval`,
            { cardIndex: cardIndex }
        );
    }

    getAgreement43(): Observable<IResponse<ITakanon>> {
        return this.httpSvc.getWithSsrCache<ITakanon>(
            `${this.appConfigsService.appConfigs.apiUrl}/umbracoMobile/getMobileAgreementById?agreementId=43`,
            'getMobileAgreementById?agreementId=43'
        );
    }

    goToMessage(message: string) {
        this.router.navigate(['digital-transfer/message'], { state: { message: message } });
    }

    isDigitalTransferCardLoginMode(): boolean {
        // find the last component in the route - where the data is
        let route = this.route.snapshot;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route.data && route.data.digitalTransferCardLoginMode === true;
    }

    getDigitalTransferDetails(): Observable<IResponseLower<IDigitalTransferDetails>> {
        return this.httpSvc
            .getLowerWithSsrCache<IDigitalTransferDetails>(
                `${this.appConfigsService.appConfigs.apiUrl}/digitalTransfer/getDigitalTransferDetails`,
                'DigitalTransferDetails'
            )
            .pipe(
                tap(res => {
                    if (res.returnCode === 0 && res.result) {
                        this.content = res.result;
                    }
                })
            );
    }
}
