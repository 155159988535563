import { Injectable, OnDestroy, Inject, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LinkDefinition } from '../models//link-definition';
import { UiHelperService } from 'core-max-lib';
import { AppConfigsService } from '../../config/app-configs.service';
@Injectable()
export class LinkService implements OnDestroy {
    private routeListener: Subscription;
    private originUrl;
    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly router: Router,
        @Optional() @Inject('serverUrl') private serverUrl: string,
        private uiHelper: UiHelperService,
        private appConfigs: AppConfigsService
    ) {
        this.originUrl = this.appConfigs.appConfigs.baseUiRoot;
    }
    /**
     * Start listening on NavigationEnd router events
     */

    /**
     * Destroy route listener when service is destroyed
     */
    ngOnDestroy(): void {
        this.routeListener.unsubscribe();
    }

    startRouteListener(): void {
        this.routeListener = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
            let url = '';
            const urlTree = this.router.parseUrl(this.router.url);
            if (urlTree.root.hasChildren()) {
                const segments = urlTree.root.children['primary'].segments;
                if (segments && segments.length > 0) {
                    url = segments.map(segment => segment.path).join('/');
                }
            }

            let splittedUrl = url.split('/');
            let isBenefitsCategory = splittedUrl && splittedUrl[0] == 'benefits' && splittedUrl.length == 2 && splittedUrl[1] != 'magazine' && splittedUrl[1] != 'lobby';

            if (url === '') {
                this.updateTag({
                    rel: 'canonical',
                    href: `${this.originUrl.indexOf('https') === -1 ? this.originUrl.replace('http', 'https') : this.originUrl}/`
                });
            } else if (!isBenefitsCategory){
                this.updateTag({
                    rel: 'canonical',
                    href: `${this.originUrl.indexOf('https') === -1 ? this.originUrl.replace('http', 'https') : this.originUrl}/${url}`
                });
            }
        });
    }
    /**
     * Create or update a link tag
     * @param  {LinkDefinition} tag
     */
    updateTag(tag: LinkDefinition): void {
        const selector = this._parseSelector(tag);
        const linkElement =
            <HTMLLinkElement>this.document.head.querySelector(selector) ||
            this.document.head.insertBefore(this.document.createElement('link'), this.document.head.firstChild);
        if (linkElement) {
            Object.keys(tag).forEach((prop: string) => {
                linkElement[prop] = tag[prop];
            });
        }
    }
    /**
     * Remove a link tag from DOM
     * @param  tag
     */
    removeTag(tag: LinkDefinition): void {
        const selector = this._parseSelector(tag);
        const linkElement = <HTMLLinkElement>this.document.head.querySelector(selector);
        if (linkElement) {
            this.document.head.removeChild(linkElement);
        }
    }
    /**
     * Get link tag
     * @param  tag
     * @return {HTMLLinkElement}
     */
    getTag(tag: LinkDefinition): HTMLLinkElement {
        const selector = this._parseSelector(tag);
        return this.document.head.querySelector(selector);
    }
    /**
     * Get all link tags
     * @return {NodeListOf<HTMLLinkElement>}
     */
    getTags(): NodeListOf<HTMLLinkElement> {
        return this.document.head.querySelectorAll('link');
    }
    /**
     * Parse tag to create a selector
     * @param  tag
     * @return {string} selector to use in querySelector
     */
    private _parseSelector(tag: LinkDefinition): string {
        const attr: string = tag.rel ? 'rel' : 'hreflang';
        return `link[${attr}="${tag[attr]}"]`;
    }
}
