import {
    IHomePageRecommendationsResult,
    ComponentBackground,
    IHomePageRecommendationsDataResult
} from '../../models/homepage-recommendation';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { IHomePageUserData, ForeignCurrency, ICardData, ICycleSummaryNew } from '../../models/homepage-user-data';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { Component, OnInit, OnDestroy, Inject, HostListener } from '@angular/core';
import { TechnicalError } from '../../../main-layout/constants/modals.consts';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { HomepageService } from '../../services/homepage.service';
import { DOCUMENT, formatDate } from '@angular/common';
import { Router } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, Subscription } from 'rxjs';
import { ILoanParams } from '../../../../shared/cardholders-core/models/loan-params';
import { IMonthlyBillingLayoutHomePage } from '../../models/monthly-billing-layout-home-page.interface';
import { IResponse, IResponseLower } from 'core-max-lib';

@Component({
    selector: 'app-my-mymax-cards',
    templateUrl: './my-mymax-max-cards.component.html',
    styleUrls: ['./my-mymax-cards.component.scss']
})
export class MyMyMaxCardsComponent implements OnInit, OnDestroy {
    userData: IHomePageUserData;
    recommendationsData: IHomePageRecommendationsResult[];
    recommendationsToShow: IHomePageRecommendationsResult[];
    myCardRecommendations: number[];
    userCardsToShow: ICardData[];
    isShowAllCards = false;
    isShowCardsText = true;
    approvedCardsCount: number;
    myCardsIsLoaded: BehaviorSubject<boolean>;
    recommendationIsLoaded: BehaviorSubject<boolean>;
    showRecommendation = true;
    showMyCards = true;
    myCardsShowLoaderBlink = true;
    backgroundFirstCardRec: ComponentBackground;
    backgroundSecondCardRec: ComponentBackground;
    tooltipText =
        'החיובים יורדים מיתרת הכרטיס באופן מיידי וניתן לשלם עד גובה היתרה הקיימת בכרטיס, לא ניתן לבצע עסקאות תשלומים.';
    digitalCardTooltipText =
        'הכרטיס הדיגיטלי ישמש אותך לרכישה באתרים או לתשלום באמצעות הנייד בחנויות (התומכות בכך) עד שהכרטיס הפיזי יגיע עם שליח או בדואר.';
    hasTooltip = false;
    loanParams: ILoanParams;
    monthlyBillingLayoutHomePage: IMonthlyBillingLayoutHomePage;
    ismobile: boolean;
    flagIsUserById: boolean;
    private subs: Subscription[] = [];

    get showMyMaxTooltip(): boolean {
        return true;
    }

    constructor(
        @Inject(DOCUMENT) private document: any,
        private authSvc: AuthService,
        private uiHelper: UiHelperService,
        private homepageService: HomepageService,
        private appConfigsService: AppConfigsService,
        private modalSvc: ModalService,
        private gtmService: GtmService,
        private router: Router
    ) {
        this.flagIsUserById = this.appConfigsService.appConfigs.FLAG_ISUserById;
    }

    @HostListener('document:click', ['$event'])
    clickOut(event: any): void {
        if (this.uiHelper.IsMobile(768) && this.hasTooltip) {
            event.stopPropagation();
            event.preventDefault();
            this.hasTooltip = false;
        }
    }
    ngOnInit(): void {
        this.ismobile = this.uiHelper.IsMobile(768);
        this.myCardsIsLoaded = new BehaviorSubject(false);
        this.recommendationIsLoaded = new BehaviorSubject<boolean>(false);
        if (this.uiHelper.isBrowser && this.authSvc.isUserAuthenticated()) {
            this.getHomePageData();
        }
        this.homepageService.productsChanged.subscribe(res => {
            this.loanParams = res.Result.LoanParams;
        });
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    getHomePageData(): void {
        this.subs.push(
            this.homepageService.getHomePageData().subscribe((res: IResponse<IHomePageUserData>) => {
                switch (res.ReturnCode) {
                    case 0: {
                        if (res.Result) {
                            this.userData = res.Result;
                            this.setShowCards();
                        } else {
                            this.showMyCards = false;
                        }
                        this.myCardsIsLoaded.next(true);
                        break;
                    }
                    case 9: {
                        this.myCardsShowLoaderBlink = false;
                        this.modalSvc.openModal(TechnicalError).subscribe(data => {
                            this.myCardsShowLoaderBlink = true;
                            this.getHomePageData();
                        });
                        break;
                    }
                    default: {
                        this.showMyCards = false;
                        break;
                    }
                }
            })
        );
    }

    onClickRecommendation(recommendation: IHomePageRecommendationsResult): void {
        this.gtmService.pushDirective({
            category: 'deals details dashboard',
            action: '20',
            label: recommendation.ExternalRecommendationId,
            value: '2'
        });
    }

    setShowCards(): void {
        this.userData.UserCards.Cards = this.userData.UserCards.Cards.filter(card => card.ReturnCode === 0 && card.IsMyMAX);
        this.approvedCardsCount = this.userData.UserCards.Cards.length;
        if (this.userData.UserCards.Cards.length <= 4) {
            this.userCardsToShow = this.userData.UserCards.Cards;
            this.isShowCardsText = false;
        } else {
            this.userCardsToShow = this.userData.UserCards.Cards.slice(0, 4);
        }
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: 'Number of cards',
            label: this.userCardsToShow.length.toString()
        });
        if (this.userCardsToShow.find(x => x.IsControlsBiZCardSubscribe)) {
            this.gtmService.pushControl('New website - registered', 'Managing  expense budget', 'show');
        }
    }
    goToControl(event: any): void {
        event.stopPropagation();
        this.gtmService.pushControl(
            'New website - card actions',
            'Managing  expense budget',
            'Click- more info and update'
        );
        this.router.navigate(['/control/personal', 'apply'], { queryParams: { SourceGA: 'homepage' } });
    }

    toggleCards(): void {
        this.isShowAllCards = !this.isShowAllCards;
        this.userCardsToShow = this.isShowAllCards
            ? this.userData.UserCards.Cards
            : this.userData.UserCards.Cards.slice(0, 4);
    }
}
