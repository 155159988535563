<div class="personal-area">
    <span
        *ngIf="isAuthenticated(); else loginLink"
        (click)="toggleUserBox()"
        class="user-details d-block d-sm-block d-md-none"
        [ngStyle]="getCompanyLogoBackground()"
    >
    </span>
    <ng-template #loginLink>
        <a (click)="onLoginClick()" title="" class="user-details d-block d-sm-block d-md-none"
           [ngStyle]="{'background-color' : isEve8 ? '#FFFB94': 'transparent'}"></a>
    </ng-template>

    <a
        *ngIf="isAuthenticated() && isMobile"
        class="letters"
        href="{{ infoRoot }}/Registred/Services/InboxAndMailing.aspx?SourceGA=HeaderLogin&Tab=letters"
    >
        <div class="rect">
            <div class="triangle">
                <div class="inner-triangle"></div>
            </div>
        </div>
        <span class="mobile-number" *ngIf="unreadLetters > 0 && unreadLetters <= 9">{{ unreadLetters }}</span>
        <span class="mobile-number oval-mobile-number" *ngIf="unreadLetters > 9">+9</span>
    </a>

    <div class="user-tooltip" id="info" [ngClass]="{ 'show-user-tooltip': isUserBoxOpen }">
        <div *ngIf="userbox.UserName !== null" class="mail">
            <span>{{ showUserName ? userbox.UserName : '&nbsp;' }}</span>
        </div>

        <div class="content">
            <ul>
                <li *ngIf="userbox.IsShowUserSettings">
                    <a
                        href="{{ infoRoot }}/Registred/UserSettings/UserSettings.aspx?SourceGA=PersonalMenu"
                        title=""
                        appGtm
                        [data]="{
                            category: 'New website - registered',
                            action: 'Personal menu',
                            label: 'Enter personal data'
                        }"
                        listen="onclick"
                        >הגדרות ופרטים אישיים</a
                    >
                </li>
                <li *ngIf="userbox.IsShowInboxAndMailing">
                    <a
                        href="{{ infoRoot }}/Registred/Services/InboxAndMailing.aspx?SourceGA= PersonalMenu&Tab=letters"
                        title=""
                        appGtm
                        [data]="{
                            category: 'New website - registered',
                            action: 'Personal menu',
                            label: unreadLetters ? 'Enter personal mail' : 'Enter personal mail - no new mail'
                        }"
                        listen="onclick"
                    >
                        המכתבים ודו"ח האשראי שלך
                        <span class="unread-letters" *ngIf="unreadLetters > 0 && unreadLetters <= 9">{{
                            unreadLetters
                        }}</span>
                        <span class="unread-letters oval-unread-letters" *ngIf="unreadLetters > 9">+9</span>
                    </a>
                </li>

                <li
                    *ngIf="!userbox.IsOtp && !fido2Exists && userbox.IsShowBiometric && showFido2"
                    class="add-finger-print hide-desktop"
                    listen="onclick"
                >
                    <span (click)="addFidoCredentials()">הצטרפות לשירות זיהוי חכם </span>
                    <span class="question-tip" (click)="(null)" [tooltip]="tootipFinger"></span>
                </li>
                <li
                    *ngIf="!userbox.IsOtp && fido2Exists && userbox.IsShowBiometric && showFido2"
                    (click)="deleteFidoCredentials()"
                    class="add-finger-print hide-desktop"
                    listen="onclick"
                >
                    הסרת אפשרות זיהוי חכם
                    <span
                        class="question-tip"
                        (click)="(null)"
                        [tooltip]="'זיהוי ביומטרי או קוד אבטחה של המכשיר'"
                    ></span>
                </li>

                <!-- <li
                    *ngIf="!userbox.IsOtp && appleSignInStates?.show && userbox.IsShowBiometric"
                    (click)="registerAppleSignIn()"
                    class="add-finger-print"
                >
                    <span>הצטרפות לשירות זיהוי חכם </span>
                    <span class="question-tip" (click)="(null)" [tooltip]="tootipFinger"></span>
                </li>
                <li
                    *ngIf="!userbox.IsOtp && appleSignInStates?.exist && userbox.IsShowBiometric"
                    (click)="removeAppleSignIn()"
                    class="add-finger-print"
                >
                    הסרת אפשרות זיהוי חכם
                    <span
                        class="question-tip"
                        (click)="(null)"
                        [tooltip]="'זיהוי ביומטרי או קוד אבטחה של המכשיר'"
                    ></span>
                </li> -->
            </ul>

            <div class="change-account">
                <a
                    *ngIf="isAdmin"
                    class="change-account-switch"
                    (click)="changeAccount()"
                    title=""
                    >החלף משתמש התחזות</a
                >
                <a
                    appGtm
                    [data]="{ category: 'New website - registered', action: 'Personal menu', label: 'Log out' }"
                    (click)="logout()"
                    title=""
                    >יציאה</a
                >
            </div>

            <!-- <div *ngIf="fido2Loader" class="loader-wrapper">
                <div class="loader">
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div> -->

            <div *ngIf="fido2Info" class="fingerprint-message">
                <div
                    class="close-message"
                    (click)="
                        fido2Info = null
                    "
                ></div>
                <p class="message">{{ fido2Info }}</p>
                <div *ngIf="fido2Loader">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a
        *ngIf="!minimal"
        href="{{
            isAuthenticated()
                ? businessRoot + '?sourcega=RegisteredHeaderCH'
                : businessRoot + '?sourcega=AnonymousHeaderCH'
        }}"
        class="to-bussiness-website d-none d-sm-none d-md-block"
        appGtm
        [data]="{ category: 'New website', action: 'Enter to business site', label: 'Click' }"
        [configuration]="{ registered: ' - registered', anonymous: ' - anonymous', before: false }"
        listen="onclick"
        target="_blank"
        >לאתר העסקי</a
    >
    <div *ngIf="isAuthenticated(); else personalAreaExpander" class="personal-area">
        <div
            (click)="toggleUserBox()"
            class="go-to-personal-area log-in-status d-none d-sm-none d-md-block"
            appGtm
            [active]="isUserBoxOpen"
            [data]="{ category: 'New website - registered', action: 'Personal menu', label: 'Click' }"
            listen="onclick"
        >
            היי {{ userbox.FirstName }} (:

        </div>
        <a
            href="{{ infoRoot }}/Registred/Services/InboxAndMailing.aspx?SourceGA=HeaderLogin&Tab=letters"
            class="message-alert-desktop"
            *ngIf="unreadLetters > 0 && !uiHelper.IsMobile(768) && !isMobileLandscape"
            appGtm
            [data]="{ category: 'New website - registered', action: 'Mail icon', label: 'Enter' }"
            listen="onclick"
        >
            <span class="number" *ngIf="unreadLetters > 0 && unreadLetters <= 9">{{ unreadLetters }}</span>
            <span class="number oval-desktop-number" *ngIf="unreadLetters > 9">+9</span>
        </a>
    </div>

    <ng-template #personalAreaExpander>
        <a (click)="onLoginClick()"
           class="go-to-personal-area log-in-status d-none d-sm-none d-md-block">
            <span class="personal-text">כניסה לאיזור האישי</span>
            <img src="../assets/images/homepage/user-icon.svg" alt="" class="user-icon" />
        </a>
    </ng-template>
    <div *ngIf="isHeaderTransparent" class="open-search-box-desktop" (click)="onSearchClick()"></div>
</div>

<ng-template #tootipFinger>
    זיהוי ביומטרי או קוד אבטחה של המכשיר
    <a [attr.href]="touchIDAggrement" title="" class="link" target="_blank">התקנון</a>
</ng-template>
