import { Component, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { UiHelperService } from "../../../../shared/cardholders-core/services/ui-helper.service";
import { AuthService } from "../../../../shared/cardholders-core/services/auth.service";
import { HomepageService } from "../../services/homepage.service";
import { GtmService } from "../../../../shared/cardholders-core/services/gtm.service";
import { ICardPointsResult } from "../../models/homepage-points-data";
import { IResponse } from 'ClientApp/app/shared/cardholders-core/services/http-service.service';
import { IBogWallet, IBogResult } from "../../models/homepage-bog-wallet";
import { ModalService } from "../../../../shared/modules/bs-modal/services/modal.service";
import { AppConfigsService } from "../../../../config/app-configs.service";

@Component({
    selector: 'app-my-bog',
    templateUrl: './my-bits-of-gold.component.html',
    styleUrls: ['./my-bits-of-gold.component.scss']
})
export class MyBitsOfGoldComponent implements OnInit {
    showMyBogWallet = false;
    isMobile: boolean;
    myWalletsIsLoaded: BehaviorSubject<boolean>;
    myWallets: IBogWallet[] = [];
    totalSum: number;
    reload: boolean;
    isOneWallet: boolean = false;
    isSSOLink: boolean;
    isCardActive: boolean;
    loader: boolean = false;
    isErrorCase: boolean = false;
    private subs: Subscription[] = [];


    myBogSliderConfig = {
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        swipeSpeed: 200,
        autoplay: false,
        mobileFirst: true,
        rtl: true,
        responsive: [
            {
                breakpoint: 767,
                settings: 'unslick'
            }
        ]
    };

    constructor(
        private authSvc: AuthService,
        private uiHelper: UiHelperService,
        private homepageService: HomepageService,
        private gtmService: GtmService,
        private modalSvc: ModalService,
        private appConfigs: AppConfigsService
    ) { }

    ngOnInit(): void {
        this.isMobile = this.uiHelper.IsMobile(768);
        this.myWalletsIsLoaded = new BehaviorSubject<boolean>(false);
        if (this.uiHelper.isBrowser && this.authSvc.isUserAuthenticated()) {
            this.getBogWalletsData();
        }
        this.reload = true;
        setTimeout(() => {
            this.reload = false;
        }, 50);

    }

    getCryptoBalance(num: number) {
        return num.toString().substring(0, num.toString().indexOf('.') + 6);
    }


    getBogWalletsData() {
        this.subs.push(
            this.homepageService.getBogWalletsData().subscribe((res: IResponse<IBogResult>) => {
                if (res.ReturnCode === 0) {
                    if (res.Result) {
                        this.showMyBogWallet = true;
                        this.myWallets = [];
                        this.totalSum = res.Result.BalanceAndRatesResult?.BOGTotalWalletSumIls;
                        if (res.Result.BalanceAndRatesResult && this.totalSum && this.totalSum > 0) {
                            if (res.Result.BalanceAndRatesResult.BtcWallet) {
                                this.myWallets.push(res.Result.BalanceAndRatesResult.BtcWallet);
                            }
                            if (res.Result.BalanceAndRatesResult.EthWallet) {
                                this.myWallets.push(res.Result.BalanceAndRatesResult.EthWallet);
                            }
                            this.isOneWallet = this.myWallets.length === 1;
                            this.gtmService.pushDirective({
                                category: 'New website - registered',
                                action: 'Crypto',
                                label: this.myWallets.length.toString()
                            });
                        }
                        else {
                            this.gtmService.pushDirective({
                                category: 'New website - registered',
                                action: 'Crypto',
                                label: 'Status in pending'
                            });
                            this.isSSOLink = res.Result.SlugUUid !== null && res.Result.SlugUUid !== "";
                            this.isCardActive = res.Result.IsActive;
                        }

                    }
                    this.myWalletsIsLoaded.next(true);
                }
                else if (res.ReturnCode === 9 || res.ReturnCode === 11) {
                    this.showMyBogWallet = true;
                    this.isErrorCase = true;
                    this.myWalletsIsLoaded.next(true);
                }

            })
        );
    }

    isPercentDown(percent: string) {
        if (percent.indexOf('-') > -1)
            return true;
        return false;
    }

    sso(policyNum: number) {
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: 'Crypto – click',
            label: 'For buying and info'
        });
        this.subs.push(
            this.homepageService.bogSSO().subscribe(x => {
                if (x.ReturnCode === 0) {
                    window.location.href = 'https://' + x.Result;
                }
            })
        );
    }

}
