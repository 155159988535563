<div *ngIf="content" class="max-benefits" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)"
    (touchend)="onTouchEnd($event)" (ngInit)="onContentload()">
    <h2 class="title-center">{{ content.title }}</h2>
    <div class="carusel-content benefit-carusel">
        <div *ngFor="let offer of offersList; let i = index" (click)="handlePanelClick(i)" class="panel"
            [class.active]="offer.isActive" [style.backgroundColor]="'#' + offer.color">
            <div [class]="getClass(offer) + ' benefit-pic'" [ngStyle]="{ backgroundImage: 'url(' + offer.image + ')' }">
                <ng-container *ngIf="getClass(offer) === 'video-benefit'">
                    <video playsinline autoplay [muted]="'muted'" loop>
                        <source [src]="offer.video" type="video/mp4">
                    </video>
                    <span *ngIf="offer.showAimage" class="a-letter"></span>
                </ng-container>
                <ng-container *ngIf="getClass(offer) === 'lottie-benefit'">
                    <div class="rail-lottie-wrapper">
                        <lottie-animation-view [options]="{
                            autoplay: true,
                            loop: true,
                            path: offer.video
                        }">
                        </lottie-animation-view>
                        <span *ngIf="offer.showAimage" class="a-letter"></span>
                    </div>
                </ng-container>
                <span *ngIf="getClass(offer) === '' && offer.showAimage" class="a-letter"></span>
            </div>
            <div class="benefit-info" [style.color]="'#' + offer.textColor">
                <div class="benefit-info-inside">
                    <a appLink [anchorLink]="offer.cTabutton.url" [isPreventDefaultForRouterLink]="true" [href]="offer.cTabutton.url" (click)="onOfferClick($event, offer)"><h2 [innerHtml]="offer.title | sanitizeHtml"></h2></a>
                    <h3 [innerHtml]="offer.subTitle | sanitizeHtml"></h3>
                    <span class="legal-terms" [innerHtml]="offer.disclaimer | sanitizeHtml"></span>
                    <div class="button-wrapper">
                        <a title="" class="home-button" appLink [anchorLink]="offer.cTabutton.url" [isPreventDefaultForRouterLink]="true" [href]="offer.cTabutton.url" (click)="onOfferClick($event, offer)">
                            <span class="text-button">{{ offer.cTabutton.text }}</span>
                        </a>
                    </div>
                    <span class="legal-terms-desk" [innerHtml]="offer.disclaimer | sanitizeHtml"></span>
                </div>
            </div>
            <span class="active-arrow"></span>
        </div>
    </div>
</div>