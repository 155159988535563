import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { AppConfigsService } from '../../../config/app-configs.service';
import { HttpService, IHttpRequestOptions, IResponseLower } from '../../../shared/cardholders-core/services/http-service.service';
import { ISavingEligibilityResponse } from '../../saving/interfaces/ISavingEligibilityResponse.interface';
import { IInsuranceDetails } from '../models/homepage-insurance-details';

@Injectable()
export class InsuranceService {
    savingEligibilityResponse: ISavingEligibilityResponse;
    ssoPassingRecapcha = 0;
    constructor(private httpSvc: HttpService, private appConfigsService: AppConfigsService) {}
    getInsuranceDetails(): Observable<IResponseLower<IInsuranceDetails>> {
        return this.httpSvc.getLower(
            `${this.appConfigsService.appConfigs.apiUrl}/registered/myInsurance/getInsuranceDetails`
        );
    }
    checkEligibility(): Observable<IResponseLower<ISavingEligibilityResponse>> {
        const options: IHttpRequestOptions = {
            defaultApiFailureBehavior: false
        };
        return this.httpSvc
            .postLower<ISavingEligibilityResponse>(
                `${this.appConfigsService.appConfigs.apiUrl}/registered/saving/checkEligibility`,
                null, options
            )
            .pipe(
                tap(x => {
                    this.savingEligibilityResponse = x.result;
                })
            );
    }
    sso(req: { policyNum: number; captchaRes: string }): Observable<IResponseLower<string>> {
        return this.httpSvc.postLower(`${this.appConfigsService.appConfigs.apiUrl}/registered/saving/getsso`, req);
    }
}
