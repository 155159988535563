import { Component, OnInit, ElementRef } from '@angular/core';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { HomepageService } from '../../../homepage/services/homepage.service';
import { ServicesMobile, ServicesDesktop } from '../../interfaces/homepage-content.interface';

@Component({
    selector: 'app-max-application',
    templateUrl: './max-application.component.html',
    styleUrls: ['./max-application.component.scss']
})
export class MaxApplicationComponent implements OnInit {
    lottieConfig: Object;
    anim: any;
    isMobile: boolean;

    get content(): ServicesMobile | ServicesDesktop {
        return this.isMobile
            ? this.hpService.homepageContent?.servicesMobile
            : this.hpService.homepageContent?.servicesDesktop;
    }

    get unTypedContent(): any {
        return this.content;
    }

    constructor(
        private hpService: HomepageService,
        private uiHelper: UiHelperService,
        private elRef: ElementRef,
        private gtmService: GtmService
    ) {
        this.isMobile = this.uiHelper.IsMobile(768);
    }

    ngOnInit(): void {
        this.initLottie();
    }

    animCreated(event: any): void {
        this.anim = event;
    }

    initLottie(): void {
        this.lottieConfig = {
            autoplay: false,
            loop: false,
            path: '/assets/images/new-homepage/COUNTER-ANIMATION-MOBILE.json'
        };
    }

    scoreNgInit(): void {
        const observer: IntersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('movement');
                } else {
                    entry.target.classList.remove('movement');
                }
            });
        });

        const actions = this.elRef.nativeElement.querySelectorAll('.banner-encapsulation');
        actions.forEach((banner) => {
            observer.observe(banner);
        });

        const appObserver: IntersectionObserver = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry: IntersectionObserverEntry) => {
                    if (entry.isIntersecting) {
                        this.anim.play();
                    } else {
                    }
                });
            },
            {
                threshold: 0.3
            }
        );

        const app = this.elRef.nativeElement.querySelectorAll('.score');
        app.forEach((banner) => {
            appObserver.observe(banner);
        });
    }

    onAppClick(): void {
        this.gtmService.pushNewWebsiteAnonymous('New - App download', 'Click');
    }

    onContactUsClick(): void {
        this.hpService.navigate(this.content.contactUsButton.url);
        this.gtmService.pushNewWebsiteAnonymous('New - Contact us', 'Click');
    }
}
