import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from '../../../../../shared/modules/bs-modal/services/modal.service';
import { UiHelperService } from '../../../../../shared/cardholders-core/services/ui-helper.service';

@Component({
    selector: 'app-alert-status-popup',
    templateUrl: './alert-status-popup.component.html',
    styleUrls: ['./alert-status-popup.component.scss']
})
export class AlertStatusPopupComponent implements OnInit {
    @Input("data") data: any;
    public isMobile: boolean;
    isBrowser: boolean;

    constructor(private uiHelper: UiHelperService,
        private modalSvc: ModalService) {}

    ngOnInit() {
        this.isMobile = this.uiHelper.IsMobile();
        this.isBrowser = this.uiHelper.isBrowser;
    }
    close() {
        this.modalSvc.closeModal();
    }

    action(val) {
        if (val === 1 && this.data.url) {
            window.open(this.data.url, "_blank");
        }
        if (this.data.closeOnAction) {
            this.modalSvc.closeModalAndAlertSubscribers(val);
            return;
        }
        this.modalSvc.alertModalSubscribers(val);
    }
}
