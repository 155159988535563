import { Injectable } from '@angular/core';
import { Router,  NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { RoutingHistoryHandlerService } from './routing-history.handler.service';

@Injectable()
export class RoutingHistoryGlobalService { 
    private archive = [];

    constructor(
        private router: Router,
        private routingHistoryHandler: RoutingHistoryHandlerService) {
        this.router.events.forEach((event: NavigationEvent) => {
            if (event instanceof NavigationEnd) {
                this.routingHistoryHandler.store.next(event.urlAfterRedirects);
            }
        });
    }
}
