import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoggerService } from '../cardholders-core/services/logger.service';
import { CorrelationIdService } from '../cardholders-core/services/correlationId.service';

@Injectable()
export class LogClickService {
    private isBrowser: boolean;
    private handleClick(event: any): void { };

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private loggerService: LoggerService,
        private correlationIdService: CorrelationIdService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        if (this.isBrowser) {
            // "handleClick" content is handled by JS outside of angular scope
            // at the moment of execution "this" is "document"
            // this is why "that" is needed - to save the context of the service
            let that = this; 
            this.handleClick = function(event: any) {
                if (event && event.path && event.path.length > 0) {
                    let elements =
                        event.path.filter(p => p instanceof HTMLButtonElement || p instanceof HTMLAnchorElement);
                    if (elements.length > 0) {
                        let element = elements[0] as HTMLBaseElement;
                        that.correlationIdService.setCorrelationId();
                        that.loggerService.log('User clicked [' + element.nodeName + '] with text [' + element.textContent + ']');
                    }
                }
            };

            document.addEventListener('click', this.handleClick);

            window.onbeforeunload = () => {
                document.removeEventListener('click', this.handleClick, false);
            }
        }
    }


}
