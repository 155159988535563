import { Injectable } from '@angular/core';
import { CookieService } from './cookie.service';

@Injectable({ providedIn: 'root' })
export class VipLogsService {
    constructor(private cookieService: CookieService) {}

    registerCookie(): void {
        if (!this.cookieService.documentIsAccessible) {
            return;
        }

        const d1 = new Date();
        const d2 = new Date(d1);
        d2.setFullYear(d1.getFullYear() + 1);

        const domain = window.location.hostname.replace('www', '').replace('onlinelc', '');
        const language = navigator.language;

        this.cookieService.set('DeviceLanguage', language, d2, '/', domain, false, null, (val) => val);
    }
}
