import { NgModule, ModuleWithProviders } from '@angular/core';
import { ModalModule} from 'ngx-bootstrap/modal';
import { ModalService } from './services/modal.service';

@NgModule({
    imports: [
     ModalModule.forRoot()
    ],
    declarations: [
    ],
    providers: [
       ModalService
    ],
    exports: [
    ]
})
export class BsModalModule {
    static forRoot(): ModuleWithProviders<BsModalModule> {
        return {
            ngModule: BsModalModule,
            providers: [
                ModalService
            ]
        }
    } 
}


