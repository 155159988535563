import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Base64Service {
    bufferToString(buf: any): string {
        return btoa(String.fromCharCode(...new Uint8Array(buf) as any)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    }

    stringToBuffer(str: string): ArrayBuffer {
        return Uint8Array.from(atob(`${str.replace(/_/g, '/').replace(/-/g, '+')}=`) as any, (c: any) => c.charCodeAt(0) as any);
    }
}
