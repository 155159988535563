import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConfigsService } from '../../../config/app-configs.service';
import { ILoginResult, LoginStatus } from '../models/login-result';
import { AuthService } from './auth.service';
import { HttpService, IResponse } from './http-service.service';
import { LoggerService } from '../../../shared/cardholders-core/services/logger.service';
import { GtmService, IGtm } from './gtm.service';
import { isIos } from '../../services/isIos';

declare const navigator: any;

@Injectable({
    providedIn: 'root'
})
export class Fido2Service {

    apiUrl = this.configService.appConfigs.apiUrl + "/fido2";
    private _fido2Info: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    fido2Loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    assertionSuccess: Subject<boolean> = new Subject();
    attestationSuccess: Subject<boolean> = new Subject();
    isPushGtm: boolean;
    isIos = isIos();
    public fido2Checked: boolean = false;
    public fido2Exists: boolean = false;

    get fido2Info(): Observable<string> {
        this._fido2Info.next(null);
        return this._fido2Info.asObservable();
    }

    constructor(private http: HttpService,
        private configService: AppConfigsService,
        private authService: AuthService,
        public logger: LoggerService,
        private gtmService: GtmService) { }

    async registerBiometric(username: string, isPushGtm: boolean = true) {
        if (!username) return;
        this.isPushGtm = isPushGtm;
        this._fido2Info.next("ההרשמה לזיהוי ביומטרי מתבצעת כעת, נא לחכות עד לסיום התהליך");
        this.pushGtm({
            category: 'New website - registered',
            action: 'Personal menu',
            label: this.isIos ? 'Register appleSignIn - click register' : 'Click on add fingerprint'
        });

        this.fido2Loading.next(true);
        this.createAttestation().subscribe(async res => {
            if (res.ReturnCode !== 0) {
                console.log("error creating credential options");
                this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
                this.pushGtm({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: this.isIos ? 'Register appleSignIn failed - general error' : 'Register fingerprint failed - general error'
                });
                this.attestationSuccess.next(false);
                return;
            }
            let makeCredentialsOptions = res.Result;
            console.log(makeCredentialsOptions.challenge);
            makeCredentialsOptions.challenge = this.coerceToArrayBuffer(makeCredentialsOptions.challenge);
            makeCredentialsOptions.user.id = this.coerceToArrayBuffer(makeCredentialsOptions.user.id);

            makeCredentialsOptions.excludeCredentials = makeCredentialsOptions.excludeCredentials.map(c => {
                c.id = this.coerceToArrayBuffer(c.id);
                return c;
            })

            if (makeCredentialsOptions.authenticatorSelection.authenticatorAttachment == null) {
                makeCredentialsOptions.authenticatorSelection.authenticatorAttachment = undefined;
            }

            let newCredential;
            try {
                newCredential = await navigator.credentials.create({
                    publicKey: makeCredentialsOptions
                });
            } catch (e) {
                if (e.message === `Cannot read properties of undefined (reading 'create')`) {
                    this._fido2Info.next('אופס, סוג המכשיר שלך או הדפדפן אינו תומך בטביעת אצבע.');
                    this.logger.error(
                        'Register fingerprint failed - general error - Cannot read property create of undefined'
                    );
                    this.pushGtm({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: this.isIos ? 'Register appleSignIn failed - device not supported' : 'Register fingerprint failed - device not supported'
                    });
                } else if (e.message === `Not implemented`) {
                    this._fido2Info.next('אופס, נראה שדפדפן זה אינו תומך עדיין בטביעת אצבע.');
                    this.logger.error(`Browser not implement fp logic. user agent: ${window.navigator.userAgent}`);
                    this.pushGtm({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: this.isIos ? 'Register appleSignIn failed - browser not supported' : 'Register fingerprint failed - browser not supported'
                    });
                } else {
                    this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
                    this.logger.error(
                        `Register fingerprint failed - general error - catch addFingerPrint. message: ${e.message ||
                        e}`
                    );
                    this.pushGtm({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: this.isIos ? 'Register appleSignIn failed - general error' : 'Register fingerprint failed - general error'
                    });
                }
                this.fido2Loading.next(false);
                this.attestationSuccess.next(false);
                console.error(e);
                console.log(e.message)
                return;
            }

            console.log("PublicKeyCredential Created", newCredential);
            try {
                this.registerNewCredential(newCredential, username);
            } catch (e) {
                console.error(e);
                this.fido2Loading.next(false);
                this.attestationSuccess.next(false);
                return;
            }
        }, err => {
            this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
            this.pushGtm({
                category: 'New website - registered',
                action: 'Personal menu',
                label: this.isIos ? 'Register appleSignIn failed - general error' : 'Register fingerprint failed - general error'
            });
            this.fido2Loading.next(false);
        })
    }

    private registerNewCredential(newCredential: any, username: string) {
        let attestationObject = newCredential.response.attestationObject;
        let clientDataJSON = newCredential.response.clientDataJSON;
        let rawId = newCredential.rawId;

        const data = {
            id: newCredential.id,
            rawId: this.coerceToBase64Url(rawId),
            type: newCredential.type,
            extensions: newCredential.getClientExtensionResults(),
            response: {
                AttestationObject: this.coerceToBase64Url(attestationObject),
                clientDataJson: this.coerceToBase64Url(clientDataJSON)
            }
        }
        try {
            this.makeCredential(data).subscribe(result => {
                if (result.ReturnCode !== 0) {
                    console.log("Error creating credential");
                    this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
                    this.pushGtm({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: this.isIos ? 'Register appleSignIn failed - general error' : 'Register fingerprint failed - general error'
                    });
                    this.attestationSuccess.next(false);
                    this.fido2Loading.next(false);
                    return;
                }
                localStorage.setItem("fido2Username", result.Result)
                this._fido2Info.next("ההרשמה לזיהוי ביומטרי הסתיים בהצלחה");
                this.pushGtm({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: this.isIos ? 'Register appleSignIn - successful' : 'Register fingerprint - successful'
                });
                this.attestationSuccess.next(true);
                this.fido2Loading.next(false);
            })
        } catch (e) {
            this.fido2Loading.next(false);
            this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
            this.pushGtm({
                category: 'New website - registered',
                action: 'Personal menu',
                label: this.isIos ? 'Register appleSignIn failed - general error' : 'Register fingerprint failed - general error'
            });
            this.attestationSuccess.next(false);
            console.error(e);
        }
    }

    async signInBiometric(username: string) {
        this.fido2Loading.next(true);
        this.assertionOptions(username).subscribe(async res => {
            if (res.ReturnCode !== 0) {
                console.log("Error creating assertion options");
                if (res.ReturnCode == 1) {
                    console.log("User does not exist")
                }
                this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
                this.assertionSuccess.next(false);
                this.fido2Loading.next(false);
                return;
            }
            var assertionOptions = res.Result;
            const challenge = assertionOptions.challenge.replace(/-/g, "+").replace(/_/g, "/");
            assertionOptions.challenge = Uint8Array.from(atob(challenge), c => c.charCodeAt(0));

            assertionOptions.allowCredentials.forEach(listItem => {
                var fixedId = listItem.id.replace(/\_/g, "/").replace(/\-/g, "+");
                listItem.id = Uint8Array.from(atob(fixedId), c => c.charCodeAt(0));
            })

            console.log("Assertion options", assertionOptions);
            let credential;
            try {
                this.gtmService.pushDirective({
                    category: 'New website - log in',
                    action: 'Password login',
                    label: this.isIos ? 'Show appleSignIn screen' : 'Show fingerprint screen'
                });
                credential = await navigator.credentials.get({ publicKey: assertionOptions })
            } catch (err) {
                console.error(err.message ? err.message : err);
                this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
                this.assertionSuccess.next(false);
                this.fido2Loading.next(false);
                return;
            }
            this.verifyAssertion(credential);
        }, err => {
            this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
            this.fido2Loading.next(false);
        })
    }

    private verifyAssertion(credential: any) {
        let authData = credential.response.authenticatorData;
        let clientDataJSON = credential.response.clientDataJSON;
        let rawId = credential.rawId;
        let sig = credential.response.signature;
        const data = {
            id: credential.id,
            rawId: this.coerceToBase64Url(rawId),
            type: credential.type,
            extensions: credential.getClientExtensionResults(),
            response: {
                authenticatorData: this.coerceToBase64Url(authData),
                clientDataJson: this.coerceToBase64Url(clientDataJSON),
                signature: this.coerceToBase64Url(sig)
            }
        }

        this.makeAssertion(data).subscribe(res => {
            console.log("Assertion Object", res);
            if (res.ReturnCode !== 0) {
                console.log("Error doing assertion");
                if (res.ReturnCode == 1) {
                    console.log("Credentials sent do not exist")
                }
                this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
                this.fido2Loading.next(false);
                this.assertionSuccess.next(false);
                return;
            }
            this.assertionSuccess.next(true);
            this.fido2Loading.next(false);
        }, err => {
            this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
            this.assertionSuccess.next(false);
            this.fido2Loading.next(false);
        });
    }

    private coerceToArrayBuffer(thing) {
        if (typeof thing === "string") {
            // base64url to base64
            thing = thing.replace(/-/g, "+").replace(/_/g, "/");

            // base64 to Uint8Array
            var str = window.atob(thing);
            var bytes = new Uint8Array(str.length);
            for (var i = 0; i < str.length; i++) {
                bytes[i] = str.charCodeAt(i);
            }
            thing = bytes;
        }

        // Array to Uint8Array
        if (Array.isArray(thing)) {
            thing = new Uint8Array(thing);
        }

        // Uint8Array to ArrayBuffer
        if (thing instanceof Uint8Array) {
            thing = thing.buffer;
        }

        // error if none of the above worked
        if (!(thing instanceof ArrayBuffer)) {
            throw new TypeError("could not coerce to ArrayBuffer");
        }

        return thing;
    }

    private coerceToBase64Url(thing: any) {
        // Array or ArrayBuffer to Uint8Array
        if (Array.isArray(thing)) {
            thing = Uint8Array.from(thing);
        }
        if (thing instanceof ArrayBuffer) {
            thing = new Uint8Array(thing);
        }
        // Uint8Array to base64
        if (thing instanceof Uint8Array) {
            var str = "";
            var len = thing.byteLength;
            for (var i = 0; i < len; i++) {
                str += String.fromCharCode(thing[i]);
            }
            thing = window.btoa(str);
        }
        if (typeof thing !== "string") {
            throw new Error("could not coerce to string");
        }
        // base64 to base64url
        // NOTE: "=" at the end of challenge is optional, strip it off here
        thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");
        return thing;
    }

    createAttestation(): Observable<IResponse<any>> {
        return this.http.post(`${this.apiUrl}/createAttestation`) as any;
    }

    makeCredential(attestationRes: any): Observable<IResponse<any>> {
        return this.http.post(`${this.apiUrl}/makeCredential`, attestationRes) as any;
    }

    assertionOptions(encryptedUsername: string): Observable<IResponse<any>> {
        return this.http.post(`${this.apiUrl}/assertionOptions` ,{ EncryptedUsername: encryptedUsername }) as any;
    }

    makeAssertion(authenticationRes: any): Observable<IResponse<any>> {
        return this.http.post(`${this.apiUrl}/makeAssertion`, authenticationRes) as any;
    }

    checkCredentials(): Observable<IResponse<boolean>> {
        return this.http.post<boolean>(`${this.apiUrl}/checkCredentials`);
    }

    login(username): Observable<IResponse<ILoginResult>> {
        return this.http.post<ILoginResult>(`${this.apiUrl}/login`, { Username: username }).pipe(
            tap(res => {
                if (res.ReturnCode === 0 && res.Result.LoginStatus !== LoginStatus.passwordExpired && res.Result.LoginStatus !== LoginStatus.falied) {
                    this.authService.markUserHasAuthenticated(res.Result.Roles);
                }
            })
        );
    }

    deleteCredentials(): Observable<IResponse<any>> {
        this._fido2Info.next("ההסרת זיהוי ביומטרי בתהליך, נא לחכות..");
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: 'Personal menu',
            label: this.isIos ? 'Click on remove appleSignIn' : 'Click on remove fingerprint'
        });
        return this.http.post(`${this.apiUrl}/deleteUserCredentials`).pipe(
            tap(res => {
                if (res.ReturnCode === 0) {
                    localStorage.removeItem("fido2Username");
                    this._fido2Info.next("הזיהוי ביומטרי הוסר בהצלחה");
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: this.isIos ? 'Remove appleSignIn - success' : 'Remove fingerprint - success'
                    });
                } else {
                    this._fido2Info.next("אירעה שגיאה טכנית, נא לנסות מאוחר יותר");
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: this.isIos ? 'Remove appleSignIn - failed' : 'Remove fingerprint - failed'
                    });
                }
            })
        );
    }

    pushGtm(gtm: IGtm){
        if(this.isPushGtm){
            this.gtmService.pushDirective(gtm);
        }
    }
}
