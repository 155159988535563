import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../shared/cardholders-core/services/http-service.service';
import { AppConfigsService } from '../../../config/app-configs.service';
import { ILoginPageContents } from '../models/login-page-contents';
import { IResponse } from '../../../shared/cardholders-core/services/http-service.service';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'core-max-lib';

@Injectable({
    providedIn: 'root'
})
export class LoginPageService {

    constructor(
        private httpSvc: HttpService,
        private appConfigsService: AppConfigsService,
        private route: ActivatedRoute) {

    }

    getLoginPageContents(): Observable<IResponse<ILoginPageContents>> {
        return this.httpSvc
            .getWithSsrCache(`${this.appConfigsService.appConfigs.apiUrl}/login/GetLoginPageContents`, 'loginPageContents');
    }

    isLoginPageMode(): boolean {
        //find the last component in the route - where the data is
        let route = this.route.snapshot;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route.data && route.data.loginPageMode === true;
    }

    isAutoLoginFailed(): boolean {
        return !isNullOrUndefined(this.route.snapshot.queryParams['IsAutoLoginFailed']) &&
            this.route.snapshot.queryParams['IsAutoLoginFailed'] === 'true';
    }
}
