import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'numberCurrency'
})
export class NumberCurrencyPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) { }

    transform(value: any, id?: any, spaces?: string, position: 'left'|'right'= 'left'): any {
        if (value === 0) {
            return `${id ? this.getSymbol(id) + (spaces ? spaces : '') : ''}0`;
        } else {
            if (position === 'right') {
                return value ? `${this.currencyPipe.transform(value, 'symbol-narrow', '', '1.0-0')}${id ? this.getSymbol(id) + (spaces ? spaces : '') : ''}` : '';
            }

            return value ? `${id ? this.getSymbol(id) + (spaces ? spaces : '') : ''}${this.currencyPipe.transform(value, 'symbol-narrow', '', '1.0-0')}` : '';
        }
    }

    getSymbol(id: any): string {
        if (id === 'ILS' || Number(id) === 0 || Number(id) === 376) { return '₪'; }
        if (id === 'USD' || Number(id) === 840) { return '$'; }
        if (id === 'EUR' || Number(id) === 978) { return '€'; }
        return ` ${id} `;
    }
}
