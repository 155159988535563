import { Component, OnInit, ElementRef, Renderer2, HostListener } from '@angular/core';
import { IHomePageRecommendationsResult } from '../../models/homepage-recommendation';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { HomepageService } from '../../services/homepage.service';
import { IProductsForYouResult } from '../../models/products-for-you';
import { IResponse } from '../../../../shared/cardholders-core/services/http-service.service';
import { ILoanParams } from '../../../../shared/cardholders-core/models/loan-params';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-registered-products',
    templateUrl: './registered-products.component.html',
    styleUrls: ['./registered-products.component.scss']
})
export class RegisteredProductsComponent implements OnInit {
    recommendations: any[] = [];
    loanParams: ILoanParams;

    regProductsSliderConfig = {
        dots: false,
        infinite: false,
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        variableWidth: true,
        variableHeight: true,
        slidesToScroll: 1,
        mobileFirst: true,
        rtl: true,
        speed: 200,
        swipeSpeed: 300,
        responsive: [
            {
                breakpoint: 768,
                settings: 'unslick'
            }
        ]
    };
    isLoaded: BehaviorSubject<boolean>;
    hide = false;
    reload = false;

    constructor(private uiHelper: UiHelperService, private homepageSvc: HomepageService) {}
    //@HostListener('window:resize', ['$event'])
    //onResize(event): void {
    //    this.reload = true;
    //    setTimeout(() => {
    //        this.reload = false;
    //    }, 50);
    //}

    ngOnInit() {
        this.reload = true;
        setTimeout(() => {
            this.reload = false;
        }, 50);
        this.isLoaded = new BehaviorSubject<boolean>(false);
        if (this.uiHelper.isBrowser) {
            this.homepageSvc.productsChanged.subscribe((res: IResponse<IProductsForYouResult>) => {
                if (res.ReturnCode === 0) {
                    this.recommendations = res.Result.Products;
                    this.loanParams = res.Result.LoanParams;
                    this.updateLoadStatus(true);
                } else {
                    this.hide = true;
                }
            });
        }
    }
    updateLoadStatus(loadStatus: boolean): void {
        this.isLoaded.next(loadStatus);
    }
}
