import { Injectable, Inject } from '@angular/core';
import { LoggerHandlerService } from './logger.handler.service';
import { HttpService } from './http-service.service';
import { AppConfigsService } from '../../../config/app-configs.service';
import { isNullOrUndefined } from 'core-max-lib';
import { DOCUMENT } from '@angular/common';
import { AuthService } from './auth.service';

@Injectable()
export class EloquaService {

    window: any;
    isElqEnabled = false;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private loggerHandler: LoggerHandlerService,
        private httpSvc: HttpService,
        private appConfigsService: AppConfigsService,
        private authSrv: AuthService) {
        this.window = this.document.defaultView;

    }

    checkElqCustomer(): void {
        if (!this.isElqEnabled && this.authSrv.isUserAuthenticated()) {
            this.sendElqCustomer();
        }
    }

    sendElqCustomer(): void {
        try {
            if (!isNullOrUndefined(this.window._elqQ) && typeof this.window._elqQ.push === "function") {
                this.window._elqQ.push(['elqGetCustomerGUID']);
            } else {
                return;
            }

            setTimeout(_ => {
                if (typeof (this.window.GetElqCustomerGUID) === 'function') {
                    this.callEloqua();
                } else {
                    setTimeout(_ => {
                        this.callEloqua();
                    },
                        3000);
                }
            },
                3000);

        } catch (e) {
            this.loggerHandler.warning.next(
                `sendElqCustomer failed with error: ${e.toString()}`);
        }

    }

    callEloqua(): void {
        try {
            const elqCustomerGuid = this.window.GetElqCustomerGUID();

            if (isNullOrUndefined(elqCustomerGuid)) {
                this.loggerHandler.warning.next(
                    `elqCustomerGUID is undefined`);
            } else {
                this.httpSvc.post(`${this.appConfigsService.appConfigs.infoRoot}/api/common/SendElqCustomer`,
                    {
                        ElqCustomerGUID: elqCustomerGuid
                    }).subscribe();
                this.isElqEnabled = true;
            }
        } catch (e) {
            this.loggerHandler.warning.next(
                `sendElqCustomer failed with error: ${e.toString()}`);
        }
    }

}