import { Directive, ElementRef, HostListener, Input, Self } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { UiHelperService } from '../services/ui-helper.service';

@Directive({
    selector: '[appNumberWithoutSymbol]'
})
export class AppNumberWithoutSymbolDirective {
    @Input() maxvalue: number;
    @Input() maxlength: number;
    constructor(private input: ElementRef, private uiHelper: UiHelperService, @Self() public ngControl: NgControl) {
        if (this.uiHelper.isBrowser) {
            input.nativeElement.value = this.setNumber(input.nativeElement.value);
        }
    }

    @HostListener('input') keyup() {
        if (this.ngControl) {
            this.ngControl.control.setValue(this.setNumber(this.input.nativeElement.value));
            return;
        }
        this.input.nativeElement.value = this.setNumber(this.input.nativeElement.value);
    }
    setNumber(value: string): string {
        const maxLength = this.maxlength || this.input.nativeElement.getAttribute('maxlength');
        const maxValue = this.maxvalue || this.input.nativeElement.getAttribute('maxvalue');
        const numberOfCharsNotNumbers = 0;
        let num;

        if (maxLength && maxLength - numberOfCharsNotNumbers < value.length) {
            num = this.uiHelper.setNumberWithoutSymbol(value.slice(0, maxLength - numberOfCharsNotNumbers));
        } else {
            num = this.uiHelper.setNumberWithoutSymbol(value);
        }

        if (maxValue && parseInt(num) > parseInt(maxValue)) {
            return maxValue;
        }

        return num;
    }
}
