import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'numToArray' })
export class NumToArrayPipe implements PipeTransform {
    transform(value: any, initalIndex: number): any {
        if (initalIndex === undefined) {
            throw new Error('NumToArrayPipe needed to be provided with inital index');
        }
        const res = [];
        for (let i = initalIndex; i <= value; i++) {
            res.push(i);
        }
        return res;
    }
}
