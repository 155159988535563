<div class="min-search-wrapper" [ngClass]="{'search-open': toggleSearchOpen, 'search-focus': isSearchFocused}">
    <input type="checkbox" class="search-box-toggler" [(ngModel)]="checkboxValue" aria-label="תפריט חיפוש" (click)="toggle()"/>
    <div class="search-box-container" [ngClass]="{'search-open': toggleSearchOpen}">
        <div class="search-box-back"></div>
        <div id="search-box-search" class="search-box-search">
            <div class="icon-container ng-star-inserted" (click)="onSearchClick($event)" u1-button aria-label="חיפוש">
                <i class="icon"></i>
            </div>
        </div>
        <!--<input type="text" #searchBoxTerm class="search-box-term" placeholder="חיפוש" autocomplete="off" (keyup.enter)="onKeyUp($event)">-->
        <div class="search-input-wrapper">
            <span class="small-search-icon"></span>
            <p-autoComplete #searchBoxTerm [inputId]="'searchBoxTermInput'" (onFocus)="onInputFocus()" (onBlur)="onInputBlur()" [(ngModel)]="textInInput" class="auto" [suggestions]="results" (completeMethod)="filterSearch($event)" [size]="20" placeholder="" (keyup.enter)="onKeyUp($event)" (onSelect)="onSelect($event)">
            </p-autoComplete>
            <label class="search-text" for="searchBoxTermInput"><span class="what-search">מה לחפש?</span>הטבות, הלוואות, כרטיסים...</label>
        </div>
        <div class="search-shadow" (click)="toggleAndChangeCheckboxManually()"></div>
    </div>
</div>