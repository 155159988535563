import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { GtmService, IGtm } from "../services/gtm.service";
import { AuthService } from "../services/auth.service";

interface IConfiguration {
    registered: string;
    anonymous: string;
    before: boolean;
}

@Directive({
    selector: '[appGtm]'
})
export class AppGtmDirective implements OnInit {
    @Input() configuration: IConfiguration;
    @Input() listen: string;
    @Input() active = true;
    @Input() data: IGtm;
    private writer: string;

    constructor(private element: ElementRef, private gtmService: GtmService, private authService: AuthService) { }

    ngOnInit() {
        if (this.configuration) {
            this.writer = this.authService.isUserAuthenticated() ? this.configuration.registered : this.configuration.anonymous;
            this.data.category = this.configuration.before ? this.writer + this.data.category : this.data.category + this.writer;
        }
        this.element.nativeElement[this.listen] = () => this.push(this.data);
    }

    push(data: IGtm) {
        if (this.active) this.gtmService.pushDirective(data);
    }
}
