import { SidePopupComponent } from './side-popup.component';
import { IModalConfig } from "../../../../../shared/modules/bs-modal/interfaces/modal-config.interface";

export const SidePopupModal: IModalConfig = {
    content: SidePopupComponent,
    options: {
        class: 'select-invest',
        initialState: {
            type: 'side'
        } as Object,
        backdrop: 'static'
    },
    type: 'SidePopupModal'
};
