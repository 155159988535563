<div class="loader-to-header" *ngIf="!justLoaderIcon; else loaderIcon">
    <div class="transaction-deny-gif-loader">
        <div class="loader-container">
            <img src="/assets/images/customer-loan/max-loader.gif" />
        </div>
    </div>
</div>
<ng-template #loaderIcon>
    <div class="loader-container">
        <img src="/assets/images/customer-loan/max-loader.gif" /></div
></ng-template>
