import { Injectable } from '@angular/core';
import { PreloadingStrategy, Router, Route } from '@angular/router';
import { EMPTY, timer } from 'rxjs';
import { flatMap } from "rxjs/operators";

@Injectable()
export class PrePredictStrategy implements PreloadingStrategy {
    loading = new Set<Route>();

    constructor(private router: Router) {

    }

    preload(route: Route, load: Function) {
        if (this.loading.has(route)) {
            // Don't preload the same route twice
            return EMPTY;
        }

        //only add preload behiver to homepage
        if (this.router.url !== '/') {
            return EMPTY;
        }

        const conn = typeof window !== 'undefined' ? (navigator as any).connection : undefined;
        if (conn) {
            // Don't preload if the user is on 3G. or if Save-Data is enabled..
            if ((conn.effectiveType || '').includes('3g') || conn.saveData) return EMPTY;
        }

        // Prevent from preloading
        if (route.data && route.data.usePrePredict) {
            this.loading.add(route);
            //wait for 10 sec before startting to download
            return timer(10000).pipe(flatMap(_ => load()))
        }

        return EMPTY;
    }
}