import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';

declare var $: any;


@Injectable({ providedIn: 'root' })
export class ScriptBootService {

    constructor(private ngZone: NgZone, @Inject(DOCUMENT) private document: any) {}

    boot() {
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener('load', () => {
                /** init gtm after 3500 when load is compleme */
                setTimeout(init, 3500);
            });

            this.document.addEventListener('scroll', initOnEvent);
            this.document.addEventListener('mousemove', initOnEvent);
            this.document.addEventListener('touchstart', initOnEvent);

            function initOnEvent(event) {
                init();
                event.currentTarget.removeEventListener(event.type, initOnEvent); // remove the event listener that got triggered
            }


            function init() {
                if (window['scriptsDidInit']) {
                    return false;
                }
                window['scriptsDidInit'] = true; // flag to ensure script does not get added to DOM more than once.

                if (window && window['dataLayer']) {
                    window['dataLayer'].push({ event: 'gtm.angularload' });
                }

                //try {
                //    var elqTemplate = document.getElementById("elqTemplate");
                //    if (elqTemplate) {
                //        $("body").append($('<script></script>').append(elqTemplate));
                //    }

                //    var gtmTemplate = document.getElementById("gtmTemplate");
                //    if (gtmTemplate) {
                //        $("body").append($('<script></script>').append(gtmTemplate));
                //    }
                //} catch (e) {
                //    console.error(e);
                //}               
            }
        });

    }
}