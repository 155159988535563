import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs/Observable/from';
import { UiHelperService } from '../cardholders-core/services/ui-helper.service';
import { IScriptAttribute, IScriptOption } from '../models/script-store/IOptions.interface';
import { IScriptState } from '../models/script-store/IState.interface';

@Injectable()
export class ScriptHelperService {
    skip = false;
    constructor(private uiHelperService: UiHelperService) {}

    singleScriptLoader(
        data: string,
        type: 'url' | 'text',
        attrs?: IScriptAttribute[],
        option?: IScriptOption,
        div?: HTMLElement
    ): Promise<IScriptState> {
        return new Promise<IScriptState>((resolve, reject) => {
            if (!this.uiHelperService.isBrowser || this.skip) {
                resolve({ script: data, loaded: false, status: 'SSR' });
                return;
            }
            const script = document.createElement('script');

            if (option) {
                script.defer = option.defer ?? false;
                script.async = option.async ?? false;
                script.type = option.type ?? 'text/javascript';
            }
            if (attrs) {
                attrs.forEach((attr) => script.setAttribute(attr.qualifiedName, attr.value));
            }
            if (type === 'url') {
                script.src = data;
            } else {
                script.text = data;
            }

            script.onload = () => {
                resolve({ script: data, loaded: true, status: 'Loaded' });
            };

            script.onerror = () => {
                resolve({ script: data, loaded: false, status: 'LoadedError' });
            };
            if (div) {
                div.appendChild(script);
            } else {
                document.body.appendChild(script);
            }
        });
    }
    singleScriptLoaderObs(
        url: string,
        type: 'url' | 'text',
        attrs?: IScriptAttribute[],
        option?: IScriptOption
    ): Observable<IScriptState> {
        return from(this.singleScriptLoader(url, type, attrs, option));
    }
}
