import { Injectable } from '@angular/core';
import { UserIdleService, UserIdleConfig } from 'angular-user-idle';
import { Subject, Observable } from 'rxjs';
import {
    Router,
    RoutesRecognized,
    NavigationEnd,
    NavigationStart,
    ActivationStart,
    ActivatedRouteSnapshot
} from '@angular/router';
import { UserIdleTimeoutService } from './user-idle-timeout.service';
import { PopupsService, PopupTypes } from './popups.service';
import { TokenService } from './token.service';
import { UiHelperService } from './ui-helper.service';
import { HttpService } from './http-service.service';
import { SessionTimeoutHandlerService } from './session-timeout-handler.service';
import { isNullOrUndefined } from 'core-max-lib';
import { AppConfigsService } from '../../../config/app-configs.service';
import { ISessionTimeoutConfig } from '../models/session-timeout-config';
import { AuthService } from './auth.service';

@Injectable()
export class SessionTimeoutService {
    get onTimeoutSubject(): Subject<any> {
        return this._onTimeoutSubject;
    }
    isLogOut = false;
    isTimeout = false;
    isWebView = false;
    webViewEndUrl: string;
    onTimeoutCountdownSubject: Subject<number> = new Subject();
    canExitSubject: Subject<boolean> = new Subject();
    onLogoutSubject: Subject<any> = new Subject();
    private _onTimeoutSubject: Subject<any>;
    private sessionTimeoutConfig: ISessionTimeoutConfig;
    private lastActivatedRouteSnapshot: ActivatedRouteSnapshot;

    constructor(
        private router: Router,
        private userIdleTimeoutService: UserIdleTimeoutService,
        private popupsSvc: PopupsService,
        private tokenService: TokenService,
        private uiHelper: UiHelperService,
        private httpSvc: HttpService,
        private appConfigsService: AppConfigsService,
        private sessionTimeoutHandlerService: SessionTimeoutHandlerService,
        private authService: AuthService
    ) {
        this.initTimers();
        this.initSessionTimeoutConfig();

        this._onTimeoutSubject = userIdleTimeoutService.onTimeoutSubject;
        this.userIdleTimeoutService.onTimerStartSubject.subscribe(timeoutInSec => {
            this.popupsSvc.openPopup(PopupTypes.sessionTimeoutPopup, timeoutInSec);
        });
        this.webViewEndUrl = this.sessionTimeoutConfig.sessionLogoutRedirectRoute;
        this.popupsSvc.sessionTimeoutPopupNavigateAwaySelection.subscribe(res => {
            this.isLogOut = res;
            if (res) {
                this.userIdleTimeoutService.stop();
                this.clearSession().subscribe(() => {
                    if (this.isWebView) {
                        this.router.navigate([this.webViewEndUrl]);
                    } else {
                        this.router.navigate([this.sessionTimeoutConfig.sessionLogoutRedirectRoute]);
                        setTimeout(() => { window.location.reload(); }, 1000); // reload for cleaning services fields
                    }
                });
            } else {
                this.userIdleTimeoutService.reset();
                this.keepAlive();
            }
        });

        this.userIdleTimeoutService.onTimeoutSubject.subscribe(() => {
            this.clearSession().subscribe(() => {
                this.isTimeout = true;
                this.popupsSvc.closePopup(PopupTypes.sessionTimeoutPopup);
                if (this.isWebView) {
                    this.router.navigate([this.webViewEndUrl]);
                } else if (
                    this.sessionTimeoutConfig.sessionExpiredRedirectRoute.includes('http') ||
                    this.sessionTimeoutConfig.sessionExpiredRedirectRoute.includes('https')
                ) {
                    window.location.href = this.sessionTimeoutConfig.sessionExpiredRedirectRoute;
                    setTimeout(() => { window.location.reload(); }, 1000); // reload for cleaning services fields
                } else {
                    this.router.navigate([this.sessionTimeoutConfig.sessionExpiredRedirectRoute], {
                        skipLocationChange: true
                    });
                    setTimeout(() => { window.location.reload(); }, 1000); // reload for cleaning services fields
                }
            });
        });

        this.userIdleTimeoutService.onTimeoutCountdownSubject.subscribe(countdown => {
            this.onTimeoutCountdownSubject.next(countdown);
        });

        this.userIdleTimeoutService.onPingSubject.subscribe(data => {
            if (
                !isNullOrUndefined(this.tokenService.lastTokenUpdateTime) &&
                !this.userIdleTimeoutService.isTimerStart
            ) {
                const currentDate = new Date();
                const differenceInTime = currentDate.getTime() - this.tokenService.lastTokenUpdateTime.getTime();
                const differenceInSeconds = Math.round(differenceInTime / 1000);
                if (differenceInSeconds > this.userIdleTimeoutService.getConfigValue().ping) {
                    this.keepAlive();
                }
            }
        });

        this.router.events.subscribe(_ => {
            if (this.uiHelper.isBrowser) {
                if (_ instanceof NavigationStart) {
                    this.initSessionTimeoutConfig();
                }

                if (_ instanceof ActivationStart) {
                    this.lastActivatedRouteSnapshot = _.snapshot;
                }
                if (_ instanceof NavigationEnd) {
                    this.setRouteConfig(this.lastActivatedRouteSnapshot, this.sessionTimeoutConfig);
                    this.isLogOut = false;
                    this.isTimeout = false;

                    if (this.sessionTimeoutConfig.sessionTimeout) {
                        if (!this.userIdleTimeoutService.isActivated) {
                            this.userIdleTimeoutService.start();
                        } else {
                            this.userIdleTimeoutService.reset();
                        }
                    } else {
                        if (this.userIdleTimeoutService.isActivated) {
                            this.userIdleTimeoutService.stop();
                        }
                    }
                }
            }
        });

        this.sessionTimeoutHandlerService.onNewApiCallResponseSubject.subscribe(() => {
            if (this.userIdleTimeoutService.isActivated) {
                this.userIdleTimeoutService.reset();
            }
        });
    }

    private setRouteConfig(
        activatedRouteSnapshot: ActivatedRouteSnapshot,
        sessionTimeoutConfig: ISessionTimeoutConfig
    ) {
        if (!activatedRouteSnapshot.parent) {
            this.timeoutConfigCheckForValidValues(sessionTimeoutConfig);
            return;
        }

        const sessionTimeoutConfigData: ISessionTimeoutConfig = activatedRouteSnapshot.data.sessionTimeoutConfig;

        if (!isNullOrUndefined(sessionTimeoutConfigData)) {
            Object.keys(sessionTimeoutConfigData).forEach(key => {
                if (!isNullOrUndefined(sessionTimeoutConfigData[key]) && isNullOrUndefined(sessionTimeoutConfig[key])) {
                    sessionTimeoutConfig[key] = sessionTimeoutConfigData[key];
                }
            });
        }

        this.setRouteConfig(activatedRouteSnapshot.parent, sessionTimeoutConfig);
    }

    private timeoutConfigCheckForValidValues(sessionTimeoutConfig: ISessionTimeoutConfig) {
        Object.keys(sessionTimeoutConfig).forEach(key => {
            if (isNullOrUndefined(sessionTimeoutConfig[key])) {
                throw new Error(`session timeout service - session timeout config value ${key} is missing!`);
            }
        });
    }

    private keepAlive() {
        this.httpSvc.post(`${this.appConfigsService.appConfigs.apiUrl}/session/keepAlive`, null).subscribe(() => {});
    }

    private initSessionTimeoutConfig() {
        this.sessionTimeoutConfig = {
            sessionTimeout: null,
            sessionLogoutRedirectRoute: null,
            sessionExpiredRedirectRoute: null
        };
    }

    private clearSession(): Observable<any> {
        return new Observable(obs => {
            this.authService.logoff().subscribe(() => {
                this.tokenService.cleanToken();
                this.canExitSubject.next(true);
                this.onLogoutSubject.next();
                obs.next();
            });
        });
    }

    private initTimers() {
        const userIdleConfig = this.getUserIdleConfig();
        this.userIdleTimeoutService.setConfigVale(userIdleConfig);
    }

    private getUserIdleConfig() {
        const sessionTimeoutConfig = this.appConfigsService.appConfigs.sessionTimeout;

        if (
            isNullOrUndefined(sessionTimeoutConfig) ||
            isNullOrUndefined(sessionTimeoutConfig.idleInSeconds) ||
            isNullOrUndefined(sessionTimeoutConfig.timeoutInSeconds) ||
            isNullOrUndefined(sessionTimeoutConfig.keepAliveInSeconds)
        ) {
            throw new Error('Session timeout configuration is missing! Cannot start session timeout service.');
        }

        const userIdleConfig: UserIdleConfig = {
            idle: sessionTimeoutConfig.idleInSeconds,
            timeout: sessionTimeoutConfig.timeoutInSeconds,
            ping: sessionTimeoutConfig.keepAliveInSeconds
        };

        return userIdleConfig;
    }
}
