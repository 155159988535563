import { ComponentBase } from './control-base';
import { IChild } from "../../../../../shared/models/child";

export class DreamCardChildComponent extends ComponentBase<number> {
    controlType = 'dreamCardChildren';
    childs: IChild[] = [];

    changeFn: (e: any) => {};

    constructor(config: {}) {
        super(config);
        this.childs = [
            {
                ChildNum: 1,
                ChildName: '',
                ChildBirthDate: ''
            }
        ];
    }

    setValue(value: any) {
        if (value) {
            this.onChange(value);
        }
    }

    setchilds(data) {
        this.childs = data;
    }

    clearchilds() {
        this.childs = [];
    }

    clearValue() {
        this.value = null;
    }

    onChange(e) {
        if (this.changeFn) {
            this.changeFn(e);
        }
    }

}

