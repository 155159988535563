import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BaseLoginComponent } from '../base-components/base-login.component';
import { Router } from '@angular/router';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { LoginService } from '../../login.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { LoginWithMaxService } from '../../../login-with-max/login-with-max.service';
import { LoginPageService } from '../../login-page/login-page.service';
import { IGetPublicKeyResult } from '../../../../shared/cardholders-core/models/finger-print.model';
import { Base64Service } from '../../../../shared/cardholders-core/services/base64.service';
import { FingerPrintService } from '../../../../shared/cardholders-core/services/fingerPrint.service';
import { ILoginResult, LoginStatus } from '../../../../shared/cardholders-core/models/login-result';
import { IResponse } from '../../../../shared/cardholders-core/services/http-service.service';
import { Subject, Subscription } from 'rxjs';
import { OpenBankingService } from '../../../open-banking/open-banking.service';
import { BiometricPopupService } from 'ClientApp/app/shared/cardholders-core/services/biometricPopup.service';

declare const navigator: any;

@Component({
    selector: 'app-google-finger-print',
    templateUrl: './google-finger-print.component.html'
})
export class GoogleFingerPrintComponent extends BaseLoginComponent implements OnInit, OnDestroy {
    @Input() dontShowEmptyFieldError: boolean;
    loginPageMode: boolean;
    @Input()
    activateFp: Subject<void>;
    publicKeyResult: IGetPublicKeyResult;
    fpId: string;
    subs: Subscription[] = [];

    constructor(
        private base64Service: Base64Service,
        private fpService: FingerPrintService,
        protected router: Router,
        protected authService: AuthService,
        protected modalSvc: ModalService,
        protected appConfigsService: AppConfigsService,
        protected gtmService: GtmService,
        protected userLoginService: LoginService,
        protected uiHelper: UiHelperService,
        protected loginWithMaxSvc: LoginWithMaxService,
        protected loginPageSvc: LoginPageService,
        protected openBankingService: OpenBankingService,
        protected bioSrv: BiometricPopupService
    ) {
        super(
            router,
            authService,
            modalSvc,
            appConfigsService,
            gtmService,
            userLoginService,
            uiHelper,
            loginWithMaxSvc,
            openBankingService,
            bioSrv
        );
    }

    ngOnInit(): void {
        this.loginPageMode = this.loginPageSvc.isLoginPageMode();
        this.publicKeyResult = this.fpService.publicKeyResult;
        this.fpId = this.fpService.fpId;

        this.subs.push(
            this.activateFp.subscribe((_) => {
                this.doActivateFp();
            })
        );
    }

    async doActivateFp(): Promise<any> {
        try {
            this.updateLoaderStatusEvent.emit(false);
            this.gtmService.pushDirective({
                category: 'New website - log in',
                action: 'Fingerprint login',
                label: 'Show fingerprint screen'
            });
            const credential: any = await navigator.credentials.get({
                publicKey: {
                    challenge: new TextEncoder().encode(this.publicKeyResult.challenge),
                    rpId: document.domain,
                    allowCredentials: [
                        { type: 'public-key', id: this.base64Service.stringToBuffer(this.publicKeyResult.publicKey) }
                    ],
                    userVerification: 'required'
                }
            });

            const parsedCred = {
                type: credential.type,
                id: credential.id,
                rawId: this.base64Service.bufferToString(new Uint8Array(credential.rawId)),
                response: {
                    clientDataJSON: this.base64Service.bufferToString(
                        new Uint8Array(credential.response.clientDataJSON)
                    ),
                    authenticatorData: this.base64Service.bufferToString(
                        new Uint8Array(credential.response.authenticatorData)
                    ),
                    signature: this.base64Service.bufferToString(new Uint8Array(credential.response.signature)),
                    userHandle: credential.response.userHandle
                }
            };

            const verifyAssertionRequest: IResponse<ILoginResult> = await this.fpService.verifyAssertion(
                parsedCred,
                this.fpId
            );
            console.log(verifyAssertionRequest);

            if (verifyAssertionRequest.ReturnCode === 0) {
                this.checkLoginStatus(verifyAssertionRequest.Result, this.updateLoaderStatusEvent);

                if (
                    verifyAssertionRequest.Result.LoginStatus === LoginStatus.success ||
                    verifyAssertionRequest.Result.LoginStatus === LoginStatus.NeedToShowInsurance
                ) {
                    this.gtmService.pushDirective({
                        category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                        action: 'Fingerprint login',
                        label: 'Fingerprint login successful'
                    });
                    if (verifyAssertionRequest.Result.LoginStatus === LoginStatus.NeedToShowInsurance) {
                        if (this.isLoginWithMax) {
                            this.userLoginService.loginWithMax();
                        } else if (this.isOpenBankingLogin) {
                            this.openBankingService.onOpenBankingLoginSuccess.next(true);
                        } else {
                            if (
                                verifyAssertionRequest.Result.UserTransferResult &&
                                verifyAssertionRequest.Result.UserTransferResult.IsTransferMessageNeeded
                            ) {
                                this.loginService.navigateUserTransferPage(
                                    '/insurance/personal',
                                    true,
                                    this.updateLoaderStatusEvent
                                );
                            } else {
                                this.router.navigate(['/insurance/personal']);
                            }
                        }
                    } else {
                        this.userLoginService.navigateReturnUrlAfterLoginSuccess();
                    }
                    this.modalSvc.closeModal();
                } else if (this.isLoginWithMax || this.isOpenBankingLogin) {
                    this.gtmService.pushDirective({
                        category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                        action: 'Fingerprint login',
                        label: 'Fingerprint login successful'
                    });
                    this.userLoginService.navigateReturnUrlAfterLoginSuccess();
                } else {
                    this.gtmService.pushDirective({
                        category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                        action: 'Fingerprint login',
                        label: 'Failed to verify fingerprint'
                    });
                }
            } else {
                this.gtmService.pushDirective({
                    category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                    action: 'Fingerprint login',
                    label: 'Failed to verify fingerprint'
                });
            }
        } catch (error) {
            console.log(error);
            this.updateLoaderStatusEvent.emit(true);
            this.gtmService.pushDirective({
                category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                action: 'Fingerprint login',
                label: 'Failed to verify fingerprint'
            });
        } finally {
            this.updateLoaderStatusEvent.emit(true);
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach((subscriber) => subscriber.unsubscribe());
    }
}
