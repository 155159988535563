import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfigsService } from 'ClientApp/app/config/app-configs.service';
import { AppleSignInService } from 'ClientApp/app/shared/cardholders-core/services/apple-sign-in.service';
import { Base64Service } from 'ClientApp/app/shared/cardholders-core/services/base64.service';
import { FingerPrintService } from 'ClientApp/app/shared/cardholders-core/services/fingerPrint.service';
import { GtmService } from 'ClientApp/app/shared/cardholders-core/services/gtm.service';
import { LoggerService } from 'ClientApp/app/shared/cardholders-core/services/logger.service';
import { UiHelperService } from 'ClientApp/app/shared/cardholders-core/services/ui-helper.service';
import { ModalService } from 'ClientApp/app/shared/modules/bs-modal/services/modal.service';
import { isAppleDevice } from 'ClientApp/app/shared/services/is-apple-device';
import { MainLayoutService } from '../../main-layout/services/main-layout.service';
import {
    BiometricPopupService,
    IAppleSignInStates,
    IFingerPrintStates
} from 'ClientApp/app/shared/cardholders-core/services/biometricPopup.service';

import { timer } from 'rxjs/observable/timer';

declare const AppleID: any;
@Component({
    selector: 'app-biometric',
    templateUrl: './biometric.component.html',
    styleUrls: ['./biometric.component.scss']
})
export class BiometricComponent implements OnInit {
    appleSignInStates: IAppleSignInStates;
    fingerPrintStates: IFingerPrintStates;
    APPLE_SIGN_IN_STORAGE_KEY = 'appleSignIn';
    BIOMETRIC_POPUP_STORAGE_KEY = 'BiometricPopup';
    appleIDAggrement: string;
    returnCode = -1;
    constructor(
        private uihelper: UiHelperService,
        private modalService: ModalService,
        private appleSignInService: AppleSignInService,
        private gtmService: GtmService,
        public logger: LoggerService,
        private bioSrv: BiometricPopupService,
        private appConfigsService: AppConfigsService
    ) {}

    ngOnInit(): void {
        if (this.uihelper.isBrowser) {
            this.appleIDAggrement = `${this.appConfigsService.appConfigs.baseUiRoot}/general/pages/touch-id`;

            if (this.uihelper.IsMobile()) {
                document.querySelector('modal-container').classList.add('full-screen');
            }
            if (this.bioSrv.appleSignInStates.show) {
                this.bioSrv.appleSignInStatesObs.subscribe(x => {
                    this.appleSignInStates = x;
                });
            } else {
                setTimeout(() => {
                    this.close();
                }, 1000);
            }
        }
    }
    get appleButton(): string {
        if (this.returnCode === 9) {
            return 'הבנתי, תודה';
        }
        return 'לנסות שוב';
    }
    get appleLink(): string {
        if (this.returnCode === 9) {
            return 'לנסות שוב';
        }
        return 'בפעם אחרת';
    }
    next(app: string, type: string): void {
        switch (app) {
            case 'apple':
                switch (type) {
                    case 'button':
                        this.returnCode === 9 ? this.close() : this.retry();
                        break;
                    case 'link':
                        this.returnCode === 9 ? this.retry() : this.close();
                        break;

                    default:
                        break;
                }
                break;

            default:
                break;
        }
    }

    async registerAppleSignIn(): Promise<any> {
        this.bioSrv.appleSignInStates = {
            ...this.bioSrv.appleSignInStates,
            showError: false,
            loader: true,
            showPopup: true
        };
        this.gtmService.pushDirective({
            category: 'New website - registered',
            action: 'Biometric Popup',
            label: 'Register appleSignIn - click register'
        });
        try {
            const result = await AppleID.auth.signIn({ nonce: this.bioSrv.appleSignInChallenge });
            console.log({ result });
            sessionStorage.setItem('Token', this.bioSrv.tokenChanllange);
            const verifyTokenRequest = await this.appleSignInService.register(result.authorization).toPromise();
            this.bioSrv.appleSignInStates = {
                ...this.bioSrv.appleSignInStates,
                loader: false,
                show: false
            };

            console.log(verifyTokenRequest);
            this.returnCode = verifyTokenRequest.returnCode;
            if (verifyTokenRequest.returnCode === 0) {
                this.bioSrv.appleSignInStates = {
                    ...this.bioSrv.appleSignInStates,
                    title: 'סיימת!',
                    message: ' מעכשיו אפשר להתחבר באמצעות Apple ID',
                    sucess: true,
                    show: false
                };
                localStorage.setItem(this.APPLE_SIGN_IN_STORAGE_KEY, verifyTokenRequest.result);
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Biometric Popup',
                    label: 'Register appleSignIn - successful'
                });
                return;
            } else if (verifyTokenRequest.returnCode === 10) {
                this.bioSrv.appleSignInStates = {
                    ...this.bioSrv.appleSignInStates,
                    message: 'אופס<br>לא הצלחנו לאמת את הApple ID שלכם',
                    showError: true,
                    show: false,
                    loader: false,
                    showPopup: false
                };
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Biometric Popup',
                    label: 'Register appleSignIn failed - security issue'
                });
            } else if (verifyTokenRequest.returnCode === 12) {
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Biometric Popup',
                    label: 'Register appleSignIn failed - appleId already in use'
                });
                this.bioSrv.appleSignInStates = {
                    ...this.bioSrv.appleSignInStates,
                    message: 'נראה שהחשבון Apple הזה כבר בשימוש על ידי משתמש אחר',
                    showError: true,
                    show: false,
                    loader: false,
                    showPopup: false
                };
            } else {
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Biometric Popup',
                    label: 'Register appleSignIn failed - general error'
                });
                this.bioSrv.appleSignInStates = {
                    ...this.bioSrv.appleSignInStates,
                    message: 'אופס, נתקלנו בשגיאה',
                    showError: true,
                    show: false,
                    loader: false,
                    showPopup: false
                };
            }
        } catch (error) {
            console.log({ error });
            this.bioSrv.appleSignInStates = {
                ...this.bioSrv.appleSignInStates,
                message: 'אופס, נתקלנו בשגיאה',
                showError: true,
                show: false,
                loader: false,
                showPopup: false
            };

            this.gtmService.pushDirective({
                category: 'New website - registered',
                action: 'Biometric Popup',
                label: 'Register appleSignIn failed - apple general error'
            });
        }
    }
    retry(): void {
        this.bioSrv.appleSignInStates = {
            ...this.bioSrv.appleSignInStates,
            loader: false,
            message: null,
            title: null,
            showError: false,
            show: true,
            sucess: false,
            isAppleDevice: true
        };
    }
    dontWant(): void {
        localStorage.setItem(this.BIOMETRIC_POPUP_STORAGE_KEY, JSON.stringify({ pass: 99, date: new Date() }));
        timer(500).subscribe(x => {
            this.close();
        });
    }
    close(): void {
        this.modalService.closeModalAndAlertSubscribers();
    }
}
