import { Injectable } from '@angular/core';
import { UiHelperService } from "core-max-lib"

@Injectable()
export class TokenService {

    public lastTokenUpdateTime : Date;

    constructor(private uiHelper: UiHelperService) {}

    public set token(token: string) {
        if (this.uiHelper.isBrowser) {
            sessionStorage.setItem('Token', token);
            this.lastTokenUpdateTime = new Date();
        }
    }

    public get token(): string {
        if (this.uiHelper.isBrowser) {
            return sessionStorage.getItem('Token');
        }
        return null;
    }

    cleanToken() {
        if (this.uiHelper.isBrowser) {
            sessionStorage.removeItem('Token');
            this.lastTokenUpdateTime = null;
        }

    }
}
