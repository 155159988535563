<header *ngIf="!remove">
    <div class="sticky-layout d-print-none new-header" [ngClass]="{'search-open': isSearchOpen, 'nav-open': isNavOpen}" id="layout-header" >
        <app-cookies-notification [element]="homepageElement"></app-cookies-notification>
        <div class="top-header container">
            <app-nav [minimal]="minimal" [isEve8]="isEve8" [isEve10]="isEve10"> [isMobileLandscape]="isMobileLandscape"></app-nav>
            <a
                *ngIf="isMobileByScreen || isMobileLandscape"
                appLink
                [anchorLink]="getRootLink()"
                [isOpenLinkInNewTab]="false"
                title=""
                class="d-block d-sm-block d-md-none homepageLinkLogo"
                u1-link
            >
                <img
                    [src]="companyLogoImage"
                    alt="{{ companyName }}"
                    class="logo"
                    [ngClass]="{
                        'lc-max-logo': companyName.toLowerCase() === 'max' && (isMobileByScreen || isMobileLandscape)
                    }"
                    [ngStyle]="getCompanyLogoBackground()"
            /></a>
            <app-min-search *ngIf="minimal"></app-min-search>
            <app-user-box [minimal]="minimal" [isMobileLandscape]="isMobileLandscape" [isEve8]="isEve8" [isEve10]="isEve10"></app-user-box>
        </div>
    </div>
    <div
        *ngIf="!minimal"
        class="search-engine col-sm-12 col-md-12 col-lg-12 col-xl-12 d-print-none"
        appBackgroundDesign
        [backgroundDetails]="backgroundDetails"
        [ngStyle]="getStyleOfHeader()"
    >
        <div class="container">
            <app-search></app-search>
            <app-actions [isMobileLandscape]="isMobileLandscape"></app-actions>
        </div>
    </div>
</header>
