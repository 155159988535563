import { Directive, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isNullOrUndefined } from 'core-max-lib';
import { LinkRelDef, RelSource } from '../models/link-rel-def';
import { UiHelperService } from '../services/ui-helper.service';


@Directive({
    selector: 'app-link-preload'
})
export class AppLinkPreLoadDirective implements OnInit {
    @Input()
    preloadList: LinkRelDef[];

    constructor(@Inject(DOCUMENT) private readonly document: Document, private uiHelper: UiHelperService) { }

    ngOnInit(): void {

        // no need for preload in client.
        if (this.uiHelper.isBrowser) {
            return;
        }

        if (this.preloadList && this.preloadList.length > 0) {
            this.preloadList.forEach(preload => {
                if (this.document.head.querySelector(`link[href="${preload.source}"]`)) {
                    return;
                }

                const linkElement = <HTMLLinkElement>(
                    this.document.head.appendChild(this.document.createElement('link'))
                );

                if (preload.type === RelSource.preconnect) {
                    this.renderPreConnect(linkElement, preload);
                } else {
                    this.renderPreload(linkElement, preload);
                }
            });
        }
    }

    renderPreConnect(linkElement: HTMLLinkElement, preload: LinkRelDef): void {
        linkElement.href = preload.source;
        linkElement.rel = 'preconnect';
        linkElement.setAttribute('crossorigin', 'anonymous');
    }

    renderPreload(linkElement: HTMLLinkElement, preload: LinkRelDef): void {
        linkElement.href = preload.source;
        linkElement.rel = 'preload';
        linkElement.setAttribute('as', preload.type.toString());

        if (preload.exstraType) {
            // tslint:disable-next-line:quotemark
            linkElement.setAttribute('type', preload.exstraType);
        }

        if (preload.crossorigin) {
            linkElement.setAttribute('crossorigin', 'anonymous');
        }
    }
}
