import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { IHomePageRecommendationsResult } from "../../../../models/homepage-recommendation";
import { UiHelperService } from "../../../../../../shared/cardholders-core/services/ui-helper.service";
import { ILoanParams } from "../../../../../../shared/cardholders-core/models/loan-params";
import { GtmService } from "../../../../../../shared/cardholders-core/services/gtm.service";
import { HomepageService } from "../../../../services/homepage.service";
import { IResponse } from "../../../../../../shared/cardholders-core/services/http-service.service";
import { BaseProductsComponent } from "../../../base-components/base-products.component";

@Component({
    selector: 'app-loan-product-box',
    templateUrl: './loan-product-box.component.html',
    styleUrls: ['./loan-product-box.component.scss']
})
export class LoanProductBoxComponent extends BaseProductsComponent implements OnInit {


    @Input() recommendation: IHomePageRecommendationsResult;
    @Input() loanParams: ILoanParams;
    @Input() indexOfRecommendation: number;
    public isMobile;

    constructor(private uiHelper: UiHelperService, private gtmService: GtmService, private input: ElementRef, private homepageService: HomepageService) {
        super();
    }

    ngOnInit() {
        this.isMobile = this.uiHelper.IsMobile();
        this.loan.amount = this.uiHelper.setNumber(this.loanParams.amount.toString());
        this.loan.payments = this.loanParams.payments.toString();
    }

    onRecommendationButtonClick(recommendationClicked: IHomePageRecommendationsResult, indexOfRecommendation: number) {
        this.gtmService.pushDirective({ category: 'New website - registered', action: 'Click on more interests', label: `RecommendationName: ${recommendationClicked.RecommendationName}` });
        this.gtmService.pushDirective({
            category: 'New website - registered', action: 'Click on more interests - placement', label: `index: ${indexOfRecommendation + 1}`
        });
        this.homepageService.updateInstantLoanData(parseInt(this.loan.amount.replace(/,/g, '').replace('₪', ''), 10),
            parseInt(this.loan.payments, 10)).subscribe(
                (res: IResponse<any>) => {
                    window.location.href = this.recommendation.RecommendationLink;
                });
    }

    onPaste(event: ClipboardEvent, numberOfChars: number) {
        let inputText = event.clipboardData.getData('text');
        if (inputText.length > numberOfChars) {
            this.loan.payments = inputText.slice(0, numberOfChars);
        }
    }
}
