import { ComponentBase } from './control-base';

export class UlListComponent extends ComponentBase<string> {
  controlType = 'ulList';
  text: string;
    options: { key: string, value: string, icon: string }[] = [];

  constructor(options: {}) {
    super(options);
    this.options = options['options'] || {};
    this.text = options['text'] || '';
  }
}
