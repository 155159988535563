<div class="interest-you" *ngIf="!hide && !reload">
    <div class="container">
        <h2>חשבנו שיענין אותך</h2>
        <ng-template #loaderTemplateEl>
            <div class="product-template app-loader-blink"></div>
            <div class="product-template app-loader-blink"></div>
            <div class="product-template app-loader-blink hide-mobile"></div>
            <div class="product-template app-loader-blink hide-mobile"></div>
        </ng-template>

        <ng-container *appIfStable>
            <ngx-slick-carousel
                class="single-item-rtl slider product-wrapper"
                #slickModal="slick-carousel"
                [config]="regProductsSliderConfig"
                *appLoader="isLoaded; template: loaderTemplateEl"
            >
                <div ngxSlickItem *ngFor="let recommendation of recommendations; let indexOfRecommendation = index">
                    <app-reg-product-box
                        [recommendation]="recommendation"
                        [indexOfRecommendation]="indexOfRecommendation"
                        [loanParams]="loanParams"
                    ></app-reg-product-box>
                </div>
            </ngx-slick-carousel>
        </ng-container>
    </div>
</div>
