export const LoginScreenMessages = {
    generalError: 'לא הצלחנו לבצע את הפעולה, ממליצים לנסות בעוד כמה רגעים.',
    wrongDetails11: 'שכחת את הפרטים? אפשר לנסות להיכנס עם תעודת זהות או לשחזר בקלות',
    wrongDetails11Id: 'שכחת את הפרטים? אפשר לנסות להיכנס עם תעודת זהות או לשחזר בקלות',
    multipleFailsNeedId17: 'בשל מספר ניסיונות לא טובים, נבקש למלא את מספר תעודת הזהות שלך',
    otpWrongDetails30_37: 'מילאת פרט שגוי, נא למלא שוב',
    otpCardNotAllowed35: 'כרטיס לא מורשה לשימוש'
};

export interface IErrorScreenParams {
    title: string;
    text: string;
    button: IErrorScreenButtonParams;
}

export interface IErrorScreenButtonParams {
    desktopLink?: IErrorButtonLink;
    mobileLink?: IErrorButtonLink;
    isButtonForTryAgain?: boolean;
    buttonForTryAgainText?: string;
}

export interface IErrorButtonLink {
    text: string;
    url: string;
    type: ErrorLinkType;
}

export enum ErrorLinkType {
    Info,
    Content,
    Route,
    Full
}

export const LoginErrorScreenMessages = {
    lockedCanReleaseOtp12: {
        title: 'אופס',
        text: 'בגלל מספר  ניסיונות שגויים, פרטי המשתמש שלך ננעלו. אל דאגה, אפשר להשתחרר מנעילה בכפתור',
        button: {
            desktopLink: {
                text: 'לשחרור מנעילה',
                url: '/Anonymous/RecoverPassword.aspx?SourceGA=OTPLoginTooManyAttempts',
                type: ErrorLinkType.Info
            }
        }
    },
    lockedCanReleasePassword12: {
        title: 'אופס',
        text: 'בגלל מספר  ניסיונות שגויים, פרטי המשתמש שלך ננעלו. אל דאגה, אפשר להשתחרר מנעילה בכפתור',
        button: {
            desktopLink: {
                text: 'לשחרור מנעילה',
                url: '/Anonymous/RecoverPassword.aspx?SourceGA=PasswordLoginTooManyAttempts',
                type: ErrorLinkType.Info
            }
        }
    },
    lockedCantRelease13: {
        title: 'אופס',
        text: 'בגלל מספר  ניסיונות שגויים, פרטי המשתמש שלך ננעלו. אל דאגה, אפשר להתקשר ל <span>1-700-70-32-32</span>',
        button: {
            mobileLink: {
                text: 'חייג אלינו',
                url: 'tel:1-700-70-32-32',
                type: ErrorLinkType.Full
            }
        }
    },
    lockedNoLogin6Months16: {
        title: '',
        text: 'וואו, אותך לא ראינו הרבה זמן! צריך להעיר את החשבון מהתרדמת',
        button: {
            desktopLink: {
                text: 'להפשרת החשבון',
                url: '/Anonymous/RecoverPassword.aspx?SourceGA=PasswordLoginDormantAccount',
                type: ErrorLinkType.Info
            }
        }
    },
    tempPasswordNoLoginForMonth18: {
        title: 'אופס',
        text: 'הסיסמה הזמנית שהופקה עבורך בהרשמה כבר לא בתוקף. ניתן לאפס סיסמה או לפנות אלינו',
        button: {
            desktopLink: {
                text: 'מייל לשרות world elite',
                url: 'mailto:we@max.co.il',
                type: ErrorLinkType.Full
            },
            mobileLink: {
                text: 'שיחה לצוות world elite',
                url: 'tel:1-800-59-59-59',
                type: ErrorLinkType.Full
            }
        }
    },
    noCardsLeft19: {
        title: 'אופס',
        text:
            'לא מצאנו כרטיסים קשורים לשם משתמש וסיסמה זו<br>ישנן שתי סיבות לזה:<br>בוצעה העברה של כלל הכרטיסים לחשבון אחר<br>בוצע שינוי בהגדרת הדיוור בחשבון',
        button: {
            desktopLink: {
                text: 'להירשם מחדש פה',
                url: '/Anonymous/Registration.aspx?SourceGA=PasswordLoginNoCards',
                type: ErrorLinkType.Info
            }
        }
    },
    crmUserNoLogin7Days21: {
        title: '',
        text: 'נרשמת בעזרת נציג שירות ומאז לא נכנסת לחשבון שלך והוא ננעל',
        button: {
            desktopLink: {
                text: 'לשחרור מנעילה',
                url: '/Anonymous/RecoverPassword.aspx?SourceGA=PasswordLoginPasswordExpired',
                type: ErrorLinkType.Info
            }
        }
    },
    kadamUserNoLogin7Days22: {
        title: 'אופס',
        text: 'נרשמת בעזרת נציג שלנו ומאז לא נכנסת לחשבון שלך והוא ננעל',
        button: {
            desktopLink: {
                text: 'לשחרור מנעילה',
                url: '/Anonymous/RecoverPassword.aspx?SourceGA=PasswordLoginPasswordExpired',
                type: ErrorLinkType.Info
            }
        }
    },
    getOtpCodeLimitIdLocked15: {
        title: 'אופס',
        text: 'לא ניתן להשלים כרגע את התהליך, ממליצים לנסות שוב מאוחר יותר',
        button: {}
    },
    userTransferToOtherUsername25: {
        title: '',
        text: 'הפרטים שמילאת עכשיו אינם בתוקף יותר,<br>נבחרו שם משתמש ו/או וסיסמה חדשים',
        button: {
            isButtonForTryAgain: true,
            buttonForTryAgainText: 'נחזור לזיהוי בבקשה'
        }
    },
    moreThanOneCountsActiveTo8Digit: {
        title: '',
        text: 'פרטי הכרטיס שמילאת מקושרים ליותר מחשבון אחד, כדי להעביר אותך למקום הנכון, יש למלא גם את מספר חשבון הבנק',
        button: {
            isButtonForTryAgain: true,
            buttonForTryAgainText: 'נחזור לזיהוי '
        }
    }
};
