import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';

@Component({
    selector: 'app-technical-error-modal',
    templateUrl: './technical-error-modal.component.html',
    styleUrls: ['./technical-error-modal.component.scss']
})
export class TechnicalErrorModalComponent {

    constructor(private router: Router, private modalSvc: ModalService) {}

    close() {
        this.modalSvc.closeModal();
    }

    refresh() {
        this.modalSvc.closeModalAndAlertSubscribers();
    }

}
