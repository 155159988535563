export class BaseProductsComponent {
    public loan = { amount: '₪100,000', payments: '30' };
    public loanPlaceHolder = { amount: '', payments: '' };

    removePlaceHolder(inputName: string) {
        if (inputName === 'amount') {
            if (this.loan.amount !== '') {
                this.loanPlaceHolder.amount = this.loan.amount;
            }
            this.loan.amount = '';
        } else {
            if (this.loan.payments !== '') {
                this.loanPlaceHolder.payments = this.loan.payments;
            }
            this.loan.payments = '';
        }
    }

    addPlaceHolder(inputName: string) {
        if (inputName === 'amount') {
            this.loan.amount = this.loan.amount === '' ? this.loanPlaceHolder.amount : this.loan.amount;
        } else {
            this.loan.payments = this.loan.payments === '' ? this.loanPlaceHolder.payments : this.loan.payments;
        }
    }
}