<div *ngIf="newFlow" id="savingDash">
    <app-my-insurance
        *ngIf="savingEligibilityResponse && savingEligibilityResponse.eligibilityResult"
        [newFlow]="true"
        [isOtp]="savingEligibilityResponse.userInfo.isOtp"
        [agreeInd]="savingEligibilityResponse.eligibilityResult.agreeInd"
        [insuranceDetail]="savingEligibilityResponse.eligibilityResult.insuranceDetailsResult"
    ></app-my-insurance>
    <div *ngIf="savingEligibilityResponse && savingEligibilityResponse.eligibilityResult">
        <app-saving
            [agreeInd]="savingEligibilityResponse.eligibilityResult.agreeInd"
            [insuranceDetail]="savingEligibilityResponse.eligibilityResult.insuranceDetailsResult"
            [homePageSavingsData]="savingEligibilityResponse.eligibilityResult.savingsData"
        ></app-saving>
    </div>
</div>
<div *ngIf="!newFlow">
    <app-my-insurance [newFlow]="false"></app-my-insurance>
</div>
