import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UiHelperService } from './ui-helper.service';
import { isNullOrUndefined } from 'core-max-lib';
import { SessionTimeoutService } from '../../../shared/cardholders-core/services/session-timeout.service';

export interface IKeyValuePair<T, Y> { };

export interface IUpdateSubject {
    key: string;
    oldValue: any;
    newValue: any;
}

export class KeyValuePair<T, Y> implements IKeyValuePair<T, Y> {
    public key: T;
    public value: Y;

    constructor(key: T, value: Y) {
        this.key = key;
        this.value = value;
    }
}

@Injectable({
    providedIn: "root"
})
export class SessionStorageService {
    private readonly viewStateName: string = "ViewState";
    public stateUpdatedSubject = new Subject<any>();

    constructor(private uiHelper: UiHelperService, private sessionTimeoutService: SessionTimeoutService) {
        if (this.uiHelper.isBrowser) {
            this.viewState = new Array<KeyValuePair<number, string>>();

            this.sessionTimeoutService.onTimeoutSubject.subscribe(() => {
                this.clear();
            })
        }
    }

    public setValue<T>(key: string, value: T) {
        if (this.uiHelper.isBrowser) {
            let oldValue = this.getValue(key, null);
            this.viewState[key] = value;

            let updateSubject = <IUpdateSubject>{
                key : key, oldValue : oldValue, newValue : value
            }

            this.stateUpdatedSubject.next(updateSubject)
        }
    }

    public getValue<T>(key: string, defaultValue: T): T {
        if (this.uiHelper.isBrowser) {
            if (isNullOrUndefined(this.viewState) || isNullOrUndefined(this.viewState[key])) {
                return defaultValue;
            }
            return <T>this.viewState[key];
        }
        return null;
    }

    private set viewState(value: Array<IKeyValuePair<string, any>>) {
        sessionStorage.setItem(this.viewStateName, JSON.stringify(value));
    }

    private get viewState(): Array<IKeyValuePair<string, any>> {
        return JSON.parse(sessionStorage.getItem(this.viewStateName));
    }

    public clear() {
        if (this.uiHelper.isBrowser) {
            this.viewState.forEach((item: KeyValuePair<string, any>, index) => {
                let updateSubject = <IUpdateSubject>{
                    key: item.key, oldValue: item.value, newValue: null
                }
                this.stateUpdatedSubject.next(updateSubject);
                this.viewState.splice(index, 1);
            });
        }
    }
}

/*Onlogout clearSession*/