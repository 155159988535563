import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "../services/auth.service";
import { IAuthConfig } from '../models/auth-config';
import { AuthHandlerService } from '../services/auth-handler.service';
import { RoutingHistoryService } from '../services/routing-history.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
    constructor(
        private authSvc: AuthService,
        private router: Router,
        private authHandlerSvc: AuthHandlerService,
        private routingHistorySvc: RoutingHistoryService
    ) { }



    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let authConfig: IAuthConfig = childRoute.data.authConfig;
        if (authConfig) {
            if (this.authSvc.isUserAuthenticated()) {
                if (authConfig.authenticatedRedirectUrl) {
                    this.router.navigate([authConfig.authenticatedRedirectUrl]);
                    return false;
                }
                if (authConfig.roles && authConfig.roles.length > 0) {
                    if (this.authSvc.isUserMatchRoles(authConfig.roles))
                        return true;
                    else {
                        this.doWhenNotAuthorized(childRoute, state.url)
                        return false;
                    }
                }
            }
            else {
                let currentUrlTree = this.router.parseUrl(state.url);
                if (authConfig.notAuthenticatedRedirectUrl && !(childRoute.queryParams.IgnoreRedirect)) {
                    this.router.navigate([authConfig.notAuthenticatedRedirectUrl]);
                    return false;
                }
                else {
                    delete currentUrlTree.queryParams['IgnoreRedirect'];
                    if (authConfig.roles && authConfig.roles.length > 0) {
                        this.doWhenNotAuthorized(childRoute, currentUrlTree.toString());
                        return false;
                    }
                }
            }
        }
        return true;
    }

    private doWhenNotAuthorized(route: ActivatedRouteSnapshot, returnUrl: string): void {
        if (this.routingHistorySvc.isFirstPage) {
            this.notAuthorizedRedirect(route, returnUrl);
        }
        else {
            this.authHandlerSvc.notAuthorizedSubject.next(returnUrl);
        }
    }

    private notAuthorizedRedirect(route: ActivatedRouteSnapshot, returnUrl: string) {
        if (!route) {
            throw `auth guard - Auth Config [notAuthorizedRedirectUrl] is missing for current route`;
        }

        let authConfig: IAuthConfig = route.data.authConfig;
        if (authConfig && authConfig.notAuthorizedRedirectUrl) {
            this.router.navigate([authConfig.notAuthorizedRedirectUrl], { skipLocationChange: authConfig.notAuthorizedSkipLocation || false, queryParams: this.getReturnUrl(authConfig, returnUrl) })
            return;
        }

        this.notAuthorizedRedirect(route.parent, returnUrl);
    }

    private getReturnUrl(authConfig: IAuthConfig, returnUrl: string): Params {
        if (authConfig.notAuthorizedSendReturnUrl)
            return { "returnUrl": returnUrl }
        return;
    }
}
