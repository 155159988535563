<div class="recommendation-box" *ngIf="recommendation">
    <lottie-animation-view *ngIf="flagIsUseRecommendationLottie && recommendation.RecommendationLottie" [options]="lottieConfig" [width]="50" [height]="50" [margin]="'0'">
    </lottie-animation-view>
    <img *ngIf="flagIsUseRecommendationLottie && !recommendation.RecommendationLottie && recommendation.RecommendationIcon" [src]="recommendation.RecommendationIcon" class="space-ship" />
    <h2 [ngClass]="{'wide-title': this.wideTitle, 'lottie-title' : flagIsUseRecommendationLottie}">{{recommendation.RecommendationTitle}}</h2>
    <span *ngIf="(recommendation.RecommendationSubTitle) || (flagIsUseRecommendationLottie && recommendation.RecommendationSubTitleForLottie)" class="recommendation-alert" [ngClass]="{'lottie-subtitle' : flagIsUseRecommendationLottie}" [innerHtml]="flagIsUseRecommendationLottie ? recommendation.RecommendationSubTitleForLottie : recommendation.RecommendationSubTitle | safeRichHtml"></span>
    <span *ngIf="isLoanRecommendation" class="recommendation-alert"> {{loanParams.amount | currency:'ILS':'symbol':'0.0-0'}}</span>
    <div *ngIf="recommendation.RecommendationLinkTitle && !flagIsUseRecommendationLottie" class="default-link target-link-bold" [ngClass]="{'with-general-text' : recommendation.GeneralText, 'without-general-text' : !recommendation.GeneralText}">{{recommendation.RecommendationLinkTitle}}</div>
    <div *ngIf="recommendation.GeneralText" class="general-text" [ngClass]="{'lottie-general-text' : flagIsUseRecommendationLottie}">{{recommendation.GeneralText}}</div>
    <div *ngIf="recommendation.RecommendationLinkTitle && flagIsUseRecommendationLottie" class="default-link target-link-bold lottie-link" [ngClass]="{'with-general-text' : recommendation.GeneralText, 'without-general-text' : !recommendation.GeneralText}">{{recommendation.RecommendationLinkTitle}}</div>
</div>