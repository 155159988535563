import { ComponentBase } from './control-base';
import { FormGroup } from '@angular/forms';
import {JoinService} from "../../../../services/join.service";

export class CheckBoxComponent extends ComponentBase<boolean> {
  controlType = 'checkbox';
  text: string;
  customTextTitle: any;
  customText: any;
  boldtext: string;
  optionalText: string;
    customClass: string;
    disabled: boolean;

  changeFn: (e: any) => {};

  constructor(options: {}) {
    super(options);
    this.text = options['text'] || undefined;
    this.customTextTitle = options['customTextTitle'] || undefined;
    this.customText = options['customText'] || undefined;
    this.boldtext = options['boldtext'] || undefined;
    this.optionalText = options['optionalText'] || undefined;
      this.customClass = options['customClass'] || undefined;
      this.disabled = options['disabled'] || false;
  }

  onChange(e) {
    if (this.changeFn) {
      this.changeFn(e);
    }
  }
}




