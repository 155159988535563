import { Injectable, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CookieService } from '../../shared/cardholders-core/services/cookie.service';


@Injectable()
export class PreviewService {

    constructor(private cookieService: CookieService, private route:ActivatedRoute) {

    }

    isEnabled(): boolean {
        return this.cookieService.check('PREVIEW') || this.isEnabledByQs();
    }

    isEnabledByQs() {
        return this.route.snapshot.queryParams['umbpreview'];
    }

}