import { Observable } from 'rxjs';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { UUID } from 'angular2-uuid';
import { isNullOrUndefined } from 'core-max-lib';
import { RoutingHistoryService } from '../services/routing-history.service';
import { CorrelationIdService } from '../services/correlationId.service';
import { InitializerService } from 'core-max-lib';
import { SessionIdService } from '../services/sessionId.service';


@Injectable()
export class HttpServiceLogInterceptor implements HttpInterceptor {

    private isBrowser: boolean;
    private version;
    
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private routingHistoryService: RoutingHistoryService,
        private correlationIdService: CorrelationIdService,
        private initializer: InitializerService,
        private sessionIdService: SessionIdService
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isBrowser || (this.isBrowser && (req.url.includes('config.json') || req.url.includes('/registered/clientcontext')))) {
            return next.handle(req);
        }

        let correlationId: string = '';
        if (!req.url.includes('logs/log')) {
            correlationId = this.correlationIdService.correlationId || '';
        }

        const urf = req.headers.get('URF');

        let newReq: HttpRequest<any> = req.clone({
            setHeaders: {
                'SID': this.sessionIdService.sessionId,
                'CID': correlationId,
                'URF': urf || this.routingHistoryService.previousUrl || '',
                'CAV': this.initializer.getClientContext().Version
            }
        });

        return next.handle(newReq);
    }
}
