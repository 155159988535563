export interface IChargingForChildCardRequest {
    MyMaxCardIndex: number;
    TransType: number;
    EffectiveDay: number | null | undefined;
    Amount: number;
}

export enum TransType {
    OneTimeCharge = 50,
    FullDischarge = 51,
    MonthlyCharge = 1,
    MonthlyChargeAmountChange = 2,
    MonthlyChargeDateChange = 3,
    MonthlyChargeDateAndAmountChange = 4,
    MonthlyChargeCancelation = 5
}
