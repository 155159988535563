import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppleSignInService } from '../../../../shared/cardholders-core/services/apple-sign-in.service';
import { BaseLoginComponent } from '../base-components/base-login.component';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { LoginService } from '../../login.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { LoginWithMaxService } from '../../../login-with-max/login-with-max.service';
import { ILoginResult, LoginStatus } from '../../../../shared/cardholders-core/models/login-result';
import { IResponse } from '../../../../shared/cardholders-core/services/http-service.service';
import { LoginPageService } from '../../login-page/login-page.service';
import { OpenBankingService } from '../../../open-banking/open-banking.service';
import { BiometricPopupService } from 'ClientApp/app/shared/cardholders-core/services/biometricPopup.service';

declare const AppleID: any;

@Component({
    selector: 'app-sign-in-with-apple',
    templateUrl: './sign-in-with-apple.component.html',
    styleUrls: ['./sign-in-with-apple.component.scss']
})
export class SignInWithAppleComponent extends BaseLoginComponent implements OnInit {
    @Input() dontShowEmptyFieldError: boolean;
    loginPageMode: boolean;

    constructor(
        private appleSignInService: AppleSignInService,
        protected router: Router,
        protected authService: AuthService,
        protected modalSvc: ModalService,
        protected appConfigsService: AppConfigsService,
        protected gtmService: GtmService,
        protected userLoginService: LoginService,
        protected uiHelper: UiHelperService,
        protected loginWithMaxSvc: LoginWithMaxService,
        protected loginPageSvc: LoginPageService,
        protected openBankingService: OpenBankingService,
        protected bioSrv: BiometricPopupService
    ) {
        super(
            router,
            authService,
            modalSvc,
            appConfigsService,
            gtmService,
            userLoginService,
            uiHelper,
            loginWithMaxSvc,
            openBankingService,
            bioSrv
        );
    }

    ngOnInit(): void {
        this.loginPageMode = this.loginPageSvc.isLoginPageMode();
    }

    async signInWithApple(): Promise<any> {
        this.dontShowEmptyFieldError = true;
        try {
            this.updateLoaderStatusEvent.emit(false);
            // this.showAppleSignIn = false;
            const result = await AppleID.auth.signIn();
            console.log({ result });

            const loginRequest: IResponse<ILoginResult> = await this.appleSignInService.login(result.authorization);
            console.log(loginRequest);

            if (loginRequest.ReturnCode === 0) {
                this.checkLoginStatus(loginRequest.Result, this.updateLoaderStatusEvent);

                if (
                    loginRequest.Result.LoginStatus === LoginStatus.success ||
                    loginRequest.Result.LoginStatus === LoginStatus.NeedToShowInsurance
                ) {
                    this.gtmService.pushDirective({
                        category: `New website - log in${this.loginPageMode ? ' page' : ''}`,
                        action: 'Password login',
                        label: 'Login appleSignIn - successful'
                    });
                    if (loginRequest.Result.LoginStatus === LoginStatus.NeedToShowInsurance) {
                        if (this.isLoginWithMax) {
                            this.userLoginService.loginWithMax();
                        } else if (this.isOpenBankingLogin) {
                            this.openBankingService.onOpenBankingLoginSuccess.next(true);
                        } else {
                            if (
                                loginRequest.Result.UserTransferResult &&
                                loginRequest.Result.UserTransferResult.IsTransferMessageNeeded
                            ) {
                                this.loginService.navigateUserTransferPage(
                                    '/insurance/personal',
                                    true,
                                    this.updateLoaderStatusEvent
                                );
                            } else {
                                this.router.navigate(['/insurance/personal']);
                            }
                        }
                    } else {
                        this.userLoginService.navigateReturnUrlAfterLoginSuccess();
                    }
                    this.modalSvc.closeModal();
                } else if (this.isLoginWithMax || this.isOpenBankingLogin) {
                    this.gtmService.pushDirective({
                        category: `New website - log in${this.loginPageMode ? ' page' : ''}`,
                        action: 'Password login',
                        label: 'Login appleSignIn - successful'
                    });
                    this.userLoginService.navigateReturnUrlAfterLoginSuccess();
                } else {
                    this.gtmService.pushDirective({
                        category: 'New website - log in',
                        action: `Password login${this.loginPageMode ? ' page' : ''}`,
                        label: 'Login appleSignIn failed - error'
                    });
                }
            } else if (loginRequest.ReturnCode === 10) {
                this.gtmService.pushDirective({
                    category: `New website - log in${this.loginPageMode ? ' page' : ''}`,
                    action: 'Password login',
                    label: 'Login appleSignIn failed - security issue'
                });
            } else {
                this.gtmService.pushDirective({
                    category: 'New website - log in',
                    action: `Password login${this.loginPageMode ? ' page' : ''}`,
                    label: 'Login appleSignIn failed - general error'
                });
            }
        } catch (error) {
            console.log({ error });
            this.updateLoaderStatusEvent.emit(true);
            this.gtmService.pushDirective({
                category: 'New website - log in',
                action: 'Password login',
                label: 'Login appleSignIn failed - apple general error'
            });
        } finally {
            this.updateLoaderStatusEvent.emit(true);
            this.dontShowEmptyFieldError = false;
        }
    }
}
