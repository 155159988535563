import { Injectable } from '@angular/core';
import { CookieService } from './cookie.service';
import { UUID } from 'angular2-uuid';

@Injectable({ providedIn: 'root' })
export class CustomTrackingService {

    constructor(private cookieService: CookieService) {

    }

    registerCookie(): void {
        if (!this.cookieService.documentIsAccessible) {
            return;
        }

        if (this.cookieService.check('ct2')) {
            return;
        }

        const uid = UUID.UUID();

        const d1 = new Date();
        const d2 = new Date(d1);
        d2.setMinutes(d1.getMinutes() + 15);

        const domain = window.location.hostname.replace('www', '').replace('onlinelc', '');

        this.cookieService.set('ct2', `t=${uid}`, d2, '/', domain, false, null, (val) => val);
    }
}
