<div class="modal-header">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="next('close')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="transfer-popup error-popup">
    <div class="content">
        <img
            [src]="'/assets/images/homepage/' + picture"
            alt=""
            [class.w100]="picture == 'icecream.svg'"
            class="icon"
        />
        <ng-container *ngIf="type == 'side'">
            <p>להמשך ביצוע פעולות נוספות על הקופה שלך יש לעבור לאפליקציית max לקוביית החסכונות שלי</p>
            <p>
                עדיין אין לך את האפליקציה שלנו? זה הזמן להוריד אותה מחנות האפליקציות במכשירך
            </p>
        </ng-container>
        <ng-container *ngIf="type == 'policy'">
            <p class="policy">אנחנו מעבירים אותך לאתר ביטוח ישיר</p>
        </ng-container>
        <ng-container *ngIf="type == 'insurance'">
            <p class="policy">אנחנו מעבירים אותך לאתר {{content.insuranceCompny}}</p>
        </ng-container>
        <ng-container *ngIf="type == 'error'">
            <p class="policy">
                יש לנו בעיה טכנית קטנה <br />
                אפשר לנסות שוב מאוחר יותר
            </p>
        </ng-container>
        <div class="button-wrapper">
            <a
                (click)="next()"
                class="brand-button aqua-button"
                title=""
                id="retry"
            >
                <span *ngIf="type == 'side' || type == 'error'">חזרה לעמוד הבית</span>
                <span *ngIf="type == 'policy' || type == 'insurance'">בואו נעבור </span>
            </a>
            <a *ngIf="type == 'policy' || type == 'insurance'" (click)="next('link')" class="link">תודה, לא עכשיו</a>
        </div>
    </div>

    <div class="login-frm_captcha">
        <re-captcha
            #captchaRef="reCaptcha"
            siteKey="{{ recaptchaSiteKey }}"
            size="invisible"
            (resolved)="$event && saveRecaptchaEvent($event)"
        >
        </re-captcha>
    </div>
</div>
<max-loader *ngIf="loader"></max-loader>
