import { Observable } from 'rxjs';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { LoggerHandlerService } from '../services/logger.handler.service';
import { ErrorsHandlerService } from '../services/errors.handler.service';
import { isNullOrUndefined } from 'core-max-lib';
import { SessionTimeoutHandlerService } from '../services/session-timeout-handler.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpServiceSessionTimeoutInterceptor implements HttpInterceptor {

    private isBrowser: boolean;

    constructor( @Inject(PLATFORM_ID) private platformId: any,
        private loggerHandlerService: LoggerHandlerService,
        private errorHandler: ErrorsHandlerService,
        private sessionTimeoutHandlerService: SessionTimeoutHandlerService) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(
            (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (this.isBrowser && !req.url.includes('logs/log') && !req.url.includes('session/keepAlive'))
                        this.sessionTimeoutHandlerService.onNewApiCallResponseSubject.next();
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (this.isBrowser && err.status === 408) {
                        let errorMessage: string = this.BuildErrorMessage(err);
                        this.loggerHandlerService.warning.next(errorMessage);
                        this.errorHandler.redirectToSessionExpiredPageSubject.next();
                    }
                }
            }
        ));
    }

    private BuildErrorMessage(error: any): string {
        let message = `HTTPERROR. Message [${error.message}]`;
        if (!isNullOrUndefined(error.name)) {
            message += ` Name [${error.name}]`;
        }
        if (!isNullOrUndefined(error.status)) {
            message += ` Status [${error.status}]`;
        }
        if (!isNullOrUndefined(error.statusText)) {
            message += ` StatusText [${error.statusText}]`;
        }
        if (!isNullOrUndefined(error.url)) {
            message += ` Url [${error.url}]`;
        }
        return message;
    }
}

