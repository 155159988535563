import { Injectable, NgZone, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
    Router,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError,
    RoutesRecognized,
    Event as NavigationEvent,
    ActivatedRoute
} from '@angular/router';
import { isNullOrUndefined } from 'core-max-lib';
import { LoggerService } from './logger.service';
import { ErrorsHandlerService } from './errors.handler.service';
import { RoutingHistoryService } from './routing-history.service';
import { Title } from '@angular/platform-browser';
import { AppConfigsService } from '../../../config/app-configs.service';
import { DOCUMENT } from '@angular/common';
import { UiHelperService } from './ui-helper.service';

@Injectable()
export class LoggerGlobalService {
    // Todo : check if subject next can return value
    private isBrowser: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private zone: NgZone,
        private logger: LoggerService,
        private errorsHandler: ErrorsHandlerService,
        private routingHistoryService: RoutingHistoryService,
        private uiHelperService: UiHelperService,
        private title: Title,
        private appConfigService: AppConfigsService,
        @Inject(DOCUMENT) private document: any
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.setSubscriptions();
        this.setRoutingLogging();
        this.setErrorListeningLogging();
        this.setDomListeningLogging();
        // todo: move to module
    }

    private setSubscriptions(): void {
        const redirectToErrorPage = this.errorsHandler.redirectToErrorPageSubject.subscribe(() => {
            redirectToErrorPage.unsubscribe();
            this.router.navigate(['error']);
        });
        if (this.uiHelperService.isBrowser) {
            const redirectToLoginPage = this.errorsHandler.redirectToLoginPage.subscribe((status: any) => {
                redirectToLoginPage.unsubscribe();
                const queryParams = status === 403 ? 'nar' : 'sto';
                this.document.location.href = `${this.appConfigService.appConfigs.infoRoot}/Logout.aspx?t=${queryParams}`;
            });
        }
        const loggerHandlerServiceRedirectToSessionExpiredPageSubjectSubscribtion = this.errorsHandler.redirectToSessionExpiredPageSubject.subscribe(
            () => {
                loggerHandlerServiceRedirectToSessionExpiredPageSubjectSubscribtion.unsubscribe();
                this.router.navigate(['session-expired'], { skipLocationChange: true });
            }
        );

        const loggerHandlerServiceRedirectToHomePageSubjectSubscribtion = this.errorsHandler.redirectToHomePageSubject.subscribe(
            () => {
                loggerHandlerServiceRedirectToHomePageSubjectSubscribtion.unsubscribe();
                this.router.navigate(['home']);
            }
        );
    }

    private setRoutingLogging(): void {
        this.router.events.forEach((event: NavigationEvent) => {
            // if (event instanceof NavigationError) {
            //    this.logger.log("An error occurred while navigating user to url [" + event.url + "]");
            // }
            // if (event instanceof RoutesRecognized) {
            //    this.logger.log("User navigation route [" + event.url + "] was recognized");
            // }
            if (event instanceof NavigationStart) {
                if (this.routingHistoryService.isFirstPage) {
                    this.logger.log('User enter the application URL [' + event.url + ']');
                }
            }
            if (event instanceof NavigationEnd) {
                this.logger.log('User entered page [' + this.title.getTitle() + ']');
            }
            if (event instanceof NavigationCancel) {
                this.logger.warning('User navigation to URL [' + event.url + '] was canceled');
            }
        });
    }

    private setErrorListeningLogging(): void {
        this.zone.onError.subscribe((event: any) => {
            let errorMessage = `ANGULAR. Error [${event}]`;
            if (!isNullOrUndefined(event.message)) {
                errorMessage += ` Message [${event.message}]`;
            }
            if (!isNullOrUndefined(event.name)) {
                errorMessage += ` Name [${event.name}]`;
            }
            // if (!isNullOrUndefined(event.stack)) {
            //    errorMessage += ` Stack [${event.stack}]`;
            // }
            this.logger.warning(errorMessage);
        });
    }

    private setDomListeningLogging(): void {
        // todo: zone dome events: click
    }
}
