import { Injectable } from '@angular/core';
import { ModalService } from '../../modules/bs-modal/services/modal.service';
import { AppConfigsService } from '../../../config/app-configs.service';
import { AppleSignInService } from '../../cardholders-core/services/apple-sign-in.service';
import { Base64Service } from '../../cardholders-core/services/base64.service';
import { FingerPrintService } from '../../cardholders-core/services/fingerPrint.service';
import { GtmService } from '../../cardholders-core/services/gtm.service';
import { LoggerService } from '../../cardholders-core/services/logger.service';
import { UiHelperService } from '../../cardholders-core/services/ui-helper.service';
import { isAppleDevice } from '../../services/is-apple-device';
import { MainLayoutService } from '../../../modules/main-layout/services/main-layout.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BiometricModal } from 'ClientApp/app/modules/login/biometric/biometric.constant';
import { Subscription } from 'rxjs/Subscription';
import { HttpService, IHttpRequestOptions, IResponseLower } from './http-service.service';
import { CookieService } from './cookie.service';

declare const AppleID: any;
declare const PublicKeyCredential: any;
export interface IFingerPrintStates {
    loader: boolean;
    title: string;
    message: string;
    showMessage: boolean;
    show: boolean;
    exist: boolean;
    enableLog: boolean;
}

export interface IAppleSignInStates {
    loader: boolean;
    title: string;
    message: string;
    showError: boolean;
    show: boolean;
    sucess: boolean;
    enableLog: boolean;
    showPopup: boolean;
    isAppleDevice: boolean;
}

@Injectable({ providedIn: 'root' })
export class BiometricPopupService {
    cookie: string;
    set appleSignInStates(value: IAppleSignInStates) {
        this._appleSignInStates.next(value);
    }
    set fingerPrintStates(value: IFingerPrintStates) {
        this._fingerPrintStates.next(value);
    }
    get appleSignInStates(): IAppleSignInStates {
        return this._appleSignInStates.getValue();
    }
    get fingerPrintStates(): IFingerPrintStates {
        return this._fingerPrintStates.getValue();
    }
    get fingerPrintStatesObs(): Observable<IFingerPrintStates> {
        return this._fingerPrintStates.asObservable();
    }
    get appleSignInStatesObs(): Observable<IAppleSignInStates> {
        return this._appleSignInStates.asObservable();
    }
    APPLE_SIGN_IN_STORAGE_KEY = 'appleSignIn';
    BIOMETRIC_POPUP_STORAGE_KEY = 'BiometricPopup';
    appleSignInChallenge: string;
    isGetChallenge: boolean;
    storageItem: { pass: number; date: Date };
    tokenChanllange: string;
    private _appleSignInStates = new BehaviorSubject<IAppleSignInStates>({
        loader: false,
        message: null,
        title: null,
        showError: false,
        show: false,
        sucess: false,
        enableLog: false,
        showPopup: false,
        isAppleDevice: false
    });
    private _fingerPrintStates = new BehaviorSubject<IFingerPrintStates>({
        title: null,
        loader: false,
        message: null,
        showMessage: false,
        show: false,
        exist: false,
        enableLog: false
    });
    constructor(
        private uihelper: UiHelperService,
        private modalService: ModalService,
        private appleSignInService: AppleSignInService,
        private gtmService: GtmService,
        public logger: LoggerService,
        private base64Service: Base64Service,
        private fingerPrintService: FingerPrintService,
        private mainLayoutService: MainLayoutService,
        private appConfig: AppConfigsService,
        private route: ActivatedRoute,
        private httpSvc: HttpService,
        private appConfigsService: AppConfigsService,
        private cookieService: CookieService
    ) {}
    check(): void {
        if (this.uihelper.isBrowser) {
            this.resetDefaultAppleState();
            this.resetDefaultFingerPrintState();
            if (!this.appConfig.appConfigs.FLAG_ISEnableBiometricPopup) {
                return;
            }
            if (!!localStorage.getItem(this.BIOMETRIC_POPUP_STORAGE_KEY)) {
                this.storageItem = JSON.parse(localStorage.getItem(this.BIOMETRIC_POPUP_STORAGE_KEY));
                const today = moment();
                const passDate = moment(this.storageItem.date);

                if (today.diff(passDate, 'months') > 4) {
                    this.storageItem = { pass: 0, date: new Date() };
                }
                if (this.storageItem.pass >= 3) {
                    return;
                }
            } else {
                this.storageItem = { pass: 0, date: new Date() };
            }

            this.appleSignInStates.sucess = !!localStorage.getItem(this.APPLE_SIGN_IN_STORAGE_KEY);
            if (this.appleSignInStates.sucess) {
                return;
            }
            this.appleSignInStates.isAppleDevice = isAppleDevice();
            if (this.appleSignInStates.isAppleDevice && this.appleSignInStates.sucess) {
                this.initAppleID();
            } else {
                this.initAndroidID();
            }
        }
    }
    async initAndroidID(): Promise<void> {
        this.fingerPrintStates.enableLog = this.route.snapshot.queryParams.log;

        if (this.fingerPrintStates.enableLog) {
            this.logger.error(
                JSON.parse(
                    JSON.stringify({
                        enabledWithoutQp: this.appConfig.appConfigs.FLAG_ISFingerPrintEnabledWithoutQp,
                        Enabled: this.appConfig.appConfigs.FLAG_ISFingerPrintEnabled,
                        fpQueryParams: this.route.snapshot.queryParams.fp === 'enable'
                    })
                )
            );
        }

        if (
            this.appConfig.appConfigs.FLAG_ISFingerPrintEnabledWithoutQp ||
            (this.appConfig.appConfigs.FLAG_ISFingerPrintEnabled && this.route.snapshot.queryParams.fp === 'enable')
        ) {
            this.fingerPrintStates = {
                ...this.fingerPrintStates,
                exist: !!localStorage.getItem('fpId'),
                show: this.uihelper.IsMobile(900) && (await this.checkIfFingerPrintAvailable())
            };
        }
        if (!this.fingerPrintStates.show) {
            return;
        }
    }
    openModal(): void {
        localStorage.setItem(
            this.BIOMETRIC_POPUP_STORAGE_KEY,
            JSON.stringify({ pass: this.storageItem.pass + 1, date: new Date() })
        );
        this.modalService.openModal(BiometricModal);
    }
    initAppleID(): void {
        if (!window['AppleID']) {
            this.loadScript().then(_ => {
                if (!window['AppleID']) {
                    return console.warn('AppleID object not loaded!');
                }
                console.log(AppleID);

                AppleID.auth.init({
                    clientId: 'il.co.max.signInService',
                    redirectURI: `${window.location.origin}/api/apple/signIn`,
                    usePopup: true
                });
            });
        }
        this.getChallenge();
    }
    async getChallenge(): Promise<any> {
        // const challengeRequest = await this.appleSignInService.getChallenge().toPromise();

        this.appleSignInService.getChallengeH().subscribe(challengeRequest => {
            this.gtmService.pushDirective({
                category: 'New website - registered',
                action: 'Biometric Popup',
                label: 'Register appleSignIn - get challenge'
            });
            if (challengeRequest.body.returnCode === 0 && challengeRequest.body.result) {
                this.tokenChanllange = challengeRequest.headers.get('token');
                this.appleSignInChallenge = challengeRequest.body.result;
                this.appleSignInStates.show = true;
                this.isGetChallenge = true;
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Biometric Popup',
                    label: 'Register appleSignIn - show register'
                });
                this.openModal();
            } else {
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Biometric Popup',
                    label: 'Register appleSignIn failed - get challenge'
                });
            }
        });
    }
    async loadScript(): Promise<any> {
        return await this.appleSignInService.loadScript('/assets/js/apple-signin.js');
    }
    checkPopup(): Observable<IResponseLower<boolean>> {
        return this.httpSvc.getLower(`${this.appConfigsService.appConfigs.apiUrl}/appleSignIn/checkPopup`);
    }
    async addFingerPrint(): Promise<boolean> {
        return new Promise<boolean>(async (res, rej) => {
            this.gtmService.pushDirective({
                category: 'New website - registered',
                action: 'Personal menu',
                label: 'Click on add fingerprint'
            });
            this.fingerPrintStates = {
                ...this.fingerPrintStates,
                loader: true
            };
            const challengeResponse = await this.fingerPrintService.getChallenge().toPromise();
            this.fingerPrintStates = {
                ...this.fingerPrintStates,
                loader: false
            };
            if (!this.mainLayoutService.userbox) {
                const userbox: any = await this.mainLayoutService.loadUserBox();
                if (!userbox || !userbox.Result) {
                    this.fingerPrintStates = {
                        loader: false,
                        title: 'אופס',
                        message: 'משהו לא התסדר ממליצים לנסות שוב',
                        showMessage: true,
                        show: false,
                        exist: false,
                        enableLog: this.fingerPrintStates.enableLog
                    };
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: 'Register fingerprint failed - general error'
                    });
                    this.logger.error(`Register fingerprint failed - userbox cannot be initiate`);
                    res(false);
                    return;
                }
            }
            if (challengeResponse.returnCode === 0) {
                try {
                    navigator.credentials
                        .create({
                            publicKey: {
                                authenticatorSelection: {
                                    authenticatorAttachment: 'platform',
                                    userVerification: 'required'
                                },
                                challenge: Uint8Array.from(
                                    challengeResponse.result as any,
                                    (c: any) => c.charCodeAt(0) as any
                                ),
                                rp: { id: document.domain, name: 'Max fido services' },
                                user: {
                                    id: Uint8Array.from('userID goes here' as any, (c: any) => c.charCodeAt(0) as any),
                                    name: this.mainLayoutService.userbox.Result.UserName,
                                    displayName: this.mainLayoutService.userbox.Result.UserName
                                },
                                pubKeyCredParams: [{ type: 'public-key', alg: -7 }],
                                attestation: 'direct'
                            }
                        })
                        .then(async credential => {
                            await this.verify(credential);
                            res(true);
                            return;
                        })
                        .catch(error => {
                            this.fingerPrintStates = {
                                loader: false,
                                title: 'אופס',
                                message: 'משהו לא התסדר ממליצים לנסות שוב',
                                showMessage: true,
                                show: false,
                                exist: false,
                                enableLog: this.fingerPrintStates.enableLog
                            };
                            this.gtmService.pushDirective({
                                category: 'New website - registered',
                                action: 'Personal menu',
                                label: 'Register fingerprint failed - general error'
                            });
                            this.logger.error(
                                `Register fingerprint failed - general error - navigator.credentials.create - message: ${error.message}`
                            );
                            res(false);
                            return;
                        });
                } catch (error) {
                    if (error.message === `Cannot read property 'create' of undefined`) {
                        this.fingerPrintStates = {
                            loader: false,
                            title: 'אופס',
                            message: 'סוג המכשיר שלך או הדפדפן אינו תומך בטביעת אצבע.',
                            showMessage: true,
                            show: false,
                            exist: false,
                            enableLog: this.fingerPrintStates.enableLog
                        };
                        sessionStorage.setItem('unsupportedFp', 'true');
                        this.gtmService.pushDirective({
                            category: 'New website - registered',
                            action: 'Personal menu',
                            label: 'Register fingerprint failed - device not supported'
                        });
                        this.logger.error(
                            'Register fingerprint failed - general error - Cannot read property create of undefined'
                        );
                        res(false);
                    } else if (error.message === `Not implemented`) {
                        this.fingerPrintStates = {
                            loader: false,
                            title: 'אופס',
                            message: 'נראה שדפדפן זה אינו תומך עדיין בטביעת אצבע.',
                            showMessage: true,
                            show: false,
                            exist: false,
                            enableLog: this.fingerPrintStates.enableLog
                        };
                        localStorage.setItem('browserNotSupported', 'true');
                        this.gtmService.pushDirective({
                            category: 'New website - registered',
                            action: 'Personal menu',
                            label: 'Register fingerprint failed - browser not supported'
                        });
                        this.logger.error(`Browser not implement fp logic. user agent: ${window.navigator.userAgent}`);
                        res(false);
                    } else {
                        this.gtmService.pushDirective({
                            category: 'New website - registered',
                            action: 'Personal menu',
                            label: 'Register fingerprint failed - general error'
                        });
                        this.logger.error(
                            `Register fingerprint failed - general error - catch addFingerPrint. message: ${error.message ||
                                error}`
                        );
                    }
                    res(false);
                }
            } else if (challengeResponse.returnCode === 10) {
                this.fingerPrintStates = {
                    showMessage: true,
                    title: 'אופס!',
                    message: 'ניתן להירשם לשירות רק באמצעות התחברות עם שם משתמש וסיסמה, ולא באמצעות קוד חד פעמי לנייד.',
                    loader: false,
                    show: false,
                    exist: false,
                    enableLog: this.fingerPrintStates.enableLog
                };
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Register fingerprint failed - user logged by otp'
                });
                this.logger.warning('Register fingerprint failed - user logged by otp');
                res(false);
            } else {
                this.fingerPrintStates = {
                    showMessage: true,
                    title: 'אופס',
                    message: 'יש לנו תקלה טכנית, מומלץ לנסות שוב מאוחר יותר',
                    loader: false,
                    show: true,
                    exist: false,
                    enableLog: this.fingerPrintStates.enableLog
                };
                this.gtmService.pushDirective({
                    category: 'New website - registered',
                    action: 'Personal menu',
                    label: 'Register fingerprint failed - general error'
                });
                this.logger.error(`Register fingerprint failed - general error - getChallenge`);
                res(false);
            }
        });
    }
    resetDefaultAppleState(): void {
        this.appleSignInStates = {
            loader: false,
            message: null,
            title: null,
            showError: false,
            show: false,
            sucess: false,
            enableLog: false,
            showPopup: false,
            isAppleDevice: false
        };
    }
    resetDefaultFingerPrintState(): void {
        this.fingerPrintStates = {
            title: null,
            loader: false,
            message: null,
            showMessage: false,
            show: false,
            exist: false,
            enableLog: false
        };
    }
    createNewCookie(): void {
        if (this.uihelper.isBrowser) {
            let needToShow = false;
            if (!!localStorage.getItem(this.BIOMETRIC_POPUP_STORAGE_KEY)) {
                this.storageItem = JSON.parse(localStorage.getItem(this.BIOMETRIC_POPUP_STORAGE_KEY));
                const today = moment();
                const passDate = moment(this.storageItem.date);

                if (today.diff(passDate, 'months') > 4) {
                    needToShow = true;
                } else if (this.storageItem.pass >= 3) {
                    needToShow = false;
                } else {
                    needToShow = true;
                }
            } else {
                needToShow = true;
            }
            if (needToShow) {
                needToShow = !localStorage.getItem(this.APPLE_SIGN_IN_STORAGE_KEY);
            }
            this.cookie = `NeedToShow=${isAppleDevice() && needToShow};LastShow=${new Date().toUTCString()}`;
            this.cookieService.set(
                this.BIOMETRIC_POPUP_STORAGE_KEY,
                this.cookie,
                new Date('3333-01-01'),
                '/',
                window.location.origin.split('.')[1] + '.co.il',
                null,
                null
            );
        }
    }

    private verify(credential: any): Promise<boolean> {
        this.fingerPrintStates = {
            ...this.fingerPrintStates,
            loader: true
        };
        this.fingerPrintStates.loader = true;
        const attestation = {
            type: credential.type,
            id: credential.id,
            response: {
                clientDataJSON: this.base64Service.bufferToString(credential['response'].clientDataJSON),
                attestationObject: this.base64Service.bufferToString(credential['response'].attestationObject)
            }
        };
        return new Promise<boolean>(async (resolve, reject) => {
            this.fingerPrintService.verifyAttestation(attestation).subscribe(res => {
                this.fingerPrintStates.loader = false;
                if (res.returnCode === 0) {
                    this.fingerPrintStates = {
                        showMessage: false,
                        title: 'סיימתם!',
                        message: 'נרשמת בהצלחה לזיהוי חכם, הכניסה הבאה תהיה קלילה יותר',
                        loader: false,
                        show: false,
                        exist: true,
                        enableLog: this.fingerPrintStates.enableLog
                    };
                    localStorage.setItem('fpId', res.result);
                    sessionStorage.removeItem('unsupportedFp');
                    localStorage.removeItem('browserNotSupported');
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: 'Register fingerprint - successful'
                    });
                    this.logger.log('Register fingerprint - successful');
                    resolve(true);
                } else if (res.returnCode === 10 || res.returnCode === 11) {
                    this.fingerPrintStates = {
                        showMessage: true,
                        title: 'אופס',
                        message: 'כרגע לא ניתן להצטרף לשירות זיהוי חכם',
                        loader: false,
                        show: false,
                        exist: false,
                        enableLog: this.fingerPrintStates.enableLog
                    };
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: 'Register fingerprint failed - security issue'
                    });
                    this.logger.error('Register fingerprint failed - security issue - verify');
                    resolve(false);
                } else {
                    this.fingerPrintStates = {
                        showMessage: true,
                        title: 'אוי!',
                        message: ' קרתה לנו תקלה, ממליצים לנסות שוב מאוחר יותר',
                        loader: false,
                        show: false,
                        exist: false,
                        enableLog: this.fingerPrintStates.enableLog
                    };
                    this.gtmService.pushDirective({
                        category: 'New website - registered',
                        action: 'Personal menu',
                        label: 'Register fingerprint failed - general error'
                    });
                    this.logger.error('Register fingerprint failed - general error - verify');
                    resolve(false);
                }
            });
        });
    }
    private async checkIfFingerPrintAvailable(): Promise<boolean> {
        try {
            if (sessionStorage.getItem('unsupportedFp')) {
                if (this.fingerPrintStates.enableLog) {
                    this.logger.error(`fingerprint not available because the SessionStorage set unsupportedFp`);
                }
                return false;
            }
            if (localStorage.getItem('fpId')) {
                if (this.fingerPrintStates.enableLog) {
                    this.logger.error(
                        `fingerprint not available because the there is already fp in localStorage ${localStorage.getItem(
                            'fpId'
                        )}`
                    );
                }
                return false;
            }
            if (localStorage.getItem('browserNotSupported')) {
                if (this.fingerPrintStates.enableLog) {
                    this.logger.error(
                        `fingerprint not available because the spcific browser not implement finger print logic yet. user agent: ${window.navigator.userAgent}`
                    );
                }
                return false;
            }
            if (isAppleDevice()) {
                if (this.fingerPrintStates.enableLog) {
                    this.logger.error(`fingerprint not available because its ios`);
                }
                return false;
            }

            if (!!PublicKeyCredential) {
                const result = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
                if (this.fingerPrintStates.enableLog) {
                    this.logger.error(
                        `Fingerprint ${result ? 'not' : ''} enabled because the device ${result ? 'not' : ''} supported`
                    );
                }
                return result;
            }
            if (this.fingerPrintStates.enableLog) {
                this.logger.error(`Fingerprint not enabled because PublicKeyCredential was not found`);
            }
            return false;
        } catch (error) {
            if (this.fingerPrintStates.enableLog) {
                this.logger.error(`fingerPrint not enabled log from catch ${error.message}`);
            }
            return false;
        }
    }
}
