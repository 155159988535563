import { ComponentBase } from './control-base';

export class SummaryBoxComponent extends ComponentBase<string> {
  controlType = 'summaryBox';
  options: { key: string, value: string };

  constructor(options: {}) {
    super(options);
    this.options = options['options'] || {};
  }
}
