import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { LoginStatus } from '../../../../shared/cardholders-core/models/login-result';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { BiometricPopupService } from '../../../../shared/cardholders-core/services/biometricPopup.service';
import { Fido2Service } from '../../../../shared/cardholders-core/services/fido2.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';
import { isIos } from '../../../../shared/services/isIos';
import { LoginWithMaxService } from '../../../login-with-max/login-with-max.service';
import { OpenBankingService } from '../../../open-banking/open-banking.service';
import { LoginPageService } from '../../login-page/login-page.service';
import { LoginService } from '../../login.service';
import { BaseLoginComponent } from '../base-components/base-login.component';

@Component({
  selector: 'app-fido2',
  templateUrl: './fido2.component.html'
})
export class Fido2Component extends BaseLoginComponent implements OnInit {

    @Input() username: string;
    loginPageMode: boolean;
    isIos = isIos();

  constructor(protected router: Router,
    protected authService: AuthService,
    protected modalSvc: ModalService,
    protected appConfigsService: AppConfigsService,
    protected gtmService: GtmService,
    protected userLoginService: LoginService,
    protected uiHelper: UiHelperService,
    protected loginWithMaxSvc: LoginWithMaxService,
    protected loginPageSvc: LoginPageService,
    protected openBankingService: OpenBankingService,
    protected bioSrv: BiometricPopupService,
    private fido2Service: Fido2Service) {
      super(router,
        authService,
        modalSvc,
        appConfigsService,
        gtmService,
        userLoginService,
        uiHelper,
        loginWithMaxSvc,
        openBankingService,
        bioSrv);
  }

  ngOnInit(): void {
    this.loginPageMode = this.loginPageSvc.isLoginPageMode();
    this.login();
  }

  login(){
    this.updateLoaderStatusEvent.emit(false);
    this.fido2Service.login(this.username).subscribe(res => {
        if (res.ReturnCode === 0) {
            this.checkLoginStatus(res.Result, this.updateLoaderStatusEvent);

            if (
                res.Result.LoginStatus === LoginStatus.success ||
                res.Result.LoginStatus === LoginStatus.NeedToShowInsurance
            ) {
                this.gtmService.pushDirective({
                    category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                    action: 'Password login',
                    label: this.isIos ? 'appleSignIn login successful' : 'Fingerprint login successful'
                });
                if (res.Result.LoginStatus === LoginStatus.NeedToShowInsurance) {
                    if (this.isLoginWithMax) {
                        this.userLoginService.loginWithMax();
                    } else if (this.isOpenBankingLogin) {
                        this.openBankingService.onOpenBankingLoginSuccess.next(true);
                    } else {
                        if (
                            res.Result.UserTransferResult &&
                            res.Result.UserTransferResult.IsTransferMessageNeeded
                        ) {
                            this.loginService.navigateUserTransferPage(
                                '/insurance/personal',
                                true,
                                this.updateLoaderStatusEvent
                            );
                        } else {
                            this.router.navigate(['/insurance/personal']);
                        }
                    }
                } else {
                    this.userLoginService.navigateReturnUrlAfterLoginSuccess();
                }
                this.modalSvc.closeModal();
            } else if (this.isLoginWithMax || this.isOpenBankingLogin) {
                this.gtmService.pushDirective({
                    category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                    action: 'Password login',
                    label: this.isIos ? 'appleSignIn login successful' : 'Fingerprint login successful'
                });
                this.userLoginService.navigateReturnUrlAfterLoginSuccess();
            } else {
                this.gtmService.pushDirective({
                    category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                    action: 'Password login',
                    label: this.isIos ? 'Failed to verify appleSignIn' : 'Failed to verify fingerprint'
                });
            }
        } else {
            this.gtmService.pushDirective({
                category: this.loginPageMode ? 'New website - log in page' : 'New website - log in',
                action: 'Password login',
                label: this.isIos ? 'Failed to verify appleSignIn' : 'Failed to verify fingerprint'
            });
        }
        this.updateLoaderStatusEvent.emit(true);
    })
  }
}
