import { ComponentBase } from './control-base';

export class DateComponent extends ComponentBase<string> {
  controlType = 'date';
  type: string;
  fromYear: number;

  constructor(options: {}) {
    super(options);
    this.type = options['type'] || '';
    this.fromYear = options['fromYear'] || 0;
  }
}
