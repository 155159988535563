
<div class="modal-header">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="status-popup">
    <img *ngIf="data?.img" [src]="data?.img" class="music-balls" alt="" />
    <h2 *ngIf="data?.title">{{data.title}}</h2>
    <p class="notice">
        {{data.subTitle}}
        
    </p>
    <p class="notice" [innerHTML]="data.text | sanitizeHtml">

    </p>
    <div class="button-wrapper">
        <a *ngIf="data?.btnText" (click)="action(1)" class="brand-button aqua-button" title=""><span>{{data?.btnText}}</span></a>
        <a *ngIf="data?.linkText" (click)="action(2)" class="link arrow-link" title="">{{data?.linkText}}</a>    
    </div>
</div>