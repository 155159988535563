import { Injectable } from '@angular/core';
import { PopupsService, PopupTypes } from "../../../shared/cardholders-core/services/popups.service";
import { ModalService } from '../../../shared/modules/bs-modal/services/modal.service';
import { SessionTimeout } from '../constants/modals.consts';

@Injectable()
export class MainLayoutSessionService {

    constructor(private popupsService: PopupsService, private modalSvc: ModalService) { }

    private sessionTimeoutInitialized: boolean = false;
    private sessionTimeoutSub: any;

    initSessionTimeout() {
        if (this.sessionTimeoutInitialized) {
            return;
        }


        this.sessionTimeoutSub = this.popupsService.popupSubject.subscribe(
            (popup: { popupType: PopupTypes; data?: any; show: boolean }) => {
                if (popup.popupType === PopupTypes.sessionTimeoutPopup) {
                    if (popup.show) {
                        this.modalSvc.openModal(SessionTimeout);
                    } else {
                        this.modalSvc.closeModal();
                    }
                }
            }
        );
        this.sessionTimeoutInitialized = true;
    }

    clearSessionTimeout() {
        if (this.sessionTimeoutInitialized) {
            this.sessionTimeoutSub.unsubscribe();
            this.sessionTimeoutInitialized = false;
        }
    }
}