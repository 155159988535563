import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IResponse, HttpService } from '../cardholders-core/services/http-service.service';
import { JoinService } from './join.service';
import { ICard } from '../models/cards/card';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { AppConfigsService } from '../../config/app-configs.service';
import { isPlatformBrowser } from '@angular/common';
import { IFooter } from '../models/footer/footer';
import { IHeader, IWorld } from '../models/header/header';
import { ICardCategories } from '../models/card-categories/card-categories';
import { ILoginInfo } from '../../modules/homepage/models/login-info';
import { PreviewService } from '../../modules/preview/preview.service';

@Injectable({
    providedIn: 'root',
})
export class RestApiService {
    headerData: IWorld[];
    footerData;
    header;
    cardsSubject: ReplaySubject<any>;
    categoriesSubject: ReplaySubject<any>;
    searchAutocompleteStrings: string[];
    private _cardValue : ICard;
    private _cardsValue;
    private _catgoriesValue;
    private isBrowser: boolean;
    private isPreviewEnabled: boolean;
    constructor(
        private http$: HttpClient,
        private appConfigsService: AppConfigsService,
        @Inject(PLATFORM_ID) private platformId: any,
        private httpSvc: HttpService,
        private previewService: PreviewService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        if (this.isBrowser) {
            // if (typeof (window['TRANSFER_CACHE']['headerData']) !== 'undefined') {
            //     this.headerData = window['TRANSFER_CACHE']['headerData'] as IWorld[];
            // }
            // if (typeof (window['TRANSFER_CACHE']['footerData']) !== 'undefined') {
            //     this.footerData = window['TRANSFER_CACHE']['footerData'];
            // }
            // if (typeof (window['TRANSFER_CACHE']['cardsData']) !== 'undefined') {
            //     this._cardsValue = window['TRANSFER_CACHE']['cardsData'];
            // }
            // if (typeof (window['TRANSFER_CACHE']['categoriesData']) !== 'undefined') {
            //     this._catgoriesValue = window['TRANSFER_CACHE']['categoriesData'];
            // }
            // if (typeof (window['TRANSFER_CACHE']['searchAutocompleteStringsData']) !== 'undefined') {
            //     this.searchAutocompleteStrings = window['TRANSFER_CACHE']['searchAutocompleteStringsData'];
            // }
            this.isPreviewEnabled = this.previewService.isEnabled();
        }
    }

    getCard(cardId: string): Observable<ICard> {
        return new Observable<ICard>(observer => {
            if (this._cardValue && this._cardValue.Id.toString() === cardId) {
                observer.next(this._cardValue);
                observer.complete();
            }
            else {
                this.http$.get<IResponse<ICard>>(`${this.appConfigsService.appConfigs.apiUrl}/card/getCard?cardId=${cardId}`).subscribe(res => {
                    if (res.ReturnCode === 0) {
                        this._cardValue = res.Result;
                        observer.next(this._cardValue);
                        observer.complete();
                    }
                });
            }
        });
    }

    getCards() {
        this.cardsSubject = new ReplaySubject<any>();
        if (!this._cardsValue || this.isPreviewEnabled) {
            this.http$.get<IResponse<ICard[]>>(`${this.appConfigsService.appConfigs.apiUrl}/card`).subscribe(_ => {
                this._cardsValue = _;
                this._cardsValue.Result.sort((a, b) => {
                    return a.Order - b.Order;
                });
                if (!this.isBrowser) {
                    global['cardsData'] = this._cardsValue;
                }
                this.cardsSubject.next(_);
                this.cardsSubject.complete();
            });
        } else {
            this.cardsSubject.next(this._cardsValue);
            this.cardsSubject.complete();
        }
        return this.cardsSubject;
    }
    getCategories() {
        this.categoriesSubject = new ReplaySubject<any>();
        if (!this._catgoriesValue || this.isPreviewEnabled) {
            this.http$.get<IResponse<ICardCategories>>(`${this.appConfigsService.appConfigs.apiUrl}/CardCategories`).subscribe(_ => {
                this._catgoriesValue = _;
                if (!this.isBrowser) {
                    global['categoriesData'] = this._catgoriesValue;
                }
                this.categoriesSubject.next(_);
                this.categoriesSubject.complete();
            });
        } else {
            this.categoriesSubject.next(this._catgoriesValue);
            this.categoriesSubject.complete();
        }
        return this.categoriesSubject;
    }
    getHeaderDetails() {
        if (!this.headerData || this.isPreviewEnabled) {
            return this.http$.get<IResponse<IHeader>>(`${this.appConfigsService.appConfigs.apiUrl}/header`).pipe(
                map((res: IResponse<IHeader>) => {
                    const data = res;
                    this.headerData = data.Result.TopMenuWorlds.map(world => {
                        return {
                            WorldID: world.WorldID,
                            topMenuImage: data.Result.TopMenuImages.find(i => {
                                if (i.WorldID === world.WorldID) {
                                    return true;
                                }
                            }),
                            Title: world.Title,
                            LeftBottomText: world.LeftBottomText,
                            url: world.LeftBottomUrl.NavigateUrl,
                            columns: data.Result.TopMenuItems.reduce((cols, item) => {
                                if (cols.find(i => i.column === item.Column)) { return cols; }
                                cols = [...cols, { column: item.Column }];
                                return cols;
                            }, []).map(item => {
                                return {
                                    column: item.column,
                                    items: data.Result.TopMenuItems.filter(menuItem => {
                                        return menuItem.WorldID === world.WorldID && menuItem.Column === item.column;
                                    })
                                };
                            })
                        };
                    });
                    if (!this.isBrowser) {
                        global['headerData'] = this.headerData;
                    }
                    return this.headerData;
                }));
        } else {
            return new Observable((ob) => {
                ob.next(this.headerData);
                ob.complete();
            });
        }
    }
    getFooterDetails() {
        if (!this.footerData || this.isPreviewEnabled) {
            return this.http$.get<IResponse<IFooter>>(`${this.appConfigsService.appConfigs.apiUrl}/footer`).pipe(
                map(res => {
                    const data = res;
                    this.footerData = {
                        itemsTopSection: this.filterFooterDetails(data.Result.AnonymousFooterSitemap),
                        itemsBottomSection: this.filterFooterDetails(data.Result.AnonymousSEOFooter),
                        FooterImages: data.Result.FooterImages,
                        FooterMessages: data.Result.FooterMessages
                    };
                    if (!this.isBrowser) {
                        global['footerData'] = this.footerData;
                    }
                    return this.footerData;
                }));
        } else {
            return new Observable((ob) => {
                ob.next(this.footerData);
                ob.complete();
            });
        }
    }
    filterFooterDetails(data) {
        let nameOfDetail = 'Row';
        if (Object.keys(data[0]).find(i => i === 'Column')) {
            nameOfDetail = 'Column';
        }
        return data
            .reduce((cols, item) => {
                if (cols.find(i => i.column === item[nameOfDetail])) { return cols; }
                cols = [...cols, { column: item[nameOfDetail] }];
                return cols;
            }, [])
            .map(item => {
                return {
                    column: item.column,
                    items: data.filter(menuItem => {
                        return menuItem[nameOfDetail] === item.column;
                    })
                };
            });
    }
    getSearchAutocompleteStrings() {
        if (!this.searchAutocompleteStrings || this.isPreviewEnabled) {
            return this.http$.get<IResponse<string[]>>(`${this.appConfigsService.appConfigs.apiUrl}/searchAutocomplete`).pipe(
                map(res => {
                    res.Result = res.Result.map(x => x.replace('\\', ''));
                    const data = res;
                    this.searchAutocompleteStrings = data.Result;
                    if (!this.isBrowser) {
                        global['searchAutocompleteStringsData'] = this.searchAutocompleteStrings;
                    }
                    return this.searchAutocompleteStrings;
                }));
        } else {
            return new Observable((ob) => {
                ob.next(this.searchAutocompleteStrings);
                ob.complete();
            });
        }
    }

    getLoginInfo(): Observable<IResponse<ILoginInfo>> {
        const url = `${this.appConfigsService.appConfigs.apiUrl
            }/registered/logininfo`;
        return this.httpSvc.get<ILoginInfo>(url);
    }
}
