import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IApiErrorLog } from '../models/api-error-log';
import { LoggerHandlerServiceAbstract } from 'core-max-lib';

/**
 * LogHandlingService gives you an option to use a LoggerService service in case you can't inject it (LoggerService) because of cyclic dependency!
 */
@Injectable()
export class LoggerHandlerService extends LoggerHandlerServiceAbstract { 
    // Todo#1: check if subject next can return value
    // Todo#2: add some identity to event NEXT to validate it in errorCompleted
    public log = new Subject();
    public warning = new Subject();
    public error = new Subject();
    public fatal = new Subject();

    public logCompleted = new Subject();
    public warningCompleted = new Subject();
    public errorCompleted = new Subject();
    public fatalCompleted = new Subject();

    public apiError = new Subject<IApiErrorLog>();
}
