import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn : 'root' })
export class SpinnerService {
  public spinner = new Subject();
  longLoading: boolean;
  title: string;
  subtitles: string[];
}
