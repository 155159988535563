import { Injectable, Inject, PLATFORM_ID } from '@angular/core';


@Injectable()
export class AutoFillOtpService {


    constructor() { }


    autoReadSMS(cb: (code: string) => void): void {
        const signalCtr = new AbortController();
        setTimeout(() => {
            signalCtr.abort();
        }, 1 * 60 * 1000);
        async function getOtp() {
            if ('OTPCredential' in window) {
                try {
                    if (window.navigator['credentials']) {
                        try {
                            let o = { otp: { transport: ['sms'] }, signal: signalCtr.signal };
                            await window.navigator['credentials']
                                .get(o)
                                .then((content: Credential) => {
                                    if (content && content['code']) {
                                        cb(content['code']);
                                    }
                                })
                                .catch(e => console.log(e));
                        } catch (e) {
                            console.log(e);
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        }
        getOtp();
    }
}
