import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CanActivate } from '@angular/router';
import { TokenService } from "../../shared/cardholders-core/services/token.service";
import { AppConfigsService } from "../../config/app-configs.service";

@Injectable()
export class SessionTimeoutGuard implements CanActivate {
    constructor(@Inject(DOCUMENT) private document: any,
        private appConfigService: AppConfigsService,
        private tokenService: TokenService,
    ) { }
    canActivate(): boolean {
        this.tokenService.cleanToken();
        this.document.location.href = `${this.appConfigService.appConfigs.infoRoot}/Logout.aspx?t=sto`;
        return false;
    }
}