<div class="input-group search-input" [ngClass]="{'hide-place-holder' : hidePlaceHolder, 'caret-color' : searchInput.filled}" *appLoader="searchRedirectLoader; template: searchLoaderTemplateEL">

    <div class="search">
        <p-autoComplete #searchInput [type]="'search'" [styleClass]="'search-autocomplete'" [inputStyleClass]="'search-input'" [suggestions]="results" (completeMethod)="search($event)" field="item" (onSelect)="onSelect($event)" (onFocus)="onFocus()" (onBlur)="onBlur()" [scrollHeight]="'200px'" [(ngModel)]="text" (onKeyUp)="onKeyUp($event)">
            <ng-template let-item pTemplate="item">
                <div fxLayout fxLayoutAlign="space-between center" class="stretch-width">
                    <div class="text-right">{{item}}</div>
                </div>
            </ng-template>
        </p-autoComplete>

        <span *ngIf="!removePlaceHolder" class="label-group" (click)="searchInput.inputEL.nativeElement.focus()">
            <label>מה לחפש?</label>&nbsp;
            <label>הטבות, הלוואות, כרטיסים...</label>
        </span>
        <div *ngIf="!searchInput.filled" class="icon-container">
            <i class="icon" (click)="searchInput.inputEL.nativeElement.focus()"></i>
        </div>
        <div *ngIf="searchInput.filled && isMobile" class="x-container" (click)="text='';searchInput.inputEL.nativeElement.focus()">
            <div class="x-wrapper">
            </div>
        </div>

        <!--<span class="search-button" (click)="search(searchInput.value)"></span>-->
    </div>
</div>
<ng-template #searchLoaderTemplateEL>
    <div class="input-group search-input search-loader-template">
        <div class="search">
            <span class="search-loader-text">
                <label>{{text}}</label>
            </span>
            <div class="icon-container">
                <i class="icon"></i>
            </div>
        </div>
    </div>
</ng-template>