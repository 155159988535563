import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SsoGeneralRoutingModule } from './sso-general-routing.module';
import { SsoGeneralComponent } from './sso-general.component';
import { SharedModule } from '../../shared/shared.module';
import { CardholdersCoreModule } from '../../shared/cardholders-core/cardholders-core.module';
import { SsoGeneralService } from './services/sso-general.service';

@NgModule({
    declarations: [SsoGeneralComponent],
    imports: [CommonModule, SsoGeneralRoutingModule, SharedModule, CardholdersCoreModule],
    providers: [SsoGeneralService]
})
export class SsoGeneralModule {}
