import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { InitializerService } from 'core-max-lib';

export enum Dimensions {
    dimension1 = 'CT Dimension 1',
    dimension2 = 'CT Dimension 2',
    dimension3 = 'CT Dimension 3',
    dimension4 = 'CT Dimension 4',
    dimension5 = 'CT Dimension 5',
    dimension6 = 'CT Dimension 6',
    dimension7 = 'CT Dimension 7',
    dimension8 = 'CT Dimension 8',
    dimension9 = 'CT Dimension 9',
    dimension10 = 'CT Dimension 10',
    dimension11 = 'CT Dimension 11',
    dimension12 = 'CT Dimension 12',
    dimension13 = 'CT Dimension 13',
    dimension14 = 'CT Dimension 14',
    dimension15 = 'CT Dimension 15',
    dimension16 = 'CT Dimension 16',
    dimension17 = 'CT Dimension 17',
    dimension18 = 'CT Dimension 18',
    dimension19 = 'CT Dimension 19',
    dimension20 = 'CT Dimension 20',
    dimension21 = 'CT Dimension 21',
    dimension22 = 'CT Dimension 22',
    dimension23 = 'CT Dimension 23',
    dimension24 = 'CT Dimension 24',
    dimension25 = 'CT Dimension 25',
    dimension26 = 'CT Dimension 26',
    dimension27 = 'CT Dimension 27',
    dimension28 = 'CT Dimension 28',
    dimension29 = 'CT Dimension 29',
    dimension30 = 'CT Dimension 30'
}

@Injectable()
export class GAExtraDataService {
    private cantPush: boolean;
    private isInLcLan;

    constructor(@Inject(PLATFORM_ID) private platformId: any, private initializer: InitializerService) {
        this.isInLcLan = initializer.getClientContext().IsInMaxLan;
        if (!isPlatformBrowser(platformId) || this.isInLcLan) {
            this.cantPush = true;
            return;
        }

        if (Object.hasOwnProperty.call(window, 'dataLayer') === false) {
            window['dataLayer'] = [];
        }
    }

    push(dimension: Dimensions, data: string): void {
        this.cantPush
            ? console.log('dataLayer not found', dimension, data)
            : window['dataLayer'].push({ [dimension]: data });
    }
}
