import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isNullOrUndefined } from 'core-max-lib';
import { UiHelperService } from './ui-helper.service';
import { tap } from 'rxjs/operators';
import { PreviewService } from '../../../modules/preview/preview.service';

export interface IEmptyResult { }

export interface IResponse<T> {
    Result: T;
    ReturnCode?: number;
    RcDesc: string;
    CorrelationID: string;
}

export interface IResponseLower<T> {
    result: T;
    returnCode: number;
    rcDesc: string;
    correlationId: string;
}

export interface IHttpRequestOptions {
    defaultApiFailureBehavior?: boolean;
    headers?: HttpHeaders;
    disableDefaultSpinnerBehavior?: boolean;
}

@Injectable()
export class HttpService {

    constructor(private http$: HttpClient, private uiHelper: UiHelperService, private previewService: PreviewService) {
        if (!uiHelper.isBrowser && isNullOrUndefined(global['SsrHttpCache'])) {
            global['SsrHttpCache'] = {};
        }
    }

    get<T>(url: string, httpParams?: HttpParams, options?: IHttpRequestOptions): Observable<IResponse<T>> {
        const params = this.getParams(httpParams, options);
        return this.http$.get<IResponse<T>>(url, { params: params });
    }

    getLower<T>(url: string, httpParams?: HttpParams, options?: IHttpRequestOptions): Observable<IResponseLower<T>> {
        const params = this.getParams(httpParams, options);
        return this.http$.get<IResponseLower<T>>(url, { params: params });
    }

    postLower<T>(url: string, body: any, options?: IHttpRequestOptions): Observable<IResponseLower<T>> {
        const params = this.getParams(null, options);
        return this.http$.post<IResponseLower<T>>(url, body, { params: params });
    }

    getWithSsrCache<T>(url: string, cacheKey: string, httpParams?: HttpParams, options?: IHttpRequestOptions): Observable<IResponse<T>> {
        const params = this.getParams(httpParams, options);
        return this.get<T>(url, params, options);
        // if (this.uiHelper.isBrowser) {
        //     let cachedResponse: IResponse<T> = this.retrieveFromTransferCache(cacheKey);
        //     const isPreviewEnabled: boolean = this.previewService.isEnabled();
        //     if (!isPreviewEnabled && !isNullOrUndefined(cachedResponse)) {
        //         return new Observable(ob => {
        //             ob.next(cachedResponse);
        //             ob.complete();
        //         });
        //     }
        //     return this.get<T>(url, params);
        // }
        // else {
        //     return this.get<T>(url, params).pipe(
        //         tap(res => {
        //             if (res.ReturnCode != 9) {
        //                 this.saveToTransferCache(cacheKey, res);
        //             }
        //         })
        //     );
        // }
    }

    getLowerWithSsrCache<T>(url: string, cacheKey: string, httpParams?: HttpParams, options?: IHttpRequestOptions): Observable<IResponseLower<T>> {
        const params = this.getParams(httpParams, options);
        return this.getLower<T>(url, params, options);
        // if (this.uiHelper.isBrowser) {
        //     let cachedResponse: IResponseLower<T> = this.retrieveFromTransferCache(cacheKey);
        //     const isPreviewEnabled: boolean = this.previewService.isEnabled();
        //     if (!isPreviewEnabled && !isNullOrUndefined(cachedResponse)) {
        //         return new Observable(ob => {
        //             ob.next(cachedResponse);
        //             ob.complete();
        //         });
        //     }
        //     return this.getLower<T>(url, params);
        // }
        // else {
        //     return this.getLower<T>(url, params).pipe(
        //         tap(res => {
        //             if (res.returnCode != 9) {
        //                 this.saveToTransferCache(cacheKey, res);
        //             }
        //         })
        //     );
        // }
    }

    post<T>(url: string, body?: any, options?: IHttpRequestOptions): Observable<IResponse<T>> {
        const params = this.getParams(null, options);
        return this.http$.post<IResponse<T>>(url, body, { params: params, headers: options?.headers });
    }

    put<T>(url: string, body: any, options?: IHttpRequestOptions): Observable<IResponse<T>> {
        const params = this.getParams(null, options);
        return this.http$.post<IResponse<T>>(url, body, { params: params });
    }

    delete<T>(url: string, options?: IHttpRequestOptions): Observable<IResponse<T>> {
        const params = this.getParams(null, options);
        return this.http$.delete<IResponse<T>>(url, { params: params });
    }

    multipart<T>(url: string, formData: FormData, options?: IHttpRequestOptions): Observable<IResponse<T>> {
        const params = this.getParams(null, options);
        return this.http$.post<IResponse<T>>(url, formData, { params: params });
    }

    private getParams(httpParams?: HttpParams, options?: IHttpRequestOptions): HttpParams {
        if (options && (!isNullOrUndefined(options.disableDefaultSpinnerBehavior)
        || !isNullOrUndefined(options.defaultApiFailureBehavior))) {
            if (!httpParams) {
                httpParams = new HttpParams();
            }
            if (!isNullOrUndefined(options.disableDefaultSpinnerBehavior)) {
                httpParams = httpParams.append('disableDefaultSpinnerBehavior', options.disableDefaultSpinnerBehavior.toString());
            }
            if (!isNullOrUndefined(options.defaultApiFailureBehavior)) {
                httpParams = httpParams.append('defaultApiFailureBehavior', options.defaultApiFailureBehavior.toString());
            }
        }
        return httpParams;
    }

    private retrieveFromTransferCache(key: string) {
        if (this.uiHelper.isBrowser) {
            return window['TRANSFER_CACHE']['SsrHttpCache'][key];
        }
    }

    private saveToTransferCache(key: string, obj: any) {
        if (!this.uiHelper.isBrowser) {
            global['SsrHttpCache'][key] = obj;
        }
    }

}
