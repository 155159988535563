import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    Input,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { ModalService } from '../../../../shared/modules/bs-modal/services/modal.service';
import { FormHelperService } from '../../../../shared/cardholders-core/services/form-helper.service';
import { AppConfigsService } from '../../../../config/app-configs.service';
import { LoginScreenMessages } from '../login-messages.consts';
import { BaseLoginComponent } from '../base-components/base-login.component';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { LoginService } from '../../login.service';
import { ILoginResult, LoginStatus } from '../../../../shared/cardholders-core/models/login-result';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { LoginWithMaxService } from '../../../login-with-max/login-with-max.service';
import { LoginPageService } from '../../login-page/login-page.service';
import { OpenBankingService } from '../../../open-banking/open-banking.service';
import { DigitalTransferService } from '../../../digital-transfer/services/digital-transfer.service';
import { BiometricPopupService } from 'ClientApp/app/shared/cardholders-core/services/biometricPopup.service';
import { IResponse } from 'ClientApp/app/shared/cardholders-core/services/http-service.service';
import { TRY_AGAIN_UNSUPPORTED_BROWSER } from 'ClientApp/app/modules/instant-issuing-order/components/try-again/states';
import { isAppleDevice } from 'ClientApp/app/shared/services/is-apple-device';
import { a11yFix } from '../../../../shared/utils/a11y.utils';
import { Fido2Service } from '../../../../shared/cardholders-core/services/fido2.service';

declare const navigator: any;
@Component({
    selector: 'app-user-login-form',
    templateUrl: './user-login-form.component.html',
    styleUrls: ['./user-login-form.component.scss']
})
export class UserLoginFormComponent extends BaseLoginComponent implements OnInit, AfterViewInit, OnDestroy {
    userLoginForm: FormGroup;
    isLoginFailedFewTimes = false;
    passwordRegExp = new RegExp('^[A-Za-z0-9`~!@#$%^&*()-_=+{[\\]};:\'"|/?><,.]*$');
    idRegExp = new RegExp('^[0-9]*$');
    needIdMsg: string;
    forgotPasswordUrl: string;
    touchIDAggrement: string;
    registrationUrl: string;
    usernameTooltipText = 'יש למלא את שם המשתמש / דוא"ל שהגדרת בהרשמה לאתר';
    passwordTooltipText = 'יש למלא את הסיסמה שבחרת לכניסה לאתר';
    idTooltipText = 'יש למלא מספר ת.ז או דרכון, עד 9 ספרות ללא אותיות';
    usernameInput = 'usernameInput';
    passwordInput = 'passwordInput';
    idInput = 'idInput';
    userId: string;
    @Output()
    userIdForOTP: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    isFingerPrintResult: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('username', { static: false })
    usernameInputEl: ElementRef;
    @ViewChild('id')
    idInputEl: ElementRef;
    @Input()
    passwordTabClicked: Subject<void>;
    passwordShownEye: boolean;
    isExtraAuthRequired: boolean;
    subscribers: Subscription[] = [];
    @Input() clearForm: Subject<void>;
    @Input() hideForgotPassword = false;
    @Input() hideRegisterLink = false;
    loginPageMode: boolean;
    @Input() dontShowEmptyFieldError: boolean;
    displayBiometric = false;
    dataLoginAsync: IResponse<ILoginResult>;
    fingerprintTitle: string;
    fingerprintDesc: string;
    fingerprintLoader: boolean;
    fido2Loader: boolean = false;
    fido2Info: string;
    useBiometric = false;
    needToRunPipelineLogin = false;

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected userLoginService: LoginService,
        protected modalSvc: ModalService,
        private formHelperService: FormHelperService,
        protected appConfigsService: AppConfigsService,
        protected uiHelper: UiHelperService,
        protected gtmService: GtmService,
        protected loginWithMaxSvc: LoginWithMaxService,
        protected openBankingSvc: OpenBankingService,
        protected digitalTransferSvc: DigitalTransferService,
        protected loginPageSvc: LoginPageService,
        protected bioSrv: BiometricPopupService,
        protected fido2Srv: Fido2Service
    ) {
        super(
            router,
            authService,
            modalSvc,
            appConfigsService,
            gtmService,
            userLoginService,
            uiHelper,
            loginWithMaxSvc,
            openBankingSvc,
            bioSrv
        );
    }


    ngOnInit(): void {

        a11yFix().tooltip("a.tooltip-icon", {
            selectors: {
                tooltip: "div.tool-tip",
                trigger: "a.tooltip-icon"
            },
            customEvent: "mouseenter"
        });

        //setTimeout(() => {
        //    this.displayBiometric = true;
        //}, 2000)
        if (!isAppleDevice()) {
            this.bioSrv.check();
        } else {
            this.bioSrv.createNewCookie();
        }
        /* this.bioSrv.fingerPrintStatesObs.subscribe(x => {
            this.displayBiometric = !x ? false : x.show && !x.exist;
            this.fingerprintTitle = x?.title;
            this.fingerprintDesc = x?.message;
        }); */
        this.isLoginWithMax = this.loginWithMaxSvc.isLoginWithMaxMode();
        this.isOpenBankingLogin = this.openBankingSvc.isOpenBankingLoginMode();
        this.isDigitalTransferCardLogin = this.digitalTransferSvc.isDigitalTransferCardLoginMode();
        this.loginPageMode = this.loginPageSvc.isLoginPageMode();
        this.userLoginForm = new FormGroup({
            id: new FormControl(null, Validators.pattern(this.idRegExp)),
            username: new FormControl(null, Validators.required),
            password: new FormControl(null, [Validators.required, Validators.pattern(this.passwordRegExp)])
        });
        this.needIdMsg = LoginScreenMessages.multipleFailsNeedId17;
        this.forgotPasswordUrl = this.appConfigsService.appConfigs.FLAG_ISUsingNewAuthRecoverPageOnlineLC
            ? `${this.appConfigsService.appConfigs.baseUiRoot}/auth/recover/enterid?SourceGA=PasswordLogin`
            : `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/RecoverPassword.aspx?SourceGA=PasswordLogin`;
        this.registrationUrl = this.appConfigsService.appConfigs.FLAG_ISEnableNewRegistrationScreen ?
            `${this.appConfigsService.appConfigs.baseUiRoot}/register-website/choose-identity`
            : `${this.appConfigsService.appConfigs.infoRoot}/Anonymous/Registration.aspx?SourceGA=PasswordLogin`;
        this.touchIDAggrement = `${this.appConfigsService.appConfigs.baseUiRoot}/general/pages/touch-id`;
        this.subscribers.push(
            this.passwordTabClicked.subscribe(() => {
                this.setFocusOnFirstInput();
            })
        );
        if (this.clearForm) {
            this.subscribers.push(
                this.clearForm.subscribe(() => {
                    this.userLoginForm.controls['username'].setValue('');
                    this.userLoginForm.controls['password'].setValue('');
                })
            );
        }
        this.isExtraAuthRequired = this.authService.isUserAuthenticated();

        if (this.loginPageSvc.isAutoLoginFailed()) {
            this.showErrorMsg(LoginScreenMessages.otpWrongDetails30_37);
        }
    }
    get displayFingerprintResult(): boolean {
        return !!this.fingerprintTitle && !!this.fingerprintDesc && !!this.dataLoginAsync;
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setFocusOnFirstInput();
        }, 1000);
    }

    ngOnDestroy(): void {
        if (this.needToRunPipelineLogin) {
            this.pipelineLogin(this.dataLoginAsync);
        }
        this.subscribers.forEach(subscriber => subscriber.unsubscribe());
    }

    setFocusOnFirstInput(): void {
        // if (this.needId) {
        //     this.setFocusOnElement(this.idInputEl);
        // } else {
        //     this.setFocusOnElement(this.usernameInputEl);
        // }
    }

    isUsernameEmpty(): boolean {
        if (this.dontShowEmptyFieldError) {
            return false;
        }
        return this.isFormControlEmpty('username');
    }

    isPasswordEmpty(): boolean {
        return this.isFormControlEmpty('password');
    }

    isButtonDisabled(): boolean {
        return (
            this.isFormControlEmpty('username') ||
            this.isFormControlEmpty('password') ||
            this.isPasswordNotValidByPattern()
        );
    }

    isFormControlEmpty(controlName: string): boolean {
        return this.formHelperService.isFormControlEmptyAfterTouched(this.userLoginForm.get(controlName));
    }

    isPasswordNotValidByPattern(): boolean {
        return this.isFormControlNotValidByPattern('password');
    }

    isIdNotValidByPattern(): boolean {
        return this.isFormControlNotValidByPattern('id');
    }

    isFormControlNotValidByPattern(controlName: string): boolean {
        return (
            !this.formHelperService.isFormControlValidByPattern(this.userLoginForm.get(controlName)) &&
            this.userLoginForm.get(controlName).touched
        );
    }

    loginClicked(): void {
        if (this.dataLoginAsync) {
            this.needToRunPipelineLogin = false;
            this.pipelineLogin(this.dataLoginAsync);
            return;
        }
        this.hideErrorMsg();
        this.formHelperService.validateAllFormFields(this.userLoginForm);
        this.userId = this.userLoginForm.get('id').value;
        if (!this.userLoginForm.valid || (this.needId && !this.userId)) {
            return;
        }
        if (this.userId !== null) {
            this.userIdForOTP.emit(this.userId);
        }
        this.updateLoaderStatusEvent.emit(false);
        this.subscribers.push(
            this.userLoginService
                .login(
                    this.userLoginForm.get('username').value,
                    this.userLoginForm.get('password').value,
                    this.userLoginForm.get('id').value
                )
                .subscribe(async data => {
                    if (!(data.Result?.LoginStatus === LoginStatus.falied) && this.useBiometric) {
                        this.updateLoaderStatusEvent.emit(true);
                        this.isFingerPrintResult.emit(true);

                        this.dataLoginAsync = data;
                        //this.fingerprintLoader = true;
                        //await this.bioSrv.addFingerPrint();
                        this.subscribers.push(this.fido2Srv.fido2Info.subscribe(res => { this.fido2Info = res }));
                        this.subscribers.push(this.fido2Srv.fido2Loading.subscribe(res => { this.fido2Loader = res }));
                        await this.fido2Srv.registerBiometric(this.userLoginForm.get('username').value, false);
                        //this.fingerprintLoader = false;
                        this.needToRunPipelineLogin = true;
                    } else {
                        this.pipelineLogin(data);
                    }
                })
        );
    }
    pipelineLogin(data: IResponse<ILoginResult>): void {
        this.isFingerPrintResult.emit(false);
        if (this.isDigitalTransferCardLogin) {
            this.digitalTransferSvc.userNameResult.userName = this.userLoginForm.get('username').value;
        }
        this.checkLoginStatus(data.Result, this.updateLoaderStatusEvent);
        if (
            data.Result.LoginStatus === LoginStatus.success ||
            data.Result.LoginStatus === LoginStatus.NeedToShowInsurance
        ) {
            if (this.needId) {
                this.pushDirectiveGtmWorkflow(
                    'New website - log in',
                    'Password login',
                    'Log in with ID',
                    '4',
                    'LoginPassword',
                    '2',
                    '2'
                );
            } else {
                this.pushDirectiveGtmWorkflow(
                    'New website - log in',
                    'Password login',
                    'Login',
                    '4',
                    'LoginPassword',
                    '2',
                    '2'
                );
            }
            if (data.Result.LoginStatus === LoginStatus.NeedToShowInsurance) {
                if (this.isLoginWithMax) {
                    this.userLoginService.loginWithMax();
                } else if (this.isOpenBankingLogin) {
                    this.openBankingSvc.onOpenBankingLoginSuccess.next(true);
                } else if (this.isDigitalTransferCardLogin) {
                    this.digitalTransferSvc.onDigitalTransferCardLoginSuccess.next(true);
                } else {
                    if (data.Result.UserTransferResult && data.Result.UserTransferResult.IsTransferMessageNeeded) {
                        this.loginService.navigateUserTransferPage(
                            '/insurance/personal',
                            true,
                            this.updateLoaderStatusEvent
                        );
                    } else {
                        this.router.navigate(['/insurance/personal']);
                    }
                }
            } else {
                this.userLoginService.navigateReturnUrlAfterLoginSuccess();
            }
            this.checkFidoCredentials();
            this.modalSvc.closeModal();
        } else if (
            (this.isLoginWithMax || this.isOpenBankingLogin || this.isDigitalTransferCardLogin) &&
            data.Result.LoginStatus !== LoginStatus.falied
        ) {
            this.userLoginService.navigateReturnUrlAfterLoginSuccess();
        }
    }

    checkFidoCredentials(){
        try{
            this.fido2Srv.checkCredentials().subscribe(res => {
                if(res.Result == true){
                    if(!localStorage.getItem('fido2Username')){
                        localStorage.setItem('fido2Username', this.userLoginForm.get('username').value.toLowerCase());
                    }
                   this.fido2Srv.fido2Exists = true;
                   this.fido2Srv.fido2Checked = true;
                }
            })
        }catch(e){
            console.error(e);
        }
    }

    usernameBlured(e: any): void {
        if (e?.relatedTarget?.classList?.contains('skip-blur')) {
            e.relatedTarget.click();
        }
        this.pushDirectiveGtmWorkflow(
            'New website - log in',
            'Password login',
            'Type User',
            '4',
            'LoginPassword',
            '1',
            '2'
        );
    }

    passwordBlured(e: any): void {
        if (e?.relatedTarget?.classList?.contains('skip-blur')) {
            e.relatedTarget.click();
        }
    }

    idBlured(): void {
        this.pushDirectiveGtmWorkflow(
            'New website - log in',
            'Password login',
            'Type TZ',
            '4',
            'LoginPassword',
            '1',
            '2'
        );
    }

    putCaretInTheEnd(event: any): void {
        // Safari Bug
        setTimeout(() => (event.target.value = event.target.value));
    }

    showHidePassword(): void {
        this.passwordShownEye = !this.passwordShownEye;
        const element = document.getElementById('password');
        if (element.getAttribute('type') === 'password') {
            element.setAttribute('type', 'text');
        } else {
            element.setAttribute('type', 'password');
        }
    }
}
