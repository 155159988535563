import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConfigsService } from '../../../config/app-configs.service';
import { IResponse, HttpService} from '../../../shared/cardholders-core/services/http-service.service';
import { UiHelperService } from '../../../shared/cardholders-core/services/ui-helper.service';
import { IGeneralText } from "../models/general-text";


@Injectable()
export class GeneralTextsService {
    constructor(
        private appConfigsService: AppConfigsService,
        private httpSvc: HttpService)
    {}

    getGeneralText(ids: string[]): Observable<IResponse<IGeneralText[]>> {
        return this.httpSvc.get(`${this.appConfigsService.appConfigs.apiUrl}/generaltexts?ids=${ids}`);
    }
}