import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { PrebootService } from 'core-max-lib';
import { xor } from 'lodash';
import { Subscription } from 'rxjs';

interface DataMessage {
    msg: string;
    isShow: boolean;
    isHide: boolean;
}

@Component({
    selector: 'max-loader-text',
    templateUrl: './max-loader-text.component.html',
    styleUrls: ['./max-loader-text.component.scss']
})
export class MaxLoaderTextComponent implements OnInit, OnDestroy {
    lottieConfig: Object;
    isBrowser: boolean;
    isFirstLoop: boolean;
    index = 0;

    @Input()
    title: string;
    @Input()
    messages: string[] = [];
    subs: Subscription[] = [];

    data: DataMessage[] = [];
    interval: NodeJS.Timeout;
    constructor(@Inject(PLATFORM_ID) private platformId: any, private prebootSvc: PrebootService) {
        this.isBrowser = isPlatformBrowser(platformId);
    }
    ngOnDestroy(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.subs.forEach((c) => c.unsubscribe());
    }

    ngOnInit(): void {
        this.buildMessages();
        this.setTransition();
    }
    buildMessages(): void {
        this.data = [];
        this.messages.forEach((subTitle, index) => {
            this.data.push({ msg: subTitle, isShow: index === 0, isHide: false });
        });
    }
    ngOnChanges(changes: SimpleChanges) {
        this.buildMessages();
    }

    setTransition(): void {
        if (this.isBrowser) {
            this.interval = setInterval(() => {
                if (this.isFirstLoop) {
                    this.data.map((x) => {
                        x.isShow = false;
                    });
                    this.isFirstLoop = false;
                }
                if (this.data.length === 0) {
                    this.index = 0;
                    return;
                }

                this.index++;
                if (this.index === this.data.length || this.index > this.data.length) {
                    this.data[this.data.length - 1].isShow = false;
                    this.data[this.data.length - 1].isHide = true;
                    this.index = 0;
                    this.data[this.index].isShow = true;
                } else {
                    if (this.index === 0) {
                        this.data[this.data.length - 1].isShow = false;
                        this.data[this.data.length - 1].isHide = true;
                    } else {
                        this.data[this.index - 1].isShow = false;
                        this.data[this.index - 1].isHide = true;
                    }
                    this.data[this.index].isShow = true;
                }
            }, 2500);
        }
    }
}
