import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, UrlSerializer } from '@angular/router';
import { appRoutes } from './app.routes';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from './shared/shared.module';
import { HttpServiceInterceptor } from './shared/cardholders-core/interceptors/http-service.interceptor';
import { HttpServiceTokenInterceptor } from './shared/cardholders-core/interceptors/http-service.token.interceptor';
import { HttpServiceLogInterceptor } from './shared/cardholders-core/interceptors/http-service.log.interceptor';
import { HttpServiceSessionTimeoutInterceptor } from './shared/cardholders-core/interceptors/http-service.session-timeout.interceptor';

import { DialogsService } from './shared/services/dialogs.service';
import { ChatManagementService } from './shared/services/chat-management.service';
import { CommonModule } from '@angular/common';
import { AppConfigsService } from './config/app-configs.service';
// import { PopupsService } from './shared/services/popups.service';
import { LoggerService } from './shared/cardholders-core/services/logger.service';
import { LoggerHandlerService } from './shared/cardholders-core/services/logger.handler.service';
import { LoggerGlobalService } from './shared/cardholders-core/services/logger-global.service';
import { ErrorsHandlerService } from './shared/cardholders-core/services/errors.handler.service';
import { RoutingHistoryService } from './shared/cardholders-core/services/routing-history.service';
import { RoutingHistoryHandlerService } from './shared/cardholders-core/services/routing-history.handler.service';
import { RoutingHistoryGlobalService } from './shared/cardholders-core/services/routing-history.global.service';
import { LogClickService } from './shared/services/log-click.service';
import { CorrelationIdService } from './shared/cardholders-core/services/correlationId.service';
import { SessionStorageService } from './shared/cardholders-core/services/session-storage.service';

// import { ServerTransition } from './server-transition.module';
import { PrebootModule } from 'preboot';
import { HttpServiceCacheVersionAppenderInterceptor } from './shared/cardholders-core/interceptors/http-service.cache-version-appender.interceptor';
import { TokenService } from './shared/cardholders-core/services/token.service';
import { VersionService } from './shared/cardholders-core/services/version.service';
import { TesterService } from './shared/cardholders-core/services/tester.service';
import { registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
import { ScriptStoreService } from './shared/services/script-store.service';
import { ScriptHelperService } from './shared/services/script-load-helper.service';
import { SessionTimeoutService } from './shared/cardholders-core/services/session-timeout.service';
import { VipLogsService } from './shared/cardholders-core/services/vipLogs.service';
import { UserIdleTimeoutService } from './shared/cardholders-core/services/user-idle-timeout.service';
import { SessionTimeoutHandlerService } from './shared/cardholders-core/services/session-timeout-handler.service';
import { SessionTimeoutGuard } from './shared/services/session-timeout.guard';
import { HttpServiceAntiForgeryInterceptor } from './shared/cardholders-core/interceptors/http-service.antiForgery.interceptor';
import { CardholdersCoreModule } from './shared/cardholders-core/cardholders-core.module';
import { LinkService } from './shared/services/link.service';
import { LowerCaseUrlSerializer } from './shared/cardholders-core/components/lower-case-url-serializer/lower-case-url-serializer.component';
import { HomepageModule } from './modules/homepage/homepage.module';
import { NewHomepageModule } from './modules/new-homepage/new-homepage.module';
import { MainLayoutModule } from './modules/main-layout/main-layout.module';
import { ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { AuthLoginRedirectComponent } from './components/auth-login-redirect/auth-login-redirect.component';
import { PrePredictStrategy } from './shared/cardholders-core/services/pre-predict-strategy.service';
import {
    InitializerModule,
    ServerConfigurationModule,
    ServerConfigurationService,
    UiHelperService,
    SeoModule,
    ExcoModule,
    WrongUrlInterceptor,
    LoggerHandlerServiceAbstract
} from 'core-max-lib';
import { PreviewModule } from './modules/preview/preview.module';
import { MaxUiDesignModule } from 'max-ui-design';
import { HttpServiceDataGuidInterceptor } from './shared/cardholders-core/interceptors/http-service.data-guid.interceptor';
// import { ServerConfigurationService } from './config/server-configuration.service';
import { CardholdersClientContextLoader } from './config/cardholders-server-client-context-loader';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserIdleModule } from 'angular-user-idle';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { SessionIdService } from './shared/cardholders-core/services/sessionId.service';
import { NavigateSsoComponent } from './components/navigate-sso/navigate-sso.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Server8Service } from './modules/server8/server8.service';
import { MoreInfoModule } from './modules/more-info/more-info.module';
import { SsoGeneralModule } from './modules/sso-general/sso-general.module';
import { AutoFillOtpService } from './shared/cardholders-core/services/auto-fill-otp.service'
registerLocaleData(localeHe, 'he');

export function init_app(serverConfigurationService: ServerConfigurationService) {
    const initAppFunction = () => serverConfigurationService.load();
    return initAppFunction;
}

@NgModule({
    declarations: [AppComponent, AuthLoginRedirectComponent, NavigateSsoComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        InitializerModule.forRoot('/configs/config.json', '/registered/clientcontext', CardholdersClientContextLoader),
        // RouterModule.forRoot(appRoutes, { useHash: false, scrollPositionRestoration: 'enabled'}),
        RouterModule.forRoot(appRoutes, { useHash: false, relativeLinkResolution: 'legacy' }),
        SeoModule.forRoot({ server8Service: Server8Service }),
        ExcoModule.forRoot({}),
        // SharedModule.forRoot(),
        CardholdersCoreModule.forRoot(),
        TransferHttpCacheModule,
        BrowserAnimationsModule,
        // CardsModule,
        // WizardModule,
        // JoinFormModule,
        // TrackerModule,
        // ButtonModule,
        // SharedPrimeng,
        // BrowserModule,
        BrowserModule.withServerTransition({
            appId: 'my-app-id' // make sure this matches with your Server NgModule
        }),
        // PrebootModule.withConfig({ appRoot: 'app-root' }),
        // ServerTransition.forRoot({ appId: 'my-app-id' }),
        MainLayoutModule,
        HomepageModule,
        NewHomepageModule,
        PreviewModule,
        MaxUiDesignModule,
        UserIdleModule,
        MatNativeDateModule,
        MoreInfoModule,
        // ServiceWorkerModule.('ngsw-worker.js', {
        //  enabled: environment.production,
        //  // Register the ServiceWorker as soon as the app is stable
        //  // or after 30 seconds (whichever comes first).
        //  registrationStrategy: 'registerWhenStable:30000'
        // }),
        SsoGeneralModule
    ],
    providers: [
        AppConfigsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WrongUrlInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpServiceSessionTimeoutInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpServiceInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpServiceLogInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpServiceCacheVersionAppenderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpServiceTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpServiceAntiForgeryInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpServiceDataGuidInterceptor,
            multi: true
        },
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        },
        { provide: MAT_DATE_LOCALE, useValue: 'he-IL' },
        { provide: LOCALE_ID, useValue: 'he-IL' },
        { provide: LoggerHandlerServiceAbstract, useExisting: LoggerHandlerService },
        // ServerConfigurationService,
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: init_app,
        //     deps: [ServerConfigurationService],
        //     multi: true
        // },
        DialogsService,
        ChatManagementService,
        // PopupsService,
        LoggerService,
        LoggerHandlerService,
        LoggerGlobalService,
        ErrorsHandlerService,
        RoutingHistoryService,
        RoutingHistoryHandlerService,
        RoutingHistoryGlobalService,
        TokenService,
        VersionService,
        TesterService,
        LogClickService,
        CorrelationIdService,
        SessionStorageService,
        ScriptStoreService,
        ScriptHelperService,
        UserIdleTimeoutService,
        SessionTimeoutService,
        SessionTimeoutHandlerService,
        SessionTimeoutGuard,
        LinkService,
        UiHelperService,
        SessionIdService,
        VipLogsService,
        AutoFillOtpService
    ],
    bootstrap: [AppComponent]
})
export class AppModuleShared {}
