import { ComponentBase } from './control-base';

export class RadioGroupExtendedComponent extends ComponentBase<string> {
    controlType = 'radioGroupExtended';
    options: { label: string, value: number, info: string }[] = [];
    attentionInfo: string;

    constructor(config: {}) {
        super(config);
        this.options = config['options'] || '';
        this.attentionInfo = config['attentionInfo'] || '';
    }

    setValue(value) {
        // console.log('radio group options', this.options);
        // console.log('radio group set value', value);
    }
}
