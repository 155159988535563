import { TypingFormat } from "../../../../../shared/enums/typing-format.enum";

export class ComponentBase<T> {
    value: T;
    key: string;
    id: number;
    parentId: number;
    label: string;
    validators: any[];
    order: number;
    controlType: string;
    visible: boolean;
    parentKey: string;
    hint: string;
    link: { to: string, action: string, key: string };
    isFormControl: boolean;
    placeholder: string;
    required: boolean;
    icons: boolean;
    typingFormat: TypingFormat;
    disablePaste: boolean;
    showWarning: boolean;
    warningText: string;
    autoCompleteHtml: string;
    customRequiredErrorMessage: string;

    constructor(config: {
        value?: T,
        key?: string,
        id?: number,
        parentId?: number,
        label?: string,
        validators?: any[],
        order?: number,
        controlType?: string,
        visible?: boolean,
        parentKey?: string,
        link?: { to: string, action: string, key: string },
        isFormControl?: boolean,
        placeholder?: string,
        required?: boolean,
        icons?: boolean,
        typingFormat?: TypingFormat,
        disablePaste?: boolean,
        showWarning?: boolean,
        warningText?: string,
        autoCompleteHtml?: string,
        customRequiredErrorMessage?: string;
    } = {}) {
        this.value = config.value;
        this.key = config.key || '';
        this.id = config.id || 0;
        this.parentId = config.parentId;
        this.label = config.label || '';
        this.validators = config.validators;
        this.order = config.order === undefined ? 1 : config.order;
        this.controlType = config.controlType || '';
        this.visible = config.visible === undefined ? true : config.visible;
        this.parentKey = config.parentKey;
        this.hint = config['hint'] || {};
        this.link = config['link'] || undefined;
        this.isFormControl = config['isFormControl'] || true;
        this.placeholder = config.placeholder || '';
        this.required = config.required || false;
        this.icons = config.icons || false;
        this.typingFormat = config.typingFormat || undefined;
        this.disablePaste = config.disablePaste || false;
        this.showWarning = config.showWarning || false;
        this.warningText = config.warningText || '';
        this.autoCompleteHtml = config.autoCompleteHtml || '';
        this.customRequiredErrorMessage = config.customRequiredErrorMessage || undefined;
    }

    afterViewInit(form?) {
    }

    show(val: boolean) {
        this.visible = val;
    }

    setValue(val?: any) {
        this.value = val;
    }

    getValue() {
        return this.value;
    }

}
