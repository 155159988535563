import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppConfigsService } from '../../../../../config/app-configs.service';
import { GtmService } from '../../../../../shared/cardholders-core/services/gtm.service';
import { UiHelperService } from '../../../../../shared/cardholders-core/services/ui-helper.service';
import { ModalService } from '../../../../../shared/modules/bs-modal/services/modal.service';
import { TechnicalError } from '../../../../main-layout/constants/modals.consts';
import { AgreeInd, DepositStatus, DepositType, LineCode } from '../../../../saving/interfaces/enum.interface';
import {
    HomePageSavingsDataResponse,
    InsuranceDetailsResult,
    PolicyDataResult
} from '../../../../saving/interfaces/ISavingEligibilityResponse.interface';
import { IHomePageRecommendationsResult } from '../../../models/homepage-recommendation';
import { HomepageService } from '../../../services/homepage.service';
import { InsuranceService } from '../../../services/insurance.service';
import { SidePopupModal } from '../side-popup/constant.modal';

@Component({
    selector: 'app-saving',
    templateUrl: './saving.component.html',
    styleUrls: ['./saving.component.scss']
})
export class SavingComponent implements OnInit, OnChanges, OnDestroy {
    @Input() agreeInd: AgreeInd;
    @Input() insuranceDetail: InsuranceDetailsResult;
    @Input() homePageSavingsData: HomePageSavingsDataResponse[];
    @Input() myInsuranceIsLoaded: boolean;
    insuranceDetailsList: PolicyDataResult[];

    showRecommendation = true;
    recommendationIsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    recommendationsToShow: IHomePageRecommendationsResult[];

    showMySaving: boolean;
    mySavingSildeConfig = {
        infinite: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
        swipeSpeed: 200,
        autoplay: false,
        mobileFirst: true,
        rtl: true,
        responsive: [
            {
                breakpoint: 767,
                settings: 'unslick'
            }
        ]
    };
    showInsuranceAcceptance: boolean;
    oncha;
    loader: boolean;
    isShowAllInsurance: any;
    private subs: Subscription[] = [];
    constructor(
        private homepageService: HomepageService,
        private uiHelper: UiHelperService,
        private insuranceHPSrv: InsuranceService,
        private modalSvc: ModalService,
        private cd: ChangeDetectorRef,
        private gtm: GtmService,
        private appConfigsService: AppConfigsService,
        private router: Router,
        private route: ActivatedRoute
    ) {}
    ngOnDestroy(): void {
        this.subs.forEach(x => x.unsubscribe());
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.init();
    }

    toggleInsurance() {
        if (!this.isShowAllInsurance) {
            this.gtm.pushTransactionDetailsUpsaleRow('my investment money', 'Click on more investment');
        }
        this.isShowAllInsurance = !this.isShowAllInsurance;
    }

    getRecommendationData() {
        this.homepageService.savingRecommendationsChanged.subscribe(res => {
            if (res && res.length) {
                this.recommendationsToShow = res;
            } else {
                this.showRecommendation = false;
            }
            if (this.route.snapshot.fragment?.toLowerCase() === 'savingdash') {
                document.getElementById('savingDash').scrollIntoView({ behavior: 'smooth', block: 'center' });
                this.cd.detectChanges();
            }
            this.recommendationIsLoaded.next(true);
            this.cd.detectChanges();
        });

        if (this.homepageService.savingRecommendation !== undefined) {
            this.homepageService.savingRecommendationsChanged.next(this.homepageService.savingRecommendation);
        }
    }

    ngOnInit(): void {
        this.getRecommendationData();
        this.init();
    }
    init() {
        this.insuranceDetailsList =
            this.insuranceDetail?.insuranceDetailsList.filter(c => c.lineCode === LineCode.Saving) ?? [];
        this.showMySaving =
            this.insuranceDetail.insuranceDetailsList.filter(c => c.lineCode === LineCode.Saving).length > 0 ||
            this.insuranceDetail.numOfSavings > 0 ||
            this.homePageSavingsData.length > 0;
        if (this.showMySaving) {
            if (this.insuranceDetail.insuranceDetailsList.length > 0) {
                this.gtm.pushTransactionDetailsUpsaleRow(
                    'my investment money',
                    `money plus [${this.insuranceDetail.insuranceDetailsList.length}]`
                );
            }
            if (this.homePageSavingsData.length > 0) {
                this.gtm.pushTransactionDetailsUpsaleRow(
                    'my investment money',
                    `saving box [${this.homePageSavingsData.length}]`
                );
            }
        }
        this.showInsuranceAcceptance = this.agreeInd === AgreeInd.NotExist && this.insuranceDetail.numOfSavings > 0;
    }
    acceptInsurance() {
        this.homepageService.checkAgreementsStatus(2).subscribe(res => {
            if (res.returnCode === 0) {
                this.router.navigate(['/insurance/personal']);
            } else {
                this.modalSvc.openModal(TechnicalError).subscribe(data => {
                    this.showInsuranceAcceptance = false;
                });
            }
        });
    }
    sso(policyNum: number) {
        this.gtm.pushTransactionDetailsUpsaleRow('my investment money', `Click - money plus`);
        SidePopupModal.options.initialState = {
            type: 'policy',
            ssoPassingRecapcha: this.insuranceHPSrv.ssoPassingRecapcha,
            policyNumber: policyNum
        } as Object;

        this.modalSvc.openModal(SidePopupModal);
    }

    openErrorModal(): void {
        this.loader = true;
        setTimeout(() => {
            this.loader = false;
            SidePopupModal.options.initialState = {
                type: 'error'
            } as Object;
            this.subs.push(this.modalSvc.openModal(SidePopupModal).subscribe(x => {}));
        }, 1000);
    }

    savingClick(saving: HomePageSavingsDataResponse) {
        this.gtm.pushTransactionDetailsUpsaleRow('my investment money', `Click - saving box`);
        if (this.appConfigsService.appConfigs.FLAG_ISNavigateFromHomepageToMySavingWeb) {
            this.router.navigate(['/saving-homepage/personal/main']);
        } else {
            if (!this.uiHelper.IsMobile(787) || !this.uiHelper.IsMobileByUserAgent()) {
                this.gtm.pushSaving('Pop up – continue at app', `show`);
                SidePopupModal.options.initialState = {
                type: 'side'
                } as Object;
                this.subs.push(this.modalSvc.openModal(SidePopupModal).subscribe());
            } else {
                window.location.href =
                this.appConfigsService.appConfigs.infoRoot +
                '/deeplink.html?web=' +
                this.appConfigsService.appConfigs.baseUiRoot +
                '/saving/personal/error&utm_source=Saving&utm_medium=Saving&utm_campaign=Saving&scr=106';
            }
        }
        
    }
    savingSideActive(hp: HomePageSavingsDataResponse) {
        return hp.userDeposits.find(
            x => x.depositType === DepositType.MultiTime && x.depositStatus === DepositStatus.Active
        );
    }
}
