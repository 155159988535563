<div class="my-max-info" *ngIf="showMyMaxInfo">
    <div class="content">
        <div class="main-title">
            <h2>{{content.title}}</h2>
            <!-- <a routerLink="/my-max/personal/registration" class="default-link target-link-bold another-card" title="" id="create-another-card">להנפקת כרטיס נוסף</a> -->
        </div>
        <div class="cards-info" *appLoader="myMaxInfoIsLoaded; template: myMaxInfoLoaderTemplateEl">
            <div *ngIf="cardsToShow.length > 1">
                <span class="child-cards">
                    הכרטיסים של {{childCardsNames}}
                </span>
                <span class="card-number">
                    <span>{{cardsToShow.length}}</span> כרטיסים
                </span>
            </div>
            <div class="child-card-info" *ngIf="cardsToShow.length === 1">
                <span class="title">היתרה בכרטיס של {{cardsToShow[0].nameHeb}}</span>
                <span class="sum">₪{{cardsToShow[0].otb}}</span>
            </div>

            <span class="charge-alert" *ngIf="appConfigsService.appConfigs.FLAG_MarkMyMaxCardOTBIsLowForParent && childrenWithoutMoney.length > 1">{{content.addMoneyNoticeChildren}}</span>
            <span class="charge-alert" *ngIf="appConfigsService.appConfigs.FLAG_MarkMyMaxCardOTBIsLowForParent &&  childrenWithoutMoney.length === 1">{{content.addMoneyNoticeSingleChild}}</span>

            <a [routerLink]="['/my-max/personal/main']" [state]="{ cardsFromHomepage: cardsToShow }" class="default-link target-link-bold more-actions" title="לפרטים ופעולות" id="more-actions" appGtm
            [data]="{
                category: gtmCategory,
                action: 'charging and actions',
                label: 'click'
            }"
            listen="onclick">לפרטים ופעולות</a>

        </div>
    </div>
    <ng-container *ngIf="showRecommendation">
    <div class="left-box">
        <!-- <div class="send-new-payment">
            <img src="../assets/images/design-system/paper-airplane-icon.svg" class="paper-airplane" alt="" />
            <h2>שליחת בקשת תשלום חדשה</h2>
            <a routerLink="/" class="default-link target-link-bold" title="לבקשת תשלום" id="new-payment">לבקשת תשלום</a>
        </div> -->
            <ng-container *appLoader="recommendationIsLoaded; template: recommendationLoaderTemplateEl">
                <app-recommendation-box
                    *ngIf="this.recommendationsToShow"
                    class="recommendation-box"
                    [recommendation]="recommendationsToShow[0]"
                ></app-recommendation-box>
            </ng-container>
        </div>
    </ng-container>
</div>

<ng-template #myMaxInfoLoaderTemplateEl>
    <div class="cards-info-skeleton card-box-wrapper">
        <div class="cards-info app-loader-blink"></div>
    </div>
</ng-template>

<ng-template #recommendationLoaderTemplateEl>
    <app-recommendation-box
    class="recommendation-box recommendation-box-template app-loader-blink"
    ></app-recommendation-box>
</ng-template>