import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { isNullOrUndefined } from 'core-max-lib';
import { UUID } from 'angular2-uuid';

@Injectable()
export class CorrelationIdService {
    private isBrowser: boolean;
    private _correlationId: string;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    public get correlationId() {
        return !isNullOrUndefined(this._correlationId) ? this._correlationId : null;
    }
    public setCorrelationId() {
        this._correlationId = UUID.UUID();
    }
}
