import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LottieModule } from '../../shared/modules/lottie/lottie.module';
import { NewHomepageComponent } from './new-homepage.component';
import { MainComponent } from './components/main/main.component';
import { WhatMaxTodayComponent } from './components/what-max-today/what-max-today.component';
import { MainBannerComponent } from './components/main-banner/main-banner.component';
import { MaxApplicationComponent } from './components/max-application/max-application.component';
import { NewsComponent } from './components/news/news.component';
import { MoreActionsInMaxComponent } from './components/more-actions-in-max/more-actions-in-max.component';
import { HereForYouMobileComponent } from './components/here-for-you-mobile/here-for-you-mobile.component';
import { BenefitCaruselComponent } from './components/benefit-carusel/benefit-carusel.component';
import { MainBannerCssComponent } from './components/main-banner-css/main-banner-css.component';
import { CardholdersCoreModule } from '../../shared/cardholders-core/cardholders-core.module';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [CommonModule, SlickCarouselModule, LottieModule, CardholdersCoreModule],
    declarations: [
        NewHomepageComponent,
        MainComponent,
        WhatMaxTodayComponent,
        MainBannerComponent,
        MaxApplicationComponent,
        NewsComponent,
        MoreActionsInMaxComponent,
        HereForYouMobileComponent,
        BenefitCaruselComponent,
        MainBannerCssComponent
    ]
})
export class NewHomepageModule {}
