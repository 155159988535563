import { IModalConfig } from '../../../shared/modules/bs-modal/interfaces/modal-config.interface';
import { BiometricComponent } from './biometric.component';

export const BiometricModal: IModalConfig = {
    content: BiometricComponent,
    options: {
        class: 'biometric-popup pop-up-general',
        initialState: {},
        backdrop: 'static'
    },
    type: 'cookiesModal'
};
