import { FormControl } from '@angular/forms';
import { isNullOrUndefined } from 'core-max-lib';

export function validateId(c: FormControl) {
    // DEFINE RETURN VALUES
    let R_ELEGAL_INPUT = {
        id: true
    };
    // let R_NOT_VALID = -2;
    let R_VALID = null;

    let IDnum = c.value;

    // if value is empty return valid
    if (IDnum === '') {
        return R_VALID;
    }
    // Validate correct input
    if (isNaN(IDnum))
        return R_ELEGAL_INPUT;
    if (IDnum && ((IDnum.length > 9) || (IDnum.length < 3) || parseInt(IDnum) > 399999999))
        return R_ELEGAL_INPUT;

    if (IDnum) {
        // The number is too short - add leading 0000
        if (IDnum.length < 9) {
            while (IDnum.length < 9) {
                IDnum = '0' + IDnum;
            }
        }

        // CHECK THE ID NUMBER
        let mone = 0, incNum;
        for (let i = 0; i < 9; i++) {
            incNum = Number(IDnum.charAt(i));
            incNum *= (i % 2) + 1;
            if (incNum > 9)
                incNum -= 9;
            mone += incNum;
        }
        if (mone % 10 == 0)
            return R_VALID;
        else
            return R_ELEGAL_INPUT;
    }

}
