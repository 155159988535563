import { Component, OnInit } from '@angular/core';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { HomepageService } from '../../../homepage/services/homepage.service';
import { Product, Products } from '../../interfaces/homepage-content.interface';

@Component({
    selector: 'app-what-max-today',
    templateUrl: './what-max-today.component.html',
    styleUrls: ['./what-max-today.component.scss']
})
export class WhatMaxTodayComponent implements OnInit {
    isMobile: boolean;
    anims = [];
    isAnimHover = [false, false, false, false, false, false, false, false];

    get content(): Products {
        return this.hpService.homepageContent?.products;
    }

    constructor(
        private hpService: HomepageService,
        private uiHelper: UiHelperService,
        private gtmService: GtmService
    ) {
        this.isMobile = this.uiHelper.IsMobile(768);
    }

    ngOnInit(): void {}

    onProductClick(product: Product): void {
        this.hpService.navigate(product.url);
        this.gtmService.pushNewWebsiteAnonymous('New - Products', 'Click - ' + product.title);
    }

    onMouseEnter(index: number): void {
        if (!this.isMobile) {
            this.isAnimHover[index] = true;
            this.anims[index].goToAndPlay(0, true);
        }
    }

    onMouseLeave(index: number): void {
        if (!this.isMobile) {
            this.isAnimHover[index] = false;
        }
    }

    onAnimCreated(anim: any, index: number): void {
        this.anims[index] = anim;

        if (this.isMobile) {
            anim.addEventListener('complete', () => {
                this.loopAnimMobile(index);
            });

            if (index === 7) {
                if (this.anims[0]) {
                    this.anims[0].goToAndPlay(0, true);
                } else {
                    this.anims[7].goToAndPlay(0, true);
                }
            }
        } else {
            anim.addEventListener('complete', () => {
                this.loopAnimDesktop(index);
            });
        }
    }

    loopAnimDesktop(index: number): void {
        if (this.isAnimHover[index]) {
            this.anims[index].goToAndPlay(0, true);
        }
    }

    loopAnimMobile(lastPlayedIndex: number): void {
        const nextIndex = this.getRandomIndex(lastPlayedIndex);
        this.anims[nextIndex].goToAndPlay(0, true);
    }

    getRandomIndex(lastPlayedIndex: number): number {
        let rnd = Math.floor(Math.random() * 8);

        while (!this.isValidIndex(rnd, lastPlayedIndex)) {
            rnd = Math.floor(Math.random() * 8);
        }

        return rnd;
    }

    isValidIndex(rnd: number, lastPlayedIndex: number): boolean {
        if (rnd === lastPlayedIndex) {
            return false;
        }
        if (!this.anims[rnd]) {
            return false;
        }

        return true;
    }
}
