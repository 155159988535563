<footer class="main-layout-footer d-print-none" appBackgroundDesign [backgroundDetails]="backgroundDetails">
    <div class="container">
        <section class="footer-wrapper">
            <div class="links-wrapper">
                <a href="#" title="" class="logo-footer"><img [src]="companyLogoImage" alt="max" /></a>
                <ul class="footer-first-column">
                    <li *ngFor="let footerItem of footerColumns[0]?.ColumnItems">
                        <a appLink [anchorLink]="getFooterItemLink(footerItem)"
                            [isOpenLinkInNewTab]="footerItem.IsOpenLinkInNewTab" [isPreventDefaultForRouterLink]="true"
                            [href]="getFooterItemLink(footerItem)"
                            [attr.target]="footerItem.IsOpenLinkInNewTab ? '_blank' : ''" title=""
                            [ngClass]="footerItem.IsHighlight ? 'help-service' : ''" class="footerLink" appGtm
                            [data]="{category: 'New website', action: 'Footer link click', label: footerItem.Name}"
                            [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}"
                            listen="onclick">{{ footerItem.Name }}</a>
                    </li>
                </ul>
                <div class="show-important" [ngClass]="showImportant? 'show-important-open' : ''">
                    <span title="" (click)="showImportant = !showImportant" class="show-button" appGtm
                        [active]="showImportant"
                        [data]="{category: 'New website', action: 'Footer link click', label: 'Open responsive bar'}"
                        [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}"
                        listen="onclick">תראה לי עוד דברים שחשובים</span>
                    <ul *ngFor="let footerColumn of footerColumns | slice:1:3">
                        <li *ngFor="let footerItem of footerColumn.ColumnItems">
                            <a appLink [anchorLink]="getFooterItemLink(footerItem)"
                                [isOpenLinkInNewTab]="footerItem.IsOpenLinkInNewTab"
                                [isPreventDefaultForRouterLink]="true" [href]="getFooterItemLink(footerItem)"
                                [attr.target]="footerItem.IsOpenLinkInNewTab ? '_blank' : ''" title=""
                                class="footerLink" appGtm
                                [data]="{category: 'New website', action: 'Footer link click', label: footerItem.Name}"
                                [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}"
                                listen="onclick">{{ footerItem.Name }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!--<p *ngIf="isMobile" class="no-application-message">אין לך את האפליקציה שלנו?</p>-->
            <div class="social-networks">

                <div class="store-wrapper">
                    <a href="https://play.google.com/store/apps/details?id=com.ideomobile.leumicard" title=""
                       aria-label="google play" class="google-play " target="_blank" appGtm
                       [data]="{category: 'New website', action: 'Footer link click', label: 'GooglePlay'}"
                       [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}"
                       listen="onclick"></a>
                    <a href="https://itunes.apple.com/us/app/l-wmy-q-rd-rnq+-leumi-card/id398915363?mt=8" title=""
                       aria-label="app store" class="app-store" target="_blank" appGtm
                       [data]="{category: 'New website', action: 'Footer link click', label: 'AppStore'}"
                       [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}"
                       listen="onclick"></a>
                </div>
                <ul class="share-links">
                    <li *ngFor="let social of socialNetworks">
                        <a href="{{social.href}}" title="" class="{{social.class}}" [attr.aria-label]="social.class"
                           target="_blank" appGtm
                           [data]="{category: 'New website', action: 'Footer link click', label: social.class}"
                           [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}"
                           listen="onclick"></a>
                    </li>
                </ul>
            </div>
            <carousel (activeSlideChange)="detectChanges()" *ngIf="legalMessages?.length !== 0" [interval]="5000"
                [noPause]="true" [showIndicators]="false">
                <div class="tarifon-change">
                    <slide *ngFor="let message of legalMessages;let i = index">
                        <p class="update" [ngClass]="{'no-animation' : legalMessages.length === 1}">{{
                            message.MessageText }}, <a appLink [anchorLink]="message.MessageLink"
                                [isOpenLinkInNewTab]="message.IsOpenLinkInNewTab" [isPreventDefaultForRouterLink]="true"
                                [href]="message.MessageLink" [attr.target]="message.IsOpenLinkInNewTab ? '_blank' : ''"
                                title="" class="default-link target-link-bold footerLink" appGtm
                                [data]="{category: 'New website', action: 'Footer link click', label: message.MessageText}"
                                [configuration]="{registered: ' - registered', anonymous: ' - anonymous', before: false}"
                                listen="onclick">{{ message.MessageLinkText }}</a></p>
                    </slide>
                </div>
            </carousel>
        </section>
    </div>
</footer>