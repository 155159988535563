import { Directive, Input, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { UiHelperService } from '../services/ui-helper.service';

@Directive({
    selector: '[appTooltip]'
})
export class TooltipDirective {
    @Input()
    tooltipText: string;
    @Input()
    tooltipHtmlParent: string;
    @Input()
    tooltipInnerHtml: boolean;
    tooltip: HTMLElement;
    offset = 10;

    constructor(private el: ElementRef, private renderer: Renderer2, private uiHelper: UiHelperService) {}

    @HostListener('mouseenter')
    onMouseEnter(): void {
        this.show();
    }

    @HostListener('click', ['$event'])
    click(event): void {
        if (this.uiHelper.IsMobile(768)) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    show(): any {
        this.createElement();
        this.setStyle();
    }

    @HostListener('mouseleave')
    onMouseLeave(): void {
        this.hide();
    }

    hide(): any {
        window.setTimeout(() => {
            this.renderer.removeChild(document.body, this.tooltip);
            this.tooltip = null;
        });
    }

    setStyle(): void {
        // this.renderer.addClass(this.tooltip, 'tool-tip');
    }

    createElement(): void {
        this.tooltip = this.renderer.createElement('div');
        this.renderer.addClass(this.tooltip, 'tool-tip');

        const tooltipTextEl: HTMLElement = this.renderer.createElement('p');
        this.renderer.addClass(tooltipTextEl, 'tool-tip-text');
        if (this.tooltipInnerHtml) {
            this.renderer.setProperty(tooltipTextEl, 'innerHTML', this.tooltipText);
        } else {
            this.renderer.appendChild(tooltipTextEl, this.renderer.createText(this.tooltipText));
        }
        this.renderer.appendChild(this.tooltip, tooltipTextEl);

        this.renderer.appendChild(document.getElementById(this.tooltipHtmlParent), this.tooltip);
    }
}
